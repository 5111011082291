import { ChatAlt2Icon } from '@heroicons/react/solid'

const obj = {
	title: 'Human Resource',
	desc: 'Chat with our Human Resource AI',
	category: 'Advisor',
	Icon: ChatAlt2Icon,
	permissions: ['user'],
	fromColor: 'gray-500',
	to: '/ai/advisors/human-resource',
	api: '/ai/advisors/human-resource',
	welcomeMessage: `I can assist you with a wide range of topics related to human resources in the behavioral health, mental health, and addiction treatment industry. Some areas I can help with include:

	1. Recruitment and hiring processes in the industry.
	2. Employee onboarding and orientation.
	3. Performance management and employee evaluations.
	4. Employee training and development.
	5. Compensation and benefits.
	6. Employee relations and conflict resolution.
	7. Compliance with employment laws and regulations.
	8. HR policies and procedures.
	9. Employee engagement and retention strategies.
	10. Succession planning and career development.
	
	If you have any questions or need assistance with any of these topics, feel free to ask!`,
}

export default obj
