import React, { Component } from "react";
import {
  BanIcon,
  DatabaseIcon,
  PencilIcon,
  MenuAlt1Icon,
  LightBulbIcon,
  SparklesIcon,
  UserCircleIcon,
} from "@heroicons/react/solid";
import Logo from "./Logo";
import config from "./config";
import { observer, inject } from "mobx-react";

@inject("store")
@observer
class Pricing extends Component {

  render() {
    return (
      <>
        {this.props.store.profile.status ? null : (
          <div className="border-b border-gray-300 bg-white shadow-sm ">
            <div className="container flex mx-auto px-4 md:px-28 flex select-none">
              <div className="relative text-gray-400 focus-within:text-green-500 flex flex-1 "></div>
              <div
                onClick={this.props.store.handleLogout}
                className="cursor-pointer text-lg flex py-3 px-6 xl:py-4 xl:px-8 hover:bg-gray-100 rounded-t-md font-medium transition items-center"
              >
                <UserCircleIcon className="w-7 h-7 lg:mr-4 transition" />
                <div className="hidden lg:block"> Log Out</div>
              </div>
            </div>
          </div>
        )}

        <div className="container mx-auto px-8 py-4 lg:px-28 lg:py-12 lg:pb-64 select-none">
          {this.props.store.profile.status ? null : (
            <>
              <div className="mx-auto">
                <Logo className="w-32 h-32 inline-block" />
              </div>

              <h2 className="text-xl sm:text-2xl md:text-5xl text-gray-700 text-center">
                Start now
              </h2>
              <p className="text-lg sm:text-xl md:text-2xl text-gray-400 mb-14 mt-2 text-center">
                Pick a pricing option to begin using NavixAI
              </p>
            </>
          )}

          <Grid>
            {this.props.store.profile.status ? <Credits
              fromColor="gray-400"
              toColor="gray-600"
              baseURL={this.props.store.baseURL}
              api={this.props.store.api}
              price={10}
              credit={250}
              priceId={config.stripe.credits10}
            /> : (
              <Free
                fromColor="gray-400"
                toColor="gray-600"
                baseURL={this.props.store.baseURL}
                api={this.props.store.api}
              />
            )}
            <Entry
              fromColor="gray-400"
              toColor="gray-600"
              baseURL={this.props.store.baseURL}
              api={this.props.store.api}
            />
            <Premium
              fromColor="gray-500"
              toColor="gray-500"
              baseURL={this.props.store.baseURL}
              api={this.props.store.api}
            />
          </Grid>
        </div>
      </>
    );
  }
}

const Free = ({ fromColor, toColor, baseURL, api }) => (
  <div className="flex relative ">
    <div
      className={`bg-gray-200 flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-400 md:flex relative transform hover:scale-105  hover:text-black`}
    >
      <div className="p-8 flex-1">
        <div
          href="#"
          className={`text-${fromColor ? fromColor : "green-500"
            } block text-lg text-2xl leading-tight font-medium mb-2`}
        >
          Free
        </div>
        <div className="text-6xl text-black font-bold">
          $0<span className="text-lg text-gray-400"> free trial</span>
        </div>
        <p className="mt-4 text-lg">14-day Free Trial.</p>
        <div className="divide-y divide-dashed divide-gray-300 mt-4">
          <div className="py-2 flex  items-center">
            <DatabaseIcon
              className={`w-6 h-6 mr-2 text-${fromColor ? fromColor : "green-500"
                }`}
            />
            <div>
              <span className="font-medium text-black">250</span>
              {` x `}Credits
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <MenuAlt1Icon
              className={`w-6 h-6 mr-2 text-${fromColor ? fromColor : "green-500"
                }`}
            />
            <div>
              <span className="font-medium text-black">15,000</span>
              {` x `}Words
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <PencilIcon
              className={`w-6 h-6 mr-2 text-${fromColor ? fromColor : "green-500"
                }`}
            />
            <div>
              <span className="font-medium text-black">60,000</span>
              {` x `}Letters
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <SparklesIcon
              className={`w-6 h-6 mr-2 text-${fromColor ? fromColor : "green-500"
                }`}
            />
            <div>
              <span className="font-medium text-black">
                Access to all tools
              </span>
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <LightBulbIcon
              className={`w-6 h-6 mr-2 text-${fromColor ? fromColor : "green-500"
                }`}
            />
            <div>
              <span className="font-medium text-black">
                Early access to beta tools
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-1">
          <button
            type="button"
            onClick={async () => {
              await api.post("user/stripe/subscribe", {
                token: api.defaults.headers.common["x-access-token"],
                action: "try-out",
                priceId: config.stripe.free
              });
							window.location.reload();
            }}
            className={`mt-8 inset-0 bg-gradient-to-r from-${fromColor ? fromColor : "green-400"
              } to-${toColor ? toColor : "blue-500"
              } shadow-lg flex-1 rounded-md p-4 text-white font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter`}
          >
            Try Out
          </button>
        </div>
      </div>
    </div>
  </div>
);

const Entry = ({ fromColor, toColor, baseURL, api }) => (
  <div className="flex relative ">
    <div className={`bg-gray-200 flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-400 md:flex relative transform hover:scale-105  hover:text-black`}>
      <div className="p-8 flex-1">
        <div
          href="#"
          className={`text-${fromColor ? fromColor : "green-500"
            } block text-lg text-2xl leading-tight font-medium mb-2`}
        >
          Entry
        </div>
        <div className="text-6xl text-black font-bold">
          $10<span className="text-lg text-gray-400">/per month</span>
        </div>
        <p className="mt-4 text-lg">
          Get access to our popular AI-powered features.
        </p>
        <div className="divide-y divide-dashed divide-gray-300 mt-4">
          <div className="py-2 flex  items-center">
            <DatabaseIcon
              className={`w-6 h-6 mr-2 text-${fromColor ? fromColor : "green-500"
                }`}
            />
            <div>
              <span className="font-medium text-black">250</span>
              {` x `}Credits
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <MenuAlt1Icon
              className={`w-6 h-6 mr-2 text-${fromColor ? fromColor : "green-500"
                }`}
            />
            <div>
              <span className="font-medium text-black">15,000</span>
              {` x `}Words
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <PencilIcon
              className={`w-6 h-6 mr-2 text-${fromColor ? fromColor : "green-500"
                }`}
            />
            <div>
              <span className="font-medium text-black">60,000</span>
              {` x `}Letters
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <BanIcon className="w-6 h-6 mr-2 text-gray-400" />
            <div>
              <span className="text-gray-400">Access to all tools</span>
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <BanIcon className="w-6 h-6 mr-2 text-gray-400" />
            <div>
              <span className="text-gray-400">Early access to beta tools</span>
            </div>
          </div>
        </div>
        <div className="flex flex-1">
          <button
            type="button"
            onClick={async () => {
              const { data } = await api.post("user/stripe/subscribe", {
                token: api.defaults.headers.common["x-access-token"],
                priceId: config.stripe.entry
              });
              window.open(data.url, '_blank');
            }}
            className={`mt-8 inset-0 bg-gradient-to-r from-${fromColor ? fromColor : "green-400"
              } to-${toColor ? toColor : "blue-500"
              } shadow-lg flex-1 rounded-md p-4 text-white font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter`}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  </div>
);

const Premium = ({ fromColor, toColor, baseURL, api }) => (
  <div className="flex relative ">
    <div className={`bg-gray-200 flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-400 md:flex relative transform hover:scale-105  hover:text-black`}>
      <div className="p-8 flex-1">
        <div
          href="#"
          className={`text-${fromColor ? fromColor : "green-500"
            } block text-lg text-2xl leading-tight font-medium mb-2`}
        >
          Pro
        </div>
        <div className="text-6xl text-black font-bold">
          $25<span className="text-lg text-gray-400">/per month</span>
        </div>
        <p className="mt-4 text-lg">
          Get access to all our AI-powered features.
        </p>
        <div className="divide-y divide-dashed divide-gray-300 mt-4">
          <div className="py-2 flex  items-center">
            <DatabaseIcon
              className={`w-6 h-6 mr-2 text-${fromColor ? fromColor : "green-500"
                }`}
            />
            <div>
              <span className="font-medium text-black">750</span>
              {` x `}Credits
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <MenuAlt1Icon
              className={`w-6 h-6 mr-2 text-${fromColor ? fromColor : "green-500"
                }`}
            />
            <div>
              <span className="font-medium text-black">45,000</span>
              {` x `}Words
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <PencilIcon
              className={`w-6 h-6 mr-2 text-${fromColor ? fromColor : "green-500"
                }`}
            />
            <div>
              <span className="font-medium text-black">180,000</span>
              {` x `}Letters
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <SparklesIcon
              className={`w-6 h-6 mr-2 text-${fromColor ? fromColor : "green-500"
                }`}
            />
            <div>
              <span className="font-medium text-black">
                Access to all tools
              </span>
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <LightBulbIcon
              className={`w-6 h-6 mr-2 text-${fromColor ? fromColor : "green-500"
                }`}
            />
            <div>
              <span className="font-medium text-black">
                Early access to beta tools
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-1">
          <button
            type="button"
            onClick={async () => {
              const { data } = await api.post("user/stripe/subscribe", {
                token: api.defaults.headers.common["x-access-token"],
                priceId: config.stripe.pro
              });
              window.open(data.url, '_blank');
            }}
            className={`mt-8 inset-0 bg-gradient-to-r from-${fromColor ? fromColor : "green-400"
              } to-${toColor ? toColor : "blue-500"
              } shadow-lg flex-1 rounded-md p-4 text-white font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter`}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  </div>
);

const Credits = ({
  fromColor,
  toColor,
  baseURL,
  api,
  price,
  credit,
  priceId,
}) => (
  <div className="flex relative ">
    <div className={`bg-gray-200 flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-400 md:flex relative transform hover:scale-105  hover:text-black`}>
      <div className="p-8 flex-1">
        <div
          href="#"
          className={`text-${fromColor ? fromColor : "green-500"
            } block text-lg text-2xl leading-tight font-medium mb-2`}
        >
          Entry
        </div>
        <div className="text-6xl text-black font-bold">
          ${price}
          <span className="text-lg text-gray-400">/{credit} credits</span>
        </div>
        <p className="mt-4 text-lg">
          Get additional credits to access our popular AI-powered features.
        </p>
        <div className="divide-y divide-dashed divide-gray-300 mt-4">
          <div className="py-2 flex  items-center">
            <DatabaseIcon
              className={`w-6 h-6 mr-2 text-${fromColor ? fromColor : "green-500"
                }`}
            />
            <div>
              <span className="font-medium text-black">{credit}</span>
              {` x `}Credits
            </div>
          </div>
        </div>
        <div className="flex flex-1">
          <button
            type="button"
            onClick={async () => {
              const { data } = await api.post("user/stripe/credits", {
                token: api.defaults.headers.common["x-access-token"],
                priceId: priceId
              });
              window.open(data.url, '_blank');
            }}

            className={`mt-8 inset-0 bg-gradient-to-r from-${fromColor ? fromColor : "green-400"
              } to-${toColor ? toColor : "blue-500"
              } shadow-lg flex-1 rounded-md p-4 text-white font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter`}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  </div>
);

const Grid = ({ children }) => (
  <div className="grid grid-cols-1 gap-12 mt-4 xl:grid-cols-3 ">{children}</div>
);

export default Pricing;
