import { Component } from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { observable, makeObservable } from 'mobx'
import { observer, inject } from 'mobx-react'
import {
	MenuIcon,
	PlusSmIcon,
	ChatIcon,
	PencilAltIcon,
	TrashIcon,
	CheckIcon,
	XIcon,
	ClipboardIcon,
	UploadIcon,
	PaperAirplaneIcon,
	RefreshIcon,
	DotsVerticalIcon,
	ExclamationIcon,
} from '@heroicons/react/outline'
import { ThumbUpIcon, ThumbDownIcon } from '@heroicons/react/solid'
import TextareaAutosize from 'react-textarea-autosize'
import toast from 'react-hot-toast'
import Select from 'react-select'
import Header from '../Components/Header'

@inject('store')
@observer
class Chat extends Component {
	@observable tool = {}
	@observable profile = {}
	@observable advisors = []
	@observable isOpenLeftMenu = false
	@observable isOpenRightMenu = false
	@observable deleteType = null
	@observable isNewChat = true
	@observable loadingChats = false
	@observable chats = []
	@observable chatId = null
	@observable chatName = null
	@observable editChatId = null
	@observable isEditing = false
	@observable isUpdating = false
	@observable deleteChatId = null
	@observable isDeleting = false
	@observable loadingMessages = false
	@observable messages = []
	@observable uploadingFile = false
	@observable allowedFiles = [
		'text/plain',
		'application/pdf',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	]
	@observable loadingOutput = false
	@observable inputValue = ''
	@observable selectedLibrary = 'resource-library'
	@observable files = []
	@observable loadingFiles = false
	@observable selectedFileIds = []
	@observable showDropdownMenu = false
	@observable dropdownMenuId = null
	@observable filename = null
	@observable editFileId = null
	@observable deleteFileId = null
	@observable showDeleteModal = false
	@observable showRenameModal = false

	constructor(props) {
		super(props)

		makeObservable(this)

		this.tool = this.props.store.getToolByUrl(this.props.location.pathname)
		this.profile = JSON.parse(localStorage.getItem('profile'))

		if (!this.tool) {
			window.location.href = '/'
		} else {
			if (this.tool.title == 'Advisor') {
				this.tool.title = 'Behavioral Health'
				this.tool.desc = 'Chat with our Behavioral Health AI'
			}
		}

		this.loadAdvisors()
		this.loadChats()
		this.loadFiles(this.selectedLibrary)
	}

	loadAdvisors = async () => {
		try {
			const url = this.tool.api.split('/')

			url.pop()

			const response = await this.props.store.api.get(url.join('/'))

			const data = response.data

			if (data.success) {
				this.advisors = data.result.advisors.map((advisor) => {
					url.push(advisor.slug)

					const advisor_url = url.join('/')

					url.pop()

					return {
						value: advisor_url,
						label: advisor.name,
					}
				})
			} else {
				throw new Error('An error occurred')
			}
		} catch (error) {
			toast.error(error.message)
		}
	}

	loadChats = async () => {
		this.loadingChats = true

		try {
			const response = await this.props.store.api.get(
				`${this.tool.api}/chats`
			)

			const data = response.data

			if (data.success) {
				this.chats = data.result.chats.map((chat) => {
					return chat
				})
			} else {
				throw new Error('An error occurred')
			}
		} catch (error) {
			toast.error(error.message)
		} finally {
			this.loadingChats = false
		}
	}

	loadFiles = async (selectedLibrary) => {
		this.loadingFiles = true

		try {
			const response = await this.props.store.api.get(
				`${this.tool.api}/files`,
				{
					params: { selectedLibrary },
				}
			)

			const data = response.data

			if (data.success) {
				this.files = data.result.files
			} else {
				throw new Error('An error occurred')
			}
		} catch (error) {
			toast.error(error.message)
		} finally {
			this.loadingFiles = false
		}
	}

	handleChangeAdvisor = (selected) => {
		window.location = selected.value
	}

	onClickNewChat = () => {
		this.isOpenLeftMenu = false
		this.isOpenRightMenu = false
		this.isNewChat = true
		this.chatId = null
		this.isEditing = false
		this.messages = []
		this.loadingOutput = false
		this.inputValue = ''
		this.selectedFileIds = []
		this.scrollToTop()
		this.focusInputValue()
	}

	onClickChat = async (chatId) => {
		this.isOpenLeftMenu = false
		this.isOpenRightMenu = false
		this.isNewChat = false
		this.isEditing = false
		this.loadingMessages = true
		this.messages = []
		this.loadingOutput = false
		this.inputValue = ''
		this.selectedFileIds = []
		this.focusInputValue()

		try {
			const response = await this.props.store.api.get(
				`${this.tool.api}/chats/${chatId}/messages`
			)

			const data = response.data
			const result = data.result

			if (data.success) {
				this.chatId = result.chatId
				this.messages = result.messages

				this.scrollToBottom()
			} else {
				throw new Error('An error occurred')
			}
		} catch (error) {
			toast.error(error.message)
		} finally {
			this.loadingMessages = false
		}
	}

	onRenameChat = async (e) => {
		e.preventDefault()

		this.isUpdating = true

		try {
			const response = await this.props.store.api.put(
				`${this.tool.api}/chats/${this.editChatId}`,
				{
					chatName: this.chatName,
				}
			)

			const data = response.data

			if (data.success) {
				this.editChatId = null

				this.updateChatLists(data.result.chat)

				toast.success('Successfully updated chat name.')
			} else {
				throw new Error('An error occurred')
			}
		} catch (error) {
			toast.error(error.message)
		} finally {
			this.isEditing = false
			this.isUpdating = false
		}
	}

	onDeleteChat = async (chatId) => {
		this.isNewChat = true
		this.isDeleting = true
		this.inputValue = ''

		try {
			const response = await this.props.store.api.delete(
				`${this.tool.api}/chats/${chatId}`
			)

			const data = response.data

			if (data.success) {
				this.deleteChatId = null

				this.deleteChat(chatId)

				if (chatId == this.chatId) {
					this.chatId = null
					this.messages = []
					this.selectedFileIds = []
				}

				toast.success('Successfully deleted chat.')
			} else {
				throw new Error('An error occurred')
			}
		} catch (error) {
			toast.error(error.message)
		} finally {
			this.isDeleting = false
			this.setShowDeleteModal(false)
		}
	}

	onCopyOutput = async (message) => {
		try {
			const humanloop_data_id = message.humanloop_data_id

			const copyOutput = async () => {
				await navigator.clipboard.writeText(message.content)

				toast.success('Successfully copied to clipboard.')
			}

			if (humanloop_data_id) {
				const response = await this.props.store.api.post(
					`${this.tool.api}/chats/message/copy`,
					{
						humanloop_data_id,
					}
				)

				const data = response.data

				if (data.success) {
					copyOutput()
				} else {
					throw new Error('An error occurred')
				}
			} else {
				copyOutput()
			}
		} catch (error) {
			toast.error(error.message)
		}
	}

	onSubmitRating = async (message, rating) => {
		try {
			const messageId = message._id
			const humanloop_data_id = message.humanloop_data_id

			const response = await this.props.store.api.post(
				`${this.tool.api}/chats/messages/rating`,
				{
					messageId,
					humanloop_data_id,
					rating,
				}
			)

			const data = response.data

			if (data.success) {
				this.messages = this.messages.map((message) => {
					if (
						message._id == messageId ||
						message.humanloop_data_id == humanloop_data_id
					) {
						message.rating = rating
					}

					return message
				})
			} else {
				throw new Error('An error occurred')
			}
		} catch (error) {
			toast.error(error.message)
		}
	}

	onSelectFile = () => {
		document.getElementById('document').click()
	}

	onUploadFile = async (e) => {
		this.uploadingFile = true

		try {
			const file = e.target.files[0]
			const maxSize = 1024 * 1024 * 50 // 50MB in bytes

			if (this.allowedFiles.includes(file.type)) {
				if (file.size < maxSize) {
					const formData = new FormData()

					formData.append('file', file)

					const response = await this.props.store.api.post(
						`${this.tool.api}/files`,
						formData,
						{
							headers: {
								'Content-Type': 'multipart/form-data',
							},
						}
					)

					const data = response.data

					if (data.success) {
						const file = data.result.file

						this.selectedFileIds = []

						if (
							(this.profile.accountType == 'admin' &&
								this.selectedLibrary == 'resource-library') ||
							this.selectedLibrary == 'my-library'
						) {
							this.files.unshift(file)
						}

						toast.success('Successfully uploaded file.')
					} else {
						throw new Error('An error occurred')
					}
				} else {
					throw new Error('File size exceeds the 50MB limit.')
				}
			} else {
				throw new Error('File type not allowed')
			}
		} catch (error) {
			toast.error(error.message)
		} finally {
			this.uploadingFile = false

			e.target.value = null
		}
	}

	onSubmitContent = async (e) => {
		e.preventDefault()

		const input = this.inputValue.trim()

		try {
			if (input != '') {
				this.isNewChat = false
				this.loadingOutput = true

				const userMessage = {
					role: 'user',
					content: input,
				}

				this.messages.push(userMessage)

				const response = await this.props.store.api.post(
					this.tool.api,
					{
						chatId: this.chatId,
						message: userMessage,
						selectedFileIds: this.selectedFileIds,
					}
				)

				const data = response.data
				const result = data.result

				if (data.success) {
					const chat = result.chat
					const chatId = chat._id
					const assistantMessage = {
						role: 'assistant',
						content: data.output,
						humanloop_data_id: data.feedbackId,
					}

					this.updateChatLists(chat)

					this.chatId = chatId
					this.messages.push(assistantMessage)
					this.inputValue = ''

					this.scrollToBottom()
				} else {
					throw new Error('An error occurred')
				}
			} else {
				throw new Error('Message is required.')
			}
		} catch (error) {
			toast.error(error.message)

			if (input != '') {
				this.messages.pop()
			}
		} finally {
			this.loadingOutput = false
		}
	}

	onSelectLibrary = (selectedLibrary) => {
		this.selectedLibrary = selectedLibrary
		this.selectedFileIds = []

		this.files = []
		this.loadFiles(selectedLibrary)
	}

	onSelectFileIds = (checked, fileId) => {
		if (checked) {
			this.selectedFileIds.push(fileId)
		} else {
			this.selectedFileIds = this.selectedFileIds.filter(
				(currentFileId) => currentFileId != fileId
			)
		}
	}

	onRenameFile = async (fileId) => {
		this.isUpdating = true

		try {
			const response = await this.props.store.api.put(
				`${this.tool.api}/files/${fileId}`,
				{
					displayName: this.filename,
				}
			)

			const data = response.data

			if (data.success) {
				this.editFileId = null

				this.files = this.files.map((file) => {
					if (file._id == fileId) {
						file.displayName = this.filename
					}

					return file
				})

				toast.success('Successfully updated filename.')
			}
		} catch (error) {
			toast.error(error.message)
		} finally {
			this.isUpdating = false
			this.setShowRenameModal(false)
		}
	}

	onDeleteFile = async (fileId) => {
		this.isDeleting = true

		try {
			const response = await this.props.store.api.delete(
				`${this.tool.api}/files/${fileId}`
			)

			const data = response.data

			if (data.success) {
				this.deleteFileId = null
				this.selectedFileIds = []

				this.files = this.files.filter((file) => file._id != fileId)

				toast.success('Successfully deleted file.')
			} else {
				throw new Error('An error occurred')
			}
		} catch (error) {
			toast.error(error.message)
		} finally {
			this.isDeleting = false
			this.setShowDeleteModal(false)
		}
	}

	sanitizeOutput = (output) => {
		let content = {
			__html: output.replaceAll(/\r?\n/g, '<br>'),
		}

		return content
	}

	scrollTo = (type) => {
		const messagesEl = document.getElementById('messages')
		let position = 0

		if (type == 'bottom') {
			position = messagesEl.scrollHeight
		}

		messagesEl.scrollTop = position
	}

	scrollToTop = () => {
		this.scrollTo('top')
	}

	scrollToBottom = () => {
		this.scrollTo('bottom')
	}

	focusInputValue = () => {
		document.getElementById('inputValue').focus()
	}

	updateChatLists = (chat) => {
		const hasChatToday = this.chats.some((row) => row.group == 'Today')

		if (!hasChatToday) {
			this.chats.unshift({
				group: 'Today',
				chats: [chat],
			})
		} else {
			this.chats[0].chats.unshift(chat)
		}

		this.deleteChat(chat._id, true)
	}

	deleteChat = (chatId, exceptUpdated = false) => {
		this.chats = this.chats.filter((row) => {
			row.chats = row.chats.filter(({ _id }, index) => {
				if (
					!exceptUpdated ||
					(exceptUpdated && (index != 0 || row.group != 'Today'))
				) {
					return _id != chatId
				}

				return true
			})

			return row.chats.length > 0
		})
	}

	setShowDeleteModal = (value) => {
		this.showDeleteModal = value
	}

	setShowRenameModal = (value) => {
		this.showRenameModal = value
	}

	render() {
		return (
			<>
				<Helmet>
					<title>{`${this.tool.title} - NavixAI`}</title>
				</Helmet>

				<Header
					title={this.tool.title}
					desc={this.tool.desc}
					Icon={this.tool.Icon}
					fromColor={this.tool.fromColor}
					category={this.tool.category}
				/>

				<div className="px-4 pt-4 bg-gray-100">
					<div className="flex md:hidden justify-between mb-2">
						<button
							type="button"
							className="inline-flex"
							onClick={() => {
								this.isOpenLeftMenu = true
							}}>
							<MenuIcon className="h-6 w-6" />
						</button>

						<button
							type="button"
							className="inline-flex"
							onClick={() => {
								this.isOpenRightMenu = true
							}}>
							<MenuIcon className="h-6 w-6" />
						</button>
					</div>

					<div
						className="flex"
						style={{ height: 'calc(100vh - 223px)' }}>
						<div
							className={`${
								this.isOpenLeftMenu
									? 'absolute top-0 left-0 z-40 h-full'
									: 'hidden'
							} w-72 md:block bg-white p-4 overflow-y-auto space-y-2`}>
							<div className="space-y-2">
								<h3 className="font-medium">Select Advisor</h3>

								<Select
									onChange={this.handleChangeAdvisor}
									options={this.advisors}
								/>
							</div>

							<button
								type="button"
								className="w-full bg-gray-200 hover:bg-gray-300 font-medium text-sm p-2 inline-flex items-center border border-black rounded"
								onClick={this.onClickNewChat}>
								<PlusSmIcon className="h-6 w-6 mr-1" />
								New Chat
							</button>

							{this.chats &&
								this.chats.map((row, groupIndex) => (
									<div key={groupIndex} className="space-y-2">
										<h3 className="font-medium">
											{row.group}
										</h3>

										{row.chats.map((chat, chatIndex) => (
											<div
												key={chatIndex}
												className={`${
													chat._id == this.chatId
														? 'bg-gray-300'
														: 'bg-gray-200'
												} hover:bg-gray-300 border border-gray-300 rounded`}>
												{this.isEditing &&
												chat._id == this.editChatId ? (
													<form
														className="w-full inline-flex"
														onSubmit={
															this.onRenameChat
														}>
														<input
															type="text"
															className="bg-gray-200 border border-gray-800 text-sm block w-full p-1"
															value={
																this.chatName
															}
															onChange={(e) => {
																this.chatName =
																	e.target.value
															}}
															autoFocus={true}
														/>

														<button
															type="submit"
															className={`font-medium text-sm p-2 ${
																this.isUpdating
																	? 'text-gray-400'
																	: 'text-gray-500 hover:text-gray-600'
															}`}
															disabled={
																this.isUpdating
															}>
															{this.isUpdating ? (
																<RefreshIcon className="h-5 w-5 animate-spin" />
															) : (
																<CheckIcon className="h-5 w-5" />
															)}
														</button>

														<button
															type="button"
															className={`font-medium text-sm p-2 ${
																this.isUpdating
																	? 'text-gray-400'
																	: 'text-gray-500 hover:text-gray-600'
															}`}
															disabled={
																this.isUpdating
															}
															onClick={() => {
																this.chatName =
																	null
																this.editChatId =
																	null
																this.isEditing = false
															}}>
															<XIcon className="h-5 w-5" />
														</button>
													</form>
												) : (
													<div className="relative">
														<div className="h-9">
															<div
																className="absolute top-2 left-2 cursor-pointer"
																onClick={() => {
																	this.onClickChat(
																		chat._id
																	)
																}}>
																<ChatIcon className="h-5 w-5" />
															</div>

															<a
																href="#"
																className="font-medium text-sm p-2 pl-9 inline-flex items-start truncate w-full"
																title={
																	chat.name
																}
																onClick={(
																	e
																) => {
																	e.preventDefault()

																	this.onClickChat(
																		chat._id
																	)
																}}>
																{chat.name}
															</a>
														</div>

														{chat._id ==
															this.chatId && (
															<div className="absolute top-0 right-0 bg-gray-300">
																<button
																	type="button"
																	className="font-medium text-sm p-2 text-gray-500 hover:text-gray-600"
																	onClick={() => {
																		this.chatName =
																			chat.name
																		this.editChatId =
																			chat._id
																		this.isEditing = true
																	}}>
																	<PencilAltIcon className="h-5 w-5" />
																</button>

																<button
																	type="button"
																	className="font-medium text-sm p-2 text-gray-500 hover:text-gray-600"
																	onClick={() => {
																		this.deleteType =
																			'chat'
																		this.deleteChatId =
																			chat._id
																		this.setShowDeleteModal(
																			true
																		)
																	}}>
																	<TrashIcon className="h-5 w-5" />
																</button>
															</div>
														)}
													</div>
												)}
											</div>
										))}
									</div>
								))}

							{this.loadingChats && (
								<div className="animate-pulse">
									<div className="mb-2">
										<div className="h-6 w-24 bg-gray-300 rounded-full"></div>
									</div>

									<div className="space-y-2">
										<div className="h-8 bg-gray-300 rounded"></div>
										<div className="h-8 bg-gray-300 rounded"></div>
										<div className="h-8 bg-gray-300 rounded"></div>
									</div>
								</div>
							)}
						</div>

						<div className="flex flex-col md:mx-4 bg-white w-full text-sm">
							<div
								id="messages"
								className="h-full overflow-y-auto">
								{this.isNewChat ? (
									<div className="p-8">
										<h1 className="text-xl font-bold mb-4">
											What can you help me with?
										</h1>

										<p
											dangerouslySetInnerHTML={this.sanitizeOutput(
												this.tool.welcomeMessage
											)}></p>
									</div>
								) : (
									<>
										{this.messages &&
											this.messages.map(
												(message, index) => (
													<div key={index}>
														{message.role ==
														'user' ? (
															<div className="bg-gray-100 px-8 py-3 border-t-2 border-b-2 border-gray-200 flex">
																<div>
																	<span className="bg-gray-800 inline-flex items-center justify-center h-8 w-8 rounded-full">
																		<span className="text-s text-white leading-none">
																			{this.profile.fname
																				.toUpperCase()
																				.charAt(
																					0
																				)}
																		</span>
																	</span>
																</div>

																<div className="ml-2">
																	{
																		message.content
																	}
																</div>
															</div>
														) : (
															<div className="px-8 py-3">
																<div className="flex justify-end mb-3">
																	<ClipboardIcon
																		className="h-6 w-6 inline cursor-pointer text-gray-500"
																		onClick={() => {
																			this.onCopyOutput(
																				message
																			)
																		}}
																	/>

																	<ThumbUpIcon
																		className={`h-6 w-6 inline cursor-pointer ${
																			message.rating ==
																			'good'
																				? 'text-green-500'
																				: 'text-gray-500 hover:text-green-500'
																		}`}
																		onClick={() => {
																			this.onSubmitRating(
																				message,
																				'good'
																			)
																		}}
																	/>

																	<ThumbDownIcon
																		className={`h-6 w-6 inline cursor-pointer ${
																			message.rating ==
																			'bad'
																				? 'text-red-500'
																				: 'text-gray-500 hover:text-red-500'
																		}`}
																		onClick={() => {
																			this.onSubmitRating(
																				message,
																				'bad'
																			)
																		}}
																	/>
																</div>

																<div>
																	<p
																		dangerouslySetInnerHTML={this.sanitizeOutput(
																			message.content
																		)}></p>
																</div>
															</div>
														)}
													</div>
												)
											)}

										{(this.loadingMessages ||
											this.loadingOutput) && (
											<div className="animate-pulse">
												{this.loadingMessages && (
													<div className="bg-gray-100 px-8 py-3 border-t-2 border-b-2 border-gray-200 flex">
														<div>
															<span className="bg-gray-300 inline-flex items-center justify-center h-8 w-8 rounded-full"></span>
														</div>

														<div className="ml-2 w-full">
															<div className="h-4 bg-gray-300 rounded-full"></div>
														</div>
													</div>
												)}

												<div className="px-8 py-3">
													<div className="flex justify-end mb-3">
														<div className="h-6 w-24 bg-gray-300 rounded-full"></div>
													</div>

													<div className="space-y-3">
														<div className="h-4 bg-gray-300 rounded-full"></div>
														<div className="h-4 bg-gray-300 rounded-full"></div>
														<div className="h-4 bg-gray-300 rounded-full"></div>
													</div>
												</div>
											</div>
										)}
									</>
								)}
							</div>

							<div className="h-30">
								<form onSubmit={this.onSubmitContent}>
									<div className="flex items-center border-2 rounded-md m-4 shadow">
										<TextareaAutosize
											id="inputValue"
											minRows={3}
											maxRows={5}
											autoFocus={true}
											className="resize-none w-full block p-2 leading-4 outline-none"
											placeholder="Write a message..."
											value={this.inputValue}
											onChange={(e) => {
												this.inputValue = e.target.value
											}}
											onKeyDown={(e) => {
												if (
													('New Line',
													e.key == 'Enter' &&
														!e.shiftKey)
												) {
													this.onSubmitContent(e)
												}
											}}
										/>

										<input
											type="file"
											id="document"
											className="hidden"
											accept={this.allowedFiles.join(',')}
											onChange={this.onUploadFile}
										/>

										<button
											type="button"
											className={`${
												this.uploadingFile
													? 'text-gray-400'
													: 'text-gray-500 hover:text-gray-600'
											}`}
											disabled={this.uploadingFile}
											onClick={this.onSelectFile}>
											{this.uploadingFile ? (
												<RefreshIcon className="h-6 w-6 inline animate-spin" />
											) : (
												<UploadIcon className="h-6 w-6 inline" />
											)}
										</button>

										<button
											type="submit"
											className={`h-8 w-10 mx-2 ${
												this.loadingOutput
													? 'text-gray-400'
													: 'text-gray-500 hover:text-gray-600'
											} align-middle`}
											disabled={this.loadingOutput}>
											{this.loadingOutput ? (
												<RefreshIcon className="h-6 w-6 inline animate-spin" />
											) : (
												<PaperAirplaneIcon className="h-6 w-6 rotate-90 inline" />
											)}
										</button>
									</div>
								</form>
							</div>
						</div>

						<div
							className={`${
								this.isOpenRightMenu
									? 'absolute top-0 right-0 z-40 h-full'
									: 'hidden'
							} w-96 md:block bg-white p-4 overflow-y-auto space-y-2`}>
							<div
								className="w-full inline-flex rounded shadow-sm"
								role="group">
								<button
									type="button"
									className={`w-1/2 ${
										this.selectedLibrary ==
										'resource-library'
											? 'bg-gray-300'
											: 'bg-gray-200'
									} hover:bg-gray-300 font-medium text-sm p-2 border border-gray-300 rounded-l`}
									onClick={() => {
										this.onSelectLibrary('resource-library')
									}}>
									Resource Library
								</button>

								<button
									type="button"
									className={`w-1/2 ${
										this.selectedLibrary == 'my-library'
											? 'bg-gray-300'
											: 'bg-gray-200'
									} hover:bg-gray-300 font-medium text-sm p-2 border border-gray-300 rounded-r`}
									onClick={() => {
										this.onSelectLibrary('my-library')
									}}>
									My Library
								</button>
							</div>

							<div className="space-y-2">
								{this.files &&
									this.files.map((file) => (
										<div
											key={file._id}
											className="flex items-center">
											<div className="mr-2">
												<input
													type="checkbox"
													id={`file-${file._id}`}
													className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer"
													checked={this.selectedFileIds.includes(
														file._id
													)}
													onChange={(e) => {
														this.onSelectFileIds(
															e.target.checked,
															file._id
														)
													}}
												/>
											</div>

											<div className="flex-col w-full">
												<div className="flex">
													<div className="w-full">
														<label
															htmlFor={`file-${file._id}`}
															className="text-sm font-medium cursor-pointer break-all"
															title={
																file.displayName
															}>
															{file.displayName}
														</label>
													</div>

													{(this.selectedLibrary ==
														'my-library' ||
														this.profile
															.accountType ==
															'admin') && (
														<div className="ml-2 relative">
															<button
																type="button"
																className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100"
																onClick={() => {
																	this.showDropdownMenu =
																		!this
																			.showDropdownMenu
																	this.dropdownMenuId =
																		file._id
																}}>
																<DotsVerticalIcon className="w-4 h-4" />
															</button>

															{this
																.showDropdownMenu &&
																this
																	.dropdownMenuId ==
																	file._id && (
																	<div>
																		<div
																			className="fixed inset-0 z-10"
																			onClick={() => {
																				this.showDropdownMenu = false
																			}}></div>

																		<div className="absolute right-0 z-20 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
																			<ul className="py-2 text-sm text-gray-700">
																				<li>
																					<a
																						href="#"
																						className="block px-4 py-2 hover:bg-gray-100"
																						onClick={(
																							e
																						) => {
																							e.preventDefault()

																							this.showDropdownMenu = false
																							this.filename =
																								file.displayName
																							this.editFileId =
																								file._id
																							this.setShowRenameModal(
																								true
																							)
																						}}>
																						Rename
																					</a>
																				</li>
																				<li>
																					<a
																						href="#"
																						className="block px-4 py-2 hover:bg-gray-100"
																						onClick={(
																							e
																						) => {
																							e.preventDefault()

																							this.showDropdownMenu = false
																							this.deleteType =
																								'file'
																							this.deleteFileId =
																								file._id
																							this.setShowDeleteModal(
																								true
																							)
																						}}>
																						Delete
																					</a>
																				</li>
																			</ul>
																		</div>
																	</div>
																)}
														</div>
													)}
												</div>

												<div className="flex justify-between">
													<small className="text-gray-500">
														{file.size}
													</small>

													<small
														className="text-gray-500"
														title={file.createdAt}>
														{file.createdAtFromNow}
													</small>
												</div>
											</div>
										</div>
									))}
							</div>

							{this.loadingFiles && (
								<div className="animate-pulse">
									<div className="space-y-2">
										<div className="h-6 bg-gray-300 rounded"></div>
										<div className="h-6 bg-gray-300 rounded"></div>
										<div className="h-6 bg-gray-300 rounded"></div>
									</div>
								</div>
							)}
						</div>

						{(this.isOpenLeftMenu || this.isOpenRightMenu) && (
							<div
								className="bg-gray-900 bg-opacity-50 fixed inset-0 z-30"
								onClick={() => {
									this.isOpenLeftMenu = false
									this.isOpenRightMenu = false
								}}></div>
						)}
					</div>
				</div>

				{this.showDeleteModal && (
					<DeleteModal
						setShowModal={this.setShowDeleteModal}
						disableButtons={this.isDeleting}
						handleDelete={() => {
							switch (this.deleteType) {
								case 'chat':
									this.onDeleteChat(this.deleteChatId)
									break
								case 'file':
									this.onDeleteFile(this.deleteFileId)
									break
							}
						}}
						handleCancel={() => {
							this.deleteChatId = null
							this.deleteFileId = null

							this.setShowDeleteModal(false)
						}}
					/>
				)}

				{this.showRenameModal && (
					<RenameModal
						setShowModal={this.setShowRenameModal}
						value={this.filename}
						disableButtons={this.isUpdating}
						handleValue={(e) => {
							this.filename = e.target.value
						}}
						handleRename={() => {
							this.onRenameFile(this.editFileId)
						}}
						handleCancel={() => {
							this.filename = null
							this.editFileId = null

							this.setShowRenameModal(false)
						}}
					/>
				)}
			</>
		)
	}
}

const DeleteModal = ({
	setShowModal,
	disableButtons,
	handleDelete,
	handleCancel,
}) => (
	<Modal
		setShowModal={setShowModal}
		disableButtons={disableButtons}
		handleCancel={handleCancel}>
		<ExclamationIcon className="h-14 w-14 mx-auto text-red-500" />

		<h3 className="mb-5 text-lg font-normal">
			Are you sure you want to delete?
		</h3>

		<button
			type="button"
			className="hover:text-white border border-red-200 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
			disabled={disableButtons}
			onClick={handleDelete}>
			{disableButtons && <RefreshIcon className="h-3 w-3 animate-spin" />}
			Yes, I'm sure
		</button>
	</Modal>
)

const RenameModal = ({
	setShowModal,
	value,
	disableButtons,
	handleValue,
	handleRename,
	handleCancel,
}) => (
	<Modal
		title={'Rename'}
		setShowModal={setShowModal}
		disableButtons={disableButtons}
		handleCancel={handleCancel}>
		<div className="mb-4">
			<input
				type="text"
				className="bg-gray-50 border border-gray-300 text-sm block w-full p-1"
				value={value}
				onChange={handleValue}
				autoFocus={true}
			/>
		</div>

		<button
			type="button"
			className="hover:text-white border border-gray-200 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
			disabled={disableButtons}
			onClick={handleRename}>
			{disableButtons && <RefreshIcon className="h-3 w-3 animate-spin" />}
			Save
		</button>
	</Modal>
)

const Modal = ({
	children,
	title,
	setShowModal,
	disableButtons,
	showCancelButton = true,
	handleCancel = null,
}) => {
	if (handleCancel == null) {
		handleCancel = () => setShowModal(false)
	}

	return (
		<div className="flex justify-center items-center bg-gray-900 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
			<div className="relative w-96 my-6 mx-auto">
				<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
					<div className="flex items-start justify-between p-5 pb-0 border-gray-300 rounded-t">
						<h3 className="mb-5 text-lg font-normal">{title}</h3>

						<button
							type="button"
							className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
							disabled={disableButtons}
							onClick={() => setShowModal(false)}>
							<XIcon className="h-5 w-5" />
						</button>
					</div>

					<div className="p-6 pt-0 text-center">
						{children}

						{showCancelButton && (
							<button
								type="button"
								className="bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900"
								disabled={disableButtons}
								onClick={handleCancel}>
								Cancel
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default withRouter(Chat)
