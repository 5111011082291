import { MenuAlt2Icon, ViewListIcon } from "@heroicons/react/solid";

const obj = {
  title: "NavixScribe",
  desc: "Rapid transcription from your audio dication. Create clinical notes in selected format.  Plus, ask questions to get decision support related to your transcription",
  category: "Clinical",
  Icon: ViewListIcon,
  // tags: [],
  permissions: ["user"],

  fromColor: "gray-500",
  toColor: "gray-500",

  to: "/ai/audio/transcriber",
  api: "/ai/audio/transcriber",

	showHistory: true,

  output: {
    title: "Output",
    desc: "Please be patient, depending on the length of your audio recording, it may take up to a minute for the response. You are advised that this AI-generated content may contain errors and requires your review.",
    Icon: false,
    color: "blue",
  },

  prompts: [
    {
      title: "Audio Transcription",
      desc: "Upload an audio for transcription. Generate clinical notes in selected formats, or interact with the transcription by asking questions. Upload an audio file to transcribe. Please note maximum dictation duration is 70 minutes.",
      // n: 1,
      prompts: [
        {
          title: "",
          attr: "file",
          value: "",
          type: "audio",
          required: true,
          options: true,
          error: "",
          audioUrl: "",
					useRecorder: true,
					isRecording: false,
					navixscribeLabel: "To generate your transcription, click the \"Perform Request\" button below. <em>Then wait for a notification that will pop-up on upper right corner</em>, indicating the completion of your transcription; this may <em>2-5 minutes</em> depending on the length of the audio. <em>Once notified, access your transcription by selecting the newest item in \"Histories\" or access it thru notification</em>. You can then proceed to generate clinical notes or ask questions using the options below."
        },
        {
          title: "Ask a question or generate a clinical notes",
          attr: "generate_summary",
          type: "dropdown",
          textarea: {
            minRows: 4,
            title: "",
            attr: "",
            placeholder: "Ask any questions related to the transcript",
            size: "sm",
            required: true,
            error: "",
          },
          options: [
            {
              value: "input",
              label: "Ask a question",
              Icon: MenuAlt2Icon,
              isInput: true,
              textarea: {
                minRows: 4,
                title: "",
                attr: "",
                placeholder: "Ask any questions related to the transcript",
                size: "sm",
                required: true,
                error: "",
              },
            },
            {
              value: "Summarize in SOAP format",
              label: "SOAP",
              Icon: MenuAlt2Icon,
            },
            {
              value: "Summarize in DAP format",
              label: "DAP",
              Icon: MenuAlt2Icon,
            },
            {
              value: "Summarize in SIRP format",
              label: "SIRP",
              Icon: MenuAlt2Icon,
            },
            {
              value: "Summarize in BIRP format",
              label: "BIRP",
              Icon: MenuAlt2Icon,
            },
            {
              value: "Summarize in GIRP format",
              label: "GIRP",
              Icon: MenuAlt2Icon,
            },
          ],
          error: "",
        },
      ],
    },
  ],
};

export default obj;
