import {
	BanIcon,
	DatabaseIcon, LightBulbIcon, MenuAlt1Icon, PencilIcon, SparklesIcon
} from "@heroicons/react/solid";
import { Component } from "react";

import { inject, observer } from "mobx-react";
@inject("store")
@observer
class Pricing2 extends Component {
  render() {
    return (
      <>
        {this.props.store.profile.status ? null : (
          <div className="border-b border-gray-300 bg-white shadow-sm ">
            <div className="container flex mx-auto px-4 md:px-28 flex select-none">
              <div className="relative text-gray-400 focus-within:text-green-500 flex flex-1 "></div>
            </div>
          </div>
        )}

        <div
          className="mt-12"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/XWou7LeNDug"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <div className="container mx-auto px-8 py-4 lg:px-28 lg:py-12 lg:pb-64 select-none">
          {this.props.store.profile.status ? null : (
            <>
              <div className="mx-auto"></div>

              <div className="text-lg sm:text-xl md:text-2xl text-gray-400 mb-14 mt-2 text-center">
                NavixAI Pricing
                <div className="text-sm text-gray-500 text-center">
                  Use the Sign Up form above to Enroll
                </div>
              </div>
            </>
          )}

          <Grid>
            {this.props.store.profile.status ? null : (
              <Free
                fromColor="gray-400"
                toColor="gray-500"
                baseURL={this.props.store.baseURL}
                api={this.props.store.api}
              />
            )}
            <Entry
              fromColor="green-400"
              toColor="green-600"
              baseURL={this.props.store.baseURL}
              api={this.props.store.api}
            />
            <Premium
              fromColor="indigo-500"
              toColor="red-500"
              baseURL={this.props.store.baseURL}
              api={this.props.store.api}
            />
          </Grid>
        </div>
      </>
    );
  }
}

const Free = ({ fromColor, toColor, baseURL, api }) => (
  <div className="flex relative col-span-3">
    <div
      className={`absolute inset-0 bg-gradient-to-r from-${
        fromColor ? fromColor : "green-400"
      } to-${
        toColor ? toColor : "blue-500"
      } shadow-lg transform skew-y-0 -rotate-3 rounded-3xl `}
    ></div>

    <div
      className={`bg-white rounded-xl transition hover:shadow-md overflow-hidden text-gray-500 border-t-2 border- hover:border-${
        fromColor ? fromColor : "blue-400"
      } md:flex relative transform hover:scale-105  hover:text-black flex-1`}
    >
      <div className="p-8 flex-1">
        <div
          href="#"
          className={`text-${
            fromColor ? fromColor : "green-500"
          } block text-lg text-2xl leading-tight font-medium mb-2`}
        >
          Free
        </div>
        <div className="text-6xl text-black font-bold">
          $0<span className="text-lg text-gray-400"> free trial</span>
        </div>
        <p className="mt-4 text-lg">14-day Free Trial.</p>
        <div className="divide-y divide-dashed divide-gray-300 mt-4">
          <div className="py-2 flex  items-center">
            <DatabaseIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">250</span>
              {` x `}Credits
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <MenuAlt1Icon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">15,000</span>
              {` x `}Words
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <PencilIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">60,000</span>
              {` x `}Letters
            </div>
          </div>

          <div className="py-2 flex  items-center">
            <BanIcon className="w-6 h-6 mr-2 text-gray-400" />
            <div>
              <span className="text-gray-400">Access to all tools</span>
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <BanIcon className="w-6 h-6 mr-2 text-gray-400" />
            <div>
              <span className="text-gray-400">New beta-feature testing</span>
            </div>
          </div>
        </div>
        <div className="py-2 xl:flex hidden  items-center">
          <BanIcon className="w-6 h-6 mr-2 text-white" />
        </div>
      </div>
    </div>
  </div>
);

const Entry = ({ fromColor, toColor, baseURL, api }) => (
  <div className="flex relative col-span-3">
    <div
      className={`absolute inset-0 bg-gradient-to-r from-${
        fromColor ? fromColor : "green-400"
      } to-${
        toColor ? toColor : "blue-500"
      } shadow-lg transform skew-y-0 -rotate-3 rounded-3xl `}
    ></div>

    <div
      className={`bg-white rounded-xl transition hover:shadow-md overflow-hidden text-gray-500 border-t-2 border- hover:border-${
        fromColor ? fromColor : "blue-400"
      } md:flex relative transform hover:scale-105  hover:text-black flex-1`}
    >
      <div className="p-8 flex-1">
        <div
          href="#"
          className={`text-${
            fromColor ? fromColor : "green-500"
          } block text-lg text-2xl leading-tight font-medium mb-2`}
        >
          Entry
        </div>
        <div className="text-6xl text-black font-bold">
          $10<span className="text-lg text-gray-400">/per month</span>
        </div>
        <p className="mt-4 text-lg">
          Get access to our popular AI-powered features.
        </p>
        <div className="divide-y divide-dashed divide-gray-300 mt-4">
          <div className="py-2 flex  items-center">
            <DatabaseIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">250</span>
              {` x `}Credits
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <MenuAlt1Icon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">15,000</span>
              {` x `}Words
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <PencilIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">60,000</span>
              {` x `}Letters
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <BanIcon className="w-6 h-6 mr-2 text-gray-400" />
            <div>
              <span className="text-gray-400">Access to all tools</span>
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <BanIcon className="w-6 h-6 mr-2 text-gray-400" />
            <div>
              <span className="text-gray-400">Early access to beta tools</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Premium = ({ fromColor, toColor, baseURL, api }) => (
  <div className="flex relative col-span-3">
    <div
      className={`absolute inset-0 bg-gradient-to-r from-${
        fromColor ? fromColor : "green-400"
      } to-${
        toColor ? toColor : "blue-500"
      }  shadow-lg transform skew-y-0 -rotate-3 rounded-3xl `}
    ></div>

    <div
      className={`bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 border-t-2 border- hover:border-${
        fromColor ? fromColor : "blue-400"
      } md:flex relative transform hover:scale-105  hover:text-black flex-1`}
    >
      <div className="p-8 flex-1">
        <div
          href="#"
          className={`text-${
            fromColor ? fromColor : "green-500"
          } block text-lg text-2xl leading-tight font-medium mb-2`}
        >
          Pro
        </div>
        <div className="text-6xl text-black font-bold">
          $25<span className="text-lg text-gray-400">/per month</span>
        </div>
        <p className="mt-4 text-lg">
          Get access to all our AI-powered features.
        </p>
        <div className="divide-y divide-dashed divide-gray-300 mt-4">
          <div className="py-2 flex  items-center">
            <DatabaseIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">750</span>
              {` x `}Credits
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <MenuAlt1Icon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">45,000</span>
              {` x `}Words
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <PencilIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">180,000</span>
              {` x `}Letters
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <SparklesIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">
                Access to all tools
              </span>
            </div>
          </div>
          <div className="py-2 flex  items-center">
            <LightBulbIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />
            <div>
              <span className="font-medium text-black">
                Early access to beta tools
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Grid = ({ children }) => (
  <div
    className="grid grid-cols-1 gap-12 mt-4 xl:grid-cols-3 "
    style={{ margin: "0 -1rem" }}
  >
    {children}
  </div>
);

export default Pricing2;
