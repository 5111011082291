import {
  ViewListIcon,
} from '@heroicons/react/solid'


const obj = {

  title: "Diagnosis Codes (ICD-10)",
  desc: "Decision support for diagnosis codes based on clinical documents.",
  category: "RCM",
  Icon: ViewListIcon,
  // tags: [],
  permissions: ['user'],

  fromColor: "gray-500",
  toColor: "gray-500",

  to: "/ai/medicalcoding",
  api: "/ai/medicalcoding",

  output: {
    title: "Diagnosis Codes",
    desc: "The following are proposed ICD-10 codes. You are advised that this AI-generated content may contain errors and requires your review.",
    Icon: false,
    color: "blue",
  },

  prompts: [{
    title: "Clinical Documentation",
    desc: "Enter the clinical documentation to analyze for diagnosis codes. ",
    // n: 1,
    prompts: [{
      title: "Content",
      attr: "content",
      value: "",
      placeholder: "The client reports that...",
      label: "",
      type: "textarea",
      maxLength: 3000,
      // max: 100,
      min: 3,
      required: true,
      error: "",
      example: "Subjective: Client was alert and engaged in the session. She reported frequent experiences of becoming infuriated, anxious, and dysregulated by “the slightest annoyance” throughout the previous week. Grounding techniques allowed her to identify the emotion early on and self regulate.\n\nObjective: Scored a 45 on the PCL-5. Affect was appropriate if slightly constricted. Speech was coherent and normally paced and thought content was clear. Thought process presented as fragmented, tangential, and filled with loose associations congruent with her PTSD diagnosis. Denied SI/HI.\n\nAssessment:  Client reports a decreased frequency and intensity in her experiences of anger and anxiety, which are linked to multiple traumas that she experienced in childhood, and which continue to impair her organizational capacities and relational responses to family members and colleagues. Client is reflective, insightful, and motivated in treatment. She’s interested in examining her emotions and behaviors, and committed to working on emotion regulation.\n\nPlan: Continue to practice grounding techniques and tease apart challenging feelings while channelling aggression in appropriate directions. Meet at the regular time next week.",
    },
    ],
    example: {
      output: [
        "Proposed ICD-10 Diagnosis Codes:\n",
        "F43.10 - Post-traumatic Stress Disorder, Unspecified\n",
        "F60.2 - Borderline Personality Disorder\n\n",
        "ICD-10 Diagnosis Code F43.10 – Post-Traumatic Stress Disorder, Unspecified: This code is justified by the client’s report of frequent experiences of becoming infuriated, anxious, and dysregulated by “the slightest annoyance” throughout the previous week which are linked to multiple traumas that she experienced in childhood and continue to impair her organizational capacities and relational responses to family members and colleagues. The client also scored a 45 on the PCL-5 which is consistent with a diagnosis of PTSD.\n\n",
        "ICD-10 Code F41.1 – Generalized Anxiety Disorder: This code is justified by the client’s report that she experiences frequent episodes of anger and anxiety which are linked to multiple traumas from her childhood as well as her insight into how these emotions affect her relationships with family members and colleagues. The objective findings also support this diagnosis as they indicate that the client has difficulty regulating their emotions due to their PTSD symptoms being present at a moderate level or higher on the PCL-5 scale (score = 45).\n\n",
      ],
      // Icon: RefreshIcon,
      color: "blue",
    }
  }]

}

export default obj

