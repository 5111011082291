import {
  ChevronLeftIcon,
  UserCircleIcon,
  PencilAltIcon,
} from "@heroicons/react/outline";
import { computed, makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { Helmet } from "react-helmet";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import MainBody from "../Components/Body";
import Header from "../Components/Header";
import Pricing from "../Pricing";
import Feedback from "./Feedback";
import Referral from "./Referral";
import toast from "react-hot-toast";
import Loader from "../Components/Loader";

@inject("store")
@observer
class Body extends Component {
  @observable showDeleteModal = false;
  @observable showChangePasswordModal = false;

  @observable password = "";
  @observable repeatPassword = "";
  @observable errorMessage = "";

  @observable showProfileModal = false;
  @observable email;
  @observable fname;
  @observable lname;
  @observable isSubmitting = false;
  @observable plan = {
    plan: "",
  };

  @computed get headerMessage() {
    if (this.props.store.profile.status === "trialing") {
      return "14 Day Trial";
    }
    if (this.props.store.profile.status === "active") {
      if (this.props.store.profile.cancel_at_period_end) {
        return `Set to cancel soon`;
      }
      return `${this.props.store.profile.plan} Plan`;
    }
    if (this.props.store.profile.status === "incomplete") {
      return `${this.props.store.profile.plan} Plan Restarted`;
    }
    return "Expired";
  }

  @computed get ifNotActive() {
    if (this.props.store.profile.cancel_at_period_end) {
      return "Canceled";
    }
    if (this.props.store.profile.status === "trialing") {
      return "Trialing";
    }
    return false;
  }

  @computed get fromColor() {
    if (this.props.store.profile.status === "trialing") {
      return "gray-400";
    }
    if (this.props.store.profile.status === "active") {
      if (this.props.store.profile.cancel_at_period_end) {
        return "yellow-500";
      }
      return "green-500";
    }
    if (this.props.store.profile.status === "incomplete") {
      return "yellow-600";
    }
    return "red-500";
  }

  @computed get currentPeriodEnd() {
    // console.log(this.props.store.profile.current_period_end)
    if (
      this.props.store.profile.current_period_end &&
      this.props.store.profile.current_period_end.length > 0
    ) {
      var days_difference = Math.round(
        (new Date(this.props.store.profile.current_period_end).getTime() -
          new Date().getTime()) /
        (1000 * 60 * 60 * 24)
      );
      if (days_difference < 0) {
        return 0;
      }
      return days_difference;
    }
    return 0;
  }

  componentDidMount() {
    this.props.store.refreshTokenAndProfile();
    makeObservable(this);
    this.init();
  }

  init = async () => {
    let res = await this.props.store.api.post("/user/stripe/plan");
    this.plan = {
      ...res.data,
    };
    this.email = this.props.store.profile.email;
    this.fname = this.props.store.profile.fname;
    this.lname = this.props.store.profile.lname;
    console.log(`this.plan`, { ...this.plan });
  };

  onBack = () => {
    this.props.history.push(`/my-profile`);
  };

  onShowDeleteModal = (state = true) => {
    this.showDeleteModal = state;
  };

  onShowChangePasswordModal = (state = true) => {
    this.errorMessage = "";
    this.showChangePasswordModal = state;
  };

  onChange = (val, attr) => {
    this[attr] = val;
    this.errorMessage = "";

    if (attr === "repeatPassword" || attr === "password") {
      // check for password changes too
      if (this.password && this.repeatPassword) {
        // only check if both fields are non-empty
        this.passwordsMatch = this.password === this.repeatPassword;
      } else {
        this.passwordsMatch = true;
      }
    }
  };

  onChangePassword = async (e) => {
    try {
      e.preventDefault();
      const { data } = await this.props.store.api.post(
        "/user/change-password",
        {
          password: this.password,
          repeatPassword: this.repeatPassword,
        }
      );

      toast.success(data.message);
      this.onShowChangePasswordModal(false);
    } catch (err) {
      if (err?.response?.data?.message) {
        this.errorMessage = err?.response?.data?.message;
      }
    }
  };

  onShowProfileModal = (state = true) => {
    this.showProfileModal = state;
  };

  onChange = (val, attr) => {
    this[attr] = val;
  };

  onUpdateProfile = async (data) => {
    await this.props.store.api.post("/user/update-profile", data);
    window.location.reload();
  };

  render() {
    return (
      <>
        <Header
          title={
            <>
              {`${this.props.store.profile.fname} ${this.props.store.profile.lname}`}
              <PencilAltIcon
                className="h-6 w-6 cursor-pointer text-gray-500"
                onClick={this.onShowProfileModal}
              />
            </>
          }
          desc={this.props.store.profile.email}
          category="Your Profile"
          Icon={UserCircleIcon}
          fromColor={this.fromColor}
          options={
            this.props.location.pathname !== "/my-profile"
              ? [
                {
                  title: "Back to Profile",
                  Icon: ChevronLeftIcon,
                  onClick: this.onBack,
                },
              ]
              : null
          }
        >
          <Route exact path="/my-profile">
            <Helmet>
              <title>{`My Profile - NavixAI`}</title>
            </Helmet>
          </Route>
        </Header>
        <PlanDetails
          plan={this.plan}
          currentPeriodEnd={this.currentPeriodEnd}
          profile={this.props.store.profile}
        />
        <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12">
          <Switch>
            <Route exact path="/my-profile/pricing">
              <Pricing />
            </Route>
            <Route exact path="/my-profile/referral">
              <Referral />
            </Route>
            <Route exact path="/my-profile/feedback">
              <Feedback />
            </Route>
            <Route>
              <Grid>
                {/* {this.plan.status === "trialing" ? 
									<ToolForm
									Icon={CheckIcon}
									title={`Active Subscription`} 
									desc={`${this.plan.plan === "Entry" ? "$10" : ""}${this.plan.plan === "Pro" ? "$25" : ""} billing immediately. Ends trial and starts billing plan.`} 
									to={this.props.store.baseURL + "/user/stripe/activate"}
									api={this.props.store.api}
									fromColor="purple-500"
									toColor="indigo-600"
								/>: null} */}

                {this.plan.plan === "None" ? (
                  <Tool
                    title={"Purchase Plan / Credits"}
                    api={this.props.store.api}
                    desc={"Select your plan or purchase additional credits."}
                    to={"/my-profile/pricing"}
                  />
                ) : null}

                {this.headerMessage === "Expired" ? null : (
                  <>
                    {this.ifNotActive ? null : (
                      <>
                        <ToolForm
                          title={"Cancel Subscription"}
                          api={this.props.store.api}
                          desc={"Immediate cancellation of subscription"}
                          // to={this.props.store.baseURL + "user/stripe/cancel"}
                          onClick={(e) => {
                            e.preventDefault();
                            this.onShowDeleteModal();
                          }}
                        />

                        {/* <ToolForm
									Icon={DatabaseIcon}
									title={"Buy Credits"} 
									desc={"250 x extra credits quick-buy"} 
									to={this.props.store.baseURL + "user/stripe/buy250"}
									api={this.props.store.api}
									fromColor="purple-500"
									toColor="indigo-600"
								/> */}
                      </>
                    )}

                    {this.props.store.profile.cancel_at_period_end ? (
                      <>
                        {/* <ToolForm
									Icon={CheckIcon}
									title={"Reactivate Subscription"} 
									api={this.props.store.api}
									desc={"Immediate cancellation of subscription and payments."} 
									to={this.props.store.baseURL + "user/stripe/uncancel"}
									fromColor={this.props.store.profile.cancel_at_period_end ? "green-400" : "green-500"} 
									toColor={this.props.store.profile.cancel_at_period_end ? "green-400" : "green-500"} 
								/> */}
                      </>
                    ) : null}
                    <ToolDiv
                      title={
                        this.props.store.profile.cancel_at_period_end
                          ? "Manage Subscription"
                          : "Update Subscription"
                      }
                      // api={this.props.store.api}
                      desc={
                        "Change your plan, card details, or cancel the plan anytime."
                      }
                      // to={
                      //   this.props.store.baseURL + "user/stripe/customer-portal"
                      // }
                      onClick={async () => {
                        const { data } = await this.props.store.api.post("user/stripe/customer-portal");
                        window.open(data.url, "_blank");
                      }}
                    />
                  </>
                )}

                {/* <Tool
								Icon={ChatAltIcon}
								title={"Feedback"} 
								desc={"Provide comments on your experience"} 
								to={"/my-profile/feedback"}
								fromColor="gray-400"
								toColor="gray-400"
							/> */}

                <ToolDiv
                  title={"Change Password"}
                  desc={"Change your current password"}
                  api={this.props.store.api}
                  onClick={this.onShowChangePasswordModal}
                />

                <ToolDiv
                  title={"Log Out"}
                  desc={"Sign out of your account"}
                  onClick={this.props.store.handleLogout}
                />
              </Grid>
            </Route>
          </Switch>
          {this.showDeleteModal && (
            <DeleteModal
              setShowModal={() => this.onShowDeleteModal(false)}
              url={this.props.store.baseURL + "user/stripe/cancel"}
              api={this.props.store.api}
            />
          )}

          {this.showChangePasswordModal && (
            <ChangePasswordModal
              setShowModal={() => this.onShowChangePasswordModal(false)}
              api={this.props.store.api}
              password={this.password}
              repeatPassword={this.repeatPassword}
              errorMessage={this.errorMessage}
              onChange={this.onChange}
              onChangePassword={this.onChangePassword}
            />
          )}

          {this.showProfileModal && (
            <ProfileModal
              setShowModal={() => this.onShowProfileModal(false)}
              url={this.props.store.baseURL + "user/update-profile"}
              api={this.props.store.api}
              onChange={this.onChange}
              email={this.email}
              fname={this.fname}
              lname={this.lname}
              onUpdateProfile={this.onUpdateProfile}
              isSubmitting={this.isSubmitting}
            />
          )}
        </MainBody>
      </>
    );
  }
}

const Grid = ({ children }) => (
  <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 ">
    {children}
  </div>
);

const ToolDiv = ({
  title,
  desc,
  group,
  fromColor,
  onClick,
}) => (
  <>
    <div className="flex relative " onClick={onClick}>
      <div
        className={`bg-gray-200 flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-400 md:flex relative transform hover:scale-105  hover:text-black`}
      >

        <div className="p-4">
          <div
            className={`uppercase tracking-wide text-sm text-${fromColor ? fromColor : "green-500"
              } font-semibold leading-none`}
          >
            {group || ""}
          </div>
          <div
            href="#"
            className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none"
          >
            {title}
          </div>
          <p className="mt-1 pr-1 text-sm ">{desc} </p>
        </div>
      </div>
    </div>
  </>
);

const ToolForm = ({
  title,
  desc,
  to,
  group,
  fromColor,
  api,
}) => (
  <>
    <form action={to} method="POST" className="flex relative">
      <input
        type="hidden"
        name="token"
        value={api.defaults.headers.common["x-access-token"]}
      />
      <button type="submit" className="flex-1 text-left">
        <div
          type="submit"
          className={`bg-gray-200 flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-400 md:flex relative transform hover:scale-105  hover:text-black`}
        >
          <div className="p-4">
            <div
              className={`uppercase tracking-wide text-sm text-font-semibold leading-none`}
            >
              {group || ""}
            </div>
            <div className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none">
              {title}
            </div>
            <p className="mt-1 pr-1 text-sm ">{desc} </p>
          </div>
        </div>
      </button>
    </form>
  </>
);

const Tool = ({
  title,
  desc,
  to,
  group,
  fromColor,
}) => (
  <Link to={to} className="flex relative">
    <div className="flex-1 text-left">
      <div
        className={`bg-gray-200 flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-400 md:flex relative transform hover:scale-105  hover:text-black`}
      >

        <div className="p-4">
          <div
            className={`uppercase tracking-wide text-sm text-${fromColor ? fromColor : "green-500"
              } font-semibold leading-none`}
          >
            {group || ""}
          </div>
          <div className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none">
            {title}
          </div>
          <p className="mt-1 pr-1 text-sm ">{desc} </p>
        </div>
      </div>
    </div>
  </Link>
);

const ATool = ({
  Icon,
  title,
  desc,
  to,
  group,
  fromColor,
}) => (
  <a href={to} className="flex relative">
    <div className="flex-1 text-left">
      <div
        className={`flex-1 bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border-t-2 border- hover:border-${fromColor ? fromColor : "blue-400"
          } md:flex relative transform hover:scale-105  hover:text-black`}
      >
        {Icon && (
          <div
            className={`md:flex-shrink-0 flex justify-start items-center ml-8 text-${fromColor ? fromColor : "green-500"
              }`}
          >
            <Icon className="h-16 w-16 mb-4 mt-4" />
          </div>
        )}
        <div className="p-4">
          <div
            className={`uppercase tracking-wide text-sm text-${fromColor ? fromColor : "green-500"
              } font-semibold leading-none`}
          >
            {group || ""}
          </div>
          <div className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none">
            {title}
          </div>
          <p className="mt-1 pr-1 text-sm ">{desc} </p>
        </div>
      </div>
    </div>
  </a>
);

const PlanDetails = ({ plan, currentPeriodEnd, profile }) => (
  <>
    <div className="container mx-auto px-4 md:px-28 flex items-center">
      <div className="bg-navix-ivory lg:py-4 lg:px-8 py-3 px-6 flex transition text-gray-500 font-medium rounded-b-2xl border-gray-400 w-full shadow-lg justify-between -mt-0.5">
        <p className="capitalize">
          <span
            className={`text-${plan?.plan === "Entry" ? "green" : "blue"}-800`}
          >
            {plan?.plan ? `Navix ${plan?.plan}` : ""}
          </span>{" "}
          {currentPeriodEnd} Days Remaining{" "}
        </p>
        <p className="capitalize">
          Status:{" "}
          <span
            className={`text-${profile?.status === "canceled" ? `red` : `green`
              }-800`}
          >
            {profile?.status}
          </span>{" "}
          | {profile.credits} Credits Remaining
        </p>
      </div>
    </div>
  </>
);

const DeleteModal = ({ setShowModal, url, api }) => (
  <>
    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-auto max-w-3xl">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-start justify-between p-5 border-gray-300 rounded-t ">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              onClick={() => setShowModal(false)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="p-6 text-center">
            <svg
              aria-hidden="true"
              className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to unsubscribe?
            </h3>
            <form action={url} method="POST" className="inline-flex">
              <input
                type="hidden"
                name="token"
                value={api.defaults.headers.common["x-access-token"]}
              />
              <button
                type="submit"
                className="hover:text-white text-gray-500 border border-red-200 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
              >
                Yes, I'm sure
              </button>
            </form>
            <button
              type="button"
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              onClick={() => setShowModal(false)}
            >
              No, cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </>
);

const ChangePasswordModal = ({
  setShowModal,
  password,
  repeatPassword,
  errorMessage,
  onChange,
  onChangePassword,
}) => (
  <>
    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-auto max-w-3xl">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-start justify-between p-5 pb-0 border-gray-300 rounded-t ">
            <h1 className="mb-5 text-lg font-bold text-gray-600 dark:text-gray-400">
              Change Password
            </h1>
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              onClick={() => setShowModal(false)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {errorMessage && (
            <div className="text-red-600 bg-red-50 rounded-md p-1 mx-5 text-center mt-4">
              {errorMessage}
            </div>
          )}
          <div className="p-6 pt-0 text-center">
            <form onSubmit={onChangePassword}>
              <div className="flex flex-col flex-1">
                <label className="text-gray-400 text-sm mt-4 inline-block text-left">
                  New Password
                </label>
                <input
                  value={password}
                  onChange={(e) => onChange(e.target.value, "password")}
                  focus="true"
                  type="password"
                  className="rounded-md text-lg px-4 py-2  border border-gray-300 "
                  placeholder="*******"
                />
              </div>
              <div className="flex flex-col flex-1">
                <label className="text-gray-400 text-sm mt-4 inline-block text-left">
                  Confirm Password
                </label>
                <input
                  value={repeatPassword}
                  onChange={(e) => onChange(e.target.value, "repeatPassword")}
                  type="password"
                  className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block"
                  placeholder="*******"
                />
              </div>
              <div className="flex justify-between mt-3">
                <button
                  type="submit"
                  className="hover:text-white text-gray-500 border border-green-200 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                >
                  Yes, I'm sure
                </button>
                <button
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  onClick={() => setShowModal(false)}
                >
                  No, cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </>
);

const ProfileModal = ({
  setShowModal,
  url,
  api,
  onChange,
  email,
  fname,
  lname,
  onUpdateProfile,
  isSubmitting,
}) => (
  <>
    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-auto max-w-3xl">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-start justify-between px-5 pt-5 pb-0 border-gray-300 rounded-t ">
            <h3 className="mb-5 text-lg font-normal text-gray-800 dark:text-gray-800">
              Update Profile
            </h3>
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              onClick={() => setShowModal(false)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div class="px-6 pb-6 pt-0 text-center">
            <form
              action={url}
              method="POST"
              className="flex-row"
              onSubmit={(e) => {
                e.preventDefault();
                onChange(true, "isSubmitting");
                onUpdateProfile({ email, fname, lname });
              }}
            >
              <input
                type="hidden"
                name="token"
                value={api.defaults.headers.common["x-access-token"]}
              />
              <div className="flex flex-col flex-1">
                <label className="text-gray-400 text-sm block mt-4 inline-block text-left">
                  Email Address
                </label>
                <input
                  value={email}
                  onChange={(e) => onChange(e.target.value, "email")}
                  name="email"
                  focus="true"
                  type="email"
                  className="rounded-md text-lg px-4 py-2  border border-gray-300 "
                />
              </div>
              <div className="flex flex-col flex-1">
                <label className="text-gray-400 text-sm block mt-4 inline-block text-left">
                  First Name
                </label>
                <input
                  value={fname}
                  name="fname"
                  onChange={(e) => onChange(e.target.value, "fname")}
                  type="text"
                  className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block"
                />
              </div>
              <div className="flex flex-col flex-1">
                <label className="text-gray-400 text-sm block mt-4 inline-block text-left">
                  Last Name
                </label>
                <input
                  value={lname}
                  name="lname"
                  onChange={(e) => onChange(e.target.value, "lname")}
                  type="text"
                  className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block"
                />
              </div>
              <div className="mt-2">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  class="hover:text-white text-gray-500 border border-red-200 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                >
                  {isSubmitting && (
                    <Loader active={true} className="w-6 h-6 mr-2" />
                  )}
                  Yes, Update
                </button>
                <button
                  type="button"
                  class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  onClick={() => setShowModal(false)}
                >
                  No, cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default withRouter(Body);
