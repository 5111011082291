import React, { Component } from 'react'
import { computed } from 'mobx'
import { observer, inject } from 'mobx-react'
import TextareaAutosize from 'react-textarea-autosize'
import { CheckIcon } from '@heroicons/react/outline'
import EntryDropdown from './EntryDropdown'
import CodeEditor from "@uiw/react-textarea-code-editor"
import EntryAudio from './EntryAudio'
import WaveSurferRecorder from './WaveSurferRecorder';

@inject("store")
@observer
class EntryInput extends Component {

  onChange = async (e) => {
    if (this.props.prompt.type === "number") {
      const value = parseFloat(e.target.value);
      if (isNaN(value)) {
        // Handle invalid numeric input
        this.props.prompt.error = "Please enter a valid number";
      } else {
        this.props.prompt.value = value;
        this.props.prompt.error = "";
      }
    } else {
      this.props.prompt.value = e.target.value;
    }
    this.props.prompt.error = "";
  }

  onChangeDropdown = async (e) => {
    this.props.prompt.value = e.value
    this.props.prompt.error = ""

    if (e?.maxLength) this.props.prompt.maxLength = e.maxLength
    else this.props.prompt.maxLength = null

    if (e?.isInput) {
      if (e.input) this.props.prompt.input = e.input
      else if (e.textarea) { this.props.prompt.textarea = e.textarea; this.props.prompt.value = "" }
    } else {
      this.props.prompt.input = null
      this.props.prompt.textarea = null;
    }
  }

  onRadioClick = (option, forElement = undefined) => {
    this.props.prompt.value = option.value

    if (option.maxLength) {
      this.props.store.setCustomCharacter({ char: option.maxLength, type: forElement })
    }
  }

  componentWillUnmount() {
    this.props.store.setCustomCharacter({ type: undefined })
  }

  @computed get isMinLength() {
    if (!this.props.prompt.min) {
      return false
    }
    if (!this.props.prompt.type === "number") {
      return false
    }
    if (this.props.prompt.value.length === 0) {
      return false
    }
    if (this.props.prompt.value.length < this.props.prompt.min) {
      return true
    }
    return false
  }

  @computed get isLongEnoughToDrop() {
    return this.props.prompt.value.length < 30
  }

  @computed get currentNumberOfCharacters() {
		let currentCharacters = 0;

		if (this.props.prompt.type === 'number') {
			currentCharacters = this.props.prompt.value.toString().length
		} else {
			currentCharacters = this.props.prompt.value.length
		}

    let maxCharacters = 80
    if (this.props.prompt.type === "textarea") {
      maxCharacters = 400
    }
    if (this.props.prompt.maxLength) {
      maxCharacters = this.props.prompt.maxLength
    }
    if (this.props.prompt.max) {
      maxCharacters = this.props.prompt.max
    }

    if (this.props.store?.customCharacter?.type === this.props.prompt.attr) {
      this.props.prompt.maxLength = maxCharacters = this.props.store.customCharacter.char;
      if (maxCharacters >= currentCharacters) this.props.prompt.error = '';
      else `The content you are trying to process exceeds the maximum character limit of ${this.props.prompt.maxLength.toLocaleString()} for this AI model. Please reduce the number of characters in your file, select a different file, or consider using a different AI model that supports larger inputs before submitting your request. Thank you for your understanding.`
    }

    return `${currentCharacters.toLocaleString()}/${maxCharacters.toLocaleString()}`
  }

  handlePreview(value) {
    if (value.length >= this.props.prompt.maxLength) {
      this.props.onLoading(true);
      this.props.prompt.error = `The content you are trying to process exceeds the maximum character limit of ${this.props.prompt.maxLength.toLocaleString()} for this AI model. Please reduce the number of characters in your file, select a different file, or consider using a different AI model that supports larger inputs before submitting your request. Thank you for your understanding.`
    }
    if (value.length > 500) {
      value = `${value.substr(0, 500)}...`
    }
    this.props.onPreviewValue(value)
  }

  render() {
    const { Icon } = this.props


    return (
      <div className={`${this.props.size ? "mt-4" : "mt-4"}`}>

        {this.props.prompt.title && <label
          htmlFor={this.props.prompt.attr}
          className="text-gray-600 font-medium text-sm">
          {this.props.prompt.title}
        </label>}

        <div className="relative flex flex-col">

          {this.props.prompt.options ? null : <div className={`text-xs absolute z-30 right-2 select-none pointer-events-none transition-all top bg-white px-2 ${this.isMinLength ? "text-red-500" : "text-gray-400"} ${(!this.isLongEnoughToDrop && this.props.size) ? "" : ""}`}>
            {this.currentNumberOfCharacters} chars
          </div>}

          <label
            htmlFor={this.props.prompt.attr} className="relative transition text-gray-600 focus-within:text-gray-800 block">
            {Icon ? <Icon className={`
							${this.props.size ? "" : "h-6 w-6 top-5 left-2"} 
							${this.props.size === "sm" && "h-4 w-4 top-5 left-2"} 
							${this.props.size === "lg" && "h-8 w-8 top-5 left-2"} 
							transition absolute  select-none pointer-events-none opacity-75`} /> : null
            }

            {this.props.prompt.type ? null :
              <input
                name={this.props.prompt.attr}
                id={this.props.prompt.attr}
                maxLength={this.props.prompt.maxLength || 80}
                {...this.props.prompt}
                autoFocus={this.props.index === 0}
                className={`outline-none focus:outline-none text-${this.props.size || "sm"} bg-white rounded-md px-4 py-2 w-full border  focus:border-gray-400 ${this.props.prompt.error ? "border-red-400" : "border-gray-300"} font-regular mt-2 transition-all ${Icon && `
								${this.props.size ? "" : "pl-9"}
								${this.props.size === "sm" && "pl-7"}
								${this.props.size === "lg" && "pl-12"}
								`} `}
                onChange={this.onChange}
              />
            }

            {this.props.prompt.type === "text" &&
              <input
                name={this.props.prompt.attr}
                id={this.props.prompt.attr}
                maxLength={this.props.prompt.maxLength || 400}
                {...this.props.prompt}
                autoFocus={this.props.index === 0}
                className={`outline-none focus:outline-none text-${this.props.size || "sm"} bg-white rounded-md px-4 py-2 w-full border  focus:border-gray-400  ${this.props.prompt.error ? "border-red-400" : "border-gray-300"} font-regular mt-2 transition-all ${Icon && `
								${this.props.size ? "" : "pl-7"}
								${this.props.size === "sm" && "pl-7"}
								${this.props.size === "lg" && "pl-12"}
								`}`}
                onChange={this.onChange}
              />
            }

          </label>

          {this.props.prompt.type === "number" && (
            <input
              type="number"
              name={this.props.prompt.attr}
              id={this.props.prompt.attr}
              max={this.props.prompt.max} // Optional: set the maximum value
              min={this.props.prompt.min} // Optional: set the minimum value
              step={this.props.prompt.step} // Optional: set the step value
              value={this.props.prompt.value} // Optional: set the initial value
              autoFocus={this.props.index === 0}
              className={`outline-none focus:outline-none text-${this.props.size || "sm"} bg-white rounded-md px-4 py-2 w-full border  focus:border-gray-400  ${this.props.prompt.error ? "border-red-400" : "border-gray-300"} font-regular mt-2 transition-all ${Icon && `
							${this.props.size ? "" : "pl-7"}
							${this.props.size === "sm" && "pl-7"}
							${this.props.size === "lg" && "pl-12"}
							`}`}
              onChange={this.onChange}
            />
          )}

          {this.props.prompt.type === "dropdown" &&
            <>
              <EntryDropdown
                onChange={this.onChangeDropdown}
                options={this.props.prompt.options}
              />
              {this.props.prompt.input && <>
                <input
                  name={this.props.prompt.input.attr}
                  id={this.props.prompt.input.attr}
                  maxLength={this.props.prompt.input.maxLength || 80}
                  placeholder={this.props.prompt.input.placeholder}
                  className={`mt-1 outline-none focus:outline-none text-${this.props.prompt.input.size || "lg"} bg-white rounded-md px-4 p-2 w-full border  focus:border-gray-400 ${this.props.prompt.input.error ? "border-red-400" : "border-gray-300"} font-regular transition-all`}
                  onBlur={e => {
                    this.props.prompt.value = e.target.value.trim();
                  }}
                />
              </>}
              {this.props.prompt.textarea && <TextareaAutosize
                autoFocus={this.props.index === 0}
                minRows={this.props.prompt.textarea.minRows || 2}
                maxRows={10}
                name={this.props.prompt.textarea.attr}
                id={this.props.prompt.textarea.attr}
                {...this.props.prompt.textarea}
                className={`outline-none focus:outline-none text-${this.props.prompt.textarea.size || "lg"} bg-white rounded-md ${this.props.prompt.textarea.size ? "px-2 py-2" : "px-4 py-4"}  min-w-full border border-gray-300 font-regular focus:border-gray-400 ${this.props.prompt.error ? "border-red-400" : "border-gray-300"} font-regular mt-2 `}
                onChange={this.onChange}
              />
              }
            </>
          }

          {this.props.prompt.type === "checkbox" && this.props.prompt.options && (
            <div className="mt-1">
              {this.props.prompt.options.map((option, index) => {
                // Ensure this.props.prompt.value is an array before trying to use array methods on it
                if (!Array.isArray(this.props.prompt.value)) {
                  this.props.prompt.value = [];
                }

                return (
                  <React.Fragment key={index}>
                    {/* <CheckboxOption
										label={option.label}
										value={option.value}
										checked={this.props.prompt.value.includes(option.value)}
										onClick={() => {
											if (this.props.prompt.value.includes(option.value)) {
												this.props.prompt.value = this.props.prompt.value.filter((value) => value !== option.value);
											} else {
												this.props.prompt.value.push(option.value);
											}

											if (option.label.toLowerCase() === 'other') {
												option.checked = !option.checked;
												option.value = 'Other';
											}
										}}
									/>
									{option.label.toLowerCase() === 'other' && option.checked && (
										<input 
											type='text'
											maxLength={this.props.prompt.maxLength || 400}
											className={`outline-none focus:outline-none text-${this.props.size || "lg"} bg-white rounded-md px-4 py-1 w-full border  focus:border-gray-400  ${this.props.prompt.error ? "border-red-400" : "border-gray-300"} font-regular transition-all`}
											onBlur={(e) => {
												let index = this.props.prompt.value.indexOf('Other');

												if (index !== -1) {
													this.props.prompt.value[index] = option.value = e.target.value;
												}
											}}
										/>
									)} */}
                    <CheckboxOption
                      label={option.label}
                      value={option.value}
                      checked={this.props.prompt.value.includes(option.value)}
                      onClick={() => {
                        if (this.props.prompt.value.includes(option.value)) {
                          this.props.prompt.value = this.props.prompt.value.filter((value) => value !== option.value);
                        } else {
                          this.props.prompt.value.push(option.value);
                        }

                        if (option.isInput) {
                          option.checked = !option.checked;
                          this.props.prompt.value.some((item, idx) => {
                            if (typeof item === 'object' && item !== null && item.hasOwnProperty(option.value)) {
                              delete this.props.prompt.value[idx][option.value];
                              this.props.prompt.value = this.props.prompt.value.filter(obj => Object.keys(obj).length !== 0);
                              return true;
                            }
                            return false;
                          })
                        }
                      }}
                    />
                    {option.isInput && option.checked && (
                      <React.Fragment>
                        {option.input.title && <label htmlFor={option.input.attr}
                          className="text-gray-600 font-medium text-sm">
                          {option.input.title}
                        </label>}
                        <input
                          name={option.input.attr}
                          id={option.input.attr}
                          maxLength={option.input.maxLength || 80}
                          placeholder={option.input.placeholder}
                          className={`outline-none focus:outline-none text-${option.input.size || "lg"} bg-white rounded-md px-4 p-2 w-full border  focus:border-gray-400 ${option.input.error ? "border-red-400" : "border-gray-300"} font-regular transition-all`}
                          onBlur={e => {
                            const { prompt: { value } } = this.props;
                            const inputValue = e.target.value.trim();
                            const optionValue = option.value;

                            let index = value.findIndex((obj) => obj.hasOwnProperty(optionValue));

                            if (index !== -1) {
                              if (inputValue === "") {
                                value.splice(index, 1);
                              } else {
                                value[index][optionValue] = inputValue;
                              }
                            } else if (inputValue !== "") {
                              value.push({ [optionValue]: inputValue });
                            }
                          }}
                        />
                        {(option.input.label || option.input.error) &&
                          <div
                            htmlFor={option.input.attr}
                            className={`mb-2 ${option.input.error ? "text-red-400" : "text-gray-400"} text-xs transition-all line`}>
                            {option.input.error || option.input.label}
                          </div>
                        }
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          )}

          {this.props.prompt.type === "code" &&
            <div className="mt-4 -mx-6" style={{ backgroundColor: "#f5f5f5" }}>
              <CodeEditor
                // height="350px"
                style={{
                  fontFamily: 'JetBrains Mono',
                  fontSize: "1rem",
                }}
                padding={30}
                language={this.props.language}
                onChange={this.onChange}
                autoFocus={this.props.index === 0}
                name={this.props.prompt.attr}
                id={this.props.prompt.attr}
                {...this.props.prompt}
              />
            </div>
          }

          {this.props.prompt.type === "textarea" &&
            <TextareaAutosize
              autoFocus={this.props.index === 0}
              minRows={this.props.minRows || 2}
              maxRows={10}
              name={this.props.prompt.attr}
              id={this.props.prompt.attr}
              {...this.props.prompt}
              className={`outline-none focus:outline-none text-${this.props.size || "sm"} bg-white rounded-md ${this.props.size ? "px-2 py-2" : "px-4 py-4"}  min-w-full border border-gray-300 font-regular focus:border-gray-400 ${this.props.prompt.error ? "border-red-400" : "border-gray-300"} font-regular mt-2 `}
              onChange={this.onChange}
            />
          }

          {this.props.prompt.type === "radio" && this.props.prompt.options && <>
            <div className="mt-1">
              {this.props.prompt.options.map((option, index) =>
                <Option
                  key={index}
                  Icon={option.Icon}
                  title={option.title}
                  desc={option.desc}
                  active={option.value === this.props.prompt.value}
                  onClick={() => this.onRadioClick(option, this.props.prompt?.for)}
                />)}
            </div>
          </>
          }

          {this.props.prompt.type === "file" &&
            <div className="mt-1">
              <input
                type="file"
                name={this.props.prompt.attr}
                id={this.props.prompt.attr}
                {...(this.props.prompt.accept && { accept: this.props.prompt.accept })}
                onChange={(e) => {
                  const file = e.target.files[0];
                  this.props.prompt.error = "";
                  this.props.onLoading(true);

                  if (file) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = async () => {
                      const formData = new FormData();
                      formData.append('file', file);
                      const response = await fetch('https://api.navix.ai/api/v1/files/upload', {
                        method: 'POST',
                        body: formData
                      });
                      const data = await response.json();
                      this.props.onLoading(false)
                      this.props.prompt.value = data.text;
                      this.handlePreview(data.text)
                    };
                  } else {
                    this.props.prompt.value = ""
                    this.handlePreview("")
                    this.props.onLoading(false)
                  }
                }}
              />
            </div>
          }

          {this.props.prompt.type === "file_preview" &&
            <div className="mt-1">{this.props.previewValue}</div>
          }

          {this.props.prompt.type === "audio" &&
						<>
							<div className={`mt-1 flex ${this.props.prompt.isRecording ? 'flex-col' : ''} justify-center items-center space-x-4`}>
								{!this.props.prompt.isRecording && <>
									<label htmlFor={this.props.prompt.attr} className="cursor-pointer inline-flex items-center bg-gray-500 px-2 py-1 rounded-md text-white text-center text-md transition hover:from-gray-700 hover:to-gray-800">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
											<path fillRule="evenodd" d="M11.47 2.47a.75.75 0 011.06 0l4.5 4.5a.75.75 0 01-1.06 1.06l-3.22-3.22V16.5a.75.75 0 01-1.5 0V4.81L8.03 8.03a.75.75 0 01-1.06-1.06l4.5-4.5zM3 15.75a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z" clipRule="evenodd" />
										</svg>
										Choose a file
									</label>
									<input
										type="file"
										name={this.props.prompt.attr}
										id={this.props.prompt.attr}
										className="hidden"
										accept='audio/*'
										onChange={(e) => {
											const file = e.target.files[0];
											if (file) {
												try {
													this.props.prompt.audioUrl = URL.createObjectURL(file)
													this.props.onSelectedFile(e.target.files[0])
												} catch (err) {
													this.props.onSelectedFile(null)
												}
											}
										}}
									/>
								</>}
								{!this.props.prompt.isRecording && this.props.prompt.useRecorder && <span>Or</span>}
								{this.props.prompt.useRecorder && <>
										<WaveSurferRecorder 
											onAudioUrl={(e) => this.props.prompt.audioUrl = e} 
											onBlob={(e) => this.props.onSelectedFile(e)}
											recording={(e) => this.props.prompt.isRecording = e} 
											isRecording={this.props.prompt.isRecording} 
										/>
									</>
								}
							</div>
              {this.props.prompt.audioUrl && <EntryAudio audioUrl={this.props.prompt.audioUrl} getAudioDuration={(val) => this.props.onAudioDuration(val)} />}
						</>
          }

        </div>

				{this.props.prompt.navixscribeLabel && <p className='text-sm text-gray-500 pt-2 text-justify' dangerouslySetInnerHTML={{__html: this.props.prompt.navixscribeLabel}} />}

        {(this.props.prompt.label || this.props.prompt.error) &&
          <div
            htmlFor={this.props.prompt.attr}
            className={`${this.props.prompt.error ? "text-red-400" : "text-gray-400"} text-xs transition-all line mt-1`}>
            {this.props.prompt.error || this.props.prompt.label}
          </div>
        }

      </div>
    )
  }
}

const Option = ({ index, title, desc, active, onClick, Icon }) =>
  <div
    className={`select-none flex w-full text-left items-center font-medium py-1 px-2 hover:bg-${active ? "gray" : "gray"}-200 bg-${active ? "gray" : "na"}-100 rounded-md cursor-pointer transition`} onClick={onClick}>

    <div
      className={`flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-${active ? "gray-500" : "gray-300"} mr-4 transition`}>

      {Icon ? <Icon className={`transition h-4 w-4 text-${active ? "white" : "gray-400"}`} aria-hidden="true" /> : <CheckIcon className={`transition h-4 w-4 text-${active ? "white" : "gray-400"}`} aria-hidden="true" />}

    </div>
    <div>

      {title}

      <div className="font-normal text-sm text-gray-400">{desc}</div>
    </div>
  </div>

const CheckboxOption = ({ label, checked, onClick }) => (
  <div
    className="select-none flex w-full text-left items-center text-sm py-1 px-2 hover:bg-gray-200 bg-gray-100 rounded-md cursor-pointer transition"
    onClick={onClick}  // move onClick handler here
  >
    <input
      type="checkbox"
      checked={checked}
      onChange={(e) => e.stopPropagation()} // add this line to stop the event from propagating to the parent div
      className="mr-4 transition"
    />
    {label}
  </div>
);


export default EntryInput
