import {
  MenuAlt2Icon,
} from '@heroicons/react/solid'


const obj = {

  title: "Blogs and Articles",
  desc: "Create an article or blog post on topic of your choice",
  category: "Content",
  Icon: MenuAlt2Icon,
  // tags: [],
  permissions: ['user'],

  fromColor: "gray-500",
  toColor: "gray-500",

  to: "/ai/writing/blogwriter",
  api: "/ai/writing/blogwriter",

  output: {
    title: "Blog",
    desc: "Here is the suggested blog post. You are advised that this AI-generated content may contain errors and requires your review.",
    // Icon: RefreshIcon,
    // color: "",
  },

  prompts: [{
    title: "Entry Text",
    desc: "Provide brief entries for each input below.",
    // n: 1,
    prompts: [
      {
        title: "Topic",
        attr: "topic",
        value: "",
        placeholder: "Article Topic",
        label: "The topic or main idea for the article",
        // type: "textarea",
        maxLength: 100,
        // max: 100,
        min: 5,
        required: true,
        error: "",
        example: "Spending Too Much Time on Social Media?",
      },
      {
        title: "Title",
        attr: "title",
        value: "",
        placeholder: "Article Title",
        label: "The title you prefer for the article.",
        // type: "textarea",
        maxLength: 100,
        // max: 100,
        min: 5,
        required: true,
        error: "",
        example: "Provide an attention grabbing title.",
      },
      {
        title: "Purpose",
        attr: "purpose",
        value: "",
        placeholder: "Article Purpose",
        label: "The purpose or goal for the article",
        // type: "textarea",
        maxLength: 100,
        // max: 100,
        min: 5,
        required: true,
        error: "",
        example: "Explore how social media can affect your stress level.",
      },
      {
        title: "Tone",
        attr: "tone",
        // value: "", 
        placeholder: "Article's Tone",
        label: "Examples of tone: serious, funny, assertive, formal, informal.",
        type: "dropdown",
        options: [
          { value: "Informative", label: "Informative", Icon: MenuAlt2Icon },
          { value: "Conversational", label: "Conversational", Icon: MenuAlt2Icon },
          { value: "Formal", label: "Formal", Icon: MenuAlt2Icon },
          { value: "Playful", label: "Playful", Icon: MenuAlt2Icon },
          { value: "Authoritative", label: "Authoritative", Icon: MenuAlt2Icon },
          { value: "Inspirational", label: "Inspirational", Icon: MenuAlt2Icon },
          { value: "Empathetic", label: "Empathetic", Icon: MenuAlt2Icon },
          { value: "Thought-provoking:", label: "Thought-provoking:", Icon: MenuAlt2Icon }
        ],
        // maxLength: 50,
        // max: 100,
        // min: 5,
        required: true,
        error: "",
        example: "Informative",
      },
      {
        title: "Length",
        attr: "length",
        // value: "", 
        placeholder: "Article Length",
        label: "The target number of words.",
        type: "dropdown",
        options: [
          { value: "50 words", label: "50 words", Icon: MenuAlt2Icon },
          { value: "100 words", label: "100 words", Icon: MenuAlt2Icon },
          { value: "150 words", label: "150 words", Icon: MenuAlt2Icon },
          { value: "200 words", label: "200 words", Icon: MenuAlt2Icon },
          { value: "250 words", label: "250 words", Icon: MenuAlt2Icon },
          { value: "300 words", label: "300 words", Icon: MenuAlt2Icon },
          { value: "350 words", label: "350 words", Icon: MenuAlt2Icon },
          { value: "400 words", label: "400 words", Icon: MenuAlt2Icon },
          { value: "450 words", label: "450 words", Icon: MenuAlt2Icon },
          { value: "500 words", label: "500 words", Icon: MenuAlt2Icon }
        ],
        // maxLength: 500,
        // max: 500,
        // min: 5,
        required: true,
        error: "",
        example: "150 words",
      },
      {
        title: "SEO Keywords",
        attr: "keywords",
        value: "",
        placeholder: "SEO keywords or phrases",
        label: "Any specific keywords you want to include?",
        type: "textarea",
        maxLength: 300,
        // max: 100,
        // min: 100,
        // required: true,
        error: "",
        example: "managing stress, social media, mental health, wellness, anxiety, depression",
      },
      {
        title: "Audience",
        attr: "audience",
        value: "",
        placeholder: "Target Audience",
        label: "Who the article is intended for.",
        // type: "textarea",
        maxLength: 50,
        // max: 100,
        // min: 5,
        // required: true,
        error: "",
        example: "People interested in better managing stress",
      },

    ],
    example: {
      outputs: [
        "Breaking the Social Media Stress Cycle: \n\nSocial media can be a great way to stay connected with friends and family; however, excessive use can also contribute to stress and mental health issues. Anxiety, depression, and feelings of isolation can all arise from spending too much time on social media. Taking steps to limit your time on these platforms and finding healthy ways to use them can help manage the stress that comes from being constantly “plugged in.”\n\nBegin by setting limits for yourself. Create a daily schedule and stick to it, making sure to keep your online time under control. Unsubscribe from notifications and emails that may be causing more stress than necessary. And don’t forget to take breaks throughout the day—it’s important to step away from your devices and get some fresh air or engage in physical activity./n/nFinally, be mindful of how you’re using social media. Use it as a way to boost your mental health by engaging with inspiring content or connecting with positive people. Don’t forget that the internet is a vast place full of both good and bad information, so make sure to take time to assess what’s best for you.\n\nIf you’re struggling with managing stress related to social media, we invite you to visit our website for more information on how to take control of your mental health and find ways to use social media that are beneficial for your overall wellness.",
      ],
      // Icon: RefreshIcon,
      // color: "",
    }
  }]

}

export default obj

