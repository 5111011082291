import { MenuAlt2Icon } from "@heroicons/react/solid";

const obj = {
  title: "Note Writer",
  desc: "Create a clinical note in your chosen format (SOAP, DAP, SIRP, BIRP, GIRP) using our form template.",
  category: "Clinical",
  Icon: MenuAlt2Icon,
  // tags: [],
  permissions: ["user"],

  fromColor: "gray-500",
  toColor: "gray-500",

  to: "/ai/clinical/clinicalnoteswriter",
  api: "/ai/clinical/clinicalnoteswriter",

  output: {
    title: "Clinical Note",
    desc: "Here is the suggested clinical note. You are advised that this AI-generated decision support tool may contain errors and requires your review.",
    // Icon: RefreshIcon,
    // color: "",
  },

  prompts: [
    {
      title: "Create your note",
      desc: "Provide brief entries for each input below.",
      // n: 1,
      prompts: [
        {
          title: "Session Date",
          attr: "sessiondate",
          value: "",
          placeholder: "10/15/2023",
          label: "Enter the date of the session",
          // type: "textarea",
          maxLength: 30,
          // max: 100,
          min: 5,
          required: true,
          error: "",
          example: "10/15/2023",
        },
        {
          title: "Session Type",
          attr: "sessiontype",
          // value: "",
          placeholder: "Office",
          label: "Type of session",
          type: "dropdown",
          options: [
            { value: "", label: "", Icon: MenuAlt2Icon },
            {
              value: "Office",
              label: "Office",
              Icon: MenuAlt2Icon,
            },
            { value: "Phone", label: "Phone", Icon: MenuAlt2Icon },
            { value: "Video", label: "Video", Icon: MenuAlt2Icon },
            { value: "Home", label: "Home", Icon: MenuAlt2Icon },
            { value: "Text", label: "Text", Icon: MenuAlt2Icon },
          ],
          // maxLength: 50,
          // max: 100,
          // min: 5,
          required: true,
          error: "",
          example: "Office",
        },
        {
          title: "Session Duration (minutes)",
          attr: "duration",
          value: "",
          placeholder: "Session duration in minutes",
          label: "Session duration in minutes",
          type: "number",
          maxLength: 5,
          // max: 100,
          // min: 100,
          // required: true,
          error: "",
          example: "30",
        },
        {
          title: "Note Format",
          attr: "noteformat",
          // value: "",
          placeholder: "Note Format",
          label: "Format of the note",
          type: "dropdown",
          options: [
            { value: "", label: "", Icon: MenuAlt2Icon },
            { value: "SOAP", label: "SOAP", Icon: MenuAlt2Icon },
            { value: "DAP", label: "DAP", Icon: MenuAlt2Icon },
            { value: "SIRP", label: "SIRP", Icon: MenuAlt2Icon },
            { value: "BIRP", label: "BIRP", Icon: MenuAlt2Icon },
            { value: "GIRP", label: "GIRP", Icon: MenuAlt2Icon },
          ],
          // maxLength: 50,
          // max: 100,
          // min: 5,
          required: true,
          error: "",
          example: "SOAP",
        },
        {
          title: "Primary Diagnosis",
          attr: "primarydiagnosis",
          // value: "",
          placeholder: "",
          label: "Primary diagnosis",
          type: "dropdown",
          options: [
            { value: "", label: "", Icon: MenuAlt2Icon },
            {
              value: "(F01.50) Vascular dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F01.50) Vascular dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.511) Vascular dementia, unspecified severity, with agitation",
              label: "(F01.511) Vascular dementia, unspecified severity, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.518) Vascular dementia, unspecified severity, with other behavioral disturbance",
              label: "(F01.518) Vascular dementia, unspecified severity, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.52) Vascular dementia, unspecified severity, with psychotic disturbance",
              label: "(F01.52) Vascular dementia, unspecified severity, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.53) Vascular dementia, unspecified severity, with mood disturbance",
              label: "(F01.53) Vascular dementia, unspecified severity, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.54) Vascular dementia, unspecified severity, with anxiety",
              label: "(F01.54) Vascular dementia, unspecified severity, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A0) Vascular dementia, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F01.A0) Vascular dementia, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A11) Vascular dementia, mild, with agitation",
              label: "(F01.A11) Vascular dementia, mild, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A18) Vascular dementia, mild, with other behavioral disturbance",
              label: "(F01.A18) Vascular dementia, mild, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A2) Vascular dementia, mild, with psychotic disturbance",
              label: "(F01.A2) Vascular dementia, mild, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A3) Vascular dementia, mild, with mood disturbance",
              label: "(F01.A3) Vascular dementia, mild, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A4) Vascular dementia, mild, with anxiety",
              label: "(F01.A4) Vascular dementia, mild, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B0) Vascular dementia, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F01.B0) Vascular dementia, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B11) Vascular dementia, moderate, with agitation",
              label: "(F01.B11) Vascular dementia, moderate, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B18) Vascular dementia, moderate, with other behavioral disturbance",
              label: "(F01.B18) Vascular dementia, moderate, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B2) Vascular dementia, moderate, with psychotic disturbance",
              label: "(F01.B2) Vascular dementia, moderate, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B3) Vascular dementia, moderate, with mood disturbance",
              label: "(F01.B3) Vascular dementia, moderate, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B4) Vascular dementia, moderate, with anxiety",
              label: "(F01.B4) Vascular dementia, moderate, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C0) Vascular dementia, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F01.C0) Vascular dementia, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C11) Vascular dementia, severe, with agitation",
              label: "(F01.C11) Vascular dementia, severe, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C18) Vascular dementia, severe, with other behavioral disturbance",
              label: "(F01.C18) Vascular dementia, severe, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C2) Vascular dementia, severe, with psychotic disturbance",
              label: "(F01.C2) Vascular dementia, severe, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C3) Vascular dementia, severe, with mood disturbance",
              label: "(F01.C3) Vascular dementia, severe, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C4) Vascular dementia, severe, with anxiety",
              label: "(F01.C4) Vascular dementia, severe, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.80) Dementia in other diseases classified elsewhere, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F02.80) Dementia in other diseases classified elsewhere, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.811) Dementia in other diseases classified elsewhere, unspecified severity, with agitation",
              label: "(F02.811) Dementia in other diseases classified elsewhere, unspecified severity, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.818) Dementia in other diseases classified elsewhere, unspecified severity, with other behavioral disturbance",
              label: "(F02.818) Dementia in other diseases classified elsewhere, unspecified severity, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.82) Dementia in other diseases classified elsewhere, unspecified severity, with psychotic disturbance",
              label: "(F02.82) Dementia in other diseases classified elsewhere, unspecified severity, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.83) Dementia in other diseases classified elsewhere, unspecified severity, with mood disturbance",
              label: "(F02.83) Dementia in other diseases classified elsewhere, unspecified severity, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.84) Dementia in other diseases classified elsewhere, unspecified severity, with anxiety",
              label: "(F02.84) Dementia in other diseases classified elsewhere, unspecified severity, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A0) Dementia in other diseases classified elsewhere, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F02.A0) Dementia in other diseases classified elsewhere, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A11) Dementia in other diseases classified elsewhere, mild, with agitation",
              label: "(F02.A11) Dementia in other diseases classified elsewhere, mild, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A18) Dementia in other diseases classified elsewhere, mild, with other behavioral disturbance",
              label: "(F02.A18) Dementia in other diseases classified elsewhere, mild, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A2) Dementia in other diseases classified elsewhere, mild, with psychotic disturbance",
              label: "(F02.A2) Dementia in other diseases classified elsewhere, mild, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A3) Dementia in other diseases classified elsewhere, mild, with mood disturbance",
              label: "(F02.A3) Dementia in other diseases classified elsewhere, mild, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A4) Dementia in other diseases classified elsewhere, mild, with anxiety",
              label: "(F02.A4) Dementia in other diseases classified elsewhere, mild, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B0) Dementia in other diseases classified elsewhere, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F02.B0) Dementia in other diseases classified elsewhere, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B11) Dementia in other diseases classified elsewhere, moderate, with agitation",
              label: "(F02.B11) Dementia in other diseases classified elsewhere, moderate, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B18) Dementia in other diseases classified elsewhere, moderate, with other behavioral disturbance",
              label: "(F02.B18) Dementia in other diseases classified elsewhere, moderate, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B2) Dementia in other diseases classified elsewhere, moderate, with psychotic disturbance",
              label: "(F02.B2) Dementia in other diseases classified elsewhere, moderate, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B3) Dementia in other diseases classified elsewhere, moderate, with mood disturbance",
              label: "(F02.B3) Dementia in other diseases classified elsewhere, moderate, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B4) Dementia in other diseases classified elsewhere, moderate, with anxiety",
              label: "(F02.B4) Dementia in other diseases classified elsewhere, moderate, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C0) Dementia in other diseases classified elsewhere, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F02.C0) Dementia in other diseases classified elsewhere, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C11) Dementia in other diseases classified elsewhere, severe, with agitation",
              label: "(F02.C11) Dementia in other diseases classified elsewhere, severe, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C18) Dementia in other diseases classified elsewhere, severe, with other behavioral disturbance",
              label: "(F02.C18) Dementia in other diseases classified elsewhere, severe, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C2) Dementia in other diseases classified elsewhere, severe, with psychotic disturbance",
              label: "(F02.C2) Dementia in other diseases classified elsewhere, severe, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C3) Dementia in other diseases classified elsewhere, severe, with mood disturbance",
              label: "(F02.C3) Dementia in other diseases classified elsewhere, severe, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C4) Dementia in other diseases classified elsewhere, severe, with anxiety",
              label: "(F02.C4) Dementia in other diseases classified elsewhere, severe, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.90) Unspecified dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F03.90) Unspecified dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.911) Unspecified dementia, unspecified severity, with agitation",
              label: "(F03.911) Unspecified dementia, unspecified severity, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.918) Unspecified dementia, unspecified severity, with other behavioral disturbance",
              label: "(F03.918) Unspecified dementia, unspecified severity, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.92) Unspecified dementia, unspecified severity, with psychotic disturbance",
              label: "(F03.92) Unspecified dementia, unspecified severity, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.93) Unspecified dementia, unspecified severity, with mood disturbance",
              label: "(F03.93) Unspecified dementia, unspecified severity, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.94) Unspecified dementia, unspecified severity, with anxiety",
              label: "(F03.94) Unspecified dementia, unspecified severity, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A0) Unspecified dementia, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F03.A0) Unspecified dementia, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A11) Unspecified dementia, mild, with agitation",
              label: "(F03.A11) Unspecified dementia, mild, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A18) Unspecified dementia, mild, with other behavioral disturbance",
              label: "(F03.A18) Unspecified dementia, mild, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A2) Unspecified dementia, mild, with psychotic disturbance",
              label: "(F03.A2) Unspecified dementia, mild, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A3) Unspecified dementia, mild, with mood disturbance",
              label: "(F03.A3) Unspecified dementia, mild, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A4) Unspecified dementia, mild, with anxiety",
              label: "(F03.A4) Unspecified dementia, mild, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B0) Unspecified dementia, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F03.B0) Unspecified dementia, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B11) Unspecified dementia, moderate, with agitation",
              label: "(F03.B11) Unspecified dementia, moderate, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B18) Unspecified dementia, moderate, with other behavioral disturbance",
              label: "(F03.B18) Unspecified dementia, moderate, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B2) Unspecified dementia, moderate, with psychotic disturbance",
              label: "(F03.B2) Unspecified dementia, moderate, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B3) Unspecified dementia, moderate, with mood disturbance",
              label: "(F03.B3) Unspecified dementia, moderate, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B4) Unspecified dementia, moderate, with anxiety",
              label: "(F03.B4) Unspecified dementia, moderate, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C0) Unspecified dementia, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F03.C0) Unspecified dementia, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C11) Unspecified dementia, severe, with agitation",
              label: "(F03.C11) Unspecified dementia, severe, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C18) Unspecified dementia, severe, with other behavioral disturbance",
              label: "(F03.C18) Unspecified dementia, severe, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C2) Unspecified dementia, severe, with psychotic disturbance",
              label: "(F03.C2) Unspecified dementia, severe, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C3) Unspecified dementia, severe, with mood disturbance",
              label: "(F03.C3) Unspecified dementia, severe, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C4) Unspecified dementia, severe, with anxiety",
              label: "(F03.C4) Unspecified dementia, severe, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F04.) Amnestic disorder due to known physiological condition",
              label: "(F04.) Amnestic disorder due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F05.) Delirium due to known physiological condition",
              label: "(F05.) Delirium due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.0) Psychotic disorder with hallucinations due to known physiological condition",
              label: "(F06.0) Psychotic disorder with hallucinations due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.1) Catatonic disorder due to known physiological condition",
              label: "(F06.1) Catatonic disorder due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.2) Psychotic disorder with delusions due to known physiological condition",
              label: "(F06.2) Psychotic disorder with delusions due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.30) Mood disorder due to known physiological condition, unspecified",
              label: "(F06.30) Mood disorder due to known physiological condition, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.31) Mood disorder due to known physiological condition with depressive features",
              label: "(F06.31) Mood disorder due to known physiological condition with depressive features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.32) Mood disorder due to known physiological condition with major depressive-like episode",
              label: "(F06.32) Mood disorder due to known physiological condition with major depressive-like episode",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.33) Mood disorder due to known physiological condition with manic features",
              label: "(F06.33) Mood disorder due to known physiological condition with manic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.34) Mood disorder due to known physiological condition with mixed features",
              label: "(F06.34) Mood disorder due to known physiological condition with mixed features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.4) Anxiety disorder due to known physiological condition",
              label: "(F06.4) Anxiety disorder due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.70) Mild neurocognitive disorder due to known physiological condition without behavioral disturbance",
              label: "(F06.70) Mild neurocognitive disorder due to known physiological condition without behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.71) Mild neurocognitive disorder due to known physiological condition with behavioral disturbance",
              label: "(F06.71) Mild neurocognitive disorder due to known physiological condition with behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.8) Other specified mental disorders due to known physiological condition",
              label: "(F06.8) Other specified mental disorders due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F07.0) Personality change due to known physiological condition",
              label: "(F07.0) Personality change due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F07.81) Postconcussional syndrome",
              label: "(F07.81) Postconcussional syndrome",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F07.89) Other personality and behavioral disorders due to known physiological condition",
              label: "(F07.89) Other personality and behavioral disorders due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F07.9) Unspecified personality and behavioral disorder due to known physiological condition",
              label: "(F07.9) Unspecified personality and behavioral disorder due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F09.) Unspecified mental disorder due to known physiological condition",
              label: "(F09.) Unspecified mental disorder due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.10) Alcohol abuse, uncomplicated",
              label: "(F10.10) Alcohol abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.11) Alcohol abuse, in remission",
              label: "(F10.11) Alcohol abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.120) Alcohol abuse with intoxication, uncomplicated",
              label: "(F10.120) Alcohol abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.121) Alcohol abuse with intoxication delirium",
              label: "(F10.121) Alcohol abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.129) Alcohol abuse with intoxication, unspecified",
              label: "(F10.129) Alcohol abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.130) Alcohol abuse with withdrawal, uncomplicated",
              label: "(F10.130) Alcohol abuse with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.131) Alcohol abuse with withdrawal delirium",
              label: "(F10.131) Alcohol abuse with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.132) Alcohol abuse with withdrawal with perceptual disturbance",
              label: "(F10.132) Alcohol abuse with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.139) Alcohol abuse with withdrawal, unspecified",
              label: "(F10.139) Alcohol abuse with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.14) Alcohol abuse with alcohol-induced mood disorder",
              label: "(F10.14) Alcohol abuse with alcohol-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.150) Alcohol abuse with alcohol-induced psychotic disorder with delusions",
              label: "(F10.150) Alcohol abuse with alcohol-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.151) Alcohol abuse with alcohol-induced psychotic disorder with hallucinations",
              label: "(F10.151) Alcohol abuse with alcohol-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.159) Alcohol abuse with alcohol-induced psychotic disorder, unspecified",
              label: "(F10.159) Alcohol abuse with alcohol-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.180) Alcohol abuse with alcohol-induced anxiety disorder",
              label: "(F10.180) Alcohol abuse with alcohol-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.181) Alcohol abuse with alcohol-induced sexual dysfunction",
              label: "(F10.181) Alcohol abuse with alcohol-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.182) Alcohol abuse with alcohol-induced sleep disorder",
              label: "(F10.182) Alcohol abuse with alcohol-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.188) Alcohol abuse with other alcohol-induced disorder",
              label: "(F10.188) Alcohol abuse with other alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.19) Alcohol abuse with unspecified alcohol-induced disorder",
              label: "(F10.19) Alcohol abuse with unspecified alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.20) Alcohol dependence, uncomplicated",
              label: "(F10.20) Alcohol dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.21) Alcohol dependence, in remission",
              label: "(F10.21) Alcohol dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.220) Alcohol dependence with intoxication, uncomplicated",
              label: "(F10.220) Alcohol dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.221) Alcohol dependence with intoxication delirium",
              label: "(F10.221) Alcohol dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.229) Alcohol dependence with intoxication, unspecified",
              label: "(F10.229) Alcohol dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.230) Alcohol dependence with withdrawal, uncomplicated",
              label: "(F10.230) Alcohol dependence with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.231) Alcohol dependence with withdrawal delirium",
              label: "(F10.231) Alcohol dependence with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.232) Alcohol dependence with withdrawal with perceptual disturbance",
              label: "(F10.232) Alcohol dependence with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.239) Alcohol dependence with withdrawal, unspecified",
              label: "(F10.239) Alcohol dependence with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.24) Alcohol dependence with alcohol-induced mood disorder",
              label: "(F10.24) Alcohol dependence with alcohol-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.250) Alcohol dependence with alcohol-induced psychotic disorder with delusions",
              label: "(F10.250) Alcohol dependence with alcohol-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.251) Alcohol dependence with alcohol-induced psychotic disorder with hallucinations",
              label: "(F10.251) Alcohol dependence with alcohol-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.259) Alcohol dependence with alcohol-induced psychotic disorder, unspecified",
              label: "(F10.259) Alcohol dependence with alcohol-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.26) Alcohol dependence with alcohol-induced persisting amnestic disorder",
              label: "(F10.26) Alcohol dependence with alcohol-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.27) Alcohol dependence with alcohol-induced persisting dementia",
              label: "(F10.27) Alcohol dependence with alcohol-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.280) Alcohol dependence with alcohol-induced anxiety disorder",
              label: "(F10.280) Alcohol dependence with alcohol-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.281) Alcohol dependence with alcohol-induced sexual dysfunction",
              label: "(F10.281) Alcohol dependence with alcohol-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.282) Alcohol dependence with alcohol-induced sleep disorder",
              label: "(F10.282) Alcohol dependence with alcohol-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.288) Alcohol dependence with other alcohol-induced disorder",
              label: "(F10.288) Alcohol dependence with other alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.29) Alcohol dependence with unspecified alcohol-induced disorder",
              label: "(F10.29) Alcohol dependence with unspecified alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.90) Alcohol use, unspecified, uncomplicated",
              label: "(F10.90) Alcohol use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.91) Alcohol use, unspecified, in remission",
              label: "(F10.91) Alcohol use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.920) Alcohol use, unspecified with intoxication, uncomplicated",
              label: "(F10.920) Alcohol use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.921) Alcohol use, unspecified with intoxication delirium",
              label: "(F10.921) Alcohol use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.929) Alcohol use, unspecified with intoxication, unspecified",
              label: "(F10.929) Alcohol use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.930) Alcohol use, unspecified with withdrawal, uncomplicated",
              label: "(F10.930) Alcohol use, unspecified with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.931) Alcohol use, unspecified with withdrawal delirium",
              label: "(F10.931) Alcohol use, unspecified with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.932) Alcohol use, unspecified with withdrawal with perceptual disturbance",
              label: "(F10.932) Alcohol use, unspecified with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.939) Alcohol use, unspecified with withdrawal, unspecified",
              label: "(F10.939) Alcohol use, unspecified with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.94) Alcohol use, unspecified with alcohol-induced mood disorder",
              label: "(F10.94) Alcohol use, unspecified with alcohol-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.950) Alcohol use, unspecified with alcohol-induced psychotic disorder with delusions",
              label: "(F10.950) Alcohol use, unspecified with alcohol-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.951) Alcohol use, unspecified with alcohol-induced psychotic disorder with hallucinations",
              label: "(F10.951) Alcohol use, unspecified with alcohol-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.959) Alcohol use, unspecified with alcohol-induced psychotic disorder, unspecified",
              label: "(F10.959) Alcohol use, unspecified with alcohol-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.96) Alcohol use, unspecified with alcohol-induced persisting amnestic disorder",
              label: "(F10.96) Alcohol use, unspecified with alcohol-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.97) Alcohol use, unspecified with alcohol-induced persisting dementia",
              label: "(F10.97) Alcohol use, unspecified with alcohol-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.980) Alcohol use, unspecified with alcohol-induced anxiety disorder",
              label: "(F10.980) Alcohol use, unspecified with alcohol-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.981) Alcohol use, unspecified with alcohol-induced sexual dysfunction",
              label: "(F10.981) Alcohol use, unspecified with alcohol-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.982) Alcohol use, unspecified with alcohol-induced sleep disorder",
              label: "(F10.982) Alcohol use, unspecified with alcohol-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.988) Alcohol use, unspecified with other alcohol-induced disorder",
              label: "(F10.988) Alcohol use, unspecified with other alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.99) Alcohol use, unspecified with unspecified alcohol-induced disorder",
              label: "(F10.99) Alcohol use, unspecified with unspecified alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.10) Opioid abuse, uncomplicated",
              label: "(F11.10) Opioid abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.11) Opioid abuse, in remission",
              label: "(F11.11) Opioid abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.120) Opioid abuse with intoxication, uncomplicated",
              label: "(F11.120) Opioid abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.121) Opioid abuse with intoxication delirium",
              label: "(F11.121) Opioid abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.122) Opioid abuse with intoxication with perceptual disturbance",
              label: "(F11.122) Opioid abuse with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.129) Opioid abuse with intoxication, unspecified",
              label: "(F11.129) Opioid abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.13) Opioid abuse with withdrawal",
              label: "(F11.13) Opioid abuse with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.14) Opioid abuse with opioid-induced mood disorder",
              label: "(F11.14) Opioid abuse with opioid-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.150) Opioid abuse with opioid-induced psychotic disorder with delusions",
              label: "(F11.150) Opioid abuse with opioid-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.151) Opioid abuse with opioid-induced psychotic disorder with hallucinations",
              label: "(F11.151) Opioid abuse with opioid-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.159) Opioid abuse with opioid-induced psychotic disorder, unspecified",
              label: "(F11.159) Opioid abuse with opioid-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.181) Opioid abuse with opioid-induced sexual dysfunction",
              label: "(F11.181) Opioid abuse with opioid-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.182) Opioid abuse with opioid-induced sleep disorder",
              label: "(F11.182) Opioid abuse with opioid-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.188) Opioid abuse with other opioid-induced disorder",
              label: "(F11.188) Opioid abuse with other opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.19) Opioid abuse with unspecified opioid-induced disorder",
              label: "(F11.19) Opioid abuse with unspecified opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.20) Opioid dependence, uncomplicated",
              label: "(F11.20) Opioid dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.21) Opioid dependence, in remission",
              label: "(F11.21) Opioid dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.220) Opioid dependence with intoxication, uncomplicated",
              label: "(F11.220) Opioid dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.221) Opioid dependence with intoxication delirium",
              label: "(F11.221) Opioid dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.222) Opioid dependence with intoxication with perceptual disturbance",
              label: "(F11.222) Opioid dependence with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.229) Opioid dependence with intoxication, unspecified",
              label: "(F11.229) Opioid dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.23) Opioid dependence with withdrawal",
              label: "(F11.23) Opioid dependence with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.24) Opioid dependence with opioid-induced mood disorder",
              label: "(F11.24) Opioid dependence with opioid-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.250) Opioid dependence with opioid-induced psychotic disorder with delusions",
              label: "(F11.250) Opioid dependence with opioid-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.251) Opioid dependence with opioid-induced psychotic disorder with hallucinations",
              label: "(F11.251) Opioid dependence with opioid-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.259) Opioid dependence with opioid-induced psychotic disorder, unspecified",
              label: "(F11.259) Opioid dependence with opioid-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.281) Opioid dependence with opioid-induced sexual dysfunction",
              label: "(F11.281) Opioid dependence with opioid-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.282) Opioid dependence with opioid-induced sleep disorder",
              label: "(F11.282) Opioid dependence with opioid-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.288) Opioid dependence with other opioid-induced disorder",
              label: "(F11.288) Opioid dependence with other opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.29) Opioid dependence with unspecified opioid-induced disorder",
              label: "(F11.29) Opioid dependence with unspecified opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.90) Opioid use, unspecified, uncomplicated",
              label: "(F11.90) Opioid use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.91) Opioid use, unspecified, in remission",
              label: "(F11.91) Opioid use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.920) Opioid use, unspecified with intoxication, uncomplicated",
              label: "(F11.920) Opioid use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.921) Opioid use, unspecified with intoxication delirium",
              label: "(F11.921) Opioid use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.922) Opioid use, unspecified with intoxication with perceptual disturbance",
              label: "(F11.922) Opioid use, unspecified with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.929) Opioid use, unspecified with intoxication, unspecified",
              label: "(F11.929) Opioid use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.93) Opioid use, unspecified with withdrawal",
              label: "(F11.93) Opioid use, unspecified with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.94) Opioid use, unspecified with opioid-induced mood disorder",
              label: "(F11.94) Opioid use, unspecified with opioid-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.950) Opioid use, unspecified with opioid-induced psychotic disorder with delusions",
              label: "(F11.950) Opioid use, unspecified with opioid-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.951) Opioid use, unspecified with opioid-induced psychotic disorder with hallucinations",
              label: "(F11.951) Opioid use, unspecified with opioid-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.959) Opioid use, unspecified with opioid-induced psychotic disorder, unspecified",
              label: "(F11.959) Opioid use, unspecified with opioid-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.981) Opioid use, unspecified with opioid-induced sexual dysfunction",
              label: "(F11.981) Opioid use, unspecified with opioid-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.982) Opioid use, unspecified with opioid-induced sleep disorder",
              label: "(F11.982) Opioid use, unspecified with opioid-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.988) Opioid use, unspecified with other opioid-induced disorder",
              label: "(F11.988) Opioid use, unspecified with other opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.99) Opioid use, unspecified with unspecified opioid-induced disorder",
              label: "(F11.99) Opioid use, unspecified with unspecified opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.10) Cannabis abuse, uncomplicated",
              label: "(F12.10) Cannabis abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.11) Cannabis abuse, in remission",
              label: "(F12.11) Cannabis abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.120) Cannabis abuse with intoxication, uncomplicated",
              label: "(F12.120) Cannabis abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.121) Cannabis abuse with intoxication delirium",
              label: "(F12.121) Cannabis abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.122) Cannabis abuse with intoxication with perceptual disturbance",
              label: "(F12.122) Cannabis abuse with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.129) Cannabis abuse with intoxication, unspecified",
              label: "(F12.129) Cannabis abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.13) Cannabis abuse with withdrawal",
              label: "(F12.13) Cannabis abuse with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.150) Cannabis abuse with psychotic disorder with delusions",
              label: "(F12.150) Cannabis abuse with psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.151) Cannabis abuse with psychotic disorder with hallucinations",
              label: "(F12.151) Cannabis abuse with psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.159) Cannabis abuse with psychotic disorder, unspecified",
              label: "(F12.159) Cannabis abuse with psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.180) Cannabis abuse with cannabis-induced anxiety disorder",
              label: "(F12.180) Cannabis abuse with cannabis-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.188) Cannabis abuse with other cannabis-induced disorder",
              label: "(F12.188) Cannabis abuse with other cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.19) Cannabis abuse with unspecified cannabis-induced disorder",
              label: "(F12.19) Cannabis abuse with unspecified cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.20) Cannabis dependence, uncomplicated",
              label: "(F12.20) Cannabis dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.21) Cannabis dependence, in remission",
              label: "(F12.21) Cannabis dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.220) Cannabis dependence with intoxication, uncomplicated",
              label: "(F12.220) Cannabis dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.221) Cannabis dependence with intoxication delirium",
              label: "(F12.221) Cannabis dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.222) Cannabis dependence with intoxication with perceptual disturbance",
              label: "(F12.222) Cannabis dependence with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.229) Cannabis dependence with intoxication, unspecified",
              label: "(F12.229) Cannabis dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.23) Cannabis dependence with withdrawal",
              label: "(F12.23) Cannabis dependence with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.250) Cannabis dependence with psychotic disorder with delusions",
              label: "(F12.250) Cannabis dependence with psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.251) Cannabis dependence with psychotic disorder with hallucinations",
              label: "(F12.251) Cannabis dependence with psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.259) Cannabis dependence with psychotic disorder, unspecified",
              label: "(F12.259) Cannabis dependence with psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.280) Cannabis dependence with cannabis-induced anxiety disorder",
              label: "(F12.280) Cannabis dependence with cannabis-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.288) Cannabis dependence with other cannabis-induced disorder",
              label: "(F12.288) Cannabis dependence with other cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.29) Cannabis dependence with unspecified cannabis-induced disorder",
              label: "(F12.29) Cannabis dependence with unspecified cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.90) Cannabis use, unspecified, uncomplicated",
              label: "(F12.90) Cannabis use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.91) Cannabis use, unspecified, in remission",
              label: "(F12.91) Cannabis use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.920) Cannabis use, unspecified with intoxication, uncomplicated",
              label: "(F12.920) Cannabis use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.921) Cannabis use, unspecified with intoxication delirium",
              label: "(F12.921) Cannabis use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.922) Cannabis use, unspecified with intoxication with perceptual disturbance",
              label: "(F12.922) Cannabis use, unspecified with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.929) Cannabis use, unspecified with intoxication, unspecified",
              label: "(F12.929) Cannabis use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.93) Cannabis use, unspecified with withdrawal",
              label: "(F12.93) Cannabis use, unspecified with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.950) Cannabis use, unspecified with psychotic disorder with delusions",
              label: "(F12.950) Cannabis use, unspecified with psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.951) Cannabis use, unspecified with psychotic disorder with hallucinations",
              label: "(F12.951) Cannabis use, unspecified with psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.959) Cannabis use, unspecified with psychotic disorder, unspecified",
              label: "(F12.959) Cannabis use, unspecified with psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.980) Cannabis use, unspecified with anxiety disorder",
              label: "(F12.980) Cannabis use, unspecified with anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.988) Cannabis use, unspecified with other cannabis-induced disorder",
              label: "(F12.988) Cannabis use, unspecified with other cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.99) Cannabis use, unspecified with unspecified cannabis-induced disorder",
              label: "(F12.99) Cannabis use, unspecified with unspecified cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.10) Sedative, hypnotic or anxiolytic abuse, uncomplicated",
              label: "(F13.10) Sedative, hypnotic or anxiolytic abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.11) Sedative, hypnotic or anxiolytic abuse, in remission",
              label: "(F13.11) Sedative, hypnotic or anxiolytic abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.120) Sedative, hypnotic or anxiolytic abuse with intoxication, uncomplicated",
              label: "(F13.120) Sedative, hypnotic or anxiolytic abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.121) Sedative, hypnotic or anxiolytic abuse with intoxication delirium",
              label: "(F13.121) Sedative, hypnotic or anxiolytic abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.129) Sedative, hypnotic or anxiolytic abuse with intoxication, unspecified",
              label: "(F13.129) Sedative, hypnotic or anxiolytic abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.130) Sedative, hypnotic or anxiolytic abuse with withdrawal, uncomplicated",
              label: "(F13.130) Sedative, hypnotic or anxiolytic abuse with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.131) Sedative, hypnotic or anxiolytic abuse with withdrawal delirium",
              label: "(F13.131) Sedative, hypnotic or anxiolytic abuse with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.132) Sedative, hypnotic or anxiolytic abuse with withdrawal with perceptual disturbance",
              label: "(F13.132) Sedative, hypnotic or anxiolytic abuse with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.139) Sedative, hypnotic or anxiolytic abuse with withdrawal, unspecified",
              label: "(F13.139) Sedative, hypnotic or anxiolytic abuse with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.14) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced mood disorder",
              label: "(F13.14) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.150) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              label: "(F13.150) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.151) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              label: "(F13.151) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.159) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              label: "(F13.159) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.180) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              label: "(F13.180) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.181) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              label: "(F13.181) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.182) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sleep disorder",
              label: "(F13.182) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.188) Sedative, hypnotic or anxiolytic abuse with other sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.188) Sedative, hypnotic or anxiolytic abuse with other sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.19) Sedative, hypnotic or anxiolytic abuse with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.19) Sedative, hypnotic or anxiolytic abuse with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.20) Sedative, hypnotic or anxiolytic dependence, uncomplicated",
              label: "(F13.20) Sedative, hypnotic or anxiolytic dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.21) Sedative, hypnotic or anxiolytic dependence, in remission",
              label: "(F13.21) Sedative, hypnotic or anxiolytic dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.220) Sedative, hypnotic or anxiolytic dependence with intoxication, uncomplicated",
              label: "(F13.220) Sedative, hypnotic or anxiolytic dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.221) Sedative, hypnotic or anxiolytic dependence with intoxication delirium",
              label: "(F13.221) Sedative, hypnotic or anxiolytic dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.229) Sedative, hypnotic or anxiolytic dependence with intoxication, unspecified",
              label: "(F13.229) Sedative, hypnotic or anxiolytic dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.230) Sedative, hypnotic or anxiolytic dependence with withdrawal, uncomplicated",
              label: "(F13.230) Sedative, hypnotic or anxiolytic dependence with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.231) Sedative, hypnotic or anxiolytic dependence with withdrawal delirium",
              label: "(F13.231) Sedative, hypnotic or anxiolytic dependence with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.232) Sedative, hypnotic or anxiolytic dependence with withdrawal with perceptual disturbance",
              label: "(F13.232) Sedative, hypnotic or anxiolytic dependence with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.239) Sedative, hypnotic or anxiolytic dependence with withdrawal, unspecified",
              label: "(F13.239) Sedative, hypnotic or anxiolytic dependence with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.24) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced mood disorder",
              label: "(F13.24) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.250) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              label: "(F13.250) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.251) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              label: "(F13.251) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.259) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              label: "(F13.259) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.26) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder",
              label: "(F13.26) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.27) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting dementia",
              label: "(F13.27) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.280) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              label: "(F13.280) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.281) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              label: "(F13.281) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.282) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sleep disorder",
              label: "(F13.282) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.288) Sedative, hypnotic or anxiolytic dependence with other sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.288) Sedative, hypnotic or anxiolytic dependence with other sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.29) Sedative, hypnotic or anxiolytic dependence with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.29) Sedative, hypnotic or anxiolytic dependence with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.90) Sedative, hypnotic, or anxiolytic use, unspecified, uncomplicated",
              label: "(F13.90) Sedative, hypnotic, or anxiolytic use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.91) Sedative, hypnotic or anxiolytic use, unspecified, in remission",
              label: "(F13.91) Sedative, hypnotic or anxiolytic use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.920) Sedative, hypnotic or anxiolytic use, unspecified with intoxication, uncomplicated",
              label: "(F13.920) Sedative, hypnotic or anxiolytic use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.921) Sedative, hypnotic or anxiolytic use, unspecified with intoxication delirium",
              label: "(F13.921) Sedative, hypnotic or anxiolytic use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.929) Sedative, hypnotic or anxiolytic use, unspecified with intoxication, unspecified",
              label: "(F13.929) Sedative, hypnotic or anxiolytic use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.930) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, uncomplicated",
              label: "(F13.930) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.931) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal delirium",
              label: "(F13.931) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.932) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal with perceptual disturbances",
              label: "(F13.932) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal with perceptual disturbances",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.939) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, unspecified",
              label: "(F13.939) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.94) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced mood disorder",
              label: "(F13.94) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.950) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              label: "(F13.950) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.951) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              label: "(F13.951) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.959) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              label: "(F13.959) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.96) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder",
              label: "(F13.96) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.97) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting dementia",
              label: "(F13.97) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.980) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              label: "(F13.980) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.981) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              label: "(F13.981) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.982) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sleep disorder",
              label: "(F13.982) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.988) Sedative, hypnotic or anxiolytic use, unspecified with other sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.988) Sedative, hypnotic or anxiolytic use, unspecified with other sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.99) Sedative, hypnotic or anxiolytic use, unspecified with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.99) Sedative, hypnotic or anxiolytic use, unspecified with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.10) Cocaine abuse, uncomplicated",
              label: "(F14.10) Cocaine abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.11) Cocaine abuse, in remission",
              label: "(F14.11) Cocaine abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.120) Cocaine abuse with intoxication, uncomplicated",
              label: "(F14.120) Cocaine abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.121) Cocaine abuse with intoxication with delirium",
              label: "(F14.121) Cocaine abuse with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.122) Cocaine abuse with intoxication with perceptual disturbance",
              label: "(F14.122) Cocaine abuse with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.129) Cocaine abuse with intoxication, unspecified",
              label: "(F14.129) Cocaine abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.13) Cocaine abuse, unspecified with withdrawal",
              label: "(F14.13) Cocaine abuse, unspecified with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.14) Cocaine abuse with cocaine-induced mood disorder",
              label: "(F14.14) Cocaine abuse with cocaine-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.150) Cocaine abuse with cocaine-induced psychotic disorder with delusions",
              label: "(F14.150) Cocaine abuse with cocaine-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.151) Cocaine abuse with cocaine-induced psychotic disorder with hallucinations",
              label: "(F14.151) Cocaine abuse with cocaine-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.159) Cocaine abuse with cocaine-induced psychotic disorder, unspecified",
              label: "(F14.159) Cocaine abuse with cocaine-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.180) Cocaine abuse with cocaine-induced anxiety disorder",
              label: "(F14.180) Cocaine abuse with cocaine-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.181) Cocaine abuse with cocaine-induced sexual dysfunction",
              label: "(F14.181) Cocaine abuse with cocaine-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.182) Cocaine abuse with cocaine-induced sleep disorder",
              label: "(F14.182) Cocaine abuse with cocaine-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.188) Cocaine abuse with other cocaine-induced disorder",
              label: "(F14.188) Cocaine abuse with other cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.19) Cocaine abuse with unspecified cocaine-induced disorder",
              label: "(F14.19) Cocaine abuse with unspecified cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.20) Cocaine dependence, uncomplicated",
              label: "(F14.20) Cocaine dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.21) Cocaine dependence, in remission",
              label: "(F14.21) Cocaine dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.220) Cocaine dependence with intoxication, uncomplicated",
              label: "(F14.220) Cocaine dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.221) Cocaine dependence with intoxication delirium",
              label: "(F14.221) Cocaine dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.222) Cocaine dependence with intoxication with perceptual disturbance",
              label: "(F14.222) Cocaine dependence with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.229) Cocaine dependence with intoxication, unspecified",
              label: "(F14.229) Cocaine dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.23) Cocaine dependence with withdrawal",
              label: "(F14.23) Cocaine dependence with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.24) Cocaine dependence with cocaine-induced mood disorder",
              label: "(F14.24) Cocaine dependence with cocaine-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.250) Cocaine dependence with cocaine-induced psychotic disorder with delusions",
              label: "(F14.250) Cocaine dependence with cocaine-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.251) Cocaine dependence with cocaine-induced psychotic disorder with hallucinations",
              label: "(F14.251) Cocaine dependence with cocaine-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.259) Cocaine dependence with cocaine-induced psychotic disorder, unspecified",
              label: "(F14.259) Cocaine dependence with cocaine-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.280) Cocaine dependence with cocaine-induced anxiety disorder",
              label: "(F14.280) Cocaine dependence with cocaine-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.281) Cocaine dependence with cocaine-induced sexual dysfunction",
              label: "(F14.281) Cocaine dependence with cocaine-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.282) Cocaine dependence with cocaine-induced sleep disorder",
              label: "(F14.282) Cocaine dependence with cocaine-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.288) Cocaine dependence with other cocaine-induced disorder",
              label: "(F14.288) Cocaine dependence with other cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.29) Cocaine dependence with unspecified cocaine-induced disorder",
              label: "(F14.29) Cocaine dependence with unspecified cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.90) Cocaine use, unspecified, uncomplicated",
              label: "(F14.90) Cocaine use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.91) Cocaine use, unspecified, in remission",
              label: "(F14.91) Cocaine use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.920) Cocaine use, unspecified with intoxication, uncomplicated",
              label: "(F14.920) Cocaine use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.921) Cocaine use, unspecified with intoxication delirium",
              label: "(F14.921) Cocaine use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.922) Cocaine use, unspecified with intoxication with perceptual disturbance",
              label: "(F14.922) Cocaine use, unspecified with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.929) Cocaine use, unspecified with intoxication, unspecified",
              label: "(F14.929) Cocaine use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.93) Cocaine use, unspecified with withdrawal",
              label: "(F14.93) Cocaine use, unspecified with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.94) Cocaine use, unspecified with cocaine-induced mood disorder",
              label: "(F14.94) Cocaine use, unspecified with cocaine-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.950) Cocaine use, unspecified with cocaine-induced psychotic disorder with delusions",
              label: "(F14.950) Cocaine use, unspecified with cocaine-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.951) Cocaine use, unspecified with cocaine-induced psychotic disorder with hallucinations",
              label: "(F14.951) Cocaine use, unspecified with cocaine-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.959) Cocaine use, unspecified with cocaine-induced psychotic disorder, unspecified",
              label: "(F14.959) Cocaine use, unspecified with cocaine-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.980) Cocaine use, unspecified with cocaine-induced anxiety disorder",
              label: "(F14.980) Cocaine use, unspecified with cocaine-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.981) Cocaine use, unspecified with cocaine-induced sexual dysfunction",
              label: "(F14.981) Cocaine use, unspecified with cocaine-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.982) Cocaine use, unspecified with cocaine-induced sleep disorder",
              label: "(F14.982) Cocaine use, unspecified with cocaine-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.988) Cocaine use, unspecified with other cocaine-induced disorder",
              label: "(F14.988) Cocaine use, unspecified with other cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.99) Cocaine use, unspecified with unspecified cocaine-induced disorder",
              label: "(F14.99) Cocaine use, unspecified with unspecified cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.10) Other stimulant abuse, uncomplicated",
              label: "(F15.10) Other stimulant abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.11) Other stimulant abuse, in remission",
              label: "(F15.11) Other stimulant abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.120) Other stimulant abuse with intoxication, uncomplicated",
              label: "(F15.120) Other stimulant abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.121) Other stimulant abuse with intoxication delirium",
              label: "(F15.121) Other stimulant abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.122) Other stimulant abuse with intoxication with perceptual disturbance",
              label: "(F15.122) Other stimulant abuse with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.129) Other stimulant abuse with intoxication, unspecified",
              label: "(F15.129) Other stimulant abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.13) Other stimulant abuse with withdrawal",
              label: "(F15.13) Other stimulant abuse with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.14) Other stimulant abuse with stimulant-induced mood disorder",
              label: "(F15.14) Other stimulant abuse with stimulant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.150) Other stimulant abuse with stimulant-induced psychotic disorder with delusions",
              label: "(F15.150) Other stimulant abuse with stimulant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.151) Other stimulant abuse with stimulant-induced psychotic disorder with hallucinations",
              label: "(F15.151) Other stimulant abuse with stimulant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.159) Other stimulant abuse with stimulant-induced psychotic disorder, unspecified",
              label: "(F15.159) Other stimulant abuse with stimulant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.180) Other stimulant abuse with stimulant-induced anxiety disorder",
              label: "(F15.180) Other stimulant abuse with stimulant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.181) Other stimulant abuse with stimulant-induced sexual dysfunction",
              label: "(F15.181) Other stimulant abuse with stimulant-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.182) Other stimulant abuse with stimulant-induced sleep disorder",
              label: "(F15.182) Other stimulant abuse with stimulant-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.188) Other stimulant abuse with other stimulant-induced disorder",
              label: "(F15.188) Other stimulant abuse with other stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.19) Other stimulant abuse with unspecified stimulant-induced disorder",
              label: "(F15.19) Other stimulant abuse with unspecified stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.20) Other stimulant dependence, uncomplicated",
              label: "(F15.20) Other stimulant dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.21) Other stimulant dependence, in remission",
              label: "(F15.21) Other stimulant dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.220) Other stimulant dependence with intoxication, uncomplicated",
              label: "(F15.220) Other stimulant dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.221) Other stimulant dependence with intoxication delirium",
              label: "(F15.221) Other stimulant dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.222) Other stimulant dependence with intoxication with perceptual disturbance",
              label: "(F15.222) Other stimulant dependence with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.229) Other stimulant dependence with intoxication, unspecified",
              label: "(F15.229) Other stimulant dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.23) Other stimulant dependence with withdrawal",
              label: "(F15.23) Other stimulant dependence with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.24) Other stimulant dependence with stimulant-induced mood disorder",
              label: "(F15.24) Other stimulant dependence with stimulant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.250) Other stimulant dependence with stimulant-induced psychotic disorder with delusions",
              label: "(F15.250) Other stimulant dependence with stimulant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.251) Other stimulant dependence with stimulant-induced psychotic disorder with hallucinations",
              label: "(F15.251) Other stimulant dependence with stimulant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.259) Other stimulant dependence with stimulant-induced psychotic disorder, unspecified",
              label: "(F15.259) Other stimulant dependence with stimulant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.280) Other stimulant dependence with stimulant-induced anxiety disorder",
              label: "(F15.280) Other stimulant dependence with stimulant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.281) Other stimulant dependence with stimulant-induced sexual dysfunction",
              label: "(F15.281) Other stimulant dependence with stimulant-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.282) Other stimulant dependence with stimulant-induced sleep disorder",
              label: "(F15.282) Other stimulant dependence with stimulant-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.288) Other stimulant dependence with other stimulant-induced disorder",
              label: "(F15.288) Other stimulant dependence with other stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.29) Other stimulant dependence with unspecified stimulant-induced disorder",
              label: "(F15.29) Other stimulant dependence with unspecified stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.90) Other stimulant use, unspecified, uncomplicated",
              label: "(F15.90) Other stimulant use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.91) Other stimulant use, unspecified, in remission",
              label: "(F15.91) Other stimulant use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.920) Other stimulant use, unspecified with intoxication, uncomplicated",
              label: "(F15.920) Other stimulant use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.921) Other stimulant use, unspecified with intoxication delirium",
              label: "(F15.921) Other stimulant use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.922) Other stimulant use, unspecified with intoxication with perceptual disturbance",
              label: "(F15.922) Other stimulant use, unspecified with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.929) Other stimulant use, unspecified with intoxication, unspecified",
              label: "(F15.929) Other stimulant use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.93) Other stimulant use, unspecified with withdrawal",
              label: "(F15.93) Other stimulant use, unspecified with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.94) Other stimulant use, unspecified with stimulant-induced mood disorder",
              label: "(F15.94) Other stimulant use, unspecified with stimulant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.950) Other stimulant use, unspecified with stimulant-induced psychotic disorder with delusions",
              label: "(F15.950) Other stimulant use, unspecified with stimulant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.951) Other stimulant use, unspecified with stimulant-induced psychotic disorder with hallucinations",
              label: "(F15.951) Other stimulant use, unspecified with stimulant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.959) Other stimulant use, unspecified with stimulant-induced psychotic disorder, unspecified",
              label: "(F15.959) Other stimulant use, unspecified with stimulant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.980) Other stimulant use, unspecified with stimulant-induced anxiety disorder",
              label: "(F15.980) Other stimulant use, unspecified with stimulant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.981) Other stimulant use, unspecified with stimulant-induced sexual dysfunction",
              label: "(F15.981) Other stimulant use, unspecified with stimulant-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.982) Other stimulant use, unspecified with stimulant-induced sleep disorder",
              label: "(F15.982) Other stimulant use, unspecified with stimulant-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.988) Other stimulant use, unspecified with other stimulant-induced disorder",
              label: "(F15.988) Other stimulant use, unspecified with other stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.99) Other stimulant use, unspecified with unspecified stimulant-induced disorder",
              label: "(F15.99) Other stimulant use, unspecified with unspecified stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.10) Hallucinogen abuse, uncomplicated",
              label: "(F16.10) Hallucinogen abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.11) Hallucinogen abuse, in remission",
              label: "(F16.11) Hallucinogen abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.120) Hallucinogen abuse with intoxication, uncomplicated",
              label: "(F16.120) Hallucinogen abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.121) Hallucinogen abuse with intoxication with delirium",
              label: "(F16.121) Hallucinogen abuse with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.122) Hallucinogen abuse with intoxication with perceptual disturbance",
              label: "(F16.122) Hallucinogen abuse with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.129) Hallucinogen abuse with intoxication, unspecified",
              label: "(F16.129) Hallucinogen abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.14) Hallucinogen abuse with hallucinogen-induced mood disorder",
              label: "(F16.14) Hallucinogen abuse with hallucinogen-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.150) Hallucinogen abuse with hallucinogen-induced psychotic disorder with delusions",
              label: "(F16.150) Hallucinogen abuse with hallucinogen-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.151) Hallucinogen abuse with hallucinogen-induced psychotic disorder with hallucinations",
              label: "(F16.151) Hallucinogen abuse with hallucinogen-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.159) Hallucinogen abuse with hallucinogen-induced psychotic disorder, unspecified",
              label: "(F16.159) Hallucinogen abuse with hallucinogen-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.180) Hallucinogen abuse with hallucinogen-induced anxiety disorder",
              label: "(F16.180) Hallucinogen abuse with hallucinogen-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.183) Hallucinogen abuse with hallucinogen persisting perception disorder (flashbacks)",
              label: "(F16.183) Hallucinogen abuse with hallucinogen persisting perception disorder (flashbacks)",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.188) Hallucinogen abuse with other hallucinogen-induced disorder",
              label: "(F16.188) Hallucinogen abuse with other hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.19) Hallucinogen abuse with unspecified hallucinogen-induced disorder",
              label: "(F16.19) Hallucinogen abuse with unspecified hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.20) Hallucinogen dependence, uncomplicated",
              label: "(F16.20) Hallucinogen dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.21) Hallucinogen dependence, in remission",
              label: "(F16.21) Hallucinogen dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.220) Hallucinogen dependence with intoxication, uncomplicated",
              label: "(F16.220) Hallucinogen dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.221) Hallucinogen dependence with intoxication with delirium",
              label: "(F16.221) Hallucinogen dependence with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.229) Hallucinogen dependence with intoxication, unspecified",
              label: "(F16.229) Hallucinogen dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.24) Hallucinogen dependence with hallucinogen-induced mood disorder",
              label: "(F16.24) Hallucinogen dependence with hallucinogen-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.250) Hallucinogen dependence with hallucinogen-induced psychotic disorder with delusions",
              label: "(F16.250) Hallucinogen dependence with hallucinogen-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.251) Hallucinogen dependence with hallucinogen-induced psychotic disorder with hallucinations",
              label: "(F16.251) Hallucinogen dependence with hallucinogen-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.259) Hallucinogen dependence with hallucinogen-induced psychotic disorder, unspecified",
              label: "(F16.259) Hallucinogen dependence with hallucinogen-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.280) Hallucinogen dependence with hallucinogen-induced anxiety disorder",
              label: "(F16.280) Hallucinogen dependence with hallucinogen-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.283) Hallucinogen dependence with hallucinogen persisting perception disorder (flashbacks)",
              label: "(F16.283) Hallucinogen dependence with hallucinogen persisting perception disorder (flashbacks)",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.288) Hallucinogen dependence with other hallucinogen-induced disorder",
              label: "(F16.288) Hallucinogen dependence with other hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.29) Hallucinogen dependence with unspecified hallucinogen-induced disorder",
              label: "(F16.29) Hallucinogen dependence with unspecified hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.90) Hallucinogen use, unspecified, uncomplicated",
              label: "(F16.90) Hallucinogen use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.91) Hallucinogen use, unspecified, in remission",
              label: "(F16.91) Hallucinogen use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.920) Hallucinogen use, unspecified with intoxication, uncomplicated",
              label: "(F16.920) Hallucinogen use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.921) Hallucinogen use, unspecified with intoxication with delirium",
              label: "(F16.921) Hallucinogen use, unspecified with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.929) Hallucinogen use, unspecified with intoxication, unspecified",
              label: "(F16.929) Hallucinogen use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.94) Hallucinogen use, unspecified with hallucinogen-induced mood disorder",
              label: "(F16.94) Hallucinogen use, unspecified with hallucinogen-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.950) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with delusions",
              label: "(F16.950) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.951) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with hallucinations",
              label: "(F16.951) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.959) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder, unspecified",
              label: "(F16.959) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.980) Hallucinogen use, unspecified with hallucinogen-induced anxiety disorder",
              label: "(F16.980) Hallucinogen use, unspecified with hallucinogen-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.983) Hallucinogen use, unspecified with hallucinogen persisting perception disorder (flashbacks)",
              label: "(F16.983) Hallucinogen use, unspecified with hallucinogen persisting perception disorder (flashbacks)",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.988) Hallucinogen use, unspecified with other hallucinogen-induced disorder",
              label: "(F16.988) Hallucinogen use, unspecified with other hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.99) Hallucinogen use, unspecified with unspecified hallucinogen-induced disorder",
              label: "(F16.99) Hallucinogen use, unspecified with unspecified hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.200) Nicotine dependence, unspecified, uncomplicated",
              label: "(F17.200) Nicotine dependence, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.201) Nicotine dependence, unspecified, in remission",
              label: "(F17.201) Nicotine dependence, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.203) Nicotine dependence unspecified, with withdrawal",
              label: "(F17.203) Nicotine dependence unspecified, with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.208) Nicotine dependence, unspecified, with other nicotine-induced disorders",
              label: "(F17.208) Nicotine dependence, unspecified, with other nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.209) Nicotine dependence, unspecified, with unspecified nicotine-induced disorders",
              label: "(F17.209) Nicotine dependence, unspecified, with unspecified nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.210) Nicotine dependence, cigarettes, uncomplicated",
              label: "(F17.210) Nicotine dependence, cigarettes, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.211) Nicotine dependence, cigarettes, in remission",
              label: "(F17.211) Nicotine dependence, cigarettes, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.213) Nicotine dependence, cigarettes, with withdrawal",
              label: "(F17.213) Nicotine dependence, cigarettes, with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.218) Nicotine dependence, cigarettes, with other nicotine-induced disorders",
              label: "(F17.218) Nicotine dependence, cigarettes, with other nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.219) Nicotine dependence, cigarettes, with unspecified nicotine-induced disorders",
              label: "(F17.219) Nicotine dependence, cigarettes, with unspecified nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.220) Nicotine dependence, chewing tobacco, uncomplicated",
              label: "(F17.220) Nicotine dependence, chewing tobacco, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.221) Nicotine dependence, chewing tobacco, in remission",
              label: "(F17.221) Nicotine dependence, chewing tobacco, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.223) Nicotine dependence, chewing tobacco, with withdrawal",
              label: "(F17.223) Nicotine dependence, chewing tobacco, with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.228) Nicotine dependence, chewing tobacco, with other nicotine-induced disorders",
              label: "(F17.228) Nicotine dependence, chewing tobacco, with other nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.229) Nicotine dependence, chewing tobacco, with unspecified nicotine-induced disorders",
              label: "(F17.229) Nicotine dependence, chewing tobacco, with unspecified nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.290) Nicotine dependence, other tobacco product, uncomplicated",
              label: "(F17.290) Nicotine dependence, other tobacco product, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.291) Nicotine dependence, other tobacco product, in remission",
              label: "(F17.291) Nicotine dependence, other tobacco product, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.293) Nicotine dependence, other tobacco product, with withdrawal",
              label: "(F17.293) Nicotine dependence, other tobacco product, with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.298) Nicotine dependence, other tobacco product, with other nicotine-induced disorders",
              label: "(F17.298) Nicotine dependence, other tobacco product, with other nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.299) Nicotine dependence, other tobacco product, with unspecified nicotine-induced disorders",
              label: "(F17.299) Nicotine dependence, other tobacco product, with unspecified nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.10) Inhalant abuse, uncomplicated",
              label: "(F18.10) Inhalant abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.11) Inhalant abuse, in remission",
              label: "(F18.11) Inhalant abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.120) Inhalant abuse with intoxication, uncomplicated",
              label: "(F18.120) Inhalant abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.121) Inhalant abuse with intoxication delirium",
              label: "(F18.121) Inhalant abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.129) Inhalant abuse with intoxication, unspecified",
              label: "(F18.129) Inhalant abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.14) Inhalant abuse with inhalant-induced mood disorder",
              label: "(F18.14) Inhalant abuse with inhalant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.150) Inhalant abuse with inhalant-induced psychotic disorder with delusions",
              label: "(F18.150) Inhalant abuse with inhalant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.151) Inhalant abuse with inhalant-induced psychotic disorder with hallucinations",
              label: "(F18.151) Inhalant abuse with inhalant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.159) Inhalant abuse with inhalant-induced psychotic disorder, unspecified",
              label: "(F18.159) Inhalant abuse with inhalant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.17) Inhalant abuse with inhalant-induced dementia",
              label: "(F18.17) Inhalant abuse with inhalant-induced dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.180) Inhalant abuse with inhalant-induced anxiety disorder",
              label: "(F18.180) Inhalant abuse with inhalant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.188) Inhalant abuse with other inhalant-induced disorder",
              label: "(F18.188) Inhalant abuse with other inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.19) Inhalant abuse with unspecified inhalant-induced disorder",
              label: "(F18.19) Inhalant abuse with unspecified inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.20) Inhalant dependence, uncomplicated",
              label: "(F18.20) Inhalant dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.21) Inhalant dependence, in remission",
              label: "(F18.21) Inhalant dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.220) Inhalant dependence with intoxication, uncomplicated",
              label: "(F18.220) Inhalant dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.221) Inhalant dependence with intoxication delirium",
              label: "(F18.221) Inhalant dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.229) Inhalant dependence with intoxication, unspecified",
              label: "(F18.229) Inhalant dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.24) Inhalant dependence with inhalant-induced mood disorder",
              label: "(F18.24) Inhalant dependence with inhalant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.250) Inhalant dependence with inhalant-induced psychotic disorder with delusions",
              label: "(F18.250) Inhalant dependence with inhalant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.251) Inhalant dependence with inhalant-induced psychotic disorder with hallucinations",
              label: "(F18.251) Inhalant dependence with inhalant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.259) Inhalant dependence with inhalant-induced psychotic disorder, unspecified",
              label: "(F18.259) Inhalant dependence with inhalant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.27) Inhalant dependence with inhalant-induced dementia",
              label: "(F18.27) Inhalant dependence with inhalant-induced dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.280) Inhalant dependence with inhalant-induced anxiety disorder",
              label: "(F18.280) Inhalant dependence with inhalant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.288) Inhalant dependence with other inhalant-induced disorder",
              label: "(F18.288) Inhalant dependence with other inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.29) Inhalant dependence with unspecified inhalant-induced disorder",
              label: "(F18.29) Inhalant dependence with unspecified inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.90) Inhalant use, unspecified, uncomplicated",
              label: "(F18.90) Inhalant use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.91) Inhalant use, unspecified, in remission",
              label: "(F18.91) Inhalant use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.920) Inhalant use, unspecified with intoxication, uncomplicated",
              label: "(F18.920) Inhalant use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.921) Inhalant use, unspecified with intoxication with delirium",
              label: "(F18.921) Inhalant use, unspecified with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.929) Inhalant use, unspecified with intoxication, unspecified",
              label: "(F18.929) Inhalant use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.94) Inhalant use, unspecified with inhalant-induced mood disorder",
              label: "(F18.94) Inhalant use, unspecified with inhalant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.950) Inhalant use, unspecified with inhalant-induced psychotic disorder with delusions",
              label: "(F18.950) Inhalant use, unspecified with inhalant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.951) Inhalant use, unspecified with inhalant-induced psychotic disorder with hallucinations",
              label: "(F18.951) Inhalant use, unspecified with inhalant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.959) Inhalant use, unspecified with inhalant-induced psychotic disorder, unspecified",
              label: "(F18.959) Inhalant use, unspecified with inhalant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.97) Inhalant use, unspecified with inhalant-induced persisting dementia",
              label: "(F18.97) Inhalant use, unspecified with inhalant-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.980) Inhalant use, unspecified with inhalant-induced anxiety disorder",
              label: "(F18.980) Inhalant use, unspecified with inhalant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.988) Inhalant use, unspecified with other inhalant-induced disorder",
              label: "(F18.988) Inhalant use, unspecified with other inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.99) Inhalant use, unspecified with unspecified inhalant-induced disorder",
              label: "(F18.99) Inhalant use, unspecified with unspecified inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.10) Other psychoactive substance abuse, uncomplicated",
              label: "(F19.10) Other psychoactive substance abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.11) Other psychoactive substance abuse, in remission",
              label: "(F19.11) Other psychoactive substance abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.120) Other psychoactive substance abuse with intoxication, uncomplicated",
              label: "(F19.120) Other psychoactive substance abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.121) Other psychoactive substance abuse with intoxication delirium",
              label: "(F19.121) Other psychoactive substance abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.122) Other psychoactive substance abuse with intoxication with perceptual disturbances",
              label: "(F19.122) Other psychoactive substance abuse with intoxication with perceptual disturbances",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.129) Other psychoactive substance abuse with intoxication, unspecified",
              label: "(F19.129) Other psychoactive substance abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.130) Other psychoactive substance abuse with withdrawal, uncomplicated",
              label: "(F19.130) Other psychoactive substance abuse with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.131) Other psychoactive substance abuse with withdrawal delirium",
              label: "(F19.131) Other psychoactive substance abuse with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.132) Other psychoactive substance abuse with withdrawal with perceptual disturbance",
              label: "(F19.132) Other psychoactive substance abuse with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.139) Other psychoactive substance abuse with withdrawal, unspecified",
              label: "(F19.139) Other psychoactive substance abuse with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.14) Other psychoactive substance abuse with psychoactive substance-induced mood disorder",
              label: "(F19.14) Other psychoactive substance abuse with psychoactive substance-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.150) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with delusions",
              label: "(F19.150) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.151) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with hallucinations",
              label: "(F19.151) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.159) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder, unspecified",
              label: "(F19.159) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.16) Other psychoactive substance abuse with psychoactive substance-induced persisting amnestic disorder",
              label: "(F19.16) Other psychoactive substance abuse with psychoactive substance-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.17) Other psychoactive substance abuse with psychoactive substance-induced persisting dementia",
              label: "(F19.17) Other psychoactive substance abuse with psychoactive substance-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.180) Other psychoactive substance abuse with psychoactive substance-induced anxiety disorder",
              label: "(F19.180) Other psychoactive substance abuse with psychoactive substance-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.181) Other psychoactive substance abuse with psychoactive substance-induced sexual dysfunction",
              label: "(F19.181) Other psychoactive substance abuse with psychoactive substance-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.182) Other psychoactive substance abuse with psychoactive substance-induced sleep disorder",
              label: "(F19.182) Other psychoactive substance abuse with psychoactive substance-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.188) Other psychoactive substance abuse with other psychoactive substance-induced disorder",
              label: "(F19.188) Other psychoactive substance abuse with other psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.19) Other psychoactive substance abuse with unspecified psychoactive substance-induced disorder",
              label: "(F19.19) Other psychoactive substance abuse with unspecified psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.20) Other psychoactive substance dependence, uncomplicated",
              label: "(F19.20) Other psychoactive substance dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.21) Other psychoactive substance dependence, in remission",
              label: "(F19.21) Other psychoactive substance dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.220) Other psychoactive substance dependence with intoxication, uncomplicated",
              label: "(F19.220) Other psychoactive substance dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.221) Other psychoactive substance dependence with intoxication delirium",
              label: "(F19.221) Other psychoactive substance dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.222) Other psychoactive substance dependence with intoxication with perceptual disturbance",
              label: "(F19.222) Other psychoactive substance dependence with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.229) Other psychoactive substance dependence with intoxication, unspecified",
              label: "(F19.229) Other psychoactive substance dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.230) Other psychoactive substance dependence with withdrawal, uncomplicated",
              label: "(F19.230) Other psychoactive substance dependence with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.231) Other psychoactive substance dependence with withdrawal delirium",
              label: "(F19.231) Other psychoactive substance dependence with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.232) Other psychoactive substance dependence with withdrawal with perceptual disturbance",
              label: "(F19.232) Other psychoactive substance dependence with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.239) Other psychoactive substance dependence with withdrawal, unspecified",
              label: "(F19.239) Other psychoactive substance dependence with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.24) Other psychoactive substance dependence with psychoactive substance-induced mood disorder",
              label: "(F19.24) Other psychoactive substance dependence with psychoactive substance-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.250) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with delusions",
              label: "(F19.250) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.251) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with hallucinations",
              label: "(F19.251) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.259) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder, unspecified",
              label: "(F19.259) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.26) Other psychoactive substance dependence with psychoactive substance-induced persisting amnestic disorder",
              label: "(F19.26) Other psychoactive substance dependence with psychoactive substance-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.27) Other psychoactive substance dependence with psychoactive substance-induced persisting dementia",
              label: "(F19.27) Other psychoactive substance dependence with psychoactive substance-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.280) Other psychoactive substance dependence with psychoactive substance-induced anxiety disorder",
              label: "(F19.280) Other psychoactive substance dependence with psychoactive substance-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.281) Other psychoactive substance dependence with psychoactive substance-induced sexual dysfunction",
              label: "(F19.281) Other psychoactive substance dependence with psychoactive substance-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.282) Other psychoactive substance dependence with psychoactive substance-induced sleep disorder",
              label: "(F19.282) Other psychoactive substance dependence with psychoactive substance-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.288) Other psychoactive substance dependence with other psychoactive substance-induced disorder",
              label: "(F19.288) Other psychoactive substance dependence with other psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.29) Other psychoactive substance dependence with unspecified psychoactive substance-induced disorder",
              label: "(F19.29) Other psychoactive substance dependence with unspecified psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.90) Other psychoactive substance use, unspecified, uncomplicated",
              label: "(F19.90) Other psychoactive substance use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.91) Other psychoactive substance use, unspecified, in remission",
              label: "(F19.91) Other psychoactive substance use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.920) Other psychoactive substance use, unspecified with intoxication, uncomplicated",
              label: "(F19.920) Other psychoactive substance use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.921) Other psychoactive substance use, unspecified with intoxication with delirium",
              label: "(F19.921) Other psychoactive substance use, unspecified with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.922) Other psychoactive substance use, unspecified with intoxication with perceptual disturbance",
              label: "(F19.922) Other psychoactive substance use, unspecified with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.929) Other psychoactive substance use, unspecified with intoxication, unspecified",
              label: "(F19.929) Other psychoactive substance use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.930) Other psychoactive substance use, unspecified with withdrawal, uncomplicated",
              label: "(F19.930) Other psychoactive substance use, unspecified with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.931) Other psychoactive substance use, unspecified with withdrawal delirium",
              label: "(F19.931) Other psychoactive substance use, unspecified with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.932) Other psychoactive substance use, unspecified with withdrawal with perceptual disturbance",
              label: "(F19.932) Other psychoactive substance use, unspecified with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.939) Other psychoactive substance use, unspecified with withdrawal, unspecified",
              label: "(F19.939) Other psychoactive substance use, unspecified with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.94) Other psychoactive substance use, unspecified with psychoactive substance-induced mood disorder",
              label: "(F19.94) Other psychoactive substance use, unspecified with psychoactive substance-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.950) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with delusions",
              label: "(F19.950) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.951) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with hallucinations",
              label: "(F19.951) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.959) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder, unspecified",
              label: "(F19.959) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.96) Other psychoactive substance use, unspecified with psychoactive substance-induced persisting amnestic disorder",
              label: "(F19.96) Other psychoactive substance use, unspecified with psychoactive substance-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.97) Other psychoactive substance use, unspecified with psychoactive substance-induced persisting dementia",
              label: "(F19.97) Other psychoactive substance use, unspecified with psychoactive substance-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.980) Other psychoactive substance use, unspecified with psychoactive substance-induced anxiety disorder",
              label: "(F19.980) Other psychoactive substance use, unspecified with psychoactive substance-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.981) Other psychoactive substance use, unspecified with psychoactive substance-induced sexual dysfunction",
              label: "(F19.981) Other psychoactive substance use, unspecified with psychoactive substance-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.982) Other psychoactive substance use, unspecified with psychoactive substance-induced sleep disorder",
              label: "(F19.982) Other psychoactive substance use, unspecified with psychoactive substance-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.988) Other psychoactive substance use, unspecified with other psychoactive substance-induced disorder",
              label: "(F19.988) Other psychoactive substance use, unspecified with other psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.99) Other psychoactive substance use, unspecified with unspecified psychoactive substance-induced disorder",
              label: "(F19.99) Other psychoactive substance use, unspecified with unspecified psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.0) Paranoid schizophrenia",
              label: "(F20.0) Paranoid schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.1) Disorganized schizophrenia",
              label: "(F20.1) Disorganized schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.2) Catatonic schizophrenia",
              label: "(F20.2) Catatonic schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.3) Undifferentiated schizophrenia",
              label: "(F20.3) Undifferentiated schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.5) Residual schizophrenia",
              label: "(F20.5) Residual schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.81) Schizophreniform disorder",
              label: "(F20.81) Schizophreniform disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.89) Other schizophrenia",
              label: "(F20.89) Other schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.9) Schizophrenia, unspecified",
              label: "(F20.9) Schizophrenia, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F21.) Schizotypal disorder",
              label: "(F21.) Schizotypal disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F22.) Delusional disorders",
              label: "(F22.) Delusional disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F23.) Brief psychotic disorder",
              label: "(F23.) Brief psychotic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F24.) Shared psychotic disorder",
              label: "(F24.) Shared psychotic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F25.0) Schizoaffective disorder, bipolar type",
              label: "(F25.0) Schizoaffective disorder, bipolar type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F25.1) Schizoaffective disorder, depressive type",
              label: "(F25.1) Schizoaffective disorder, depressive type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F25.8) Other schizoaffective disorders",
              label: "(F25.8) Other schizoaffective disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F25.9) Schizoaffective disorder, unspecified",
              label: "(F25.9) Schizoaffective disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F28.) Other psychotic disorder not due to a substance or known physiological condition",
              label: "(F28.) Other psychotic disorder not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F29.) Unspecified psychosis not due to a substance or known physiological condition",
              label: "(F29.) Unspecified psychosis not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.10) Manic episode without psychotic symptoms, unspecified",
              label: "(F30.10) Manic episode without psychotic symptoms, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.11) Manic episode without psychotic symptoms, mild",
              label: "(F30.11) Manic episode without psychotic symptoms, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.12) Manic episode without psychotic symptoms, moderate",
              label: "(F30.12) Manic episode without psychotic symptoms, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.13) Manic episode, severe, without psychotic symptoms",
              label: "(F30.13) Manic episode, severe, without psychotic symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.2) Manic episode, severe with psychotic symptoms",
              label: "(F30.2) Manic episode, severe with psychotic symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.3) Manic episode in partial remission",
              label: "(F30.3) Manic episode in partial remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.4) Manic episode in full remission",
              label: "(F30.4) Manic episode in full remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.8) Other manic episodes",
              label: "(F30.8) Other manic episodes",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.9) Manic episode, unspecified",
              label: "(F30.9) Manic episode, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.0) Bipolar disorder, current episode hypomanic",
              label: "(F31.0) Bipolar disorder, current episode hypomanic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.10) Bipolar disorder, current episode manic without psychotic features, unspecified",
              label: "(F31.10) Bipolar disorder, current episode manic without psychotic features, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.11) Bipolar disorder, current episode manic without psychotic features, mild",
              label: "(F31.11) Bipolar disorder, current episode manic without psychotic features, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.12) Bipolar disorder, current episode manic without psychotic features, moderate",
              label: "(F31.12) Bipolar disorder, current episode manic without psychotic features, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.13) Bipolar disorder, current episode manic without psychotic features, severe",
              label: "(F31.13) Bipolar disorder, current episode manic without psychotic features, severe",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.2) Bipolar disorder, current episode manic severe with psychotic features",
              label: "(F31.2) Bipolar disorder, current episode manic severe with psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.30) Bipolar disorder, current episode depressed, mild or moderate severity, unspecified",
              label: "(F31.30) Bipolar disorder, current episode depressed, mild or moderate severity, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.31) Bipolar disorder, current episode depressed, mild",
              label: "(F31.31) Bipolar disorder, current episode depressed, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.32) Bipolar disorder, current episode depressed, moderate",
              label: "(F31.32) Bipolar disorder, current episode depressed, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.4) Bipolar disorder, current episode depressed, severe, without psychotic features",
              label: "(F31.4) Bipolar disorder, current episode depressed, severe, without psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.5) Bipolar disorder, current episode depressed, severe, with psychotic features",
              label: "(F31.5) Bipolar disorder, current episode depressed, severe, with psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.60) Bipolar disorder, current episode mixed, unspecified",
              label: "(F31.60) Bipolar disorder, current episode mixed, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.61) Bipolar disorder, current episode mixed, mild",
              label: "(F31.61) Bipolar disorder, current episode mixed, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.62) Bipolar disorder, current episode mixed, moderate",
              label: "(F31.62) Bipolar disorder, current episode mixed, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.63) Bipolar disorder, current episode mixed, severe, without psychotic features",
              label: "(F31.63) Bipolar disorder, current episode mixed, severe, without psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.64) Bipolar disorder, current episode mixed, severe, with psychotic features",
              label: "(F31.64) Bipolar disorder, current episode mixed, severe, with psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.70) Bipolar disorder, currently in remission, most recent episode unspecified",
              label: "(F31.70) Bipolar disorder, currently in remission, most recent episode unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.71) Bipolar disorder, in partial remission, most recent episode hypomanic",
              label: "(F31.71) Bipolar disorder, in partial remission, most recent episode hypomanic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.72) Bipolar disorder, in full remission, most recent episode hypomanic",
              label: "(F31.72) Bipolar disorder, in full remission, most recent episode hypomanic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.73) Bipolar disorder, in partial remission, most recent episode manic",
              label: "(F31.73) Bipolar disorder, in partial remission, most recent episode manic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.74) Bipolar disorder, in full remission, most recent episode manic",
              label: "(F31.74) Bipolar disorder, in full remission, most recent episode manic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.75) Bipolar disorder, in partial remission, most recent episode depressed",
              label: "(F31.75) Bipolar disorder, in partial remission, most recent episode depressed",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.76) Bipolar disorder, in full remission, most recent episode depressed",
              label: "(F31.76) Bipolar disorder, in full remission, most recent episode depressed",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.77) Bipolar disorder, in partial remission, most recent episode mixed",
              label: "(F31.77) Bipolar disorder, in partial remission, most recent episode mixed",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.78) Bipolar disorder, in full remission, most recent episode mixed",
              label: "(F31.78) Bipolar disorder, in full remission, most recent episode mixed",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.81) Bipolar II disorder",
              label: "(F31.81) Bipolar II disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.89) Other bipolar disorder",
              label: "(F31.89) Other bipolar disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.9) Bipolar disorder, unspecified",
              label: "(F31.9) Bipolar disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.0) Major depressive disorder, single episode, mild",
              label: "(F32.0) Major depressive disorder, single episode, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.1) Major depressive disorder, single episode, moderate",
              label: "(F32.1) Major depressive disorder, single episode, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.2) Major depressive disorder, single episode, severe without psychotic features",
              label: "(F32.2) Major depressive disorder, single episode, severe without psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.3) Major depressive disorder, single episode, severe with psychotic features",
              label: "(F32.3) Major depressive disorder, single episode, severe with psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.4) Major depressive disorder, single episode, in partial remission",
              label: "(F32.4) Major depressive disorder, single episode, in partial remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.5) Major depressive disorder, single episode, in full remission",
              label: "(F32.5) Major depressive disorder, single episode, in full remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.81) Premenstrual dysphoric disorder",
              label: "(F32.81) Premenstrual dysphoric disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.89) Other specified depressive episodes",
              label: "(F32.89) Other specified depressive episodes",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.9) Major depressive disorder, single episode, unspecified",
              label: "(F32.9) Major depressive disorder, single episode, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.A) Depression, unspecified",
              label: "(F32.A) Depression, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.0) Major depressive disorder, recurrent, mild",
              label: "(F33.0) Major depressive disorder, recurrent, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.1) Major depressive disorder, recurrent, moderate",
              label: "(F33.1) Major depressive disorder, recurrent, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.2) Major depressive disorder, recurrent severe without psychotic features",
              label: "(F33.2) Major depressive disorder, recurrent severe without psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.3) Major depressive disorder, recurrent, severe with psychotic symptoms",
              label: "(F33.3) Major depressive disorder, recurrent, severe with psychotic symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.40) Major depressive disorder, recurrent, in remission, unspecified",
              label: "(F33.40) Major depressive disorder, recurrent, in remission, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.41) Major depressive disorder, recurrent, in partial remission",
              label: "(F33.41) Major depressive disorder, recurrent, in partial remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.42) Major depressive disorder, recurrent, in full remission",
              label: "(F33.42) Major depressive disorder, recurrent, in full remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.8) Other recurrent depressive disorders",
              label: "(F33.8) Other recurrent depressive disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.9) Major depressive disorder, recurrent, unspecified",
              label: "(F33.9) Major depressive disorder, recurrent, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F34.0) Cyclothymic disorder",
              label: "(F34.0) Cyclothymic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F34.1) Dysthymic disorder",
              label: "(F34.1) Dysthymic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F34.81) Disruptive mood dysregulation disorder",
              label: "(F34.81) Disruptive mood dysregulation disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F34.89) Other specified persistent mood disorders",
              label: "(F34.89) Other specified persistent mood disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F34.9) Persistent mood [affective] disorder, unspecified",
              label: "(F34.9) Persistent mood [affective] disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F39.) Unspecified mood [affective] disorder",
              label: "(F39.) Unspecified mood [affective] disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.00) Agoraphobia, unspecified",
              label: "(F40.00) Agoraphobia, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.01) Agoraphobia with panic disorder",
              label: "(F40.01) Agoraphobia with panic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.02) Agoraphobia without panic disorder",
              label: "(F40.02) Agoraphobia without panic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.10) Social phobia, unspecified",
              label: "(F40.10) Social phobia, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.11) Social phobia, generalized",
              label: "(F40.11) Social phobia, generalized",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.210) Arachnophobia",
              label: "(F40.210) Arachnophobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.218) Other animal type phobia",
              label: "(F40.218) Other animal type phobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.220) Fear of thunderstorms",
              label: "(F40.220) Fear of thunderstorms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.228) Other natural environment type phobia",
              label: "(F40.228) Other natural environment type phobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.230) Fear of blood",
              label: "(F40.230) Fear of blood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.231) Fear of injections and transfusions",
              label: "(F40.231) Fear of injections and transfusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.232) Fear of other medical care",
              label: "(F40.232) Fear of other medical care",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.233) Fear of injury",
              label: "(F40.233) Fear of injury",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.240) Claustrophobia",
              label: "(F40.240) Claustrophobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.241) Acrophobia",
              label: "(F40.241) Acrophobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.242) Fear of bridges",
              label: "(F40.242) Fear of bridges",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.243) Fear of flying",
              label: "(F40.243) Fear of flying",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.248) Other situational type phobia",
              label: "(F40.248) Other situational type phobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.290) Androphobia",
              label: "(F40.290) Androphobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.291) Gynephobia",
              label: "(F40.291) Gynephobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.298) Other specified phobia",
              label: "(F40.298) Other specified phobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.8) Other phobic anxiety disorders",
              label: "(F40.8) Other phobic anxiety disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.9) Phobic anxiety disorder, unspecified",
              label: "(F40.9) Phobic anxiety disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F41.0) Panic disorder [episodic paroxysmal anxiety]",
              label: "(F41.0) Panic disorder [episodic paroxysmal anxiety]",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F41.1) Generalized anxiety disorder",
              label: "(F41.1) Generalized anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F41.3) Other mixed anxiety disorders",
              label: "(F41.3) Other mixed anxiety disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F41.8) Other specified anxiety disorders",
              label: "(F41.8) Other specified anxiety disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F41.9) Anxiety disorder, unspecified",
              label: "(F41.9) Anxiety disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F42.2) Mixed obsessional thoughts and acts",
              label: "(F42.2) Mixed obsessional thoughts and acts",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F42.3) Hoarding disorder",
              label: "(F42.3) Hoarding disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F42.4) Excoriation (skin-picking) disorder",
              label: "(F42.4) Excoriation (skin-picking) disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F42.8) Other obsessive-compulsive disorder",
              label: "(F42.8) Other obsessive-compulsive disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F42.9) Obsessive-compulsive disorder, unspecified",
              label: "(F42.9) Obsessive-compulsive disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.0) Acute stress reaction",
              label: "(F43.0) Acute stress reaction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.10) Post-traumatic stress disorder, unspecified",
              label: "(F43.10) Post-traumatic stress disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.11) Post-traumatic stress disorder, acute",
              label: "(F43.11) Post-traumatic stress disorder, acute",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.12) Post-traumatic stress disorder, chronic",
              label: "(F43.12) Post-traumatic stress disorder, chronic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.20) Adjustment disorder, unspecified",
              label: "(F43.20) Adjustment disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.21) Adjustment disorder with depressed mood",
              label: "(F43.21) Adjustment disorder with depressed mood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.22) Adjustment disorder with anxiety",
              label: "(F43.22) Adjustment disorder with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.23) Adjustment disorder with mixed anxiety and depressed mood",
              label: "(F43.23) Adjustment disorder with mixed anxiety and depressed mood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.24) Adjustment disorder with disturbance of conduct",
              label: "(F43.24) Adjustment disorder with disturbance of conduct",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.25) Adjustment disorder with mixed disturbance of emotions and conduct",
              label: "(F43.25) Adjustment disorder with mixed disturbance of emotions and conduct",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.29) Adjustment disorder with other symptoms",
              label: "(F43.29) Adjustment disorder with other symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.81) Prolonged grief disorder",
              label: "(F43.81) Prolonged grief disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.89) Other reactions to severe stress",
              label: "(F43.89) Other reactions to severe stress",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.9) Reaction to severe stress, unspecified",
              label: "(F43.9) Reaction to severe stress, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.0) Dissociative amnesia",
              label: "(F44.0) Dissociative amnesia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.1) Dissociative fugue",
              label: "(F44.1) Dissociative fugue",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.2) Dissociative stupor",
              label: "(F44.2) Dissociative stupor",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.4) Conversion disorder with motor symptom or deficit",
              label: "(F44.4) Conversion disorder with motor symptom or deficit",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.5) Conversion disorder with seizures or convulsions",
              label: "(F44.5) Conversion disorder with seizures or convulsions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.6) Conversion disorder with sensory symptom or deficit",
              label: "(F44.6) Conversion disorder with sensory symptom or deficit",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.7) Conversion disorder with mixed symptom presentation",
              label: "(F44.7) Conversion disorder with mixed symptom presentation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.81) Dissociative identity disorder",
              label: "(F44.81) Dissociative identity disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.89) Other dissociative and conversion disorders",
              label: "(F44.89) Other dissociative and conversion disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.9) Dissociative and conversion disorder, unspecified",
              label: "(F44.9) Dissociative and conversion disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.0) Somatization disorder",
              label: "(F45.0) Somatization disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.1) Undifferentiated somatoform disorder",
              label: "(F45.1) Undifferentiated somatoform disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.20) Hypochondriacal disorder, unspecified",
              label: "(F45.20) Hypochondriacal disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.21) Hypochondriasis",
              label: "(F45.21) Hypochondriasis",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.22) Body dysmorphic disorder",
              label: "(F45.22) Body dysmorphic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.29) Other hypochondriacal disorders",
              label: "(F45.29) Other hypochondriacal disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.41) Pain disorder exclusively related to psychological factors",
              label: "(F45.41) Pain disorder exclusively related to psychological factors",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.42) Pain disorder with related psychological factors",
              label: "(F45.42) Pain disorder with related psychological factors",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.8) Other somatoform disorders",
              label: "(F45.8) Other somatoform disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.9) Somatoform disorder, unspecified",
              label: "(F45.9) Somatoform disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F48.1) Depersonalization-derealization syndrome",
              label: "(F48.1) Depersonalization-derealization syndrome",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F48.2) Pseudobulbar affect",
              label: "(F48.2) Pseudobulbar affect",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F48.8) Other specified nonpsychotic mental disorders",
              label: "(F48.8) Other specified nonpsychotic mental disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F48.9) Nonpsychotic mental disorder, unspecified",
              label: "(F48.9) Nonpsychotic mental disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.00) Anorexia nervosa, unspecified",
              label: "(F50.00) Anorexia nervosa, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.01) Anorexia nervosa, restricting type",
              label: "(F50.01) Anorexia nervosa, restricting type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.02) Anorexia nervosa, binge eating/purging type",
              label: "(F50.02) Anorexia nervosa, binge eating/purging type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.2) Bulimia nervosa",
              label: "(F50.2) Bulimia nervosa",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.81) Binge eating disorder",
              label: "(F50.81) Binge eating disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.82) Avoidant/restrictive food intake disorder",
              label: "(F50.82) Avoidant/restrictive food intake disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.89) Other specified eating disorder",
              label: "(F50.89) Other specified eating disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.9) Eating disorder, unspecified",
              label: "(F50.9) Eating disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.01) Primary insomnia",
              label: "(F51.01) Primary insomnia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.02) Adjustment insomnia",
              label: "(F51.02) Adjustment insomnia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.03) Paradoxical insomnia",
              label: "(F51.03) Paradoxical insomnia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.04) Psychophysiologic insomnia",
              label: "(F51.04) Psychophysiologic insomnia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.05) Insomnia due to other mental disorder",
              label: "(F51.05) Insomnia due to other mental disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.09) Other insomnia not due to a substance or known physiological condition",
              label: "(F51.09) Other insomnia not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.11) Primary hypersomnia",
              label: "(F51.11) Primary hypersomnia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.12) Insufficient sleep syndrome",
              label: "(F51.12) Insufficient sleep syndrome",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.13) Hypersomnia due to other mental disorder",
              label: "(F51.13) Hypersomnia due to other mental disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.19) Other hypersomnia not due to a substance or known physiological condition",
              label: "(F51.19) Other hypersomnia not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.3) Sleepwalking [somnambulism]",
              label: "(F51.3) Sleepwalking [somnambulism]",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.4) Sleep terrors [night terrors]",
              label: "(F51.4) Sleep terrors [night terrors]",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.5) Nightmare disorder",
              label: "(F51.5) Nightmare disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.8) Other sleep disorders not due to a substance or known physiological condition",
              label: "(F51.8) Other sleep disorders not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.9) Sleep disorder not due to a substance or known physiological condition, unspecified",
              label: "(F51.9) Sleep disorder not due to a substance or known physiological condition, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.0) Hypoactive sexual desire disorder",
              label: "(F52.0) Hypoactive sexual desire disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.1) Sexual aversion disorder",
              label: "(F52.1) Sexual aversion disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.21) Male erectile disorder",
              label: "(F52.21) Male erectile disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.22) Female sexual arousal disorder",
              label: "(F52.22) Female sexual arousal disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.31) Female orgasmic disorder",
              label: "(F52.31) Female orgasmic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.32) Male orgasmic disorder",
              label: "(F52.32) Male orgasmic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.4) Premature ejaculation",
              label: "(F52.4) Premature ejaculation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.5) Vaginismus not due to a substance or known physiological condition",
              label: "(F52.5) Vaginismus not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.6) Dyspareunia not due to a substance or known physiological condition",
              label: "(F52.6) Dyspareunia not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.8) Other sexual dysfunction not due to a substance or known physiological condition",
              label: "(F52.8) Other sexual dysfunction not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.9) Unspecified sexual dysfunction not due to a substance or known physiological condition",
              label: "(F52.9) Unspecified sexual dysfunction not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F53.0) Postpartum depression",
              label: "(F53.0) Postpartum depression",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F53.1) Puerperal psychosis",
              label: "(F53.1) Puerperal psychosis",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F54.) Psychological and behavioral factors associated with disorders or diseases classified elsewhere",
              label: "(F54.) Psychological and behavioral factors associated with disorders or diseases classified elsewhere",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.0) Abuse of antacids",
              label: "(F55.0) Abuse of antacids",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.1) Abuse of herbal or folk remedies",
              label: "(F55.1) Abuse of herbal or folk remedies",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.2) Abuse of laxatives",
              label: "(F55.2) Abuse of laxatives",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.3) Abuse of steroids or hormones",
              label: "(F55.3) Abuse of steroids or hormones",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.4) Abuse of vitamins",
              label: "(F55.4) Abuse of vitamins",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.8) Abuse of other non-psychoactive substances",
              label: "(F55.8) Abuse of other non-psychoactive substances",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F59.) Unspecified behavioral syndromes associated with physiological disturbances and physical factors",
              label: "(F59.) Unspecified behavioral syndromes associated with physiological disturbances and physical factors",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.0) Paranoid personality disorder",
              label: "(F60.0) Paranoid personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.1) Schizoid personality disorder",
              label: "(F60.1) Schizoid personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.2) Antisocial personality disorder",
              label: "(F60.2) Antisocial personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.3) Borderline personality disorder",
              label: "(F60.3) Borderline personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.4) Histrionic personality disorder",
              label: "(F60.4) Histrionic personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.5) Obsessive-compulsive personality disorder",
              label: "(F60.5) Obsessive-compulsive personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.6) Avoidant personality disorder",
              label: "(F60.6) Avoidant personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.7) Dependent personality disorder",
              label: "(F60.7) Dependent personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.81) Narcissistic personality disorder",
              label: "(F60.81) Narcissistic personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.89) Other specific personality disorders",
              label: "(F60.89) Other specific personality disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.9) Personality disorder, unspecified",
              label: "(F60.9) Personality disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.0) Pathological gambling",
              label: "(F63.0) Pathological gambling",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.1) Pyromania",
              label: "(F63.1) Pyromania",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.2) Kleptomania",
              label: "(F63.2) Kleptomania",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.3) Trichotillomania",
              label: "(F63.3) Trichotillomania",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.81) Intermittent explosive disorder",
              label: "(F63.81) Intermittent explosive disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.89) Other impulse disorders",
              label: "(F63.89) Other impulse disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.9) Impulse disorder, unspecified",
              label: "(F63.9) Impulse disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F64.0) Transsexualism",
              label: "(F64.0) Transsexualism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F64.1) Dual role transvestism",
              label: "(F64.1) Dual role transvestism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F64.2) Gender identity disorder of childhood",
              label: "(F64.2) Gender identity disorder of childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F64.8) Other gender identity disorders",
              label: "(F64.8) Other gender identity disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F64.9) Gender identity disorder, unspecified",
              label: "(F64.9) Gender identity disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.0) Fetishism",
              label: "(F65.0) Fetishism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.1) Transvestic fetishism",
              label: "(F65.1) Transvestic fetishism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.2) Exhibitionism",
              label: "(F65.2) Exhibitionism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.3) Voyeurism",
              label: "(F65.3) Voyeurism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.4) Pedophilia",
              label: "(F65.4) Pedophilia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.50) Sadomasochism, unspecified",
              label: "(F65.50) Sadomasochism, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.51) Sexual masochism",
              label: "(F65.51) Sexual masochism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.52) Sexual sadism",
              label: "(F65.52) Sexual sadism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.81) Frotteurism",
              label: "(F65.81) Frotteurism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.89) Other paraphilias",
              label: "(F65.89) Other paraphilias",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.9) Paraphilia, unspecified",
              label: "(F65.9) Paraphilia, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F66.) Other sexual disorders",
              label: "(F66.) Other sexual disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.10) Factitious disorder imposed on self, unspecified",
              label: "(F68.10) Factitious disorder imposed on self, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.11) Factitious disorder imposed on self, with predominantly psychological signs and symptoms",
              label: "(F68.11) Factitious disorder imposed on self, with predominantly psychological signs and symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.12) Factitious disorder imposed on self, with predominantly physical signs and symptoms",
              label: "(F68.12) Factitious disorder imposed on self, with predominantly physical signs and symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.13) Factitious disorder imposed on self, with combined psychological and physical signs and symptoms",
              label: "(F68.13) Factitious disorder imposed on self, with combined psychological and physical signs and symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.A) Factitious disorder imposed on another",
              label: "(F68.A) Factitious disorder imposed on another",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.8) Other specified disorders of adult personality and behavior",
              label: "(F68.8) Other specified disorders of adult personality and behavior",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F69.) Unspecified disorder of adult personality and behavior",
              label: "(F69.) Unspecified disorder of adult personality and behavior",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F70.) Mild intellectual disabilities",
              label: "(F70.) Mild intellectual disabilities",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F71.) Moderate intellectual disabilities",
              label: "(F71.) Moderate intellectual disabilities",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F72.) Severe intellectual disabilities",
              label: "(F72.) Severe intellectual disabilities",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F73.) Profound intellectual disabilities",
              label: "(F73.) Profound intellectual disabilities",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F78.A1) SYNGAP1-related intellectual disability",
              label: "(F78.A1) SYNGAP1-related intellectual disability",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F78.A9) Other genetic related intellectual disability",
              label: "(F78.A9) Other genetic related intellectual disability",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F79.) Unspecified intellectual disabilities",
              label: "(F79.) Unspecified intellectual disabilities",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.0) Phonological disorder",
              label: "(F80.0) Phonological disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.1) Expressive language disorder",
              label: "(F80.1) Expressive language disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.2) Mixed receptive-expressive language disorder",
              label: "(F80.2) Mixed receptive-expressive language disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.4) Speech and language development delay due to hearing loss",
              label: "(F80.4) Speech and language development delay due to hearing loss",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.81) Childhood onset fluency disorder",
              label: "(F80.81) Childhood onset fluency disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.82) Social pragmatic communication disorder",
              label: "(F80.82) Social pragmatic communication disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.89) Other developmental disorders of speech and language",
              label: "(F80.89) Other developmental disorders of speech and language",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.9) Developmental disorder of speech and language, unspecified",
              label: "(F80.9) Developmental disorder of speech and language, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F81.0) Specific reading disorder",
              label: "(F81.0) Specific reading disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F81.2) Mathematics disorder",
              label: "(F81.2) Mathematics disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F81.81) Disorder of written expression",
              label: "(F81.81) Disorder of written expression",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F81.89) Other developmental disorders of scholastic skills",
              label: "(F81.89) Other developmental disorders of scholastic skills",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F81.9) Developmental disorder of scholastic skills, unspecified",
              label: "(F81.9) Developmental disorder of scholastic skills, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F82.) Specific developmental disorder of motor function",
              label: "(F82.) Specific developmental disorder of motor function",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.0) Autistic disorder",
              label: "(F84.0) Autistic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.2) Rett's syndrome",
              label: "(F84.2) Rett's syndrome",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.3) Other childhood disintegrative disorder",
              label: "(F84.3) Other childhood disintegrative disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.5) Asperger's syndrome",
              label: "(F84.5) Asperger's syndrome",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.8) Other pervasive developmental disorders",
              label: "(F84.8) Other pervasive developmental disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.9) Pervasive developmental disorder, unspecified",
              label: "(F84.9) Pervasive developmental disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F88.) Other disorders of psychological development",
              label: "(F88.) Other disorders of psychological development",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F89.) Unspecified disorder of psychological development",
              label: "(F89.) Unspecified disorder of psychological development",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F90.0) Attention-deficit hyperactivity disorder, predominantly inattentive type",
              label: "(F90.0) Attention-deficit hyperactivity disorder, predominantly inattentive type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F90.1) Attention-deficit hyperactivity disorder, predominantly hyperactive type",
              label: "(F90.1) Attention-deficit hyperactivity disorder, predominantly hyperactive type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F90.2) Attention-deficit hyperactivity disorder, combined type",
              label: "(F90.2) Attention-deficit hyperactivity disorder, combined type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F90.8) Attention-deficit hyperactivity disorder, other type",
              label: "(F90.8) Attention-deficit hyperactivity disorder, other type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F90.9) Attention-deficit hyperactivity disorder, unspecified type",
              label: "(F90.9) Attention-deficit hyperactivity disorder, unspecified type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.0) Conduct disorder confined to family context",
              label: "(F91.0) Conduct disorder confined to family context",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.1) Conduct disorder, childhood-onset type",
              label: "(F91.1) Conduct disorder, childhood-onset type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.2) Conduct disorder, adolescent-onset type",
              label: "(F91.2) Conduct disorder, adolescent-onset type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.3) Oppositional defiant disorder",
              label: "(F91.3) Oppositional defiant disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.8) Other conduct disorders",
              label: "(F91.8) Other conduct disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.9) Conduct disorder, unspecified",
              label: "(F91.9) Conduct disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F93.0) Separation anxiety disorder of childhood",
              label: "(F93.0) Separation anxiety disorder of childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F93.8) Other childhood emotional disorders",
              label: "(F93.8) Other childhood emotional disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F93.9) Childhood emotional disorder, unspecified",
              label: "(F93.9) Childhood emotional disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F94.0) Selective mutism",
              label: "(F94.0) Selective mutism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F94.1) Reactive attachment disorder of childhood",
              label: "(F94.1) Reactive attachment disorder of childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F94.2) Disinhibited attachment disorder of childhood",
              label: "(F94.2) Disinhibited attachment disorder of childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F94.8) Other childhood disorders of social functioning",
              label: "(F94.8) Other childhood disorders of social functioning",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F94.9) Childhood disorder of social functioning, unspecified",
              label: "(F94.9) Childhood disorder of social functioning, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F95.0) Transient tic disorder",
              label: "(F95.0) Transient tic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F95.1) Chronic motor or vocal tic disorder",
              label: "(F95.1) Chronic motor or vocal tic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F95.2) Tourette's disorder",
              label: "(F95.2) Tourette's disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F95.8) Other tic disorders",
              label: "(F95.8) Other tic disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F95.9) Tic disorder, unspecified",
              label: "(F95.9) Tic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.0) Enuresis not due to a substance or known physiological condition",
              label: "(F98.0) Enuresis not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.1) Encopresis not due to a substance or known physiological condition",
              label: "(F98.1) Encopresis not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.21) Rumination disorder of infancy",
              label: "(F98.21) Rumination disorder of infancy",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.29) Other feeding disorders of infancy and early childhood",
              label: "(F98.29) Other feeding disorders of infancy and early childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.3) Pica of infancy and childhood",
              label: "(F98.3) Pica of infancy and childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.4) Stereotyped movement disorders",
              label: "(F98.4) Stereotyped movement disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.5) Adult onset fluency disorder",
              label: "(F98.5) Adult onset fluency disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.8) Other specified behavioral and emotional disorders with onset usually occurring in childhood and adolescence",
              label: "(F98.8) Other specified behavioral and emotional disorders with onset usually occurring in childhood and adolescence",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.9) Unspecified behavioral and emotional disorders with onset usually occurring in childhood and adolescence",
              label: "(F98.9) Unspecified behavioral and emotional disorders with onset usually occurring in childhood and adolescence",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F99.) Mental disorder, not otherwise specified",
              label: "(F99.) Mental disorder, not otherwise specified",
              Icon: MenuAlt2Icon,
            },
            { value: "() ", label: "() ", Icon: MenuAlt2Icon },
            {
              value: "(Z00.00) Encounter for general adult medical examination without abnormal findings",
              label: "(Z00.00) Encounter for general adult medical examination without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.01) Encounter for general adult medical examination with abnormal findings",
              label: "(Z00.01) Encounter for general adult medical examination with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.110) Health examination for newborn under 8 days old",
              label: "(Z00.110) Health examination for newborn under 8 days old",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.111) Health examination for newborn 8 to 28 days old",
              label: "(Z00.111) Health examination for newborn 8 to 28 days old",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.121) Encounter for routine child health examination with abnormal findings",
              label: "(Z00.121) Encounter for routine child health examination with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.129) Encounter for routine child health examination without abnormal findings",
              label: "(Z00.129) Encounter for routine child health examination without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.2) Encounter for examination for period of rapid growth in childhood",
              label: "(Z00.2) Encounter for examination for period of rapid growth in childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.3) Encounter for examination for adolescent development state",
              label: "(Z00.3) Encounter for examination for adolescent development state",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.5) Encounter for examination of potential donor of organ and tissue",
              label: "(Z00.5) Encounter for examination of potential donor of organ and tissue",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.6) Encounter for examination for normal comparison and control in clinical research program",
              label: "(Z00.6) Encounter for examination for normal comparison and control in clinical research program",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.70) Encounter for examination for period of delayed growth in childhood without abnormal findings",
              label: "(Z00.70) Encounter for examination for period of delayed growth in childhood without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.71) Encounter for examination for period of delayed growth in childhood with abnormal findings",
              label: "(Z00.71) Encounter for examination for period of delayed growth in childhood with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.8) Encounter for other general examination",
              label: "(Z00.8) Encounter for other general examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.00) Encounter for examination of eyes and vision without abnormal findings",
              label: "(Z01.00) Encounter for examination of eyes and vision without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.01) Encounter for examination of eyes and vision with abnormal findings",
              label: "(Z01.01) Encounter for examination of eyes and vision with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.020) Encounter for examination of eyes and vision following failed vision screening without abnormal findings",
              label: "(Z01.020) Encounter for examination of eyes and vision following failed vision screening without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.021) Encounter for examination of eyes and vision following failed vision screening with abnormal findings",
              label: "(Z01.021) Encounter for examination of eyes and vision following failed vision screening with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.10) Encounter for examination of ears and hearing without abnormal findings",
              label: "(Z01.10) Encounter for examination of ears and hearing without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.110) Encounter for hearing examination following failed hearing screening",
              label: "(Z01.110) Encounter for hearing examination following failed hearing screening",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.118) Encounter for examination of ears and hearing with other abnormal findings",
              label: "(Z01.118) Encounter for examination of ears and hearing with other abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.12) Encounter for hearing conservation and treatment",
              label: "(Z01.12) Encounter for hearing conservation and treatment",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.20) Encounter for dental examination and cleaning without abnormal findings",
              label: "(Z01.20) Encounter for dental examination and cleaning without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.21) Encounter for dental examination and cleaning with abnormal findings",
              label: "(Z01.21) Encounter for dental examination and cleaning with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.30) Encounter for examination of blood pressure without abnormal findings",
              label: "(Z01.30) Encounter for examination of blood pressure without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.31) Encounter for examination of blood pressure with abnormal findings",
              label: "(Z01.31) Encounter for examination of blood pressure with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.411) Encounter for gynecological examination (general) (routine) with abnormal findings",
              label: "(Z01.411) Encounter for gynecological examination (general) (routine) with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.419) Encounter for gynecological examination (general) (routine) without abnormal findings",
              label: "(Z01.419) Encounter for gynecological examination (general) (routine) without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.42) Encounter for cervical smear to confirm findings of recent normal smear following initial abnormal smear",
              label: "(Z01.42) Encounter for cervical smear to confirm findings of recent normal smear following initial abnormal smear",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.810) Encounter for preprocedural cardiovascular examination",
              label: "(Z01.810) Encounter for preprocedural cardiovascular examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.811) Encounter for preprocedural respiratory examination",
              label: "(Z01.811) Encounter for preprocedural respiratory examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.812) Encounter for preprocedural laboratory examination",
              label: "(Z01.812) Encounter for preprocedural laboratory examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.818) Encounter for other preprocedural examination",
              label: "(Z01.818) Encounter for other preprocedural examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.82) Encounter for allergy testing",
              label: "(Z01.82) Encounter for allergy testing",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.83) Encounter for blood typing",
              label: "(Z01.83) Encounter for blood typing",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.84) Encounter for antibody response examination",
              label: "(Z01.84) Encounter for antibody response examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.89) Encounter for other specified special examinations",
              label: "(Z01.89) Encounter for other specified special examinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.0) Encounter for examination for admission to educational institution",
              label: "(Z02.0) Encounter for examination for admission to educational institution",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.1) Encounter for pre-employment examination",
              label: "(Z02.1) Encounter for pre-employment examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.2) Encounter for examination for admission to residential institution",
              label: "(Z02.2) Encounter for examination for admission to residential institution",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.3) Encounter for examination for recruitment to armed forces",
              label: "(Z02.3) Encounter for examination for recruitment to armed forces",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.4) Encounter for examination for driving license",
              label: "(Z02.4) Encounter for examination for driving license",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.5) Encounter for examination for participation in sport",
              label: "(Z02.5) Encounter for examination for participation in sport",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.6) Encounter for examination for insurance purposes",
              label: "(Z02.6) Encounter for examination for insurance purposes",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.71) Encounter for disability determination",
              label: "(Z02.71) Encounter for disability determination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.79) Encounter for issue of other medical certificate",
              label: "(Z02.79) Encounter for issue of other medical certificate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.81) Encounter for paternity testing",
              label: "(Z02.81) Encounter for paternity testing",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.82) Encounter for adoption services",
              label: "(Z02.82) Encounter for adoption services",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.83) Encounter for blood-alcohol and blood-drug test",
              label: "(Z02.83) Encounter for blood-alcohol and blood-drug test",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.89) Encounter for other administrative examinations",
              label: "(Z02.89) Encounter for other administrative examinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.9) Encounter for administrative examinations, unspecified",
              label: "(Z02.9) Encounter for administrative examinations, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.6) Encounter for observation for suspected toxic effect from ingested substance ruled out",
              label: "(Z03.6) Encounter for observation for suspected toxic effect from ingested substance ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.71) Encounter for suspected problem with amniotic cavity and membrane ruled out",
              label: "(Z03.71) Encounter for suspected problem with amniotic cavity and membrane ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.72) Encounter for suspected placental problem ruled out",
              label: "(Z03.72) Encounter for suspected placental problem ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.73) Encounter for suspected fetal anomaly ruled out",
              label: "(Z03.73) Encounter for suspected fetal anomaly ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.74) Encounter for suspected problem with fetal growth ruled out",
              label: "(Z03.74) Encounter for suspected problem with fetal growth ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.75) Encounter for suspected cervical shortening ruled out",
              label: "(Z03.75) Encounter for suspected cervical shortening ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.79) Encounter for other suspected maternal and fetal conditions ruled out",
              label: "(Z03.79) Encounter for other suspected maternal and fetal conditions ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.810) Encounter for observation for suspected exposure to anthrax ruled out",
              label: "(Z03.810) Encounter for observation for suspected exposure to anthrax ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.818) Encounter for observation for suspected exposure to other biological agents ruled out",
              label: "(Z03.818) Encounter for observation for suspected exposure to other biological agents ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.821) Encounter for observation for suspected ingested foreign body ruled out",
              label: "(Z03.821) Encounter for observation for suspected ingested foreign body ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.822) Encounter for observation for suspected aspirated (inhaled) foreign body ruled out",
              label: "(Z03.822) Encounter for observation for suspected aspirated (inhaled) foreign body ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.823) Encounter for observation for suspected inserted (injected) foreign body ruled out",
              label: "(Z03.823) Encounter for observation for suspected inserted (injected) foreign body ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.83) Encounter for observation for suspected conditions related to home physiologic monitoring device ruled out",
              label: "(Z03.83) Encounter for observation for suspected conditions related to home physiologic monitoring device ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.89) Encounter for observation for other suspected diseases and conditions ruled out",
              label: "(Z03.89) Encounter for observation for other suspected diseases and conditions ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.1) Encounter for examination and observation following transport accident",
              label: "(Z04.1) Encounter for examination and observation following transport accident",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.2) Encounter for examination and observation following work accident",
              label: "(Z04.2) Encounter for examination and observation following work accident",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.3) Encounter for examination and observation following other accident",
              label: "(Z04.3) Encounter for examination and observation following other accident",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.41) Encounter for examination and observation following alleged adult rape",
              label: "(Z04.41) Encounter for examination and observation following alleged adult rape",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.42) Encounter for examination and observation following alleged child rape",
              label: "(Z04.42) Encounter for examination and observation following alleged child rape",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.6) Encounter for general psychiatric examination, requested by authority",
              label: "(Z04.6) Encounter for general psychiatric examination, requested by authority",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.71) Encounter for examination and observation following alleged adult physical abuse",
              label: "(Z04.71) Encounter for examination and observation following alleged adult physical abuse",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.72) Encounter for examination and observation following alleged child physical abuse",
              label: "(Z04.72) Encounter for examination and observation following alleged child physical abuse",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.81) Encounter for examination and observation of victim following forced sexual exploitation",
              label: "(Z04.81) Encounter for examination and observation of victim following forced sexual exploitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.82) Encounter for examination and observation of victim following forced labor exploitation",
              label: "(Z04.82) Encounter for examination and observation of victim following forced labor exploitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.89) Encounter for examination and observation for other specified reasons",
              label: "(Z04.89) Encounter for examination and observation for other specified reasons",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.9) Encounter for examination and observation for unspecified reason",
              label: "(Z04.9) Encounter for examination and observation for unspecified reason",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.0) Observation and evaluation of newborn for suspected cardiac condition ruled out",
              label: "(Z05.0) Observation and evaluation of newborn for suspected cardiac condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.1) Observation and evaluation of newborn for suspected infectious condition ruled out",
              label: "(Z05.1) Observation and evaluation of newborn for suspected infectious condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.2) Observation and evaluation of newborn for suspected neurological condition ruled out",
              label: "(Z05.2) Observation and evaluation of newborn for suspected neurological condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.3) Observation and evaluation of newborn for suspected respiratory condition ruled out",
              label: "(Z05.3) Observation and evaluation of newborn for suspected respiratory condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.41) Observation and evaluation of newborn for suspected genetic condition ruled out",
              label: "(Z05.41) Observation and evaluation of newborn for suspected genetic condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.42) Observation and evaluation of newborn for suspected metabolic condition ruled out",
              label: "(Z05.42) Observation and evaluation of newborn for suspected metabolic condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.43) Observation and evaluation of newborn for suspected immunologic condition ruled out",
              label: "(Z05.43) Observation and evaluation of newborn for suspected immunologic condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.5) Observation and evaluation of newborn for suspected gastrointestinal condition ruled out",
              label: "(Z05.5) Observation and evaluation of newborn for suspected gastrointestinal condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.6) Observation and evaluation of newborn for suspected genitourinary condition ruled out",
              label: "(Z05.6) Observation and evaluation of newborn for suspected genitourinary condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.71) Observation and evaluation of newborn for suspected skin and subcutaneous tissue condition ruled out",
              label: "(Z05.71) Observation and evaluation of newborn for suspected skin and subcutaneous tissue condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.72) Observation and evaluation of newborn for suspected musculoskeletal condition ruled out",
              label: "(Z05.72) Observation and evaluation of newborn for suspected musculoskeletal condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.73) Observation and evaluation of newborn for suspected connective tissue condition ruled out",
              label: "(Z05.73) Observation and evaluation of newborn for suspected connective tissue condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.8) Observation and evaluation of newborn for other specified suspected condition ruled out",
              label: "(Z05.8) Observation and evaluation of newborn for other specified suspected condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.9) Observation and evaluation of newborn for unspecified suspected condition ruled out",
              label: "(Z05.9) Observation and evaluation of newborn for unspecified suspected condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z08.) Encounter for follow-up examination after completed treatment for malignant neoplasm",
              label: "(Z08.) Encounter for follow-up examination after completed treatment for malignant neoplasm",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z09.) Encounter for follow-up examination after completed treatment for conditions other than malignant neoplasm",
              label: "(Z09.) Encounter for follow-up examination after completed treatment for conditions other than malignant neoplasm",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.0) Encounter for screening for intestinal infectious diseases",
              label: "(Z11.0) Encounter for screening for intestinal infectious diseases",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.1) Encounter for screening for respiratory tuberculosis",
              label: "(Z11.1) Encounter for screening for respiratory tuberculosis",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.2) Encounter for screening for other bacterial diseases",
              label: "(Z11.2) Encounter for screening for other bacterial diseases",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.3) Encounter for screening for infections with a predominantly sexual mode of transmission",
              label: "(Z11.3) Encounter for screening for infections with a predominantly sexual mode of transmission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.4) Encounter for screening for human immunodeficiency virus [HIV]",
              label: "(Z11.4) Encounter for screening for human immunodeficiency virus [HIV]",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.51) Encounter for screening for human papillomavirus (HPV)",
              label: "(Z11.51) Encounter for screening for human papillomavirus (HPV)",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.52) Encounter for screening for COVID-19",
              label: "(Z11.52) Encounter for screening for COVID-19",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.59) Encounter for screening for other viral diseases",
              label: "(Z11.59) Encounter for screening for other viral diseases",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.6) Encounter for screening for other protozoal diseases and helminthiases",
              label: "(Z11.6) Encounter for screening for other protozoal diseases and helminthiases",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.7) Encounter for testing for latent tuberculosis infection",
              label: "(Z11.7) Encounter for testing for latent tuberculosis infection",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.8) Encounter for screening for other infectious and parasitic diseases",
              label: "(Z11.8) Encounter for screening for other infectious and parasitic diseases",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.9) Encounter for screening for infectious and parasitic diseases, unspecified",
              label: "(Z11.9) Encounter for screening for infectious and parasitic diseases, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.0) Encounter for screening for malignant neoplasm of stomach",
              label: "(Z12.0) Encounter for screening for malignant neoplasm of stomach",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.10) Encounter for screening for malignant neoplasm of intestinal tract, unspecified",
              label: "(Z12.10) Encounter for screening for malignant neoplasm of intestinal tract, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.11) Encounter for screening for malignant neoplasm of colon",
              label: "(Z12.11) Encounter for screening for malignant neoplasm of colon",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.12) Encounter for screening for malignant neoplasm of rectum",
              label: "(Z12.12) Encounter for screening for malignant neoplasm of rectum",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.13) Encounter for screening for malignant neoplasm of small intestine",
              label: "(Z12.13) Encounter for screening for malignant neoplasm of small intestine",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.2) Encounter for screening for malignant neoplasm of respiratory organs",
              label: "(Z12.2) Encounter for screening for malignant neoplasm of respiratory organs",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.31) Encounter for screening mammogram for malignant neoplasm of breast",
              label: "(Z12.31) Encounter for screening mammogram for malignant neoplasm of breast",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.39) Encounter for other screening for malignant neoplasm of breast",
              label: "(Z12.39) Encounter for other screening for malignant neoplasm of breast",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.4) Encounter for screening for malignant neoplasm of cervix",
              label: "(Z12.4) Encounter for screening for malignant neoplasm of cervix",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.5) Encounter for screening for malignant neoplasm of prostate",
              label: "(Z12.5) Encounter for screening for malignant neoplasm of prostate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.6) Encounter for screening for malignant neoplasm of bladder",
              label: "(Z12.6) Encounter for screening for malignant neoplasm of bladder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.71) Encounter for screening for malignant neoplasm of testis",
              label: "(Z12.71) Encounter for screening for malignant neoplasm of testis",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.72) Encounter for screening for malignant neoplasm of vagina",
              label: "(Z12.72) Encounter for screening for malignant neoplasm of vagina",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.73) Encounter for screening for malignant neoplasm of ovary",
              label: "(Z12.73) Encounter for screening for malignant neoplasm of ovary",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.79) Encounter for screening for malignant neoplasm of other genitourinary organs",
              label: "(Z12.79) Encounter for screening for malignant neoplasm of other genitourinary organs",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.81) Encounter for screening for malignant neoplasm of oral cavity",
              label: "(Z12.81) Encounter for screening for malignant neoplasm of oral cavity",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.82) Encounter for screening for malignant neoplasm of nervous system",
              label: "(Z12.82) Encounter for screening for malignant neoplasm of nervous system",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.83) Encounter for screening for malignant neoplasm of skin",
              label: "(Z12.83) Encounter for screening for malignant neoplasm of skin",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.89) Encounter for screening for malignant neoplasm of other sites",
              label: "(Z12.89) Encounter for screening for malignant neoplasm of other sites",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.9) Encounter for screening for malignant neoplasm, site unspecified",
              label: "(Z12.9) Encounter for screening for malignant neoplasm, site unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.0) Encounter for screening for diseases of the blood and blood-forming organs and certain disorders involving immune mechanism",
              label: "(Z13.0) Encounter for screening for diseases of the blood and blood-forming organs and certain disorders involving immune mechanism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.1) Encounter for screening for diabetes mellitus",
              label: "(Z13.1) Encounter for screening for diabetes mellitus",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.21) Encounter for screening for nutritional disorder",
              label: "(Z13.21) Encounter for screening for nutritional disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.220) Encounter for screening for lipoid disorders",
              label: "(Z13.220) Encounter for screening for lipoid disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.228) Encounter for screening for other metabolic disorders",
              label: "(Z13.228) Encounter for screening for other metabolic disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.29) Encounter for screening for other suspected endocrine disorder",
              label: "(Z13.29) Encounter for screening for other suspected endocrine disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.30) Encounter for screening examination for mental health and behavioral disorders, unspecified",
              label: "(Z13.30) Encounter for screening examination for mental health and behavioral disorders, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.31) Encounter for screening for depression",
              label: "(Z13.31) Encounter for screening for depression",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.32) Encounter for screening for maternal depression",
              label: "(Z13.32) Encounter for screening for maternal depression",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.39) Encounter for screening examination for other mental health and behavioral disorders",
              label: "(Z13.39) Encounter for screening examination for other mental health and behavioral disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.40) Encounter for screening for unspecified developmental delays",
              label: "(Z13.40) Encounter for screening for unspecified developmental delays",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.41) Encounter for autism screening",
              label: "(Z13.41) Encounter for autism screening",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.42) Encounter for screening for global developmental delays (milestones)",
              label: "(Z13.42) Encounter for screening for global developmental delays (milestones)",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.49) Encounter for screening for other developmental delays",
              label: "(Z13.49) Encounter for screening for other developmental delays",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.5) Encounter for screening for eye and ear disorders",
              label: "(Z13.5) Encounter for screening for eye and ear disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.6) Encounter for screening for cardiovascular disorders",
              label: "(Z13.6) Encounter for screening for cardiovascular disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.71) Encounter for nonprocreative screening for genetic disease carrier status",
              label: "(Z13.71) Encounter for nonprocreative screening for genetic disease carrier status",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.79) Encounter for other screening for genetic and chromosomal anomalies",
              label: "(Z13.79) Encounter for other screening for genetic and chromosomal anomalies",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.810) Encounter for screening for upper gastrointestinal disorder",
              label: "(Z13.810) Encounter for screening for upper gastrointestinal disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.811) Encounter for screening for lower gastrointestinal disorder",
              label: "(Z13.811) Encounter for screening for lower gastrointestinal disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.818) Encounter for screening for other digestive system disorders",
              label: "(Z13.818) Encounter for screening for other digestive system disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.820) Encounter for screening for osteoporosis",
              label: "(Z13.820) Encounter for screening for osteoporosis",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.828) Encounter for screening for other musculoskeletal disorder",
              label: "(Z13.828) Encounter for screening for other musculoskeletal disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.83) Encounter for screening for respiratory disorder NEC",
              label: "(Z13.83) Encounter for screening for respiratory disorder NEC",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.84) Encounter for screening for dental disorders",
              label: "(Z13.84) Encounter for screening for dental disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.850) Encounter for screening for traumatic brain injury",
              label: "(Z13.850) Encounter for screening for traumatic brain injury",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.858) Encounter for screening for other nervous system disorders",
              label: "(Z13.858) Encounter for screening for other nervous system disorders",
              Icon: MenuAlt2Icon,
            },
          ],
          // maxLength: 50,
          // max: 100,
          // min: 5,
          required: true,
          error: "",
          example: "SOAP",
        },
        {
          title: "Secondary Diagnosis",
          attr: "secondarydiagnosis",
          // value: "",
          placeholder: "",
          label: "Secondary diagnosis",
          type: "dropdown",
          options: [
            { value: "", label: "", Icon: MenuAlt2Icon },
            {
              value: "(F01.50) Vascular dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F01.50) Vascular dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.511) Vascular dementia, unspecified severity, with agitation",
              label: "(F01.511) Vascular dementia, unspecified severity, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.518) Vascular dementia, unspecified severity, with other behavioral disturbance",
              label: "(F01.518) Vascular dementia, unspecified severity, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.52) Vascular dementia, unspecified severity, with psychotic disturbance",
              label: "(F01.52) Vascular dementia, unspecified severity, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.53) Vascular dementia, unspecified severity, with mood disturbance",
              label: "(F01.53) Vascular dementia, unspecified severity, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.54) Vascular dementia, unspecified severity, with anxiety",
              label: "(F01.54) Vascular dementia, unspecified severity, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A0) Vascular dementia, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F01.A0) Vascular dementia, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A11) Vascular dementia, mild, with agitation",
              label: "(F01.A11) Vascular dementia, mild, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A18) Vascular dementia, mild, with other behavioral disturbance",
              label: "(F01.A18) Vascular dementia, mild, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A2) Vascular dementia, mild, with psychotic disturbance",
              label: "(F01.A2) Vascular dementia, mild, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A3) Vascular dementia, mild, with mood disturbance",
              label: "(F01.A3) Vascular dementia, mild, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A4) Vascular dementia, mild, with anxiety",
              label: "(F01.A4) Vascular dementia, mild, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B0) Vascular dementia, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F01.B0) Vascular dementia, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B11) Vascular dementia, moderate, with agitation",
              label: "(F01.B11) Vascular dementia, moderate, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B18) Vascular dementia, moderate, with other behavioral disturbance",
              label: "(F01.B18) Vascular dementia, moderate, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B2) Vascular dementia, moderate, with psychotic disturbance",
              label: "(F01.B2) Vascular dementia, moderate, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B3) Vascular dementia, moderate, with mood disturbance",
              label: "(F01.B3) Vascular dementia, moderate, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B4) Vascular dementia, moderate, with anxiety",
              label: "(F01.B4) Vascular dementia, moderate, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C0) Vascular dementia, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F01.C0) Vascular dementia, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C11) Vascular dementia, severe, with agitation",
              label: "(F01.C11) Vascular dementia, severe, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C18) Vascular dementia, severe, with other behavioral disturbance",
              label: "(F01.C18) Vascular dementia, severe, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C2) Vascular dementia, severe, with psychotic disturbance",
              label: "(F01.C2) Vascular dementia, severe, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C3) Vascular dementia, severe, with mood disturbance",
              label: "(F01.C3) Vascular dementia, severe, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C4) Vascular dementia, severe, with anxiety",
              label: "(F01.C4) Vascular dementia, severe, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.80) Dementia in other diseases classified elsewhere, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F02.80) Dementia in other diseases classified elsewhere, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.811) Dementia in other diseases classified elsewhere, unspecified severity, with agitation",
              label: "(F02.811) Dementia in other diseases classified elsewhere, unspecified severity, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.818) Dementia in other diseases classified elsewhere, unspecified severity, with other behavioral disturbance",
              label: "(F02.818) Dementia in other diseases classified elsewhere, unspecified severity, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.82) Dementia in other diseases classified elsewhere, unspecified severity, with psychotic disturbance",
              label: "(F02.82) Dementia in other diseases classified elsewhere, unspecified severity, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.83) Dementia in other diseases classified elsewhere, unspecified severity, with mood disturbance",
              label: "(F02.83) Dementia in other diseases classified elsewhere, unspecified severity, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.84) Dementia in other diseases classified elsewhere, unspecified severity, with anxiety",
              label: "(F02.84) Dementia in other diseases classified elsewhere, unspecified severity, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A0) Dementia in other diseases classified elsewhere, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F02.A0) Dementia in other diseases classified elsewhere, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A11) Dementia in other diseases classified elsewhere, mild, with agitation",
              label: "(F02.A11) Dementia in other diseases classified elsewhere, mild, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A18) Dementia in other diseases classified elsewhere, mild, with other behavioral disturbance",
              label: "(F02.A18) Dementia in other diseases classified elsewhere, mild, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A2) Dementia in other diseases classified elsewhere, mild, with psychotic disturbance",
              label: "(F02.A2) Dementia in other diseases classified elsewhere, mild, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A3) Dementia in other diseases classified elsewhere, mild, with mood disturbance",
              label: "(F02.A3) Dementia in other diseases classified elsewhere, mild, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A4) Dementia in other diseases classified elsewhere, mild, with anxiety",
              label: "(F02.A4) Dementia in other diseases classified elsewhere, mild, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B0) Dementia in other diseases classified elsewhere, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F02.B0) Dementia in other diseases classified elsewhere, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B11) Dementia in other diseases classified elsewhere, moderate, with agitation",
              label: "(F02.B11) Dementia in other diseases classified elsewhere, moderate, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B18) Dementia in other diseases classified elsewhere, moderate, with other behavioral disturbance",
              label: "(F02.B18) Dementia in other diseases classified elsewhere, moderate, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B2) Dementia in other diseases classified elsewhere, moderate, with psychotic disturbance",
              label: "(F02.B2) Dementia in other diseases classified elsewhere, moderate, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B3) Dementia in other diseases classified elsewhere, moderate, with mood disturbance",
              label: "(F02.B3) Dementia in other diseases classified elsewhere, moderate, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B4) Dementia in other diseases classified elsewhere, moderate, with anxiety",
              label: "(F02.B4) Dementia in other diseases classified elsewhere, moderate, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C0) Dementia in other diseases classified elsewhere, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F02.C0) Dementia in other diseases classified elsewhere, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C11) Dementia in other diseases classified elsewhere, severe, with agitation",
              label: "(F02.C11) Dementia in other diseases classified elsewhere, severe, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C18) Dementia in other diseases classified elsewhere, severe, with other behavioral disturbance",
              label: "(F02.C18) Dementia in other diseases classified elsewhere, severe, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C2) Dementia in other diseases classified elsewhere, severe, with psychotic disturbance",
              label: "(F02.C2) Dementia in other diseases classified elsewhere, severe, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C3) Dementia in other diseases classified elsewhere, severe, with mood disturbance",
              label: "(F02.C3) Dementia in other diseases classified elsewhere, severe, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C4) Dementia in other diseases classified elsewhere, severe, with anxiety",
              label: "(F02.C4) Dementia in other diseases classified elsewhere, severe, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.90) Unspecified dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F03.90) Unspecified dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.911) Unspecified dementia, unspecified severity, with agitation",
              label: "(F03.911) Unspecified dementia, unspecified severity, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.918) Unspecified dementia, unspecified severity, with other behavioral disturbance",
              label: "(F03.918) Unspecified dementia, unspecified severity, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.92) Unspecified dementia, unspecified severity, with psychotic disturbance",
              label: "(F03.92) Unspecified dementia, unspecified severity, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.93) Unspecified dementia, unspecified severity, with mood disturbance",
              label: "(F03.93) Unspecified dementia, unspecified severity, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.94) Unspecified dementia, unspecified severity, with anxiety",
              label: "(F03.94) Unspecified dementia, unspecified severity, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A0) Unspecified dementia, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F03.A0) Unspecified dementia, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A11) Unspecified dementia, mild, with agitation",
              label: "(F03.A11) Unspecified dementia, mild, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A18) Unspecified dementia, mild, with other behavioral disturbance",
              label: "(F03.A18) Unspecified dementia, mild, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A2) Unspecified dementia, mild, with psychotic disturbance",
              label: "(F03.A2) Unspecified dementia, mild, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A3) Unspecified dementia, mild, with mood disturbance",
              label: "(F03.A3) Unspecified dementia, mild, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A4) Unspecified dementia, mild, with anxiety",
              label: "(F03.A4) Unspecified dementia, mild, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B0) Unspecified dementia, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F03.B0) Unspecified dementia, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B11) Unspecified dementia, moderate, with agitation",
              label: "(F03.B11) Unspecified dementia, moderate, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B18) Unspecified dementia, moderate, with other behavioral disturbance",
              label: "(F03.B18) Unspecified dementia, moderate, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B2) Unspecified dementia, moderate, with psychotic disturbance",
              label: "(F03.B2) Unspecified dementia, moderate, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B3) Unspecified dementia, moderate, with mood disturbance",
              label: "(F03.B3) Unspecified dementia, moderate, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B4) Unspecified dementia, moderate, with anxiety",
              label: "(F03.B4) Unspecified dementia, moderate, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C0) Unspecified dementia, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F03.C0) Unspecified dementia, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C11) Unspecified dementia, severe, with agitation",
              label: "(F03.C11) Unspecified dementia, severe, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C18) Unspecified dementia, severe, with other behavioral disturbance",
              label: "(F03.C18) Unspecified dementia, severe, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C2) Unspecified dementia, severe, with psychotic disturbance",
              label: "(F03.C2) Unspecified dementia, severe, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C3) Unspecified dementia, severe, with mood disturbance",
              label: "(F03.C3) Unspecified dementia, severe, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C4) Unspecified dementia, severe, with anxiety",
              label: "(F03.C4) Unspecified dementia, severe, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F04.) Amnestic disorder due to known physiological condition",
              label: "(F04.) Amnestic disorder due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F05.) Delirium due to known physiological condition",
              label: "(F05.) Delirium due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.0) Psychotic disorder with hallucinations due to known physiological condition",
              label: "(F06.0) Psychotic disorder with hallucinations due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.1) Catatonic disorder due to known physiological condition",
              label: "(F06.1) Catatonic disorder due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.2) Psychotic disorder with delusions due to known physiological condition",
              label: "(F06.2) Psychotic disorder with delusions due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.30) Mood disorder due to known physiological condition, unspecified",
              label: "(F06.30) Mood disorder due to known physiological condition, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.31) Mood disorder due to known physiological condition with depressive features",
              label: "(F06.31) Mood disorder due to known physiological condition with depressive features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.32) Mood disorder due to known physiological condition with major depressive-like episode",
              label: "(F06.32) Mood disorder due to known physiological condition with major depressive-like episode",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.33) Mood disorder due to known physiological condition with manic features",
              label: "(F06.33) Mood disorder due to known physiological condition with manic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.34) Mood disorder due to known physiological condition with mixed features",
              label: "(F06.34) Mood disorder due to known physiological condition with mixed features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.4) Anxiety disorder due to known physiological condition",
              label: "(F06.4) Anxiety disorder due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.70) Mild neurocognitive disorder due to known physiological condition without behavioral disturbance",
              label: "(F06.70) Mild neurocognitive disorder due to known physiological condition without behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.71) Mild neurocognitive disorder due to known physiological condition with behavioral disturbance",
              label: "(F06.71) Mild neurocognitive disorder due to known physiological condition with behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.8) Other specified mental disorders due to known physiological condition",
              label: "(F06.8) Other specified mental disorders due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F07.0) Personality change due to known physiological condition",
              label: "(F07.0) Personality change due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F07.81) Postconcussional syndrome",
              label: "(F07.81) Postconcussional syndrome",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F07.89) Other personality and behavioral disorders due to known physiological condition",
              label: "(F07.89) Other personality and behavioral disorders due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F07.9) Unspecified personality and behavioral disorder due to known physiological condition",
              label: "(F07.9) Unspecified personality and behavioral disorder due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F09.) Unspecified mental disorder due to known physiological condition",
              label: "(F09.) Unspecified mental disorder due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.10) Alcohol abuse, uncomplicated",
              label: "(F10.10) Alcohol abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.11) Alcohol abuse, in remission",
              label: "(F10.11) Alcohol abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.120) Alcohol abuse with intoxication, uncomplicated",
              label: "(F10.120) Alcohol abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.121) Alcohol abuse with intoxication delirium",
              label: "(F10.121) Alcohol abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.129) Alcohol abuse with intoxication, unspecified",
              label: "(F10.129) Alcohol abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.130) Alcohol abuse with withdrawal, uncomplicated",
              label: "(F10.130) Alcohol abuse with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.131) Alcohol abuse with withdrawal delirium",
              label: "(F10.131) Alcohol abuse with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.132) Alcohol abuse with withdrawal with perceptual disturbance",
              label: "(F10.132) Alcohol abuse with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.139) Alcohol abuse with withdrawal, unspecified",
              label: "(F10.139) Alcohol abuse with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.14) Alcohol abuse with alcohol-induced mood disorder",
              label: "(F10.14) Alcohol abuse with alcohol-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.150) Alcohol abuse with alcohol-induced psychotic disorder with delusions",
              label: "(F10.150) Alcohol abuse with alcohol-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.151) Alcohol abuse with alcohol-induced psychotic disorder with hallucinations",
              label: "(F10.151) Alcohol abuse with alcohol-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.159) Alcohol abuse with alcohol-induced psychotic disorder, unspecified",
              label: "(F10.159) Alcohol abuse with alcohol-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.180) Alcohol abuse with alcohol-induced anxiety disorder",
              label: "(F10.180) Alcohol abuse with alcohol-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.181) Alcohol abuse with alcohol-induced sexual dysfunction",
              label: "(F10.181) Alcohol abuse with alcohol-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.182) Alcohol abuse with alcohol-induced sleep disorder",
              label: "(F10.182) Alcohol abuse with alcohol-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.188) Alcohol abuse with other alcohol-induced disorder",
              label: "(F10.188) Alcohol abuse with other alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.19) Alcohol abuse with unspecified alcohol-induced disorder",
              label: "(F10.19) Alcohol abuse with unspecified alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.20) Alcohol dependence, uncomplicated",
              label: "(F10.20) Alcohol dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.21) Alcohol dependence, in remission",
              label: "(F10.21) Alcohol dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.220) Alcohol dependence with intoxication, uncomplicated",
              label: "(F10.220) Alcohol dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.221) Alcohol dependence with intoxication delirium",
              label: "(F10.221) Alcohol dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.229) Alcohol dependence with intoxication, unspecified",
              label: "(F10.229) Alcohol dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.230) Alcohol dependence with withdrawal, uncomplicated",
              label: "(F10.230) Alcohol dependence with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.231) Alcohol dependence with withdrawal delirium",
              label: "(F10.231) Alcohol dependence with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.232) Alcohol dependence with withdrawal with perceptual disturbance",
              label: "(F10.232) Alcohol dependence with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.239) Alcohol dependence with withdrawal, unspecified",
              label: "(F10.239) Alcohol dependence with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.24) Alcohol dependence with alcohol-induced mood disorder",
              label: "(F10.24) Alcohol dependence with alcohol-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.250) Alcohol dependence with alcohol-induced psychotic disorder with delusions",
              label: "(F10.250) Alcohol dependence with alcohol-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.251) Alcohol dependence with alcohol-induced psychotic disorder with hallucinations",
              label: "(F10.251) Alcohol dependence with alcohol-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.259) Alcohol dependence with alcohol-induced psychotic disorder, unspecified",
              label: "(F10.259) Alcohol dependence with alcohol-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.26) Alcohol dependence with alcohol-induced persisting amnestic disorder",
              label: "(F10.26) Alcohol dependence with alcohol-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.27) Alcohol dependence with alcohol-induced persisting dementia",
              label: "(F10.27) Alcohol dependence with alcohol-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.280) Alcohol dependence with alcohol-induced anxiety disorder",
              label: "(F10.280) Alcohol dependence with alcohol-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.281) Alcohol dependence with alcohol-induced sexual dysfunction",
              label: "(F10.281) Alcohol dependence with alcohol-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.282) Alcohol dependence with alcohol-induced sleep disorder",
              label: "(F10.282) Alcohol dependence with alcohol-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.288) Alcohol dependence with other alcohol-induced disorder",
              label: "(F10.288) Alcohol dependence with other alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.29) Alcohol dependence with unspecified alcohol-induced disorder",
              label: "(F10.29) Alcohol dependence with unspecified alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.90) Alcohol use, unspecified, uncomplicated",
              label: "(F10.90) Alcohol use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.91) Alcohol use, unspecified, in remission",
              label: "(F10.91) Alcohol use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.920) Alcohol use, unspecified with intoxication, uncomplicated",
              label: "(F10.920) Alcohol use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.921) Alcohol use, unspecified with intoxication delirium",
              label: "(F10.921) Alcohol use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.929) Alcohol use, unspecified with intoxication, unspecified",
              label: "(F10.929) Alcohol use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.930) Alcohol use, unspecified with withdrawal, uncomplicated",
              label: "(F10.930) Alcohol use, unspecified with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.931) Alcohol use, unspecified with withdrawal delirium",
              label: "(F10.931) Alcohol use, unspecified with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.932) Alcohol use, unspecified with withdrawal with perceptual disturbance",
              label: "(F10.932) Alcohol use, unspecified with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.939) Alcohol use, unspecified with withdrawal, unspecified",
              label: "(F10.939) Alcohol use, unspecified with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.94) Alcohol use, unspecified with alcohol-induced mood disorder",
              label: "(F10.94) Alcohol use, unspecified with alcohol-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.950) Alcohol use, unspecified with alcohol-induced psychotic disorder with delusions",
              label: "(F10.950) Alcohol use, unspecified with alcohol-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.951) Alcohol use, unspecified with alcohol-induced psychotic disorder with hallucinations",
              label: "(F10.951) Alcohol use, unspecified with alcohol-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.959) Alcohol use, unspecified with alcohol-induced psychotic disorder, unspecified",
              label: "(F10.959) Alcohol use, unspecified with alcohol-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.96) Alcohol use, unspecified with alcohol-induced persisting amnestic disorder",
              label: "(F10.96) Alcohol use, unspecified with alcohol-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.97) Alcohol use, unspecified with alcohol-induced persisting dementia",
              label: "(F10.97) Alcohol use, unspecified with alcohol-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.980) Alcohol use, unspecified with alcohol-induced anxiety disorder",
              label: "(F10.980) Alcohol use, unspecified with alcohol-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.981) Alcohol use, unspecified with alcohol-induced sexual dysfunction",
              label: "(F10.981) Alcohol use, unspecified with alcohol-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.982) Alcohol use, unspecified with alcohol-induced sleep disorder",
              label: "(F10.982) Alcohol use, unspecified with alcohol-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.988) Alcohol use, unspecified with other alcohol-induced disorder",
              label: "(F10.988) Alcohol use, unspecified with other alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.99) Alcohol use, unspecified with unspecified alcohol-induced disorder",
              label: "(F10.99) Alcohol use, unspecified with unspecified alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.10) Opioid abuse, uncomplicated",
              label: "(F11.10) Opioid abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.11) Opioid abuse, in remission",
              label: "(F11.11) Opioid abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.120) Opioid abuse with intoxication, uncomplicated",
              label: "(F11.120) Opioid abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.121) Opioid abuse with intoxication delirium",
              label: "(F11.121) Opioid abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.122) Opioid abuse with intoxication with perceptual disturbance",
              label: "(F11.122) Opioid abuse with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.129) Opioid abuse with intoxication, unspecified",
              label: "(F11.129) Opioid abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.13) Opioid abuse with withdrawal",
              label: "(F11.13) Opioid abuse with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.14) Opioid abuse with opioid-induced mood disorder",
              label: "(F11.14) Opioid abuse with opioid-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.150) Opioid abuse with opioid-induced psychotic disorder with delusions",
              label: "(F11.150) Opioid abuse with opioid-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.151) Opioid abuse with opioid-induced psychotic disorder with hallucinations",
              label: "(F11.151) Opioid abuse with opioid-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.159) Opioid abuse with opioid-induced psychotic disorder, unspecified",
              label: "(F11.159) Opioid abuse with opioid-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.181) Opioid abuse with opioid-induced sexual dysfunction",
              label: "(F11.181) Opioid abuse with opioid-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.182) Opioid abuse with opioid-induced sleep disorder",
              label: "(F11.182) Opioid abuse with opioid-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.188) Opioid abuse with other opioid-induced disorder",
              label: "(F11.188) Opioid abuse with other opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.19) Opioid abuse with unspecified opioid-induced disorder",
              label: "(F11.19) Opioid abuse with unspecified opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.20) Opioid dependence, uncomplicated",
              label: "(F11.20) Opioid dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.21) Opioid dependence, in remission",
              label: "(F11.21) Opioid dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.220) Opioid dependence with intoxication, uncomplicated",
              label: "(F11.220) Opioid dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.221) Opioid dependence with intoxication delirium",
              label: "(F11.221) Opioid dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.222) Opioid dependence with intoxication with perceptual disturbance",
              label: "(F11.222) Opioid dependence with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.229) Opioid dependence with intoxication, unspecified",
              label: "(F11.229) Opioid dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.23) Opioid dependence with withdrawal",
              label: "(F11.23) Opioid dependence with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.24) Opioid dependence with opioid-induced mood disorder",
              label: "(F11.24) Opioid dependence with opioid-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.250) Opioid dependence with opioid-induced psychotic disorder with delusions",
              label: "(F11.250) Opioid dependence with opioid-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.251) Opioid dependence with opioid-induced psychotic disorder with hallucinations",
              label: "(F11.251) Opioid dependence with opioid-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.259) Opioid dependence with opioid-induced psychotic disorder, unspecified",
              label: "(F11.259) Opioid dependence with opioid-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.281) Opioid dependence with opioid-induced sexual dysfunction",
              label: "(F11.281) Opioid dependence with opioid-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.282) Opioid dependence with opioid-induced sleep disorder",
              label: "(F11.282) Opioid dependence with opioid-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.288) Opioid dependence with other opioid-induced disorder",
              label: "(F11.288) Opioid dependence with other opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.29) Opioid dependence with unspecified opioid-induced disorder",
              label: "(F11.29) Opioid dependence with unspecified opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.90) Opioid use, unspecified, uncomplicated",
              label: "(F11.90) Opioid use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.91) Opioid use, unspecified, in remission",
              label: "(F11.91) Opioid use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.920) Opioid use, unspecified with intoxication, uncomplicated",
              label: "(F11.920) Opioid use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.921) Opioid use, unspecified with intoxication delirium",
              label: "(F11.921) Opioid use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.922) Opioid use, unspecified with intoxication with perceptual disturbance",
              label: "(F11.922) Opioid use, unspecified with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.929) Opioid use, unspecified with intoxication, unspecified",
              label: "(F11.929) Opioid use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.93) Opioid use, unspecified with withdrawal",
              label: "(F11.93) Opioid use, unspecified with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.94) Opioid use, unspecified with opioid-induced mood disorder",
              label: "(F11.94) Opioid use, unspecified with opioid-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.950) Opioid use, unspecified with opioid-induced psychotic disorder with delusions",
              label: "(F11.950) Opioid use, unspecified with opioid-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.951) Opioid use, unspecified with opioid-induced psychotic disorder with hallucinations",
              label: "(F11.951) Opioid use, unspecified with opioid-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.959) Opioid use, unspecified with opioid-induced psychotic disorder, unspecified",
              label: "(F11.959) Opioid use, unspecified with opioid-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.981) Opioid use, unspecified with opioid-induced sexual dysfunction",
              label: "(F11.981) Opioid use, unspecified with opioid-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.982) Opioid use, unspecified with opioid-induced sleep disorder",
              label: "(F11.982) Opioid use, unspecified with opioid-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.988) Opioid use, unspecified with other opioid-induced disorder",
              label: "(F11.988) Opioid use, unspecified with other opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.99) Opioid use, unspecified with unspecified opioid-induced disorder",
              label: "(F11.99) Opioid use, unspecified with unspecified opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.10) Cannabis abuse, uncomplicated",
              label: "(F12.10) Cannabis abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.11) Cannabis abuse, in remission",
              label: "(F12.11) Cannabis abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.120) Cannabis abuse with intoxication, uncomplicated",
              label: "(F12.120) Cannabis abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.121) Cannabis abuse with intoxication delirium",
              label: "(F12.121) Cannabis abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.122) Cannabis abuse with intoxication with perceptual disturbance",
              label: "(F12.122) Cannabis abuse with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.129) Cannabis abuse with intoxication, unspecified",
              label: "(F12.129) Cannabis abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.13) Cannabis abuse with withdrawal",
              label: "(F12.13) Cannabis abuse with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.150) Cannabis abuse with psychotic disorder with delusions",
              label: "(F12.150) Cannabis abuse with psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.151) Cannabis abuse with psychotic disorder with hallucinations",
              label: "(F12.151) Cannabis abuse with psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.159) Cannabis abuse with psychotic disorder, unspecified",
              label: "(F12.159) Cannabis abuse with psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.180) Cannabis abuse with cannabis-induced anxiety disorder",
              label: "(F12.180) Cannabis abuse with cannabis-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.188) Cannabis abuse with other cannabis-induced disorder",
              label: "(F12.188) Cannabis abuse with other cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.19) Cannabis abuse with unspecified cannabis-induced disorder",
              label: "(F12.19) Cannabis abuse with unspecified cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.20) Cannabis dependence, uncomplicated",
              label: "(F12.20) Cannabis dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.21) Cannabis dependence, in remission",
              label: "(F12.21) Cannabis dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.220) Cannabis dependence with intoxication, uncomplicated",
              label: "(F12.220) Cannabis dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.221) Cannabis dependence with intoxication delirium",
              label: "(F12.221) Cannabis dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.222) Cannabis dependence with intoxication with perceptual disturbance",
              label: "(F12.222) Cannabis dependence with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.229) Cannabis dependence with intoxication, unspecified",
              label: "(F12.229) Cannabis dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.23) Cannabis dependence with withdrawal",
              label: "(F12.23) Cannabis dependence with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.250) Cannabis dependence with psychotic disorder with delusions",
              label: "(F12.250) Cannabis dependence with psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.251) Cannabis dependence with psychotic disorder with hallucinations",
              label: "(F12.251) Cannabis dependence with psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.259) Cannabis dependence with psychotic disorder, unspecified",
              label: "(F12.259) Cannabis dependence with psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.280) Cannabis dependence with cannabis-induced anxiety disorder",
              label: "(F12.280) Cannabis dependence with cannabis-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.288) Cannabis dependence with other cannabis-induced disorder",
              label: "(F12.288) Cannabis dependence with other cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.29) Cannabis dependence with unspecified cannabis-induced disorder",
              label: "(F12.29) Cannabis dependence with unspecified cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.90) Cannabis use, unspecified, uncomplicated",
              label: "(F12.90) Cannabis use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.91) Cannabis use, unspecified, in remission",
              label: "(F12.91) Cannabis use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.920) Cannabis use, unspecified with intoxication, uncomplicated",
              label: "(F12.920) Cannabis use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.921) Cannabis use, unspecified with intoxication delirium",
              label: "(F12.921) Cannabis use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.922) Cannabis use, unspecified with intoxication with perceptual disturbance",
              label: "(F12.922) Cannabis use, unspecified with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.929) Cannabis use, unspecified with intoxication, unspecified",
              label: "(F12.929) Cannabis use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.93) Cannabis use, unspecified with withdrawal",
              label: "(F12.93) Cannabis use, unspecified with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.950) Cannabis use, unspecified with psychotic disorder with delusions",
              label: "(F12.950) Cannabis use, unspecified with psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.951) Cannabis use, unspecified with psychotic disorder with hallucinations",
              label: "(F12.951) Cannabis use, unspecified with psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.959) Cannabis use, unspecified with psychotic disorder, unspecified",
              label: "(F12.959) Cannabis use, unspecified with psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.980) Cannabis use, unspecified with anxiety disorder",
              label: "(F12.980) Cannabis use, unspecified with anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.988) Cannabis use, unspecified with other cannabis-induced disorder",
              label: "(F12.988) Cannabis use, unspecified with other cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.99) Cannabis use, unspecified with unspecified cannabis-induced disorder",
              label: "(F12.99) Cannabis use, unspecified with unspecified cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.10) Sedative, hypnotic or anxiolytic abuse, uncomplicated",
              label: "(F13.10) Sedative, hypnotic or anxiolytic abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.11) Sedative, hypnotic or anxiolytic abuse, in remission",
              label: "(F13.11) Sedative, hypnotic or anxiolytic abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.120) Sedative, hypnotic or anxiolytic abuse with intoxication, uncomplicated",
              label: "(F13.120) Sedative, hypnotic or anxiolytic abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.121) Sedative, hypnotic or anxiolytic abuse with intoxication delirium",
              label: "(F13.121) Sedative, hypnotic or anxiolytic abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.129) Sedative, hypnotic or anxiolytic abuse with intoxication, unspecified",
              label: "(F13.129) Sedative, hypnotic or anxiolytic abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.130) Sedative, hypnotic or anxiolytic abuse with withdrawal, uncomplicated",
              label: "(F13.130) Sedative, hypnotic or anxiolytic abuse with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.131) Sedative, hypnotic or anxiolytic abuse with withdrawal delirium",
              label: "(F13.131) Sedative, hypnotic or anxiolytic abuse with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.132) Sedative, hypnotic or anxiolytic abuse with withdrawal with perceptual disturbance",
              label: "(F13.132) Sedative, hypnotic or anxiolytic abuse with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.139) Sedative, hypnotic or anxiolytic abuse with withdrawal, unspecified",
              label: "(F13.139) Sedative, hypnotic or anxiolytic abuse with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.14) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced mood disorder",
              label: "(F13.14) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.150) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              label: "(F13.150) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.151) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              label: "(F13.151) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.159) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              label: "(F13.159) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.180) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              label: "(F13.180) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.181) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              label: "(F13.181) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.182) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sleep disorder",
              label: "(F13.182) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.188) Sedative, hypnotic or anxiolytic abuse with other sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.188) Sedative, hypnotic or anxiolytic abuse with other sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.19) Sedative, hypnotic or anxiolytic abuse with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.19) Sedative, hypnotic or anxiolytic abuse with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.20) Sedative, hypnotic or anxiolytic dependence, uncomplicated",
              label: "(F13.20) Sedative, hypnotic or anxiolytic dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.21) Sedative, hypnotic or anxiolytic dependence, in remission",
              label: "(F13.21) Sedative, hypnotic or anxiolytic dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.220) Sedative, hypnotic or anxiolytic dependence with intoxication, uncomplicated",
              label: "(F13.220) Sedative, hypnotic or anxiolytic dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.221) Sedative, hypnotic or anxiolytic dependence with intoxication delirium",
              label: "(F13.221) Sedative, hypnotic or anxiolytic dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.229) Sedative, hypnotic or anxiolytic dependence with intoxication, unspecified",
              label: "(F13.229) Sedative, hypnotic or anxiolytic dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.230) Sedative, hypnotic or anxiolytic dependence with withdrawal, uncomplicated",
              label: "(F13.230) Sedative, hypnotic or anxiolytic dependence with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.231) Sedative, hypnotic or anxiolytic dependence with withdrawal delirium",
              label: "(F13.231) Sedative, hypnotic or anxiolytic dependence with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.232) Sedative, hypnotic or anxiolytic dependence with withdrawal with perceptual disturbance",
              label: "(F13.232) Sedative, hypnotic or anxiolytic dependence with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.239) Sedative, hypnotic or anxiolytic dependence with withdrawal, unspecified",
              label: "(F13.239) Sedative, hypnotic or anxiolytic dependence with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.24) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced mood disorder",
              label: "(F13.24) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.250) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              label: "(F13.250) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.251) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              label: "(F13.251) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.259) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              label: "(F13.259) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.26) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder",
              label: "(F13.26) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.27) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting dementia",
              label: "(F13.27) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.280) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              label: "(F13.280) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.281) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              label: "(F13.281) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.282) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sleep disorder",
              label: "(F13.282) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.288) Sedative, hypnotic or anxiolytic dependence with other sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.288) Sedative, hypnotic or anxiolytic dependence with other sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.29) Sedative, hypnotic or anxiolytic dependence with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.29) Sedative, hypnotic or anxiolytic dependence with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.90) Sedative, hypnotic, or anxiolytic use, unspecified, uncomplicated",
              label: "(F13.90) Sedative, hypnotic, or anxiolytic use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.91) Sedative, hypnotic or anxiolytic use, unspecified, in remission",
              label: "(F13.91) Sedative, hypnotic or anxiolytic use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.920) Sedative, hypnotic or anxiolytic use, unspecified with intoxication, uncomplicated",
              label: "(F13.920) Sedative, hypnotic or anxiolytic use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.921) Sedative, hypnotic or anxiolytic use, unspecified with intoxication delirium",
              label: "(F13.921) Sedative, hypnotic or anxiolytic use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.929) Sedative, hypnotic or anxiolytic use, unspecified with intoxication, unspecified",
              label: "(F13.929) Sedative, hypnotic or anxiolytic use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.930) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, uncomplicated",
              label: "(F13.930) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.931) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal delirium",
              label: "(F13.931) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.932) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal with perceptual disturbances",
              label: "(F13.932) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal with perceptual disturbances",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.939) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, unspecified",
              label: "(F13.939) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.94) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced mood disorder",
              label: "(F13.94) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.950) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              label: "(F13.950) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.951) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              label: "(F13.951) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.959) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              label: "(F13.959) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.96) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder",
              label: "(F13.96) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.97) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting dementia",
              label: "(F13.97) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.980) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              label: "(F13.980) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.981) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              label: "(F13.981) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.982) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sleep disorder",
              label: "(F13.982) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.988) Sedative, hypnotic or anxiolytic use, unspecified with other sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.988) Sedative, hypnotic or anxiolytic use, unspecified with other sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.99) Sedative, hypnotic or anxiolytic use, unspecified with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.99) Sedative, hypnotic or anxiolytic use, unspecified with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.10) Cocaine abuse, uncomplicated",
              label: "(F14.10) Cocaine abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.11) Cocaine abuse, in remission",
              label: "(F14.11) Cocaine abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.120) Cocaine abuse with intoxication, uncomplicated",
              label: "(F14.120) Cocaine abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.121) Cocaine abuse with intoxication with delirium",
              label: "(F14.121) Cocaine abuse with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.122) Cocaine abuse with intoxication with perceptual disturbance",
              label: "(F14.122) Cocaine abuse with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.129) Cocaine abuse with intoxication, unspecified",
              label: "(F14.129) Cocaine abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.13) Cocaine abuse, unspecified with withdrawal",
              label: "(F14.13) Cocaine abuse, unspecified with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.14) Cocaine abuse with cocaine-induced mood disorder",
              label: "(F14.14) Cocaine abuse with cocaine-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.150) Cocaine abuse with cocaine-induced psychotic disorder with delusions",
              label: "(F14.150) Cocaine abuse with cocaine-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.151) Cocaine abuse with cocaine-induced psychotic disorder with hallucinations",
              label: "(F14.151) Cocaine abuse with cocaine-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.159) Cocaine abuse with cocaine-induced psychotic disorder, unspecified",
              label: "(F14.159) Cocaine abuse with cocaine-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.180) Cocaine abuse with cocaine-induced anxiety disorder",
              label: "(F14.180) Cocaine abuse with cocaine-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.181) Cocaine abuse with cocaine-induced sexual dysfunction",
              label: "(F14.181) Cocaine abuse with cocaine-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.182) Cocaine abuse with cocaine-induced sleep disorder",
              label: "(F14.182) Cocaine abuse with cocaine-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.188) Cocaine abuse with other cocaine-induced disorder",
              label: "(F14.188) Cocaine abuse with other cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.19) Cocaine abuse with unspecified cocaine-induced disorder",
              label: "(F14.19) Cocaine abuse with unspecified cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.20) Cocaine dependence, uncomplicated",
              label: "(F14.20) Cocaine dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.21) Cocaine dependence, in remission",
              label: "(F14.21) Cocaine dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.220) Cocaine dependence with intoxication, uncomplicated",
              label: "(F14.220) Cocaine dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.221) Cocaine dependence with intoxication delirium",
              label: "(F14.221) Cocaine dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.222) Cocaine dependence with intoxication with perceptual disturbance",
              label: "(F14.222) Cocaine dependence with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.229) Cocaine dependence with intoxication, unspecified",
              label: "(F14.229) Cocaine dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.23) Cocaine dependence with withdrawal",
              label: "(F14.23) Cocaine dependence with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.24) Cocaine dependence with cocaine-induced mood disorder",
              label: "(F14.24) Cocaine dependence with cocaine-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.250) Cocaine dependence with cocaine-induced psychotic disorder with delusions",
              label: "(F14.250) Cocaine dependence with cocaine-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.251) Cocaine dependence with cocaine-induced psychotic disorder with hallucinations",
              label: "(F14.251) Cocaine dependence with cocaine-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.259) Cocaine dependence with cocaine-induced psychotic disorder, unspecified",
              label: "(F14.259) Cocaine dependence with cocaine-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.280) Cocaine dependence with cocaine-induced anxiety disorder",
              label: "(F14.280) Cocaine dependence with cocaine-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.281) Cocaine dependence with cocaine-induced sexual dysfunction",
              label: "(F14.281) Cocaine dependence with cocaine-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.282) Cocaine dependence with cocaine-induced sleep disorder",
              label: "(F14.282) Cocaine dependence with cocaine-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.288) Cocaine dependence with other cocaine-induced disorder",
              label: "(F14.288) Cocaine dependence with other cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.29) Cocaine dependence with unspecified cocaine-induced disorder",
              label: "(F14.29) Cocaine dependence with unspecified cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.90) Cocaine use, unspecified, uncomplicated",
              label: "(F14.90) Cocaine use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.91) Cocaine use, unspecified, in remission",
              label: "(F14.91) Cocaine use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.920) Cocaine use, unspecified with intoxication, uncomplicated",
              label: "(F14.920) Cocaine use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.921) Cocaine use, unspecified with intoxication delirium",
              label: "(F14.921) Cocaine use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.922) Cocaine use, unspecified with intoxication with perceptual disturbance",
              label: "(F14.922) Cocaine use, unspecified with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.929) Cocaine use, unspecified with intoxication, unspecified",
              label: "(F14.929) Cocaine use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.93) Cocaine use, unspecified with withdrawal",
              label: "(F14.93) Cocaine use, unspecified with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.94) Cocaine use, unspecified with cocaine-induced mood disorder",
              label: "(F14.94) Cocaine use, unspecified with cocaine-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.950) Cocaine use, unspecified with cocaine-induced psychotic disorder with delusions",
              label: "(F14.950) Cocaine use, unspecified with cocaine-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.951) Cocaine use, unspecified with cocaine-induced psychotic disorder with hallucinations",
              label: "(F14.951) Cocaine use, unspecified with cocaine-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.959) Cocaine use, unspecified with cocaine-induced psychotic disorder, unspecified",
              label: "(F14.959) Cocaine use, unspecified with cocaine-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.980) Cocaine use, unspecified with cocaine-induced anxiety disorder",
              label: "(F14.980) Cocaine use, unspecified with cocaine-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.981) Cocaine use, unspecified with cocaine-induced sexual dysfunction",
              label: "(F14.981) Cocaine use, unspecified with cocaine-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.982) Cocaine use, unspecified with cocaine-induced sleep disorder",
              label: "(F14.982) Cocaine use, unspecified with cocaine-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.988) Cocaine use, unspecified with other cocaine-induced disorder",
              label: "(F14.988) Cocaine use, unspecified with other cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.99) Cocaine use, unspecified with unspecified cocaine-induced disorder",
              label: "(F14.99) Cocaine use, unspecified with unspecified cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.10) Other stimulant abuse, uncomplicated",
              label: "(F15.10) Other stimulant abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.11) Other stimulant abuse, in remission",
              label: "(F15.11) Other stimulant abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.120) Other stimulant abuse with intoxication, uncomplicated",
              label: "(F15.120) Other stimulant abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.121) Other stimulant abuse with intoxication delirium",
              label: "(F15.121) Other stimulant abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.122) Other stimulant abuse with intoxication with perceptual disturbance",
              label: "(F15.122) Other stimulant abuse with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.129) Other stimulant abuse with intoxication, unspecified",
              label: "(F15.129) Other stimulant abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.13) Other stimulant abuse with withdrawal",
              label: "(F15.13) Other stimulant abuse with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.14) Other stimulant abuse with stimulant-induced mood disorder",
              label: "(F15.14) Other stimulant abuse with stimulant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.150) Other stimulant abuse with stimulant-induced psychotic disorder with delusions",
              label: "(F15.150) Other stimulant abuse with stimulant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.151) Other stimulant abuse with stimulant-induced psychotic disorder with hallucinations",
              label: "(F15.151) Other stimulant abuse with stimulant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.159) Other stimulant abuse with stimulant-induced psychotic disorder, unspecified",
              label: "(F15.159) Other stimulant abuse with stimulant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.180) Other stimulant abuse with stimulant-induced anxiety disorder",
              label: "(F15.180) Other stimulant abuse with stimulant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.181) Other stimulant abuse with stimulant-induced sexual dysfunction",
              label: "(F15.181) Other stimulant abuse with stimulant-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.182) Other stimulant abuse with stimulant-induced sleep disorder",
              label: "(F15.182) Other stimulant abuse with stimulant-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.188) Other stimulant abuse with other stimulant-induced disorder",
              label: "(F15.188) Other stimulant abuse with other stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.19) Other stimulant abuse with unspecified stimulant-induced disorder",
              label: "(F15.19) Other stimulant abuse with unspecified stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.20) Other stimulant dependence, uncomplicated",
              label: "(F15.20) Other stimulant dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.21) Other stimulant dependence, in remission",
              label: "(F15.21) Other stimulant dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.220) Other stimulant dependence with intoxication, uncomplicated",
              label: "(F15.220) Other stimulant dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.221) Other stimulant dependence with intoxication delirium",
              label: "(F15.221) Other stimulant dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.222) Other stimulant dependence with intoxication with perceptual disturbance",
              label: "(F15.222) Other stimulant dependence with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.229) Other stimulant dependence with intoxication, unspecified",
              label: "(F15.229) Other stimulant dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.23) Other stimulant dependence with withdrawal",
              label: "(F15.23) Other stimulant dependence with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.24) Other stimulant dependence with stimulant-induced mood disorder",
              label: "(F15.24) Other stimulant dependence with stimulant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.250) Other stimulant dependence with stimulant-induced psychotic disorder with delusions",
              label: "(F15.250) Other stimulant dependence with stimulant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.251) Other stimulant dependence with stimulant-induced psychotic disorder with hallucinations",
              label: "(F15.251) Other stimulant dependence with stimulant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.259) Other stimulant dependence with stimulant-induced psychotic disorder, unspecified",
              label: "(F15.259) Other stimulant dependence with stimulant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.280) Other stimulant dependence with stimulant-induced anxiety disorder",
              label: "(F15.280) Other stimulant dependence with stimulant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.281) Other stimulant dependence with stimulant-induced sexual dysfunction",
              label: "(F15.281) Other stimulant dependence with stimulant-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.282) Other stimulant dependence with stimulant-induced sleep disorder",
              label: "(F15.282) Other stimulant dependence with stimulant-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.288) Other stimulant dependence with other stimulant-induced disorder",
              label: "(F15.288) Other stimulant dependence with other stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.29) Other stimulant dependence with unspecified stimulant-induced disorder",
              label: "(F15.29) Other stimulant dependence with unspecified stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.90) Other stimulant use, unspecified, uncomplicated",
              label: "(F15.90) Other stimulant use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.91) Other stimulant use, unspecified, in remission",
              label: "(F15.91) Other stimulant use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.920) Other stimulant use, unspecified with intoxication, uncomplicated",
              label: "(F15.920) Other stimulant use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.921) Other stimulant use, unspecified with intoxication delirium",
              label: "(F15.921) Other stimulant use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.922) Other stimulant use, unspecified with intoxication with perceptual disturbance",
              label: "(F15.922) Other stimulant use, unspecified with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.929) Other stimulant use, unspecified with intoxication, unspecified",
              label: "(F15.929) Other stimulant use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.93) Other stimulant use, unspecified with withdrawal",
              label: "(F15.93) Other stimulant use, unspecified with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.94) Other stimulant use, unspecified with stimulant-induced mood disorder",
              label: "(F15.94) Other stimulant use, unspecified with stimulant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.950) Other stimulant use, unspecified with stimulant-induced psychotic disorder with delusions",
              label: "(F15.950) Other stimulant use, unspecified with stimulant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.951) Other stimulant use, unspecified with stimulant-induced psychotic disorder with hallucinations",
              label: "(F15.951) Other stimulant use, unspecified with stimulant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.959) Other stimulant use, unspecified with stimulant-induced psychotic disorder, unspecified",
              label: "(F15.959) Other stimulant use, unspecified with stimulant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.980) Other stimulant use, unspecified with stimulant-induced anxiety disorder",
              label: "(F15.980) Other stimulant use, unspecified with stimulant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.981) Other stimulant use, unspecified with stimulant-induced sexual dysfunction",
              label: "(F15.981) Other stimulant use, unspecified with stimulant-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.982) Other stimulant use, unspecified with stimulant-induced sleep disorder",
              label: "(F15.982) Other stimulant use, unspecified with stimulant-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.988) Other stimulant use, unspecified with other stimulant-induced disorder",
              label: "(F15.988) Other stimulant use, unspecified with other stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.99) Other stimulant use, unspecified with unspecified stimulant-induced disorder",
              label: "(F15.99) Other stimulant use, unspecified with unspecified stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.10) Hallucinogen abuse, uncomplicated",
              label: "(F16.10) Hallucinogen abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.11) Hallucinogen abuse, in remission",
              label: "(F16.11) Hallucinogen abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.120) Hallucinogen abuse with intoxication, uncomplicated",
              label: "(F16.120) Hallucinogen abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.121) Hallucinogen abuse with intoxication with delirium",
              label: "(F16.121) Hallucinogen abuse with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.122) Hallucinogen abuse with intoxication with perceptual disturbance",
              label: "(F16.122) Hallucinogen abuse with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.129) Hallucinogen abuse with intoxication, unspecified",
              label: "(F16.129) Hallucinogen abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.14) Hallucinogen abuse with hallucinogen-induced mood disorder",
              label: "(F16.14) Hallucinogen abuse with hallucinogen-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.150) Hallucinogen abuse with hallucinogen-induced psychotic disorder with delusions",
              label: "(F16.150) Hallucinogen abuse with hallucinogen-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.151) Hallucinogen abuse with hallucinogen-induced psychotic disorder with hallucinations",
              label: "(F16.151) Hallucinogen abuse with hallucinogen-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.159) Hallucinogen abuse with hallucinogen-induced psychotic disorder, unspecified",
              label: "(F16.159) Hallucinogen abuse with hallucinogen-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.180) Hallucinogen abuse with hallucinogen-induced anxiety disorder",
              label: "(F16.180) Hallucinogen abuse with hallucinogen-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.183) Hallucinogen abuse with hallucinogen persisting perception disorder (flashbacks)",
              label: "(F16.183) Hallucinogen abuse with hallucinogen persisting perception disorder (flashbacks)",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.188) Hallucinogen abuse with other hallucinogen-induced disorder",
              label: "(F16.188) Hallucinogen abuse with other hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.19) Hallucinogen abuse with unspecified hallucinogen-induced disorder",
              label: "(F16.19) Hallucinogen abuse with unspecified hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.20) Hallucinogen dependence, uncomplicated",
              label: "(F16.20) Hallucinogen dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.21) Hallucinogen dependence, in remission",
              label: "(F16.21) Hallucinogen dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.220) Hallucinogen dependence with intoxication, uncomplicated",
              label: "(F16.220) Hallucinogen dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.221) Hallucinogen dependence with intoxication with delirium",
              label: "(F16.221) Hallucinogen dependence with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.229) Hallucinogen dependence with intoxication, unspecified",
              label: "(F16.229) Hallucinogen dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.24) Hallucinogen dependence with hallucinogen-induced mood disorder",
              label: "(F16.24) Hallucinogen dependence with hallucinogen-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.250) Hallucinogen dependence with hallucinogen-induced psychotic disorder with delusions",
              label: "(F16.250) Hallucinogen dependence with hallucinogen-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.251) Hallucinogen dependence with hallucinogen-induced psychotic disorder with hallucinations",
              label: "(F16.251) Hallucinogen dependence with hallucinogen-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.259) Hallucinogen dependence with hallucinogen-induced psychotic disorder, unspecified",
              label: "(F16.259) Hallucinogen dependence with hallucinogen-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.280) Hallucinogen dependence with hallucinogen-induced anxiety disorder",
              label: "(F16.280) Hallucinogen dependence with hallucinogen-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.283) Hallucinogen dependence with hallucinogen persisting perception disorder (flashbacks)",
              label: "(F16.283) Hallucinogen dependence with hallucinogen persisting perception disorder (flashbacks)",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.288) Hallucinogen dependence with other hallucinogen-induced disorder",
              label: "(F16.288) Hallucinogen dependence with other hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.29) Hallucinogen dependence with unspecified hallucinogen-induced disorder",
              label: "(F16.29) Hallucinogen dependence with unspecified hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.90) Hallucinogen use, unspecified, uncomplicated",
              label: "(F16.90) Hallucinogen use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.91) Hallucinogen use, unspecified, in remission",
              label: "(F16.91) Hallucinogen use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.920) Hallucinogen use, unspecified with intoxication, uncomplicated",
              label: "(F16.920) Hallucinogen use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.921) Hallucinogen use, unspecified with intoxication with delirium",
              label: "(F16.921) Hallucinogen use, unspecified with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.929) Hallucinogen use, unspecified with intoxication, unspecified",
              label: "(F16.929) Hallucinogen use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.94) Hallucinogen use, unspecified with hallucinogen-induced mood disorder",
              label: "(F16.94) Hallucinogen use, unspecified with hallucinogen-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.950) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with delusions",
              label: "(F16.950) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.951) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with hallucinations",
              label: "(F16.951) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.959) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder, unspecified",
              label: "(F16.959) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.980) Hallucinogen use, unspecified with hallucinogen-induced anxiety disorder",
              label: "(F16.980) Hallucinogen use, unspecified with hallucinogen-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.983) Hallucinogen use, unspecified with hallucinogen persisting perception disorder (flashbacks)",
              label: "(F16.983) Hallucinogen use, unspecified with hallucinogen persisting perception disorder (flashbacks)",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.988) Hallucinogen use, unspecified with other hallucinogen-induced disorder",
              label: "(F16.988) Hallucinogen use, unspecified with other hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.99) Hallucinogen use, unspecified with unspecified hallucinogen-induced disorder",
              label: "(F16.99) Hallucinogen use, unspecified with unspecified hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.200) Nicotine dependence, unspecified, uncomplicated",
              label: "(F17.200) Nicotine dependence, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.201) Nicotine dependence, unspecified, in remission",
              label: "(F17.201) Nicotine dependence, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.203) Nicotine dependence unspecified, with withdrawal",
              label: "(F17.203) Nicotine dependence unspecified, with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.208) Nicotine dependence, unspecified, with other nicotine-induced disorders",
              label: "(F17.208) Nicotine dependence, unspecified, with other nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.209) Nicotine dependence, unspecified, with unspecified nicotine-induced disorders",
              label: "(F17.209) Nicotine dependence, unspecified, with unspecified nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.210) Nicotine dependence, cigarettes, uncomplicated",
              label: "(F17.210) Nicotine dependence, cigarettes, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.211) Nicotine dependence, cigarettes, in remission",
              label: "(F17.211) Nicotine dependence, cigarettes, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.213) Nicotine dependence, cigarettes, with withdrawal",
              label: "(F17.213) Nicotine dependence, cigarettes, with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.218) Nicotine dependence, cigarettes, with other nicotine-induced disorders",
              label: "(F17.218) Nicotine dependence, cigarettes, with other nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.219) Nicotine dependence, cigarettes, with unspecified nicotine-induced disorders",
              label: "(F17.219) Nicotine dependence, cigarettes, with unspecified nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.220) Nicotine dependence, chewing tobacco, uncomplicated",
              label: "(F17.220) Nicotine dependence, chewing tobacco, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.221) Nicotine dependence, chewing tobacco, in remission",
              label: "(F17.221) Nicotine dependence, chewing tobacco, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.223) Nicotine dependence, chewing tobacco, with withdrawal",
              label: "(F17.223) Nicotine dependence, chewing tobacco, with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.228) Nicotine dependence, chewing tobacco, with other nicotine-induced disorders",
              label: "(F17.228) Nicotine dependence, chewing tobacco, with other nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.229) Nicotine dependence, chewing tobacco, with unspecified nicotine-induced disorders",
              label: "(F17.229) Nicotine dependence, chewing tobacco, with unspecified nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.290) Nicotine dependence, other tobacco product, uncomplicated",
              label: "(F17.290) Nicotine dependence, other tobacco product, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.291) Nicotine dependence, other tobacco product, in remission",
              label: "(F17.291) Nicotine dependence, other tobacco product, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.293) Nicotine dependence, other tobacco product, with withdrawal",
              label: "(F17.293) Nicotine dependence, other tobacco product, with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.298) Nicotine dependence, other tobacco product, with other nicotine-induced disorders",
              label: "(F17.298) Nicotine dependence, other tobacco product, with other nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.299) Nicotine dependence, other tobacco product, with unspecified nicotine-induced disorders",
              label: "(F17.299) Nicotine dependence, other tobacco product, with unspecified nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.10) Inhalant abuse, uncomplicated",
              label: "(F18.10) Inhalant abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.11) Inhalant abuse, in remission",
              label: "(F18.11) Inhalant abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.120) Inhalant abuse with intoxication, uncomplicated",
              label: "(F18.120) Inhalant abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.121) Inhalant abuse with intoxication delirium",
              label: "(F18.121) Inhalant abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.129) Inhalant abuse with intoxication, unspecified",
              label: "(F18.129) Inhalant abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.14) Inhalant abuse with inhalant-induced mood disorder",
              label: "(F18.14) Inhalant abuse with inhalant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.150) Inhalant abuse with inhalant-induced psychotic disorder with delusions",
              label: "(F18.150) Inhalant abuse with inhalant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.151) Inhalant abuse with inhalant-induced psychotic disorder with hallucinations",
              label: "(F18.151) Inhalant abuse with inhalant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.159) Inhalant abuse with inhalant-induced psychotic disorder, unspecified",
              label: "(F18.159) Inhalant abuse with inhalant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.17) Inhalant abuse with inhalant-induced dementia",
              label: "(F18.17) Inhalant abuse with inhalant-induced dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.180) Inhalant abuse with inhalant-induced anxiety disorder",
              label: "(F18.180) Inhalant abuse with inhalant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.188) Inhalant abuse with other inhalant-induced disorder",
              label: "(F18.188) Inhalant abuse with other inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.19) Inhalant abuse with unspecified inhalant-induced disorder",
              label: "(F18.19) Inhalant abuse with unspecified inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.20) Inhalant dependence, uncomplicated",
              label: "(F18.20) Inhalant dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.21) Inhalant dependence, in remission",
              label: "(F18.21) Inhalant dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.220) Inhalant dependence with intoxication, uncomplicated",
              label: "(F18.220) Inhalant dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.221) Inhalant dependence with intoxication delirium",
              label: "(F18.221) Inhalant dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.229) Inhalant dependence with intoxication, unspecified",
              label: "(F18.229) Inhalant dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.24) Inhalant dependence with inhalant-induced mood disorder",
              label: "(F18.24) Inhalant dependence with inhalant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.250) Inhalant dependence with inhalant-induced psychotic disorder with delusions",
              label: "(F18.250) Inhalant dependence with inhalant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.251) Inhalant dependence with inhalant-induced psychotic disorder with hallucinations",
              label: "(F18.251) Inhalant dependence with inhalant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.259) Inhalant dependence with inhalant-induced psychotic disorder, unspecified",
              label: "(F18.259) Inhalant dependence with inhalant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.27) Inhalant dependence with inhalant-induced dementia",
              label: "(F18.27) Inhalant dependence with inhalant-induced dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.280) Inhalant dependence with inhalant-induced anxiety disorder",
              label: "(F18.280) Inhalant dependence with inhalant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.288) Inhalant dependence with other inhalant-induced disorder",
              label: "(F18.288) Inhalant dependence with other inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.29) Inhalant dependence with unspecified inhalant-induced disorder",
              label: "(F18.29) Inhalant dependence with unspecified inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.90) Inhalant use, unspecified, uncomplicated",
              label: "(F18.90) Inhalant use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.91) Inhalant use, unspecified, in remission",
              label: "(F18.91) Inhalant use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.920) Inhalant use, unspecified with intoxication, uncomplicated",
              label: "(F18.920) Inhalant use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.921) Inhalant use, unspecified with intoxication with delirium",
              label: "(F18.921) Inhalant use, unspecified with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.929) Inhalant use, unspecified with intoxication, unspecified",
              label: "(F18.929) Inhalant use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.94) Inhalant use, unspecified with inhalant-induced mood disorder",
              label: "(F18.94) Inhalant use, unspecified with inhalant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.950) Inhalant use, unspecified with inhalant-induced psychotic disorder with delusions",
              label: "(F18.950) Inhalant use, unspecified with inhalant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.951) Inhalant use, unspecified with inhalant-induced psychotic disorder with hallucinations",
              label: "(F18.951) Inhalant use, unspecified with inhalant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.959) Inhalant use, unspecified with inhalant-induced psychotic disorder, unspecified",
              label: "(F18.959) Inhalant use, unspecified with inhalant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.97) Inhalant use, unspecified with inhalant-induced persisting dementia",
              label: "(F18.97) Inhalant use, unspecified with inhalant-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.980) Inhalant use, unspecified with inhalant-induced anxiety disorder",
              label: "(F18.980) Inhalant use, unspecified with inhalant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.988) Inhalant use, unspecified with other inhalant-induced disorder",
              label: "(F18.988) Inhalant use, unspecified with other inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.99) Inhalant use, unspecified with unspecified inhalant-induced disorder",
              label: "(F18.99) Inhalant use, unspecified with unspecified inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.10) Other psychoactive substance abuse, uncomplicated",
              label: "(F19.10) Other psychoactive substance abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.11) Other psychoactive substance abuse, in remission",
              label: "(F19.11) Other psychoactive substance abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.120) Other psychoactive substance abuse with intoxication, uncomplicated",
              label: "(F19.120) Other psychoactive substance abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.121) Other psychoactive substance abuse with intoxication delirium",
              label: "(F19.121) Other psychoactive substance abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.122) Other psychoactive substance abuse with intoxication with perceptual disturbances",
              label: "(F19.122) Other psychoactive substance abuse with intoxication with perceptual disturbances",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.129) Other psychoactive substance abuse with intoxication, unspecified",
              label: "(F19.129) Other psychoactive substance abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.130) Other psychoactive substance abuse with withdrawal, uncomplicated",
              label: "(F19.130) Other psychoactive substance abuse with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.131) Other psychoactive substance abuse with withdrawal delirium",
              label: "(F19.131) Other psychoactive substance abuse with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.132) Other psychoactive substance abuse with withdrawal with perceptual disturbance",
              label: "(F19.132) Other psychoactive substance abuse with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.139) Other psychoactive substance abuse with withdrawal, unspecified",
              label: "(F19.139) Other psychoactive substance abuse with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.14) Other psychoactive substance abuse with psychoactive substance-induced mood disorder",
              label: "(F19.14) Other psychoactive substance abuse with psychoactive substance-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.150) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with delusions",
              label: "(F19.150) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.151) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with hallucinations",
              label: "(F19.151) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.159) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder, unspecified",
              label: "(F19.159) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.16) Other psychoactive substance abuse with psychoactive substance-induced persisting amnestic disorder",
              label: "(F19.16) Other psychoactive substance abuse with psychoactive substance-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.17) Other psychoactive substance abuse with psychoactive substance-induced persisting dementia",
              label: "(F19.17) Other psychoactive substance abuse with psychoactive substance-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.180) Other psychoactive substance abuse with psychoactive substance-induced anxiety disorder",
              label: "(F19.180) Other psychoactive substance abuse with psychoactive substance-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.181) Other psychoactive substance abuse with psychoactive substance-induced sexual dysfunction",
              label: "(F19.181) Other psychoactive substance abuse with psychoactive substance-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.182) Other psychoactive substance abuse with psychoactive substance-induced sleep disorder",
              label: "(F19.182) Other psychoactive substance abuse with psychoactive substance-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.188) Other psychoactive substance abuse with other psychoactive substance-induced disorder",
              label: "(F19.188) Other psychoactive substance abuse with other psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.19) Other psychoactive substance abuse with unspecified psychoactive substance-induced disorder",
              label: "(F19.19) Other psychoactive substance abuse with unspecified psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.20) Other psychoactive substance dependence, uncomplicated",
              label: "(F19.20) Other psychoactive substance dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.21) Other psychoactive substance dependence, in remission",
              label: "(F19.21) Other psychoactive substance dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.220) Other psychoactive substance dependence with intoxication, uncomplicated",
              label: "(F19.220) Other psychoactive substance dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.221) Other psychoactive substance dependence with intoxication delirium",
              label: "(F19.221) Other psychoactive substance dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.222) Other psychoactive substance dependence with intoxication with perceptual disturbance",
              label: "(F19.222) Other psychoactive substance dependence with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.229) Other psychoactive substance dependence with intoxication, unspecified",
              label: "(F19.229) Other psychoactive substance dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.230) Other psychoactive substance dependence with withdrawal, uncomplicated",
              label: "(F19.230) Other psychoactive substance dependence with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.231) Other psychoactive substance dependence with withdrawal delirium",
              label: "(F19.231) Other psychoactive substance dependence with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.232) Other psychoactive substance dependence with withdrawal with perceptual disturbance",
              label: "(F19.232) Other psychoactive substance dependence with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.239) Other psychoactive substance dependence with withdrawal, unspecified",
              label: "(F19.239) Other psychoactive substance dependence with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.24) Other psychoactive substance dependence with psychoactive substance-induced mood disorder",
              label: "(F19.24) Other psychoactive substance dependence with psychoactive substance-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.250) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with delusions",
              label: "(F19.250) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.251) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with hallucinations",
              label: "(F19.251) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.259) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder, unspecified",
              label: "(F19.259) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.26) Other psychoactive substance dependence with psychoactive substance-induced persisting amnestic disorder",
              label: "(F19.26) Other psychoactive substance dependence with psychoactive substance-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.27) Other psychoactive substance dependence with psychoactive substance-induced persisting dementia",
              label: "(F19.27) Other psychoactive substance dependence with psychoactive substance-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.280) Other psychoactive substance dependence with psychoactive substance-induced anxiety disorder",
              label: "(F19.280) Other psychoactive substance dependence with psychoactive substance-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.281) Other psychoactive substance dependence with psychoactive substance-induced sexual dysfunction",
              label: "(F19.281) Other psychoactive substance dependence with psychoactive substance-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.282) Other psychoactive substance dependence with psychoactive substance-induced sleep disorder",
              label: "(F19.282) Other psychoactive substance dependence with psychoactive substance-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.288) Other psychoactive substance dependence with other psychoactive substance-induced disorder",
              label: "(F19.288) Other psychoactive substance dependence with other psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.29) Other psychoactive substance dependence with unspecified psychoactive substance-induced disorder",
              label: "(F19.29) Other psychoactive substance dependence with unspecified psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.90) Other psychoactive substance use, unspecified, uncomplicated",
              label: "(F19.90) Other psychoactive substance use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.91) Other psychoactive substance use, unspecified, in remission",
              label: "(F19.91) Other psychoactive substance use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.920) Other psychoactive substance use, unspecified with intoxication, uncomplicated",
              label: "(F19.920) Other psychoactive substance use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.921) Other psychoactive substance use, unspecified with intoxication with delirium",
              label: "(F19.921) Other psychoactive substance use, unspecified with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.922) Other psychoactive substance use, unspecified with intoxication with perceptual disturbance",
              label: "(F19.922) Other psychoactive substance use, unspecified with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.929) Other psychoactive substance use, unspecified with intoxication, unspecified",
              label: "(F19.929) Other psychoactive substance use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.930) Other psychoactive substance use, unspecified with withdrawal, uncomplicated",
              label: "(F19.930) Other psychoactive substance use, unspecified with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.931) Other psychoactive substance use, unspecified with withdrawal delirium",
              label: "(F19.931) Other psychoactive substance use, unspecified with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.932) Other psychoactive substance use, unspecified with withdrawal with perceptual disturbance",
              label: "(F19.932) Other psychoactive substance use, unspecified with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.939) Other psychoactive substance use, unspecified with withdrawal, unspecified",
              label: "(F19.939) Other psychoactive substance use, unspecified with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.94) Other psychoactive substance use, unspecified with psychoactive substance-induced mood disorder",
              label: "(F19.94) Other psychoactive substance use, unspecified with psychoactive substance-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.950) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with delusions",
              label: "(F19.950) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.951) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with hallucinations",
              label: "(F19.951) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.959) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder, unspecified",
              label: "(F19.959) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.96) Other psychoactive substance use, unspecified with psychoactive substance-induced persisting amnestic disorder",
              label: "(F19.96) Other psychoactive substance use, unspecified with psychoactive substance-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.97) Other psychoactive substance use, unspecified with psychoactive substance-induced persisting dementia",
              label: "(F19.97) Other psychoactive substance use, unspecified with psychoactive substance-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.980) Other psychoactive substance use, unspecified with psychoactive substance-induced anxiety disorder",
              label: "(F19.980) Other psychoactive substance use, unspecified with psychoactive substance-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.981) Other psychoactive substance use, unspecified with psychoactive substance-induced sexual dysfunction",
              label: "(F19.981) Other psychoactive substance use, unspecified with psychoactive substance-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.982) Other psychoactive substance use, unspecified with psychoactive substance-induced sleep disorder",
              label: "(F19.982) Other psychoactive substance use, unspecified with psychoactive substance-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.988) Other psychoactive substance use, unspecified with other psychoactive substance-induced disorder",
              label: "(F19.988) Other psychoactive substance use, unspecified with other psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.99) Other psychoactive substance use, unspecified with unspecified psychoactive substance-induced disorder",
              label: "(F19.99) Other psychoactive substance use, unspecified with unspecified psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.0) Paranoid schizophrenia",
              label: "(F20.0) Paranoid schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.1) Disorganized schizophrenia",
              label: "(F20.1) Disorganized schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.2) Catatonic schizophrenia",
              label: "(F20.2) Catatonic schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.3) Undifferentiated schizophrenia",
              label: "(F20.3) Undifferentiated schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.5) Residual schizophrenia",
              label: "(F20.5) Residual schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.81) Schizophreniform disorder",
              label: "(F20.81) Schizophreniform disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.89) Other schizophrenia",
              label: "(F20.89) Other schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.9) Schizophrenia, unspecified",
              label: "(F20.9) Schizophrenia, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F21.) Schizotypal disorder",
              label: "(F21.) Schizotypal disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F22.) Delusional disorders",
              label: "(F22.) Delusional disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F23.) Brief psychotic disorder",
              label: "(F23.) Brief psychotic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F24.) Shared psychotic disorder",
              label: "(F24.) Shared psychotic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F25.0) Schizoaffective disorder, bipolar type",
              label: "(F25.0) Schizoaffective disorder, bipolar type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F25.1) Schizoaffective disorder, depressive type",
              label: "(F25.1) Schizoaffective disorder, depressive type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F25.8) Other schizoaffective disorders",
              label: "(F25.8) Other schizoaffective disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F25.9) Schizoaffective disorder, unspecified",
              label: "(F25.9) Schizoaffective disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F28.) Other psychotic disorder not due to a substance or known physiological condition",
              label: "(F28.) Other psychotic disorder not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F29.) Unspecified psychosis not due to a substance or known physiological condition",
              label: "(F29.) Unspecified psychosis not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.10) Manic episode without psychotic symptoms, unspecified",
              label: "(F30.10) Manic episode without psychotic symptoms, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.11) Manic episode without psychotic symptoms, mild",
              label: "(F30.11) Manic episode without psychotic symptoms, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.12) Manic episode without psychotic symptoms, moderate",
              label: "(F30.12) Manic episode without psychotic symptoms, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.13) Manic episode, severe, without psychotic symptoms",
              label: "(F30.13) Manic episode, severe, without psychotic symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.2) Manic episode, severe with psychotic symptoms",
              label: "(F30.2) Manic episode, severe with psychotic symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.3) Manic episode in partial remission",
              label: "(F30.3) Manic episode in partial remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.4) Manic episode in full remission",
              label: "(F30.4) Manic episode in full remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.8) Other manic episodes",
              label: "(F30.8) Other manic episodes",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.9) Manic episode, unspecified",
              label: "(F30.9) Manic episode, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.0) Bipolar disorder, current episode hypomanic",
              label: "(F31.0) Bipolar disorder, current episode hypomanic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.10) Bipolar disorder, current episode manic without psychotic features, unspecified",
              label: "(F31.10) Bipolar disorder, current episode manic without psychotic features, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.11) Bipolar disorder, current episode manic without psychotic features, mild",
              label: "(F31.11) Bipolar disorder, current episode manic without psychotic features, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.12) Bipolar disorder, current episode manic without psychotic features, moderate",
              label: "(F31.12) Bipolar disorder, current episode manic without psychotic features, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.13) Bipolar disorder, current episode manic without psychotic features, severe",
              label: "(F31.13) Bipolar disorder, current episode manic without psychotic features, severe",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.2) Bipolar disorder, current episode manic severe with psychotic features",
              label: "(F31.2) Bipolar disorder, current episode manic severe with psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.30) Bipolar disorder, current episode depressed, mild or moderate severity, unspecified",
              label: "(F31.30) Bipolar disorder, current episode depressed, mild or moderate severity, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.31) Bipolar disorder, current episode depressed, mild",
              label: "(F31.31) Bipolar disorder, current episode depressed, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.32) Bipolar disorder, current episode depressed, moderate",
              label: "(F31.32) Bipolar disorder, current episode depressed, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.4) Bipolar disorder, current episode depressed, severe, without psychotic features",
              label: "(F31.4) Bipolar disorder, current episode depressed, severe, without psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.5) Bipolar disorder, current episode depressed, severe, with psychotic features",
              label: "(F31.5) Bipolar disorder, current episode depressed, severe, with psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.60) Bipolar disorder, current episode mixed, unspecified",
              label: "(F31.60) Bipolar disorder, current episode mixed, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.61) Bipolar disorder, current episode mixed, mild",
              label: "(F31.61) Bipolar disorder, current episode mixed, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.62) Bipolar disorder, current episode mixed, moderate",
              label: "(F31.62) Bipolar disorder, current episode mixed, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.63) Bipolar disorder, current episode mixed, severe, without psychotic features",
              label: "(F31.63) Bipolar disorder, current episode mixed, severe, without psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.64) Bipolar disorder, current episode mixed, severe, with psychotic features",
              label: "(F31.64) Bipolar disorder, current episode mixed, severe, with psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.70) Bipolar disorder, currently in remission, most recent episode unspecified",
              label: "(F31.70) Bipolar disorder, currently in remission, most recent episode unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.71) Bipolar disorder, in partial remission, most recent episode hypomanic",
              label: "(F31.71) Bipolar disorder, in partial remission, most recent episode hypomanic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.72) Bipolar disorder, in full remission, most recent episode hypomanic",
              label: "(F31.72) Bipolar disorder, in full remission, most recent episode hypomanic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.73) Bipolar disorder, in partial remission, most recent episode manic",
              label: "(F31.73) Bipolar disorder, in partial remission, most recent episode manic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.74) Bipolar disorder, in full remission, most recent episode manic",
              label: "(F31.74) Bipolar disorder, in full remission, most recent episode manic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.75) Bipolar disorder, in partial remission, most recent episode depressed",
              label: "(F31.75) Bipolar disorder, in partial remission, most recent episode depressed",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.76) Bipolar disorder, in full remission, most recent episode depressed",
              label: "(F31.76) Bipolar disorder, in full remission, most recent episode depressed",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.77) Bipolar disorder, in partial remission, most recent episode mixed",
              label: "(F31.77) Bipolar disorder, in partial remission, most recent episode mixed",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.78) Bipolar disorder, in full remission, most recent episode mixed",
              label: "(F31.78) Bipolar disorder, in full remission, most recent episode mixed",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.81) Bipolar II disorder",
              label: "(F31.81) Bipolar II disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.89) Other bipolar disorder",
              label: "(F31.89) Other bipolar disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.9) Bipolar disorder, unspecified",
              label: "(F31.9) Bipolar disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.0) Major depressive disorder, single episode, mild",
              label: "(F32.0) Major depressive disorder, single episode, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.1) Major depressive disorder, single episode, moderate",
              label: "(F32.1) Major depressive disorder, single episode, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.2) Major depressive disorder, single episode, severe without psychotic features",
              label: "(F32.2) Major depressive disorder, single episode, severe without psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.3) Major depressive disorder, single episode, severe with psychotic features",
              label: "(F32.3) Major depressive disorder, single episode, severe with psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.4) Major depressive disorder, single episode, in partial remission",
              label: "(F32.4) Major depressive disorder, single episode, in partial remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.5) Major depressive disorder, single episode, in full remission",
              label: "(F32.5) Major depressive disorder, single episode, in full remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.81) Premenstrual dysphoric disorder",
              label: "(F32.81) Premenstrual dysphoric disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.89) Other specified depressive episodes",
              label: "(F32.89) Other specified depressive episodes",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.9) Major depressive disorder, single episode, unspecified",
              label: "(F32.9) Major depressive disorder, single episode, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.A) Depression, unspecified",
              label: "(F32.A) Depression, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.0) Major depressive disorder, recurrent, mild",
              label: "(F33.0) Major depressive disorder, recurrent, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.1) Major depressive disorder, recurrent, moderate",
              label: "(F33.1) Major depressive disorder, recurrent, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.2) Major depressive disorder, recurrent severe without psychotic features",
              label: "(F33.2) Major depressive disorder, recurrent severe without psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.3) Major depressive disorder, recurrent, severe with psychotic symptoms",
              label: "(F33.3) Major depressive disorder, recurrent, severe with psychotic symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.40) Major depressive disorder, recurrent, in remission, unspecified",
              label: "(F33.40) Major depressive disorder, recurrent, in remission, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.41) Major depressive disorder, recurrent, in partial remission",
              label: "(F33.41) Major depressive disorder, recurrent, in partial remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.42) Major depressive disorder, recurrent, in full remission",
              label: "(F33.42) Major depressive disorder, recurrent, in full remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.8) Other recurrent depressive disorders",
              label: "(F33.8) Other recurrent depressive disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.9) Major depressive disorder, recurrent, unspecified",
              label: "(F33.9) Major depressive disorder, recurrent, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F34.0) Cyclothymic disorder",
              label: "(F34.0) Cyclothymic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F34.1) Dysthymic disorder",
              label: "(F34.1) Dysthymic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F34.81) Disruptive mood dysregulation disorder",
              label: "(F34.81) Disruptive mood dysregulation disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F34.89) Other specified persistent mood disorders",
              label: "(F34.89) Other specified persistent mood disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F34.9) Persistent mood [affective] disorder, unspecified",
              label: "(F34.9) Persistent mood [affective] disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F39.) Unspecified mood [affective] disorder",
              label: "(F39.) Unspecified mood [affective] disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.00) Agoraphobia, unspecified",
              label: "(F40.00) Agoraphobia, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.01) Agoraphobia with panic disorder",
              label: "(F40.01) Agoraphobia with panic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.02) Agoraphobia without panic disorder",
              label: "(F40.02) Agoraphobia without panic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.10) Social phobia, unspecified",
              label: "(F40.10) Social phobia, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.11) Social phobia, generalized",
              label: "(F40.11) Social phobia, generalized",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.210) Arachnophobia",
              label: "(F40.210) Arachnophobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.218) Other animal type phobia",
              label: "(F40.218) Other animal type phobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.220) Fear of thunderstorms",
              label: "(F40.220) Fear of thunderstorms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.228) Other natural environment type phobia",
              label: "(F40.228) Other natural environment type phobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.230) Fear of blood",
              label: "(F40.230) Fear of blood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.231) Fear of injections and transfusions",
              label: "(F40.231) Fear of injections and transfusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.232) Fear of other medical care",
              label: "(F40.232) Fear of other medical care",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.233) Fear of injury",
              label: "(F40.233) Fear of injury",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.240) Claustrophobia",
              label: "(F40.240) Claustrophobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.241) Acrophobia",
              label: "(F40.241) Acrophobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.242) Fear of bridges",
              label: "(F40.242) Fear of bridges",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.243) Fear of flying",
              label: "(F40.243) Fear of flying",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.248) Other situational type phobia",
              label: "(F40.248) Other situational type phobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.290) Androphobia",
              label: "(F40.290) Androphobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.291) Gynephobia",
              label: "(F40.291) Gynephobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.298) Other specified phobia",
              label: "(F40.298) Other specified phobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.8) Other phobic anxiety disorders",
              label: "(F40.8) Other phobic anxiety disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.9) Phobic anxiety disorder, unspecified",
              label: "(F40.9) Phobic anxiety disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F41.0) Panic disorder [episodic paroxysmal anxiety]",
              label: "(F41.0) Panic disorder [episodic paroxysmal anxiety]",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F41.1) Generalized anxiety disorder",
              label: "(F41.1) Generalized anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F41.3) Other mixed anxiety disorders",
              label: "(F41.3) Other mixed anxiety disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F41.8) Other specified anxiety disorders",
              label: "(F41.8) Other specified anxiety disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F41.9) Anxiety disorder, unspecified",
              label: "(F41.9) Anxiety disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F42.2) Mixed obsessional thoughts and acts",
              label: "(F42.2) Mixed obsessional thoughts and acts",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F42.3) Hoarding disorder",
              label: "(F42.3) Hoarding disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F42.4) Excoriation (skin-picking) disorder",
              label: "(F42.4) Excoriation (skin-picking) disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F42.8) Other obsessive-compulsive disorder",
              label: "(F42.8) Other obsessive-compulsive disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F42.9) Obsessive-compulsive disorder, unspecified",
              label: "(F42.9) Obsessive-compulsive disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.0) Acute stress reaction",
              label: "(F43.0) Acute stress reaction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.10) Post-traumatic stress disorder, unspecified",
              label: "(F43.10) Post-traumatic stress disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.11) Post-traumatic stress disorder, acute",
              label: "(F43.11) Post-traumatic stress disorder, acute",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.12) Post-traumatic stress disorder, chronic",
              label: "(F43.12) Post-traumatic stress disorder, chronic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.20) Adjustment disorder, unspecified",
              label: "(F43.20) Adjustment disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.21) Adjustment disorder with depressed mood",
              label: "(F43.21) Adjustment disorder with depressed mood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.22) Adjustment disorder with anxiety",
              label: "(F43.22) Adjustment disorder with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.23) Adjustment disorder with mixed anxiety and depressed mood",
              label: "(F43.23) Adjustment disorder with mixed anxiety and depressed mood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.24) Adjustment disorder with disturbance of conduct",
              label: "(F43.24) Adjustment disorder with disturbance of conduct",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.25) Adjustment disorder with mixed disturbance of emotions and conduct",
              label: "(F43.25) Adjustment disorder with mixed disturbance of emotions and conduct",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.29) Adjustment disorder with other symptoms",
              label: "(F43.29) Adjustment disorder with other symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.81) Prolonged grief disorder",
              label: "(F43.81) Prolonged grief disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.89) Other reactions to severe stress",
              label: "(F43.89) Other reactions to severe stress",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.9) Reaction to severe stress, unspecified",
              label: "(F43.9) Reaction to severe stress, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.0) Dissociative amnesia",
              label: "(F44.0) Dissociative amnesia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.1) Dissociative fugue",
              label: "(F44.1) Dissociative fugue",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.2) Dissociative stupor",
              label: "(F44.2) Dissociative stupor",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.4) Conversion disorder with motor symptom or deficit",
              label: "(F44.4) Conversion disorder with motor symptom or deficit",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.5) Conversion disorder with seizures or convulsions",
              label: "(F44.5) Conversion disorder with seizures or convulsions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.6) Conversion disorder with sensory symptom or deficit",
              label: "(F44.6) Conversion disorder with sensory symptom or deficit",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.7) Conversion disorder with mixed symptom presentation",
              label: "(F44.7) Conversion disorder with mixed symptom presentation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.81) Dissociative identity disorder",
              label: "(F44.81) Dissociative identity disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.89) Other dissociative and conversion disorders",
              label: "(F44.89) Other dissociative and conversion disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.9) Dissociative and conversion disorder, unspecified",
              label: "(F44.9) Dissociative and conversion disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.0) Somatization disorder",
              label: "(F45.0) Somatization disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.1) Undifferentiated somatoform disorder",
              label: "(F45.1) Undifferentiated somatoform disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.20) Hypochondriacal disorder, unspecified",
              label: "(F45.20) Hypochondriacal disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.21) Hypochondriasis",
              label: "(F45.21) Hypochondriasis",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.22) Body dysmorphic disorder",
              label: "(F45.22) Body dysmorphic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.29) Other hypochondriacal disorders",
              label: "(F45.29) Other hypochondriacal disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.41) Pain disorder exclusively related to psychological factors",
              label: "(F45.41) Pain disorder exclusively related to psychological factors",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.42) Pain disorder with related psychological factors",
              label: "(F45.42) Pain disorder with related psychological factors",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.8) Other somatoform disorders",
              label: "(F45.8) Other somatoform disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.9) Somatoform disorder, unspecified",
              label: "(F45.9) Somatoform disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F48.1) Depersonalization-derealization syndrome",
              label: "(F48.1) Depersonalization-derealization syndrome",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F48.2) Pseudobulbar affect",
              label: "(F48.2) Pseudobulbar affect",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F48.8) Other specified nonpsychotic mental disorders",
              label: "(F48.8) Other specified nonpsychotic mental disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F48.9) Nonpsychotic mental disorder, unspecified",
              label: "(F48.9) Nonpsychotic mental disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.00) Anorexia nervosa, unspecified",
              label: "(F50.00) Anorexia nervosa, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.01) Anorexia nervosa, restricting type",
              label: "(F50.01) Anorexia nervosa, restricting type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.02) Anorexia nervosa, binge eating/purging type",
              label: "(F50.02) Anorexia nervosa, binge eating/purging type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.2) Bulimia nervosa",
              label: "(F50.2) Bulimia nervosa",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.81) Binge eating disorder",
              label: "(F50.81) Binge eating disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.82) Avoidant/restrictive food intake disorder",
              label: "(F50.82) Avoidant/restrictive food intake disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.89) Other specified eating disorder",
              label: "(F50.89) Other specified eating disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.9) Eating disorder, unspecified",
              label: "(F50.9) Eating disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.01) Primary insomnia",
              label: "(F51.01) Primary insomnia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.02) Adjustment insomnia",
              label: "(F51.02) Adjustment insomnia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.03) Paradoxical insomnia",
              label: "(F51.03) Paradoxical insomnia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.04) Psychophysiologic insomnia",
              label: "(F51.04) Psychophysiologic insomnia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.05) Insomnia due to other mental disorder",
              label: "(F51.05) Insomnia due to other mental disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.09) Other insomnia not due to a substance or known physiological condition",
              label: "(F51.09) Other insomnia not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.11) Primary hypersomnia",
              label: "(F51.11) Primary hypersomnia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.12) Insufficient sleep syndrome",
              label: "(F51.12) Insufficient sleep syndrome",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.13) Hypersomnia due to other mental disorder",
              label: "(F51.13) Hypersomnia due to other mental disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.19) Other hypersomnia not due to a substance or known physiological condition",
              label: "(F51.19) Other hypersomnia not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.3) Sleepwalking [somnambulism]",
              label: "(F51.3) Sleepwalking [somnambulism]",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.4) Sleep terrors [night terrors]",
              label: "(F51.4) Sleep terrors [night terrors]",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.5) Nightmare disorder",
              label: "(F51.5) Nightmare disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.8) Other sleep disorders not due to a substance or known physiological condition",
              label: "(F51.8) Other sleep disorders not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.9) Sleep disorder not due to a substance or known physiological condition, unspecified",
              label: "(F51.9) Sleep disorder not due to a substance or known physiological condition, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.0) Hypoactive sexual desire disorder",
              label: "(F52.0) Hypoactive sexual desire disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.1) Sexual aversion disorder",
              label: "(F52.1) Sexual aversion disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.21) Male erectile disorder",
              label: "(F52.21) Male erectile disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.22) Female sexual arousal disorder",
              label: "(F52.22) Female sexual arousal disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.31) Female orgasmic disorder",
              label: "(F52.31) Female orgasmic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.32) Male orgasmic disorder",
              label: "(F52.32) Male orgasmic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.4) Premature ejaculation",
              label: "(F52.4) Premature ejaculation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.5) Vaginismus not due to a substance or known physiological condition",
              label: "(F52.5) Vaginismus not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.6) Dyspareunia not due to a substance or known physiological condition",
              label: "(F52.6) Dyspareunia not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.8) Other sexual dysfunction not due to a substance or known physiological condition",
              label: "(F52.8) Other sexual dysfunction not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.9) Unspecified sexual dysfunction not due to a substance or known physiological condition",
              label: "(F52.9) Unspecified sexual dysfunction not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F53.0) Postpartum depression",
              label: "(F53.0) Postpartum depression",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F53.1) Puerperal psychosis",
              label: "(F53.1) Puerperal psychosis",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F54.) Psychological and behavioral factors associated with disorders or diseases classified elsewhere",
              label: "(F54.) Psychological and behavioral factors associated with disorders or diseases classified elsewhere",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.0) Abuse of antacids",
              label: "(F55.0) Abuse of antacids",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.1) Abuse of herbal or folk remedies",
              label: "(F55.1) Abuse of herbal or folk remedies",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.2) Abuse of laxatives",
              label: "(F55.2) Abuse of laxatives",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.3) Abuse of steroids or hormones",
              label: "(F55.3) Abuse of steroids or hormones",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.4) Abuse of vitamins",
              label: "(F55.4) Abuse of vitamins",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.8) Abuse of other non-psychoactive substances",
              label: "(F55.8) Abuse of other non-psychoactive substances",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F59.) Unspecified behavioral syndromes associated with physiological disturbances and physical factors",
              label: "(F59.) Unspecified behavioral syndromes associated with physiological disturbances and physical factors",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.0) Paranoid personality disorder",
              label: "(F60.0) Paranoid personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.1) Schizoid personality disorder",
              label: "(F60.1) Schizoid personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.2) Antisocial personality disorder",
              label: "(F60.2) Antisocial personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.3) Borderline personality disorder",
              label: "(F60.3) Borderline personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.4) Histrionic personality disorder",
              label: "(F60.4) Histrionic personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.5) Obsessive-compulsive personality disorder",
              label: "(F60.5) Obsessive-compulsive personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.6) Avoidant personality disorder",
              label: "(F60.6) Avoidant personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.7) Dependent personality disorder",
              label: "(F60.7) Dependent personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.81) Narcissistic personality disorder",
              label: "(F60.81) Narcissistic personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.89) Other specific personality disorders",
              label: "(F60.89) Other specific personality disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.9) Personality disorder, unspecified",
              label: "(F60.9) Personality disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.0) Pathological gambling",
              label: "(F63.0) Pathological gambling",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.1) Pyromania",
              label: "(F63.1) Pyromania",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.2) Kleptomania",
              label: "(F63.2) Kleptomania",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.3) Trichotillomania",
              label: "(F63.3) Trichotillomania",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.81) Intermittent explosive disorder",
              label: "(F63.81) Intermittent explosive disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.89) Other impulse disorders",
              label: "(F63.89) Other impulse disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.9) Impulse disorder, unspecified",
              label: "(F63.9) Impulse disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F64.0) Transsexualism",
              label: "(F64.0) Transsexualism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F64.1) Dual role transvestism",
              label: "(F64.1) Dual role transvestism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F64.2) Gender identity disorder of childhood",
              label: "(F64.2) Gender identity disorder of childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F64.8) Other gender identity disorders",
              label: "(F64.8) Other gender identity disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F64.9) Gender identity disorder, unspecified",
              label: "(F64.9) Gender identity disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.0) Fetishism",
              label: "(F65.0) Fetishism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.1) Transvestic fetishism",
              label: "(F65.1) Transvestic fetishism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.2) Exhibitionism",
              label: "(F65.2) Exhibitionism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.3) Voyeurism",
              label: "(F65.3) Voyeurism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.4) Pedophilia",
              label: "(F65.4) Pedophilia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.50) Sadomasochism, unspecified",
              label: "(F65.50) Sadomasochism, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.51) Sexual masochism",
              label: "(F65.51) Sexual masochism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.52) Sexual sadism",
              label: "(F65.52) Sexual sadism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.81) Frotteurism",
              label: "(F65.81) Frotteurism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.89) Other paraphilias",
              label: "(F65.89) Other paraphilias",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.9) Paraphilia, unspecified",
              label: "(F65.9) Paraphilia, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F66.) Other sexual disorders",
              label: "(F66.) Other sexual disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.10) Factitious disorder imposed on self, unspecified",
              label: "(F68.10) Factitious disorder imposed on self, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.11) Factitious disorder imposed on self, with predominantly psychological signs and symptoms",
              label: "(F68.11) Factitious disorder imposed on self, with predominantly psychological signs and symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.12) Factitious disorder imposed on self, with predominantly physical signs and symptoms",
              label: "(F68.12) Factitious disorder imposed on self, with predominantly physical signs and symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.13) Factitious disorder imposed on self, with combined psychological and physical signs and symptoms",
              label: "(F68.13) Factitious disorder imposed on self, with combined psychological and physical signs and symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.A) Factitious disorder imposed on another",
              label: "(F68.A) Factitious disorder imposed on another",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.8) Other specified disorders of adult personality and behavior",
              label: "(F68.8) Other specified disorders of adult personality and behavior",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F69.) Unspecified disorder of adult personality and behavior",
              label: "(F69.) Unspecified disorder of adult personality and behavior",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F70.) Mild intellectual disabilities",
              label: "(F70.) Mild intellectual disabilities",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F71.) Moderate intellectual disabilities",
              label: "(F71.) Moderate intellectual disabilities",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F72.) Severe intellectual disabilities",
              label: "(F72.) Severe intellectual disabilities",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F73.) Profound intellectual disabilities",
              label: "(F73.) Profound intellectual disabilities",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F78.A1) SYNGAP1-related intellectual disability",
              label: "(F78.A1) SYNGAP1-related intellectual disability",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F78.A9) Other genetic related intellectual disability",
              label: "(F78.A9) Other genetic related intellectual disability",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F79.) Unspecified intellectual disabilities",
              label: "(F79.) Unspecified intellectual disabilities",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.0) Phonological disorder",
              label: "(F80.0) Phonological disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.1) Expressive language disorder",
              label: "(F80.1) Expressive language disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.2) Mixed receptive-expressive language disorder",
              label: "(F80.2) Mixed receptive-expressive language disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.4) Speech and language development delay due to hearing loss",
              label: "(F80.4) Speech and language development delay due to hearing loss",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.81) Childhood onset fluency disorder",
              label: "(F80.81) Childhood onset fluency disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.82) Social pragmatic communication disorder",
              label: "(F80.82) Social pragmatic communication disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.89) Other developmental disorders of speech and language",
              label: "(F80.89) Other developmental disorders of speech and language",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.9) Developmental disorder of speech and language, unspecified",
              label: "(F80.9) Developmental disorder of speech and language, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F81.0) Specific reading disorder",
              label: "(F81.0) Specific reading disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F81.2) Mathematics disorder",
              label: "(F81.2) Mathematics disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F81.81) Disorder of written expression",
              label: "(F81.81) Disorder of written expression",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F81.89) Other developmental disorders of scholastic skills",
              label: "(F81.89) Other developmental disorders of scholastic skills",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F81.9) Developmental disorder of scholastic skills, unspecified",
              label: "(F81.9) Developmental disorder of scholastic skills, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F82.) Specific developmental disorder of motor function",
              label: "(F82.) Specific developmental disorder of motor function",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.0) Autistic disorder",
              label: "(F84.0) Autistic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.2) Rett's syndrome",
              label: "(F84.2) Rett's syndrome",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.3) Other childhood disintegrative disorder",
              label: "(F84.3) Other childhood disintegrative disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.5) Asperger's syndrome",
              label: "(F84.5) Asperger's syndrome",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.8) Other pervasive developmental disorders",
              label: "(F84.8) Other pervasive developmental disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.9) Pervasive developmental disorder, unspecified",
              label: "(F84.9) Pervasive developmental disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F88.) Other disorders of psychological development",
              label: "(F88.) Other disorders of psychological development",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F89.) Unspecified disorder of psychological development",
              label: "(F89.) Unspecified disorder of psychological development",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F90.0) Attention-deficit hyperactivity disorder, predominantly inattentive type",
              label: "(F90.0) Attention-deficit hyperactivity disorder, predominantly inattentive type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F90.1) Attention-deficit hyperactivity disorder, predominantly hyperactive type",
              label: "(F90.1) Attention-deficit hyperactivity disorder, predominantly hyperactive type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F90.2) Attention-deficit hyperactivity disorder, combined type",
              label: "(F90.2) Attention-deficit hyperactivity disorder, combined type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F90.8) Attention-deficit hyperactivity disorder, other type",
              label: "(F90.8) Attention-deficit hyperactivity disorder, other type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F90.9) Attention-deficit hyperactivity disorder, unspecified type",
              label: "(F90.9) Attention-deficit hyperactivity disorder, unspecified type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.0) Conduct disorder confined to family context",
              label: "(F91.0) Conduct disorder confined to family context",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.1) Conduct disorder, childhood-onset type",
              label: "(F91.1) Conduct disorder, childhood-onset type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.2) Conduct disorder, adolescent-onset type",
              label: "(F91.2) Conduct disorder, adolescent-onset type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.3) Oppositional defiant disorder",
              label: "(F91.3) Oppositional defiant disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.8) Other conduct disorders",
              label: "(F91.8) Other conduct disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.9) Conduct disorder, unspecified",
              label: "(F91.9) Conduct disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F93.0) Separation anxiety disorder of childhood",
              label: "(F93.0) Separation anxiety disorder of childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F93.8) Other childhood emotional disorders",
              label: "(F93.8) Other childhood emotional disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F93.9) Childhood emotional disorder, unspecified",
              label: "(F93.9) Childhood emotional disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F94.0) Selective mutism",
              label: "(F94.0) Selective mutism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F94.1) Reactive attachment disorder of childhood",
              label: "(F94.1) Reactive attachment disorder of childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F94.2) Disinhibited attachment disorder of childhood",
              label: "(F94.2) Disinhibited attachment disorder of childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F94.8) Other childhood disorders of social functioning",
              label: "(F94.8) Other childhood disorders of social functioning",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F94.9) Childhood disorder of social functioning, unspecified",
              label: "(F94.9) Childhood disorder of social functioning, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F95.0) Transient tic disorder",
              label: "(F95.0) Transient tic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F95.1) Chronic motor or vocal tic disorder",
              label: "(F95.1) Chronic motor or vocal tic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F95.2) Tourette's disorder",
              label: "(F95.2) Tourette's disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F95.8) Other tic disorders",
              label: "(F95.8) Other tic disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F95.9) Tic disorder, unspecified",
              label: "(F95.9) Tic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.0) Enuresis not due to a substance or known physiological condition",
              label: "(F98.0) Enuresis not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.1) Encopresis not due to a substance or known physiological condition",
              label: "(F98.1) Encopresis not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.21) Rumination disorder of infancy",
              label: "(F98.21) Rumination disorder of infancy",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.29) Other feeding disorders of infancy and early childhood",
              label: "(F98.29) Other feeding disorders of infancy and early childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.3) Pica of infancy and childhood",
              label: "(F98.3) Pica of infancy and childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.4) Stereotyped movement disorders",
              label: "(F98.4) Stereotyped movement disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.5) Adult onset fluency disorder",
              label: "(F98.5) Adult onset fluency disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.8) Other specified behavioral and emotional disorders with onset usually occurring in childhood and adolescence",
              label: "(F98.8) Other specified behavioral and emotional disorders with onset usually occurring in childhood and adolescence",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.9) Unspecified behavioral and emotional disorders with onset usually occurring in childhood and adolescence",
              label: "(F98.9) Unspecified behavioral and emotional disorders with onset usually occurring in childhood and adolescence",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F99.) Mental disorder, not otherwise specified",
              label: "(F99.) Mental disorder, not otherwise specified",
              Icon: MenuAlt2Icon,
            },
            { value: "() ", label: "() ", Icon: MenuAlt2Icon },
            {
              value: "(Z00.00) Encounter for general adult medical examination without abnormal findings",
              label: "(Z00.00) Encounter for general adult medical examination without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.01) Encounter for general adult medical examination with abnormal findings",
              label: "(Z00.01) Encounter for general adult medical examination with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.110) Health examination for newborn under 8 days old",
              label: "(Z00.110) Health examination for newborn under 8 days old",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.111) Health examination for newborn 8 to 28 days old",
              label: "(Z00.111) Health examination for newborn 8 to 28 days old",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.121) Encounter for routine child health examination with abnormal findings",
              label: "(Z00.121) Encounter for routine child health examination with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.129) Encounter for routine child health examination without abnormal findings",
              label: "(Z00.129) Encounter for routine child health examination without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.2) Encounter for examination for period of rapid growth in childhood",
              label: "(Z00.2) Encounter for examination for period of rapid growth in childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.3) Encounter for examination for adolescent development state",
              label: "(Z00.3) Encounter for examination for adolescent development state",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.5) Encounter for examination of potential donor of organ and tissue",
              label: "(Z00.5) Encounter for examination of potential donor of organ and tissue",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.6) Encounter for examination for normal comparison and control in clinical research program",
              label: "(Z00.6) Encounter for examination for normal comparison and control in clinical research program",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.70) Encounter for examination for period of delayed growth in childhood without abnormal findings",
              label: "(Z00.70) Encounter for examination for period of delayed growth in childhood without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.71) Encounter for examination for period of delayed growth in childhood with abnormal findings",
              label: "(Z00.71) Encounter for examination for period of delayed growth in childhood with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.8) Encounter for other general examination",
              label: "(Z00.8) Encounter for other general examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.00) Encounter for examination of eyes and vision without abnormal findings",
              label: "(Z01.00) Encounter for examination of eyes and vision without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.01) Encounter for examination of eyes and vision with abnormal findings",
              label: "(Z01.01) Encounter for examination of eyes and vision with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.020) Encounter for examination of eyes and vision following failed vision screening without abnormal findings",
              label: "(Z01.020) Encounter for examination of eyes and vision following failed vision screening without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.021) Encounter for examination of eyes and vision following failed vision screening with abnormal findings",
              label: "(Z01.021) Encounter for examination of eyes and vision following failed vision screening with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.10) Encounter for examination of ears and hearing without abnormal findings",
              label: "(Z01.10) Encounter for examination of ears and hearing without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.110) Encounter for hearing examination following failed hearing screening",
              label: "(Z01.110) Encounter for hearing examination following failed hearing screening",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.118) Encounter for examination of ears and hearing with other abnormal findings",
              label: "(Z01.118) Encounter for examination of ears and hearing with other abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.12) Encounter for hearing conservation and treatment",
              label: "(Z01.12) Encounter for hearing conservation and treatment",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.20) Encounter for dental examination and cleaning without abnormal findings",
              label: "(Z01.20) Encounter for dental examination and cleaning without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.21) Encounter for dental examination and cleaning with abnormal findings",
              label: "(Z01.21) Encounter for dental examination and cleaning with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.30) Encounter for examination of blood pressure without abnormal findings",
              label: "(Z01.30) Encounter for examination of blood pressure without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.31) Encounter for examination of blood pressure with abnormal findings",
              label: "(Z01.31) Encounter for examination of blood pressure with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.411) Encounter for gynecological examination (general) (routine) with abnormal findings",
              label: "(Z01.411) Encounter for gynecological examination (general) (routine) with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.419) Encounter for gynecological examination (general) (routine) without abnormal findings",
              label: "(Z01.419) Encounter for gynecological examination (general) (routine) without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.42) Encounter for cervical smear to confirm findings of recent normal smear following initial abnormal smear",
              label: "(Z01.42) Encounter for cervical smear to confirm findings of recent normal smear following initial abnormal smear",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.810) Encounter for preprocedural cardiovascular examination",
              label: "(Z01.810) Encounter for preprocedural cardiovascular examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.811) Encounter for preprocedural respiratory examination",
              label: "(Z01.811) Encounter for preprocedural respiratory examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.812) Encounter for preprocedural laboratory examination",
              label: "(Z01.812) Encounter for preprocedural laboratory examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.818) Encounter for other preprocedural examination",
              label: "(Z01.818) Encounter for other preprocedural examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.82) Encounter for allergy testing",
              label: "(Z01.82) Encounter for allergy testing",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.83) Encounter for blood typing",
              label: "(Z01.83) Encounter for blood typing",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.84) Encounter for antibody response examination",
              label: "(Z01.84) Encounter for antibody response examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.89) Encounter for other specified special examinations",
              label: "(Z01.89) Encounter for other specified special examinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.0) Encounter for examination for admission to educational institution",
              label: "(Z02.0) Encounter for examination for admission to educational institution",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.1) Encounter for pre-employment examination",
              label: "(Z02.1) Encounter for pre-employment examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.2) Encounter for examination for admission to residential institution",
              label: "(Z02.2) Encounter for examination for admission to residential institution",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.3) Encounter for examination for recruitment to armed forces",
              label: "(Z02.3) Encounter for examination for recruitment to armed forces",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.4) Encounter for examination for driving license",
              label: "(Z02.4) Encounter for examination for driving license",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.5) Encounter for examination for participation in sport",
              label: "(Z02.5) Encounter for examination for participation in sport",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.6) Encounter for examination for insurance purposes",
              label: "(Z02.6) Encounter for examination for insurance purposes",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.71) Encounter for disability determination",
              label: "(Z02.71) Encounter for disability determination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.79) Encounter for issue of other medical certificate",
              label: "(Z02.79) Encounter for issue of other medical certificate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.81) Encounter for paternity testing",
              label: "(Z02.81) Encounter for paternity testing",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.82) Encounter for adoption services",
              label: "(Z02.82) Encounter for adoption services",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.83) Encounter for blood-alcohol and blood-drug test",
              label: "(Z02.83) Encounter for blood-alcohol and blood-drug test",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.89) Encounter for other administrative examinations",
              label: "(Z02.89) Encounter for other administrative examinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.9) Encounter for administrative examinations, unspecified",
              label: "(Z02.9) Encounter for administrative examinations, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.6) Encounter for observation for suspected toxic effect from ingested substance ruled out",
              label: "(Z03.6) Encounter for observation for suspected toxic effect from ingested substance ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.71) Encounter for suspected problem with amniotic cavity and membrane ruled out",
              label: "(Z03.71) Encounter for suspected problem with amniotic cavity and membrane ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.72) Encounter for suspected placental problem ruled out",
              label: "(Z03.72) Encounter for suspected placental problem ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.73) Encounter for suspected fetal anomaly ruled out",
              label: "(Z03.73) Encounter for suspected fetal anomaly ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.74) Encounter for suspected problem with fetal growth ruled out",
              label: "(Z03.74) Encounter for suspected problem with fetal growth ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.75) Encounter for suspected cervical shortening ruled out",
              label: "(Z03.75) Encounter for suspected cervical shortening ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.79) Encounter for other suspected maternal and fetal conditions ruled out",
              label: "(Z03.79) Encounter for other suspected maternal and fetal conditions ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.810) Encounter for observation for suspected exposure to anthrax ruled out",
              label: "(Z03.810) Encounter for observation for suspected exposure to anthrax ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.818) Encounter for observation for suspected exposure to other biological agents ruled out",
              label: "(Z03.818) Encounter for observation for suspected exposure to other biological agents ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.821) Encounter for observation for suspected ingested foreign body ruled out",
              label: "(Z03.821) Encounter for observation for suspected ingested foreign body ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.822) Encounter for observation for suspected aspirated (inhaled) foreign body ruled out",
              label: "(Z03.822) Encounter for observation for suspected aspirated (inhaled) foreign body ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.823) Encounter for observation for suspected inserted (injected) foreign body ruled out",
              label: "(Z03.823) Encounter for observation for suspected inserted (injected) foreign body ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.83) Encounter for observation for suspected conditions related to home physiologic monitoring device ruled out",
              label: "(Z03.83) Encounter for observation for suspected conditions related to home physiologic monitoring device ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.89) Encounter for observation for other suspected diseases and conditions ruled out",
              label: "(Z03.89) Encounter for observation for other suspected diseases and conditions ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.1) Encounter for examination and observation following transport accident",
              label: "(Z04.1) Encounter for examination and observation following transport accident",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.2) Encounter for examination and observation following work accident",
              label: "(Z04.2) Encounter for examination and observation following work accident",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.3) Encounter for examination and observation following other accident",
              label: "(Z04.3) Encounter for examination and observation following other accident",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.41) Encounter for examination and observation following alleged adult rape",
              label: "(Z04.41) Encounter for examination and observation following alleged adult rape",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.42) Encounter for examination and observation following alleged child rape",
              label: "(Z04.42) Encounter for examination and observation following alleged child rape",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.6) Encounter for general psychiatric examination, requested by authority",
              label: "(Z04.6) Encounter for general psychiatric examination, requested by authority",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.71) Encounter for examination and observation following alleged adult physical abuse",
              label: "(Z04.71) Encounter for examination and observation following alleged adult physical abuse",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.72) Encounter for examination and observation following alleged child physical abuse",
              label: "(Z04.72) Encounter for examination and observation following alleged child physical abuse",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.81) Encounter for examination and observation of victim following forced sexual exploitation",
              label: "(Z04.81) Encounter for examination and observation of victim following forced sexual exploitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.82) Encounter for examination and observation of victim following forced labor exploitation",
              label: "(Z04.82) Encounter for examination and observation of victim following forced labor exploitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.89) Encounter for examination and observation for other specified reasons",
              label: "(Z04.89) Encounter for examination and observation for other specified reasons",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.9) Encounter for examination and observation for unspecified reason",
              label: "(Z04.9) Encounter for examination and observation for unspecified reason",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.0) Observation and evaluation of newborn for suspected cardiac condition ruled out",
              label: "(Z05.0) Observation and evaluation of newborn for suspected cardiac condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.1) Observation and evaluation of newborn for suspected infectious condition ruled out",
              label: "(Z05.1) Observation and evaluation of newborn for suspected infectious condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.2) Observation and evaluation of newborn for suspected neurological condition ruled out",
              label: "(Z05.2) Observation and evaluation of newborn for suspected neurological condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.3) Observation and evaluation of newborn for suspected respiratory condition ruled out",
              label: "(Z05.3) Observation and evaluation of newborn for suspected respiratory condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.41) Observation and evaluation of newborn for suspected genetic condition ruled out",
              label: "(Z05.41) Observation and evaluation of newborn for suspected genetic condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.42) Observation and evaluation of newborn for suspected metabolic condition ruled out",
              label: "(Z05.42) Observation and evaluation of newborn for suspected metabolic condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.43) Observation and evaluation of newborn for suspected immunologic condition ruled out",
              label: "(Z05.43) Observation and evaluation of newborn for suspected immunologic condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.5) Observation and evaluation of newborn for suspected gastrointestinal condition ruled out",
              label: "(Z05.5) Observation and evaluation of newborn for suspected gastrointestinal condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.6) Observation and evaluation of newborn for suspected genitourinary condition ruled out",
              label: "(Z05.6) Observation and evaluation of newborn for suspected genitourinary condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.71) Observation and evaluation of newborn for suspected skin and subcutaneous tissue condition ruled out",
              label: "(Z05.71) Observation and evaluation of newborn for suspected skin and subcutaneous tissue condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.72) Observation and evaluation of newborn for suspected musculoskeletal condition ruled out",
              label: "(Z05.72) Observation and evaluation of newborn for suspected musculoskeletal condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.73) Observation and evaluation of newborn for suspected connective tissue condition ruled out",
              label: "(Z05.73) Observation and evaluation of newborn for suspected connective tissue condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.8) Observation and evaluation of newborn for other specified suspected condition ruled out",
              label: "(Z05.8) Observation and evaluation of newborn for other specified suspected condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.9) Observation and evaluation of newborn for unspecified suspected condition ruled out",
              label: "(Z05.9) Observation and evaluation of newborn for unspecified suspected condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z08.) Encounter for follow-up examination after completed treatment for malignant neoplasm",
              label: "(Z08.) Encounter for follow-up examination after completed treatment for malignant neoplasm",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z09.) Encounter for follow-up examination after completed treatment for conditions other than malignant neoplasm",
              label: "(Z09.) Encounter for follow-up examination after completed treatment for conditions other than malignant neoplasm",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.0) Encounter for screening for intestinal infectious diseases",
              label: "(Z11.0) Encounter for screening for intestinal infectious diseases",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.1) Encounter for screening for respiratory tuberculosis",
              label: "(Z11.1) Encounter for screening for respiratory tuberculosis",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.2) Encounter for screening for other bacterial diseases",
              label: "(Z11.2) Encounter for screening for other bacterial diseases",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.3) Encounter for screening for infections with a predominantly sexual mode of transmission",
              label: "(Z11.3) Encounter for screening for infections with a predominantly sexual mode of transmission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.4) Encounter for screening for human immunodeficiency virus [HIV]",
              label: "(Z11.4) Encounter for screening for human immunodeficiency virus [HIV]",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.51) Encounter for screening for human papillomavirus (HPV)",
              label: "(Z11.51) Encounter for screening for human papillomavirus (HPV)",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.52) Encounter for screening for COVID-19",
              label: "(Z11.52) Encounter for screening for COVID-19",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.59) Encounter for screening for other viral diseases",
              label: "(Z11.59) Encounter for screening for other viral diseases",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.6) Encounter for screening for other protozoal diseases and helminthiases",
              label: "(Z11.6) Encounter for screening for other protozoal diseases and helminthiases",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.7) Encounter for testing for latent tuberculosis infection",
              label: "(Z11.7) Encounter for testing for latent tuberculosis infection",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.8) Encounter for screening for other infectious and parasitic diseases",
              label: "(Z11.8) Encounter for screening for other infectious and parasitic diseases",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.9) Encounter for screening for infectious and parasitic diseases, unspecified",
              label: "(Z11.9) Encounter for screening for infectious and parasitic diseases, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.0) Encounter for screening for malignant neoplasm of stomach",
              label: "(Z12.0) Encounter for screening for malignant neoplasm of stomach",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.10) Encounter for screening for malignant neoplasm of intestinal tract, unspecified",
              label: "(Z12.10) Encounter for screening for malignant neoplasm of intestinal tract, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.11) Encounter for screening for malignant neoplasm of colon",
              label: "(Z12.11) Encounter for screening for malignant neoplasm of colon",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.12) Encounter for screening for malignant neoplasm of rectum",
              label: "(Z12.12) Encounter for screening for malignant neoplasm of rectum",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.13) Encounter for screening for malignant neoplasm of small intestine",
              label: "(Z12.13) Encounter for screening for malignant neoplasm of small intestine",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.2) Encounter for screening for malignant neoplasm of respiratory organs",
              label: "(Z12.2) Encounter for screening for malignant neoplasm of respiratory organs",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.31) Encounter for screening mammogram for malignant neoplasm of breast",
              label: "(Z12.31) Encounter for screening mammogram for malignant neoplasm of breast",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.39) Encounter for other screening for malignant neoplasm of breast",
              label: "(Z12.39) Encounter for other screening for malignant neoplasm of breast",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.4) Encounter for screening for malignant neoplasm of cervix",
              label: "(Z12.4) Encounter for screening for malignant neoplasm of cervix",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.5) Encounter for screening for malignant neoplasm of prostate",
              label: "(Z12.5) Encounter for screening for malignant neoplasm of prostate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.6) Encounter for screening for malignant neoplasm of bladder",
              label: "(Z12.6) Encounter for screening for malignant neoplasm of bladder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.71) Encounter for screening for malignant neoplasm of testis",
              label: "(Z12.71) Encounter for screening for malignant neoplasm of testis",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.72) Encounter for screening for malignant neoplasm of vagina",
              label: "(Z12.72) Encounter for screening for malignant neoplasm of vagina",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.73) Encounter for screening for malignant neoplasm of ovary",
              label: "(Z12.73) Encounter for screening for malignant neoplasm of ovary",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.79) Encounter for screening for malignant neoplasm of other genitourinary organs",
              label: "(Z12.79) Encounter for screening for malignant neoplasm of other genitourinary organs",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.81) Encounter for screening for malignant neoplasm of oral cavity",
              label: "(Z12.81) Encounter for screening for malignant neoplasm of oral cavity",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.82) Encounter for screening for malignant neoplasm of nervous system",
              label: "(Z12.82) Encounter for screening for malignant neoplasm of nervous system",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.83) Encounter for screening for malignant neoplasm of skin",
              label: "(Z12.83) Encounter for screening for malignant neoplasm of skin",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.89) Encounter for screening for malignant neoplasm of other sites",
              label: "(Z12.89) Encounter for screening for malignant neoplasm of other sites",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.9) Encounter for screening for malignant neoplasm, site unspecified",
              label: "(Z12.9) Encounter for screening for malignant neoplasm, site unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.0) Encounter for screening for diseases of the blood and blood-forming organs and certain disorders involving immune mechanism",
              label: "(Z13.0) Encounter for screening for diseases of the blood and blood-forming organs and certain disorders involving immune mechanism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.1) Encounter for screening for diabetes mellitus",
              label: "(Z13.1) Encounter for screening for diabetes mellitus",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.21) Encounter for screening for nutritional disorder",
              label: "(Z13.21) Encounter for screening for nutritional disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.220) Encounter for screening for lipoid disorders",
              label: "(Z13.220) Encounter for screening for lipoid disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.228) Encounter for screening for other metabolic disorders",
              label: "(Z13.228) Encounter for screening for other metabolic disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.29) Encounter for screening for other suspected endocrine disorder",
              label: "(Z13.29) Encounter for screening for other suspected endocrine disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.30) Encounter for screening examination for mental health and behavioral disorders, unspecified",
              label: "(Z13.30) Encounter for screening examination for mental health and behavioral disorders, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.31) Encounter for screening for depression",
              label: "(Z13.31) Encounter for screening for depression",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.32) Encounter for screening for maternal depression",
              label: "(Z13.32) Encounter for screening for maternal depression",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.39) Encounter for screening examination for other mental health and behavioral disorders",
              label: "(Z13.39) Encounter for screening examination for other mental health and behavioral disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.40) Encounter for screening for unspecified developmental delays",
              label: "(Z13.40) Encounter for screening for unspecified developmental delays",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.41) Encounter for autism screening",
              label: "(Z13.41) Encounter for autism screening",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.42) Encounter for screening for global developmental delays (milestones)",
              label: "(Z13.42) Encounter for screening for global developmental delays (milestones)",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.49) Encounter for screening for other developmental delays",
              label: "(Z13.49) Encounter for screening for other developmental delays",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.5) Encounter for screening for eye and ear disorders",
              label: "(Z13.5) Encounter for screening for eye and ear disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.6) Encounter for screening for cardiovascular disorders",
              label: "(Z13.6) Encounter for screening for cardiovascular disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.71) Encounter for nonprocreative screening for genetic disease carrier status",
              label: "(Z13.71) Encounter for nonprocreative screening for genetic disease carrier status",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.79) Encounter for other screening for genetic and chromosomal anomalies",
              label: "(Z13.79) Encounter for other screening for genetic and chromosomal anomalies",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.810) Encounter for screening for upper gastrointestinal disorder",
              label: "(Z13.810) Encounter for screening for upper gastrointestinal disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.811) Encounter for screening for lower gastrointestinal disorder",
              label: "(Z13.811) Encounter for screening for lower gastrointestinal disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.818) Encounter for screening for other digestive system disorders",
              label: "(Z13.818) Encounter for screening for other digestive system disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.820) Encounter for screening for osteoporosis",
              label: "(Z13.820) Encounter for screening for osteoporosis",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.828) Encounter for screening for other musculoskeletal disorder",
              label: "(Z13.828) Encounter for screening for other musculoskeletal disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.83) Encounter for screening for respiratory disorder NEC",
              label: "(Z13.83) Encounter for screening for respiratory disorder NEC",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.84) Encounter for screening for dental disorders",
              label: "(Z13.84) Encounter for screening for dental disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.850) Encounter for screening for traumatic brain injury",
              label: "(Z13.850) Encounter for screening for traumatic brain injury",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.858) Encounter for screening for other nervous system disorders",
              label: "(Z13.858) Encounter for screening for other nervous system disorders",
              Icon: MenuAlt2Icon,
            },
          ],
          // maxLength: 50,
          // max: 100,
          // min: 5,
          required: true,
          error: "",
          example: "SOAP",
        },
        {
          title: "Tertiary Diagnosis",
          attr: "tertiarydiagnosis",
          // value: "",
          placeholder: "",
          label: "Tertiary diagnosis",
          type: "dropdown",
          options: [
            { value: "", label: "", Icon: MenuAlt2Icon },
            {
              value: "(F01.50) Vascular dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F01.50) Vascular dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.511) Vascular dementia, unspecified severity, with agitation",
              label: "(F01.511) Vascular dementia, unspecified severity, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.518) Vascular dementia, unspecified severity, with other behavioral disturbance",
              label: "(F01.518) Vascular dementia, unspecified severity, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.52) Vascular dementia, unspecified severity, with psychotic disturbance",
              label: "(F01.52) Vascular dementia, unspecified severity, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.53) Vascular dementia, unspecified severity, with mood disturbance",
              label: "(F01.53) Vascular dementia, unspecified severity, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.54) Vascular dementia, unspecified severity, with anxiety",
              label: "(F01.54) Vascular dementia, unspecified severity, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A0) Vascular dementia, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F01.A0) Vascular dementia, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A11) Vascular dementia, mild, with agitation",
              label: "(F01.A11) Vascular dementia, mild, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A18) Vascular dementia, mild, with other behavioral disturbance",
              label: "(F01.A18) Vascular dementia, mild, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A2) Vascular dementia, mild, with psychotic disturbance",
              label: "(F01.A2) Vascular dementia, mild, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A3) Vascular dementia, mild, with mood disturbance",
              label: "(F01.A3) Vascular dementia, mild, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.A4) Vascular dementia, mild, with anxiety",
              label: "(F01.A4) Vascular dementia, mild, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B0) Vascular dementia, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F01.B0) Vascular dementia, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B11) Vascular dementia, moderate, with agitation",
              label: "(F01.B11) Vascular dementia, moderate, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B18) Vascular dementia, moderate, with other behavioral disturbance",
              label: "(F01.B18) Vascular dementia, moderate, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B2) Vascular dementia, moderate, with psychotic disturbance",
              label: "(F01.B2) Vascular dementia, moderate, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B3) Vascular dementia, moderate, with mood disturbance",
              label: "(F01.B3) Vascular dementia, moderate, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.B4) Vascular dementia, moderate, with anxiety",
              label: "(F01.B4) Vascular dementia, moderate, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C0) Vascular dementia, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F01.C0) Vascular dementia, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C11) Vascular dementia, severe, with agitation",
              label: "(F01.C11) Vascular dementia, severe, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C18) Vascular dementia, severe, with other behavioral disturbance",
              label: "(F01.C18) Vascular dementia, severe, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C2) Vascular dementia, severe, with psychotic disturbance",
              label: "(F01.C2) Vascular dementia, severe, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C3) Vascular dementia, severe, with mood disturbance",
              label: "(F01.C3) Vascular dementia, severe, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F01.C4) Vascular dementia, severe, with anxiety",
              label: "(F01.C4) Vascular dementia, severe, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.80) Dementia in other diseases classified elsewhere, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F02.80) Dementia in other diseases classified elsewhere, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.811) Dementia in other diseases classified elsewhere, unspecified severity, with agitation",
              label: "(F02.811) Dementia in other diseases classified elsewhere, unspecified severity, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.818) Dementia in other diseases classified elsewhere, unspecified severity, with other behavioral disturbance",
              label: "(F02.818) Dementia in other diseases classified elsewhere, unspecified severity, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.82) Dementia in other diseases classified elsewhere, unspecified severity, with psychotic disturbance",
              label: "(F02.82) Dementia in other diseases classified elsewhere, unspecified severity, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.83) Dementia in other diseases classified elsewhere, unspecified severity, with mood disturbance",
              label: "(F02.83) Dementia in other diseases classified elsewhere, unspecified severity, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.84) Dementia in other diseases classified elsewhere, unspecified severity, with anxiety",
              label: "(F02.84) Dementia in other diseases classified elsewhere, unspecified severity, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A0) Dementia in other diseases classified elsewhere, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F02.A0) Dementia in other diseases classified elsewhere, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A11) Dementia in other diseases classified elsewhere, mild, with agitation",
              label: "(F02.A11) Dementia in other diseases classified elsewhere, mild, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A18) Dementia in other diseases classified elsewhere, mild, with other behavioral disturbance",
              label: "(F02.A18) Dementia in other diseases classified elsewhere, mild, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A2) Dementia in other diseases classified elsewhere, mild, with psychotic disturbance",
              label: "(F02.A2) Dementia in other diseases classified elsewhere, mild, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A3) Dementia in other diseases classified elsewhere, mild, with mood disturbance",
              label: "(F02.A3) Dementia in other diseases classified elsewhere, mild, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.A4) Dementia in other diseases classified elsewhere, mild, with anxiety",
              label: "(F02.A4) Dementia in other diseases classified elsewhere, mild, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B0) Dementia in other diseases classified elsewhere, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F02.B0) Dementia in other diseases classified elsewhere, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B11) Dementia in other diseases classified elsewhere, moderate, with agitation",
              label: "(F02.B11) Dementia in other diseases classified elsewhere, moderate, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B18) Dementia in other diseases classified elsewhere, moderate, with other behavioral disturbance",
              label: "(F02.B18) Dementia in other diseases classified elsewhere, moderate, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B2) Dementia in other diseases classified elsewhere, moderate, with psychotic disturbance",
              label: "(F02.B2) Dementia in other diseases classified elsewhere, moderate, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B3) Dementia in other diseases classified elsewhere, moderate, with mood disturbance",
              label: "(F02.B3) Dementia in other diseases classified elsewhere, moderate, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.B4) Dementia in other diseases classified elsewhere, moderate, with anxiety",
              label: "(F02.B4) Dementia in other diseases classified elsewhere, moderate, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C0) Dementia in other diseases classified elsewhere, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F02.C0) Dementia in other diseases classified elsewhere, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C11) Dementia in other diseases classified elsewhere, severe, with agitation",
              label: "(F02.C11) Dementia in other diseases classified elsewhere, severe, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C18) Dementia in other diseases classified elsewhere, severe, with other behavioral disturbance",
              label: "(F02.C18) Dementia in other diseases classified elsewhere, severe, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C2) Dementia in other diseases classified elsewhere, severe, with psychotic disturbance",
              label: "(F02.C2) Dementia in other diseases classified elsewhere, severe, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C3) Dementia in other diseases classified elsewhere, severe, with mood disturbance",
              label: "(F02.C3) Dementia in other diseases classified elsewhere, severe, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F02.C4) Dementia in other diseases classified elsewhere, severe, with anxiety",
              label: "(F02.C4) Dementia in other diseases classified elsewhere, severe, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.90) Unspecified dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F03.90) Unspecified dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.911) Unspecified dementia, unspecified severity, with agitation",
              label: "(F03.911) Unspecified dementia, unspecified severity, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.918) Unspecified dementia, unspecified severity, with other behavioral disturbance",
              label: "(F03.918) Unspecified dementia, unspecified severity, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.92) Unspecified dementia, unspecified severity, with psychotic disturbance",
              label: "(F03.92) Unspecified dementia, unspecified severity, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.93) Unspecified dementia, unspecified severity, with mood disturbance",
              label: "(F03.93) Unspecified dementia, unspecified severity, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.94) Unspecified dementia, unspecified severity, with anxiety",
              label: "(F03.94) Unspecified dementia, unspecified severity, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A0) Unspecified dementia, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F03.A0) Unspecified dementia, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A11) Unspecified dementia, mild, with agitation",
              label: "(F03.A11) Unspecified dementia, mild, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A18) Unspecified dementia, mild, with other behavioral disturbance",
              label: "(F03.A18) Unspecified dementia, mild, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A2) Unspecified dementia, mild, with psychotic disturbance",
              label: "(F03.A2) Unspecified dementia, mild, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A3) Unspecified dementia, mild, with mood disturbance",
              label: "(F03.A3) Unspecified dementia, mild, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.A4) Unspecified dementia, mild, with anxiety",
              label: "(F03.A4) Unspecified dementia, mild, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B0) Unspecified dementia, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F03.B0) Unspecified dementia, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B11) Unspecified dementia, moderate, with agitation",
              label: "(F03.B11) Unspecified dementia, moderate, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B18) Unspecified dementia, moderate, with other behavioral disturbance",
              label: "(F03.B18) Unspecified dementia, moderate, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B2) Unspecified dementia, moderate, with psychotic disturbance",
              label: "(F03.B2) Unspecified dementia, moderate, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B3) Unspecified dementia, moderate, with mood disturbance",
              label: "(F03.B3) Unspecified dementia, moderate, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.B4) Unspecified dementia, moderate, with anxiety",
              label: "(F03.B4) Unspecified dementia, moderate, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C0) Unspecified dementia, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              label: "(F03.C0) Unspecified dementia, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C11) Unspecified dementia, severe, with agitation",
              label: "(F03.C11) Unspecified dementia, severe, with agitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C18) Unspecified dementia, severe, with other behavioral disturbance",
              label: "(F03.C18) Unspecified dementia, severe, with other behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C2) Unspecified dementia, severe, with psychotic disturbance",
              label: "(F03.C2) Unspecified dementia, severe, with psychotic disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C3) Unspecified dementia, severe, with mood disturbance",
              label: "(F03.C3) Unspecified dementia, severe, with mood disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F03.C4) Unspecified dementia, severe, with anxiety",
              label: "(F03.C4) Unspecified dementia, severe, with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F04.) Amnestic disorder due to known physiological condition",
              label: "(F04.) Amnestic disorder due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F05.) Delirium due to known physiological condition",
              label: "(F05.) Delirium due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.0) Psychotic disorder with hallucinations due to known physiological condition",
              label: "(F06.0) Psychotic disorder with hallucinations due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.1) Catatonic disorder due to known physiological condition",
              label: "(F06.1) Catatonic disorder due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.2) Psychotic disorder with delusions due to known physiological condition",
              label: "(F06.2) Psychotic disorder with delusions due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.30) Mood disorder due to known physiological condition, unspecified",
              label: "(F06.30) Mood disorder due to known physiological condition, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.31) Mood disorder due to known physiological condition with depressive features",
              label: "(F06.31) Mood disorder due to known physiological condition with depressive features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.32) Mood disorder due to known physiological condition with major depressive-like episode",
              label: "(F06.32) Mood disorder due to known physiological condition with major depressive-like episode",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.33) Mood disorder due to known physiological condition with manic features",
              label: "(F06.33) Mood disorder due to known physiological condition with manic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.34) Mood disorder due to known physiological condition with mixed features",
              label: "(F06.34) Mood disorder due to known physiological condition with mixed features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.4) Anxiety disorder due to known physiological condition",
              label: "(F06.4) Anxiety disorder due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.70) Mild neurocognitive disorder due to known physiological condition without behavioral disturbance",
              label: "(F06.70) Mild neurocognitive disorder due to known physiological condition without behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.71) Mild neurocognitive disorder due to known physiological condition with behavioral disturbance",
              label: "(F06.71) Mild neurocognitive disorder due to known physiological condition with behavioral disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F06.8) Other specified mental disorders due to known physiological condition",
              label: "(F06.8) Other specified mental disorders due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F07.0) Personality change due to known physiological condition",
              label: "(F07.0) Personality change due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F07.81) Postconcussional syndrome",
              label: "(F07.81) Postconcussional syndrome",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F07.89) Other personality and behavioral disorders due to known physiological condition",
              label: "(F07.89) Other personality and behavioral disorders due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F07.9) Unspecified personality and behavioral disorder due to known physiological condition",
              label: "(F07.9) Unspecified personality and behavioral disorder due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F09.) Unspecified mental disorder due to known physiological condition",
              label: "(F09.) Unspecified mental disorder due to known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.10) Alcohol abuse, uncomplicated",
              label: "(F10.10) Alcohol abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.11) Alcohol abuse, in remission",
              label: "(F10.11) Alcohol abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.120) Alcohol abuse with intoxication, uncomplicated",
              label: "(F10.120) Alcohol abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.121) Alcohol abuse with intoxication delirium",
              label: "(F10.121) Alcohol abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.129) Alcohol abuse with intoxication, unspecified",
              label: "(F10.129) Alcohol abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.130) Alcohol abuse with withdrawal, uncomplicated",
              label: "(F10.130) Alcohol abuse with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.131) Alcohol abuse with withdrawal delirium",
              label: "(F10.131) Alcohol abuse with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.132) Alcohol abuse with withdrawal with perceptual disturbance",
              label: "(F10.132) Alcohol abuse with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.139) Alcohol abuse with withdrawal, unspecified",
              label: "(F10.139) Alcohol abuse with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.14) Alcohol abuse with alcohol-induced mood disorder",
              label: "(F10.14) Alcohol abuse with alcohol-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.150) Alcohol abuse with alcohol-induced psychotic disorder with delusions",
              label: "(F10.150) Alcohol abuse with alcohol-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.151) Alcohol abuse with alcohol-induced psychotic disorder with hallucinations",
              label: "(F10.151) Alcohol abuse with alcohol-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.159) Alcohol abuse with alcohol-induced psychotic disorder, unspecified",
              label: "(F10.159) Alcohol abuse with alcohol-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.180) Alcohol abuse with alcohol-induced anxiety disorder",
              label: "(F10.180) Alcohol abuse with alcohol-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.181) Alcohol abuse with alcohol-induced sexual dysfunction",
              label: "(F10.181) Alcohol abuse with alcohol-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.182) Alcohol abuse with alcohol-induced sleep disorder",
              label: "(F10.182) Alcohol abuse with alcohol-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.188) Alcohol abuse with other alcohol-induced disorder",
              label: "(F10.188) Alcohol abuse with other alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.19) Alcohol abuse with unspecified alcohol-induced disorder",
              label: "(F10.19) Alcohol abuse with unspecified alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.20) Alcohol dependence, uncomplicated",
              label: "(F10.20) Alcohol dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.21) Alcohol dependence, in remission",
              label: "(F10.21) Alcohol dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.220) Alcohol dependence with intoxication, uncomplicated",
              label: "(F10.220) Alcohol dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.221) Alcohol dependence with intoxication delirium",
              label: "(F10.221) Alcohol dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.229) Alcohol dependence with intoxication, unspecified",
              label: "(F10.229) Alcohol dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.230) Alcohol dependence with withdrawal, uncomplicated",
              label: "(F10.230) Alcohol dependence with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.231) Alcohol dependence with withdrawal delirium",
              label: "(F10.231) Alcohol dependence with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.232) Alcohol dependence with withdrawal with perceptual disturbance",
              label: "(F10.232) Alcohol dependence with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.239) Alcohol dependence with withdrawal, unspecified",
              label: "(F10.239) Alcohol dependence with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.24) Alcohol dependence with alcohol-induced mood disorder",
              label: "(F10.24) Alcohol dependence with alcohol-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.250) Alcohol dependence with alcohol-induced psychotic disorder with delusions",
              label: "(F10.250) Alcohol dependence with alcohol-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.251) Alcohol dependence with alcohol-induced psychotic disorder with hallucinations",
              label: "(F10.251) Alcohol dependence with alcohol-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.259) Alcohol dependence with alcohol-induced psychotic disorder, unspecified",
              label: "(F10.259) Alcohol dependence with alcohol-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.26) Alcohol dependence with alcohol-induced persisting amnestic disorder",
              label: "(F10.26) Alcohol dependence with alcohol-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.27) Alcohol dependence with alcohol-induced persisting dementia",
              label: "(F10.27) Alcohol dependence with alcohol-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.280) Alcohol dependence with alcohol-induced anxiety disorder",
              label: "(F10.280) Alcohol dependence with alcohol-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.281) Alcohol dependence with alcohol-induced sexual dysfunction",
              label: "(F10.281) Alcohol dependence with alcohol-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.282) Alcohol dependence with alcohol-induced sleep disorder",
              label: "(F10.282) Alcohol dependence with alcohol-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.288) Alcohol dependence with other alcohol-induced disorder",
              label: "(F10.288) Alcohol dependence with other alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.29) Alcohol dependence with unspecified alcohol-induced disorder",
              label: "(F10.29) Alcohol dependence with unspecified alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.90) Alcohol use, unspecified, uncomplicated",
              label: "(F10.90) Alcohol use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.91) Alcohol use, unspecified, in remission",
              label: "(F10.91) Alcohol use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.920) Alcohol use, unspecified with intoxication, uncomplicated",
              label: "(F10.920) Alcohol use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.921) Alcohol use, unspecified with intoxication delirium",
              label: "(F10.921) Alcohol use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.929) Alcohol use, unspecified with intoxication, unspecified",
              label: "(F10.929) Alcohol use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.930) Alcohol use, unspecified with withdrawal, uncomplicated",
              label: "(F10.930) Alcohol use, unspecified with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.931) Alcohol use, unspecified with withdrawal delirium",
              label: "(F10.931) Alcohol use, unspecified with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.932) Alcohol use, unspecified with withdrawal with perceptual disturbance",
              label: "(F10.932) Alcohol use, unspecified with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.939) Alcohol use, unspecified with withdrawal, unspecified",
              label: "(F10.939) Alcohol use, unspecified with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.94) Alcohol use, unspecified with alcohol-induced mood disorder",
              label: "(F10.94) Alcohol use, unspecified with alcohol-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.950) Alcohol use, unspecified with alcohol-induced psychotic disorder with delusions",
              label: "(F10.950) Alcohol use, unspecified with alcohol-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.951) Alcohol use, unspecified with alcohol-induced psychotic disorder with hallucinations",
              label: "(F10.951) Alcohol use, unspecified with alcohol-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.959) Alcohol use, unspecified with alcohol-induced psychotic disorder, unspecified",
              label: "(F10.959) Alcohol use, unspecified with alcohol-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.96) Alcohol use, unspecified with alcohol-induced persisting amnestic disorder",
              label: "(F10.96) Alcohol use, unspecified with alcohol-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.97) Alcohol use, unspecified with alcohol-induced persisting dementia",
              label: "(F10.97) Alcohol use, unspecified with alcohol-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.980) Alcohol use, unspecified with alcohol-induced anxiety disorder",
              label: "(F10.980) Alcohol use, unspecified with alcohol-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.981) Alcohol use, unspecified with alcohol-induced sexual dysfunction",
              label: "(F10.981) Alcohol use, unspecified with alcohol-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.982) Alcohol use, unspecified with alcohol-induced sleep disorder",
              label: "(F10.982) Alcohol use, unspecified with alcohol-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.988) Alcohol use, unspecified with other alcohol-induced disorder",
              label: "(F10.988) Alcohol use, unspecified with other alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F10.99) Alcohol use, unspecified with unspecified alcohol-induced disorder",
              label: "(F10.99) Alcohol use, unspecified with unspecified alcohol-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.10) Opioid abuse, uncomplicated",
              label: "(F11.10) Opioid abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.11) Opioid abuse, in remission",
              label: "(F11.11) Opioid abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.120) Opioid abuse with intoxication, uncomplicated",
              label: "(F11.120) Opioid abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.121) Opioid abuse with intoxication delirium",
              label: "(F11.121) Opioid abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.122) Opioid abuse with intoxication with perceptual disturbance",
              label: "(F11.122) Opioid abuse with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.129) Opioid abuse with intoxication, unspecified",
              label: "(F11.129) Opioid abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.13) Opioid abuse with withdrawal",
              label: "(F11.13) Opioid abuse with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.14) Opioid abuse with opioid-induced mood disorder",
              label: "(F11.14) Opioid abuse with opioid-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.150) Opioid abuse with opioid-induced psychotic disorder with delusions",
              label: "(F11.150) Opioid abuse with opioid-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.151) Opioid abuse with opioid-induced psychotic disorder with hallucinations",
              label: "(F11.151) Opioid abuse with opioid-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.159) Opioid abuse with opioid-induced psychotic disorder, unspecified",
              label: "(F11.159) Opioid abuse with opioid-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.181) Opioid abuse with opioid-induced sexual dysfunction",
              label: "(F11.181) Opioid abuse with opioid-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.182) Opioid abuse with opioid-induced sleep disorder",
              label: "(F11.182) Opioid abuse with opioid-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.188) Opioid abuse with other opioid-induced disorder",
              label: "(F11.188) Opioid abuse with other opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.19) Opioid abuse with unspecified opioid-induced disorder",
              label: "(F11.19) Opioid abuse with unspecified opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.20) Opioid dependence, uncomplicated",
              label: "(F11.20) Opioid dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.21) Opioid dependence, in remission",
              label: "(F11.21) Opioid dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.220) Opioid dependence with intoxication, uncomplicated",
              label: "(F11.220) Opioid dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.221) Opioid dependence with intoxication delirium",
              label: "(F11.221) Opioid dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.222) Opioid dependence with intoxication with perceptual disturbance",
              label: "(F11.222) Opioid dependence with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.229) Opioid dependence with intoxication, unspecified",
              label: "(F11.229) Opioid dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.23) Opioid dependence with withdrawal",
              label: "(F11.23) Opioid dependence with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.24) Opioid dependence with opioid-induced mood disorder",
              label: "(F11.24) Opioid dependence with opioid-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.250) Opioid dependence with opioid-induced psychotic disorder with delusions",
              label: "(F11.250) Opioid dependence with opioid-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.251) Opioid dependence with opioid-induced psychotic disorder with hallucinations",
              label: "(F11.251) Opioid dependence with opioid-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.259) Opioid dependence with opioid-induced psychotic disorder, unspecified",
              label: "(F11.259) Opioid dependence with opioid-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.281) Opioid dependence with opioid-induced sexual dysfunction",
              label: "(F11.281) Opioid dependence with opioid-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.282) Opioid dependence with opioid-induced sleep disorder",
              label: "(F11.282) Opioid dependence with opioid-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.288) Opioid dependence with other opioid-induced disorder",
              label: "(F11.288) Opioid dependence with other opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.29) Opioid dependence with unspecified opioid-induced disorder",
              label: "(F11.29) Opioid dependence with unspecified opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.90) Opioid use, unspecified, uncomplicated",
              label: "(F11.90) Opioid use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.91) Opioid use, unspecified, in remission",
              label: "(F11.91) Opioid use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.920) Opioid use, unspecified with intoxication, uncomplicated",
              label: "(F11.920) Opioid use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.921) Opioid use, unspecified with intoxication delirium",
              label: "(F11.921) Opioid use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.922) Opioid use, unspecified with intoxication with perceptual disturbance",
              label: "(F11.922) Opioid use, unspecified with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.929) Opioid use, unspecified with intoxication, unspecified",
              label: "(F11.929) Opioid use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.93) Opioid use, unspecified with withdrawal",
              label: "(F11.93) Opioid use, unspecified with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.94) Opioid use, unspecified with opioid-induced mood disorder",
              label: "(F11.94) Opioid use, unspecified with opioid-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.950) Opioid use, unspecified with opioid-induced psychotic disorder with delusions",
              label: "(F11.950) Opioid use, unspecified with opioid-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.951) Opioid use, unspecified with opioid-induced psychotic disorder with hallucinations",
              label: "(F11.951) Opioid use, unspecified with opioid-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.959) Opioid use, unspecified with opioid-induced psychotic disorder, unspecified",
              label: "(F11.959) Opioid use, unspecified with opioid-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.981) Opioid use, unspecified with opioid-induced sexual dysfunction",
              label: "(F11.981) Opioid use, unspecified with opioid-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.982) Opioid use, unspecified with opioid-induced sleep disorder",
              label: "(F11.982) Opioid use, unspecified with opioid-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.988) Opioid use, unspecified with other opioid-induced disorder",
              label: "(F11.988) Opioid use, unspecified with other opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F11.99) Opioid use, unspecified with unspecified opioid-induced disorder",
              label: "(F11.99) Opioid use, unspecified with unspecified opioid-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.10) Cannabis abuse, uncomplicated",
              label: "(F12.10) Cannabis abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.11) Cannabis abuse, in remission",
              label: "(F12.11) Cannabis abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.120) Cannabis abuse with intoxication, uncomplicated",
              label: "(F12.120) Cannabis abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.121) Cannabis abuse with intoxication delirium",
              label: "(F12.121) Cannabis abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.122) Cannabis abuse with intoxication with perceptual disturbance",
              label: "(F12.122) Cannabis abuse with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.129) Cannabis abuse with intoxication, unspecified",
              label: "(F12.129) Cannabis abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.13) Cannabis abuse with withdrawal",
              label: "(F12.13) Cannabis abuse with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.150) Cannabis abuse with psychotic disorder with delusions",
              label: "(F12.150) Cannabis abuse with psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.151) Cannabis abuse with psychotic disorder with hallucinations",
              label: "(F12.151) Cannabis abuse with psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.159) Cannabis abuse with psychotic disorder, unspecified",
              label: "(F12.159) Cannabis abuse with psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.180) Cannabis abuse with cannabis-induced anxiety disorder",
              label: "(F12.180) Cannabis abuse with cannabis-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.188) Cannabis abuse with other cannabis-induced disorder",
              label: "(F12.188) Cannabis abuse with other cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.19) Cannabis abuse with unspecified cannabis-induced disorder",
              label: "(F12.19) Cannabis abuse with unspecified cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.20) Cannabis dependence, uncomplicated",
              label: "(F12.20) Cannabis dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.21) Cannabis dependence, in remission",
              label: "(F12.21) Cannabis dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.220) Cannabis dependence with intoxication, uncomplicated",
              label: "(F12.220) Cannabis dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.221) Cannabis dependence with intoxication delirium",
              label: "(F12.221) Cannabis dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.222) Cannabis dependence with intoxication with perceptual disturbance",
              label: "(F12.222) Cannabis dependence with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.229) Cannabis dependence with intoxication, unspecified",
              label: "(F12.229) Cannabis dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.23) Cannabis dependence with withdrawal",
              label: "(F12.23) Cannabis dependence with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.250) Cannabis dependence with psychotic disorder with delusions",
              label: "(F12.250) Cannabis dependence with psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.251) Cannabis dependence with psychotic disorder with hallucinations",
              label: "(F12.251) Cannabis dependence with psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.259) Cannabis dependence with psychotic disorder, unspecified",
              label: "(F12.259) Cannabis dependence with psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.280) Cannabis dependence with cannabis-induced anxiety disorder",
              label: "(F12.280) Cannabis dependence with cannabis-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.288) Cannabis dependence with other cannabis-induced disorder",
              label: "(F12.288) Cannabis dependence with other cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.29) Cannabis dependence with unspecified cannabis-induced disorder",
              label: "(F12.29) Cannabis dependence with unspecified cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.90) Cannabis use, unspecified, uncomplicated",
              label: "(F12.90) Cannabis use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.91) Cannabis use, unspecified, in remission",
              label: "(F12.91) Cannabis use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.920) Cannabis use, unspecified with intoxication, uncomplicated",
              label: "(F12.920) Cannabis use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.921) Cannabis use, unspecified with intoxication delirium",
              label: "(F12.921) Cannabis use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.922) Cannabis use, unspecified with intoxication with perceptual disturbance",
              label: "(F12.922) Cannabis use, unspecified with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.929) Cannabis use, unspecified with intoxication, unspecified",
              label: "(F12.929) Cannabis use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.93) Cannabis use, unspecified with withdrawal",
              label: "(F12.93) Cannabis use, unspecified with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.950) Cannabis use, unspecified with psychotic disorder with delusions",
              label: "(F12.950) Cannabis use, unspecified with psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.951) Cannabis use, unspecified with psychotic disorder with hallucinations",
              label: "(F12.951) Cannabis use, unspecified with psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.959) Cannabis use, unspecified with psychotic disorder, unspecified",
              label: "(F12.959) Cannabis use, unspecified with psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.980) Cannabis use, unspecified with anxiety disorder",
              label: "(F12.980) Cannabis use, unspecified with anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.988) Cannabis use, unspecified with other cannabis-induced disorder",
              label: "(F12.988) Cannabis use, unspecified with other cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F12.99) Cannabis use, unspecified with unspecified cannabis-induced disorder",
              label: "(F12.99) Cannabis use, unspecified with unspecified cannabis-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.10) Sedative, hypnotic or anxiolytic abuse, uncomplicated",
              label: "(F13.10) Sedative, hypnotic or anxiolytic abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.11) Sedative, hypnotic or anxiolytic abuse, in remission",
              label: "(F13.11) Sedative, hypnotic or anxiolytic abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.120) Sedative, hypnotic or anxiolytic abuse with intoxication, uncomplicated",
              label: "(F13.120) Sedative, hypnotic or anxiolytic abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.121) Sedative, hypnotic or anxiolytic abuse with intoxication delirium",
              label: "(F13.121) Sedative, hypnotic or anxiolytic abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.129) Sedative, hypnotic or anxiolytic abuse with intoxication, unspecified",
              label: "(F13.129) Sedative, hypnotic or anxiolytic abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.130) Sedative, hypnotic or anxiolytic abuse with withdrawal, uncomplicated",
              label: "(F13.130) Sedative, hypnotic or anxiolytic abuse with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.131) Sedative, hypnotic or anxiolytic abuse with withdrawal delirium",
              label: "(F13.131) Sedative, hypnotic or anxiolytic abuse with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.132) Sedative, hypnotic or anxiolytic abuse with withdrawal with perceptual disturbance",
              label: "(F13.132) Sedative, hypnotic or anxiolytic abuse with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.139) Sedative, hypnotic or anxiolytic abuse with withdrawal, unspecified",
              label: "(F13.139) Sedative, hypnotic or anxiolytic abuse with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.14) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced mood disorder",
              label: "(F13.14) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.150) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              label: "(F13.150) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.151) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              label: "(F13.151) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.159) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              label: "(F13.159) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.180) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              label: "(F13.180) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.181) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              label: "(F13.181) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.182) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sleep disorder",
              label: "(F13.182) Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.188) Sedative, hypnotic or anxiolytic abuse with other sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.188) Sedative, hypnotic or anxiolytic abuse with other sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.19) Sedative, hypnotic or anxiolytic abuse with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.19) Sedative, hypnotic or anxiolytic abuse with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.20) Sedative, hypnotic or anxiolytic dependence, uncomplicated",
              label: "(F13.20) Sedative, hypnotic or anxiolytic dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.21) Sedative, hypnotic or anxiolytic dependence, in remission",
              label: "(F13.21) Sedative, hypnotic or anxiolytic dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.220) Sedative, hypnotic or anxiolytic dependence with intoxication, uncomplicated",
              label: "(F13.220) Sedative, hypnotic or anxiolytic dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.221) Sedative, hypnotic or anxiolytic dependence with intoxication delirium",
              label: "(F13.221) Sedative, hypnotic or anxiolytic dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.229) Sedative, hypnotic or anxiolytic dependence with intoxication, unspecified",
              label: "(F13.229) Sedative, hypnotic or anxiolytic dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.230) Sedative, hypnotic or anxiolytic dependence with withdrawal, uncomplicated",
              label: "(F13.230) Sedative, hypnotic or anxiolytic dependence with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.231) Sedative, hypnotic or anxiolytic dependence with withdrawal delirium",
              label: "(F13.231) Sedative, hypnotic or anxiolytic dependence with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.232) Sedative, hypnotic or anxiolytic dependence with withdrawal with perceptual disturbance",
              label: "(F13.232) Sedative, hypnotic or anxiolytic dependence with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.239) Sedative, hypnotic or anxiolytic dependence with withdrawal, unspecified",
              label: "(F13.239) Sedative, hypnotic or anxiolytic dependence with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.24) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced mood disorder",
              label: "(F13.24) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.250) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              label: "(F13.250) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.251) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              label: "(F13.251) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.259) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              label: "(F13.259) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.26) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder",
              label: "(F13.26) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.27) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting dementia",
              label: "(F13.27) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.280) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              label: "(F13.280) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.281) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              label: "(F13.281) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.282) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sleep disorder",
              label: "(F13.282) Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.288) Sedative, hypnotic or anxiolytic dependence with other sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.288) Sedative, hypnotic or anxiolytic dependence with other sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.29) Sedative, hypnotic or anxiolytic dependence with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.29) Sedative, hypnotic or anxiolytic dependence with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.90) Sedative, hypnotic, or anxiolytic use, unspecified, uncomplicated",
              label: "(F13.90) Sedative, hypnotic, or anxiolytic use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.91) Sedative, hypnotic or anxiolytic use, unspecified, in remission",
              label: "(F13.91) Sedative, hypnotic or anxiolytic use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.920) Sedative, hypnotic or anxiolytic use, unspecified with intoxication, uncomplicated",
              label: "(F13.920) Sedative, hypnotic or anxiolytic use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.921) Sedative, hypnotic or anxiolytic use, unspecified with intoxication delirium",
              label: "(F13.921) Sedative, hypnotic or anxiolytic use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.929) Sedative, hypnotic or anxiolytic use, unspecified with intoxication, unspecified",
              label: "(F13.929) Sedative, hypnotic or anxiolytic use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.930) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, uncomplicated",
              label: "(F13.930) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.931) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal delirium",
              label: "(F13.931) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.932) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal with perceptual disturbances",
              label: "(F13.932) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal with perceptual disturbances",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.939) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, unspecified",
              label: "(F13.939) Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.94) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced mood disorder",
              label: "(F13.94) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.950) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              label: "(F13.950) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.951) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              label: "(F13.951) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.959) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              label: "(F13.959) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.96) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder",
              label: "(F13.96) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.97) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting dementia",
              label: "(F13.97) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.980) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              label: "(F13.980) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.981) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              label: "(F13.981) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.982) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sleep disorder",
              label: "(F13.982) Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.988) Sedative, hypnotic or anxiolytic use, unspecified with other sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.988) Sedative, hypnotic or anxiolytic use, unspecified with other sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F13.99) Sedative, hypnotic or anxiolytic use, unspecified with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              label: "(F13.99) Sedative, hypnotic or anxiolytic use, unspecified with unspecified sedative, hypnotic or anxiolytic-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.10) Cocaine abuse, uncomplicated",
              label: "(F14.10) Cocaine abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.11) Cocaine abuse, in remission",
              label: "(F14.11) Cocaine abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.120) Cocaine abuse with intoxication, uncomplicated",
              label: "(F14.120) Cocaine abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.121) Cocaine abuse with intoxication with delirium",
              label: "(F14.121) Cocaine abuse with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.122) Cocaine abuse with intoxication with perceptual disturbance",
              label: "(F14.122) Cocaine abuse with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.129) Cocaine abuse with intoxication, unspecified",
              label: "(F14.129) Cocaine abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.13) Cocaine abuse, unspecified with withdrawal",
              label: "(F14.13) Cocaine abuse, unspecified with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.14) Cocaine abuse with cocaine-induced mood disorder",
              label: "(F14.14) Cocaine abuse with cocaine-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.150) Cocaine abuse with cocaine-induced psychotic disorder with delusions",
              label: "(F14.150) Cocaine abuse with cocaine-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.151) Cocaine abuse with cocaine-induced psychotic disorder with hallucinations",
              label: "(F14.151) Cocaine abuse with cocaine-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.159) Cocaine abuse with cocaine-induced psychotic disorder, unspecified",
              label: "(F14.159) Cocaine abuse with cocaine-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.180) Cocaine abuse with cocaine-induced anxiety disorder",
              label: "(F14.180) Cocaine abuse with cocaine-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.181) Cocaine abuse with cocaine-induced sexual dysfunction",
              label: "(F14.181) Cocaine abuse with cocaine-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.182) Cocaine abuse with cocaine-induced sleep disorder",
              label: "(F14.182) Cocaine abuse with cocaine-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.188) Cocaine abuse with other cocaine-induced disorder",
              label: "(F14.188) Cocaine abuse with other cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.19) Cocaine abuse with unspecified cocaine-induced disorder",
              label: "(F14.19) Cocaine abuse with unspecified cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.20) Cocaine dependence, uncomplicated",
              label: "(F14.20) Cocaine dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.21) Cocaine dependence, in remission",
              label: "(F14.21) Cocaine dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.220) Cocaine dependence with intoxication, uncomplicated",
              label: "(F14.220) Cocaine dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.221) Cocaine dependence with intoxication delirium",
              label: "(F14.221) Cocaine dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.222) Cocaine dependence with intoxication with perceptual disturbance",
              label: "(F14.222) Cocaine dependence with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.229) Cocaine dependence with intoxication, unspecified",
              label: "(F14.229) Cocaine dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.23) Cocaine dependence with withdrawal",
              label: "(F14.23) Cocaine dependence with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.24) Cocaine dependence with cocaine-induced mood disorder",
              label: "(F14.24) Cocaine dependence with cocaine-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.250) Cocaine dependence with cocaine-induced psychotic disorder with delusions",
              label: "(F14.250) Cocaine dependence with cocaine-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.251) Cocaine dependence with cocaine-induced psychotic disorder with hallucinations",
              label: "(F14.251) Cocaine dependence with cocaine-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.259) Cocaine dependence with cocaine-induced psychotic disorder, unspecified",
              label: "(F14.259) Cocaine dependence with cocaine-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.280) Cocaine dependence with cocaine-induced anxiety disorder",
              label: "(F14.280) Cocaine dependence with cocaine-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.281) Cocaine dependence with cocaine-induced sexual dysfunction",
              label: "(F14.281) Cocaine dependence with cocaine-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.282) Cocaine dependence with cocaine-induced sleep disorder",
              label: "(F14.282) Cocaine dependence with cocaine-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.288) Cocaine dependence with other cocaine-induced disorder",
              label: "(F14.288) Cocaine dependence with other cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.29) Cocaine dependence with unspecified cocaine-induced disorder",
              label: "(F14.29) Cocaine dependence with unspecified cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.90) Cocaine use, unspecified, uncomplicated",
              label: "(F14.90) Cocaine use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.91) Cocaine use, unspecified, in remission",
              label: "(F14.91) Cocaine use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.920) Cocaine use, unspecified with intoxication, uncomplicated",
              label: "(F14.920) Cocaine use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.921) Cocaine use, unspecified with intoxication delirium",
              label: "(F14.921) Cocaine use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.922) Cocaine use, unspecified with intoxication with perceptual disturbance",
              label: "(F14.922) Cocaine use, unspecified with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.929) Cocaine use, unspecified with intoxication, unspecified",
              label: "(F14.929) Cocaine use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.93) Cocaine use, unspecified with withdrawal",
              label: "(F14.93) Cocaine use, unspecified with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.94) Cocaine use, unspecified with cocaine-induced mood disorder",
              label: "(F14.94) Cocaine use, unspecified with cocaine-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.950) Cocaine use, unspecified with cocaine-induced psychotic disorder with delusions",
              label: "(F14.950) Cocaine use, unspecified with cocaine-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.951) Cocaine use, unspecified with cocaine-induced psychotic disorder with hallucinations",
              label: "(F14.951) Cocaine use, unspecified with cocaine-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.959) Cocaine use, unspecified with cocaine-induced psychotic disorder, unspecified",
              label: "(F14.959) Cocaine use, unspecified with cocaine-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.980) Cocaine use, unspecified with cocaine-induced anxiety disorder",
              label: "(F14.980) Cocaine use, unspecified with cocaine-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.981) Cocaine use, unspecified with cocaine-induced sexual dysfunction",
              label: "(F14.981) Cocaine use, unspecified with cocaine-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.982) Cocaine use, unspecified with cocaine-induced sleep disorder",
              label: "(F14.982) Cocaine use, unspecified with cocaine-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.988) Cocaine use, unspecified with other cocaine-induced disorder",
              label: "(F14.988) Cocaine use, unspecified with other cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F14.99) Cocaine use, unspecified with unspecified cocaine-induced disorder",
              label: "(F14.99) Cocaine use, unspecified with unspecified cocaine-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.10) Other stimulant abuse, uncomplicated",
              label: "(F15.10) Other stimulant abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.11) Other stimulant abuse, in remission",
              label: "(F15.11) Other stimulant abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.120) Other stimulant abuse with intoxication, uncomplicated",
              label: "(F15.120) Other stimulant abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.121) Other stimulant abuse with intoxication delirium",
              label: "(F15.121) Other stimulant abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.122) Other stimulant abuse with intoxication with perceptual disturbance",
              label: "(F15.122) Other stimulant abuse with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.129) Other stimulant abuse with intoxication, unspecified",
              label: "(F15.129) Other stimulant abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.13) Other stimulant abuse with withdrawal",
              label: "(F15.13) Other stimulant abuse with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.14) Other stimulant abuse with stimulant-induced mood disorder",
              label: "(F15.14) Other stimulant abuse with stimulant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.150) Other stimulant abuse with stimulant-induced psychotic disorder with delusions",
              label: "(F15.150) Other stimulant abuse with stimulant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.151) Other stimulant abuse with stimulant-induced psychotic disorder with hallucinations",
              label: "(F15.151) Other stimulant abuse with stimulant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.159) Other stimulant abuse with stimulant-induced psychotic disorder, unspecified",
              label: "(F15.159) Other stimulant abuse with stimulant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.180) Other stimulant abuse with stimulant-induced anxiety disorder",
              label: "(F15.180) Other stimulant abuse with stimulant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.181) Other stimulant abuse with stimulant-induced sexual dysfunction",
              label: "(F15.181) Other stimulant abuse with stimulant-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.182) Other stimulant abuse with stimulant-induced sleep disorder",
              label: "(F15.182) Other stimulant abuse with stimulant-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.188) Other stimulant abuse with other stimulant-induced disorder",
              label: "(F15.188) Other stimulant abuse with other stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.19) Other stimulant abuse with unspecified stimulant-induced disorder",
              label: "(F15.19) Other stimulant abuse with unspecified stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.20) Other stimulant dependence, uncomplicated",
              label: "(F15.20) Other stimulant dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.21) Other stimulant dependence, in remission",
              label: "(F15.21) Other stimulant dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.220) Other stimulant dependence with intoxication, uncomplicated",
              label: "(F15.220) Other stimulant dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.221) Other stimulant dependence with intoxication delirium",
              label: "(F15.221) Other stimulant dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.222) Other stimulant dependence with intoxication with perceptual disturbance",
              label: "(F15.222) Other stimulant dependence with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.229) Other stimulant dependence with intoxication, unspecified",
              label: "(F15.229) Other stimulant dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.23) Other stimulant dependence with withdrawal",
              label: "(F15.23) Other stimulant dependence with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.24) Other stimulant dependence with stimulant-induced mood disorder",
              label: "(F15.24) Other stimulant dependence with stimulant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.250) Other stimulant dependence with stimulant-induced psychotic disorder with delusions",
              label: "(F15.250) Other stimulant dependence with stimulant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.251) Other stimulant dependence with stimulant-induced psychotic disorder with hallucinations",
              label: "(F15.251) Other stimulant dependence with stimulant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.259) Other stimulant dependence with stimulant-induced psychotic disorder, unspecified",
              label: "(F15.259) Other stimulant dependence with stimulant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.280) Other stimulant dependence with stimulant-induced anxiety disorder",
              label: "(F15.280) Other stimulant dependence with stimulant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.281) Other stimulant dependence with stimulant-induced sexual dysfunction",
              label: "(F15.281) Other stimulant dependence with stimulant-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.282) Other stimulant dependence with stimulant-induced sleep disorder",
              label: "(F15.282) Other stimulant dependence with stimulant-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.288) Other stimulant dependence with other stimulant-induced disorder",
              label: "(F15.288) Other stimulant dependence with other stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.29) Other stimulant dependence with unspecified stimulant-induced disorder",
              label: "(F15.29) Other stimulant dependence with unspecified stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.90) Other stimulant use, unspecified, uncomplicated",
              label: "(F15.90) Other stimulant use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.91) Other stimulant use, unspecified, in remission",
              label: "(F15.91) Other stimulant use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.920) Other stimulant use, unspecified with intoxication, uncomplicated",
              label: "(F15.920) Other stimulant use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.921) Other stimulant use, unspecified with intoxication delirium",
              label: "(F15.921) Other stimulant use, unspecified with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.922) Other stimulant use, unspecified with intoxication with perceptual disturbance",
              label: "(F15.922) Other stimulant use, unspecified with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.929) Other stimulant use, unspecified with intoxication, unspecified",
              label: "(F15.929) Other stimulant use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.93) Other stimulant use, unspecified with withdrawal",
              label: "(F15.93) Other stimulant use, unspecified with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.94) Other stimulant use, unspecified with stimulant-induced mood disorder",
              label: "(F15.94) Other stimulant use, unspecified with stimulant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.950) Other stimulant use, unspecified with stimulant-induced psychotic disorder with delusions",
              label: "(F15.950) Other stimulant use, unspecified with stimulant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.951) Other stimulant use, unspecified with stimulant-induced psychotic disorder with hallucinations",
              label: "(F15.951) Other stimulant use, unspecified with stimulant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.959) Other stimulant use, unspecified with stimulant-induced psychotic disorder, unspecified",
              label: "(F15.959) Other stimulant use, unspecified with stimulant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.980) Other stimulant use, unspecified with stimulant-induced anxiety disorder",
              label: "(F15.980) Other stimulant use, unspecified with stimulant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.981) Other stimulant use, unspecified with stimulant-induced sexual dysfunction",
              label: "(F15.981) Other stimulant use, unspecified with stimulant-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.982) Other stimulant use, unspecified with stimulant-induced sleep disorder",
              label: "(F15.982) Other stimulant use, unspecified with stimulant-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.988) Other stimulant use, unspecified with other stimulant-induced disorder",
              label: "(F15.988) Other stimulant use, unspecified with other stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F15.99) Other stimulant use, unspecified with unspecified stimulant-induced disorder",
              label: "(F15.99) Other stimulant use, unspecified with unspecified stimulant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.10) Hallucinogen abuse, uncomplicated",
              label: "(F16.10) Hallucinogen abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.11) Hallucinogen abuse, in remission",
              label: "(F16.11) Hallucinogen abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.120) Hallucinogen abuse with intoxication, uncomplicated",
              label: "(F16.120) Hallucinogen abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.121) Hallucinogen abuse with intoxication with delirium",
              label: "(F16.121) Hallucinogen abuse with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.122) Hallucinogen abuse with intoxication with perceptual disturbance",
              label: "(F16.122) Hallucinogen abuse with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.129) Hallucinogen abuse with intoxication, unspecified",
              label: "(F16.129) Hallucinogen abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.14) Hallucinogen abuse with hallucinogen-induced mood disorder",
              label: "(F16.14) Hallucinogen abuse with hallucinogen-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.150) Hallucinogen abuse with hallucinogen-induced psychotic disorder with delusions",
              label: "(F16.150) Hallucinogen abuse with hallucinogen-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.151) Hallucinogen abuse with hallucinogen-induced psychotic disorder with hallucinations",
              label: "(F16.151) Hallucinogen abuse with hallucinogen-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.159) Hallucinogen abuse with hallucinogen-induced psychotic disorder, unspecified",
              label: "(F16.159) Hallucinogen abuse with hallucinogen-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.180) Hallucinogen abuse with hallucinogen-induced anxiety disorder",
              label: "(F16.180) Hallucinogen abuse with hallucinogen-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.183) Hallucinogen abuse with hallucinogen persisting perception disorder (flashbacks)",
              label: "(F16.183) Hallucinogen abuse with hallucinogen persisting perception disorder (flashbacks)",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.188) Hallucinogen abuse with other hallucinogen-induced disorder",
              label: "(F16.188) Hallucinogen abuse with other hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.19) Hallucinogen abuse with unspecified hallucinogen-induced disorder",
              label: "(F16.19) Hallucinogen abuse with unspecified hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.20) Hallucinogen dependence, uncomplicated",
              label: "(F16.20) Hallucinogen dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.21) Hallucinogen dependence, in remission",
              label: "(F16.21) Hallucinogen dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.220) Hallucinogen dependence with intoxication, uncomplicated",
              label: "(F16.220) Hallucinogen dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.221) Hallucinogen dependence with intoxication with delirium",
              label: "(F16.221) Hallucinogen dependence with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.229) Hallucinogen dependence with intoxication, unspecified",
              label: "(F16.229) Hallucinogen dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.24) Hallucinogen dependence with hallucinogen-induced mood disorder",
              label: "(F16.24) Hallucinogen dependence with hallucinogen-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.250) Hallucinogen dependence with hallucinogen-induced psychotic disorder with delusions",
              label: "(F16.250) Hallucinogen dependence with hallucinogen-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.251) Hallucinogen dependence with hallucinogen-induced psychotic disorder with hallucinations",
              label: "(F16.251) Hallucinogen dependence with hallucinogen-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.259) Hallucinogen dependence with hallucinogen-induced psychotic disorder, unspecified",
              label: "(F16.259) Hallucinogen dependence with hallucinogen-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.280) Hallucinogen dependence with hallucinogen-induced anxiety disorder",
              label: "(F16.280) Hallucinogen dependence with hallucinogen-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.283) Hallucinogen dependence with hallucinogen persisting perception disorder (flashbacks)",
              label: "(F16.283) Hallucinogen dependence with hallucinogen persisting perception disorder (flashbacks)",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.288) Hallucinogen dependence with other hallucinogen-induced disorder",
              label: "(F16.288) Hallucinogen dependence with other hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.29) Hallucinogen dependence with unspecified hallucinogen-induced disorder",
              label: "(F16.29) Hallucinogen dependence with unspecified hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.90) Hallucinogen use, unspecified, uncomplicated",
              label: "(F16.90) Hallucinogen use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.91) Hallucinogen use, unspecified, in remission",
              label: "(F16.91) Hallucinogen use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.920) Hallucinogen use, unspecified with intoxication, uncomplicated",
              label: "(F16.920) Hallucinogen use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.921) Hallucinogen use, unspecified with intoxication with delirium",
              label: "(F16.921) Hallucinogen use, unspecified with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.929) Hallucinogen use, unspecified with intoxication, unspecified",
              label: "(F16.929) Hallucinogen use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.94) Hallucinogen use, unspecified with hallucinogen-induced mood disorder",
              label: "(F16.94) Hallucinogen use, unspecified with hallucinogen-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.950) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with delusions",
              label: "(F16.950) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.951) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with hallucinations",
              label: "(F16.951) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.959) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder, unspecified",
              label: "(F16.959) Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.980) Hallucinogen use, unspecified with hallucinogen-induced anxiety disorder",
              label: "(F16.980) Hallucinogen use, unspecified with hallucinogen-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.983) Hallucinogen use, unspecified with hallucinogen persisting perception disorder (flashbacks)",
              label: "(F16.983) Hallucinogen use, unspecified with hallucinogen persisting perception disorder (flashbacks)",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.988) Hallucinogen use, unspecified with other hallucinogen-induced disorder",
              label: "(F16.988) Hallucinogen use, unspecified with other hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F16.99) Hallucinogen use, unspecified with unspecified hallucinogen-induced disorder",
              label: "(F16.99) Hallucinogen use, unspecified with unspecified hallucinogen-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.200) Nicotine dependence, unspecified, uncomplicated",
              label: "(F17.200) Nicotine dependence, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.201) Nicotine dependence, unspecified, in remission",
              label: "(F17.201) Nicotine dependence, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.203) Nicotine dependence unspecified, with withdrawal",
              label: "(F17.203) Nicotine dependence unspecified, with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.208) Nicotine dependence, unspecified, with other nicotine-induced disorders",
              label: "(F17.208) Nicotine dependence, unspecified, with other nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.209) Nicotine dependence, unspecified, with unspecified nicotine-induced disorders",
              label: "(F17.209) Nicotine dependence, unspecified, with unspecified nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.210) Nicotine dependence, cigarettes, uncomplicated",
              label: "(F17.210) Nicotine dependence, cigarettes, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.211) Nicotine dependence, cigarettes, in remission",
              label: "(F17.211) Nicotine dependence, cigarettes, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.213) Nicotine dependence, cigarettes, with withdrawal",
              label: "(F17.213) Nicotine dependence, cigarettes, with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.218) Nicotine dependence, cigarettes, with other nicotine-induced disorders",
              label: "(F17.218) Nicotine dependence, cigarettes, with other nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.219) Nicotine dependence, cigarettes, with unspecified nicotine-induced disorders",
              label: "(F17.219) Nicotine dependence, cigarettes, with unspecified nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.220) Nicotine dependence, chewing tobacco, uncomplicated",
              label: "(F17.220) Nicotine dependence, chewing tobacco, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.221) Nicotine dependence, chewing tobacco, in remission",
              label: "(F17.221) Nicotine dependence, chewing tobacco, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.223) Nicotine dependence, chewing tobacco, with withdrawal",
              label: "(F17.223) Nicotine dependence, chewing tobacco, with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.228) Nicotine dependence, chewing tobacco, with other nicotine-induced disorders",
              label: "(F17.228) Nicotine dependence, chewing tobacco, with other nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.229) Nicotine dependence, chewing tobacco, with unspecified nicotine-induced disorders",
              label: "(F17.229) Nicotine dependence, chewing tobacco, with unspecified nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.290) Nicotine dependence, other tobacco product, uncomplicated",
              label: "(F17.290) Nicotine dependence, other tobacco product, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.291) Nicotine dependence, other tobacco product, in remission",
              label: "(F17.291) Nicotine dependence, other tobacco product, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.293) Nicotine dependence, other tobacco product, with withdrawal",
              label: "(F17.293) Nicotine dependence, other tobacco product, with withdrawal",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.298) Nicotine dependence, other tobacco product, with other nicotine-induced disorders",
              label: "(F17.298) Nicotine dependence, other tobacco product, with other nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F17.299) Nicotine dependence, other tobacco product, with unspecified nicotine-induced disorders",
              label: "(F17.299) Nicotine dependence, other tobacco product, with unspecified nicotine-induced disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.10) Inhalant abuse, uncomplicated",
              label: "(F18.10) Inhalant abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.11) Inhalant abuse, in remission",
              label: "(F18.11) Inhalant abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.120) Inhalant abuse with intoxication, uncomplicated",
              label: "(F18.120) Inhalant abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.121) Inhalant abuse with intoxication delirium",
              label: "(F18.121) Inhalant abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.129) Inhalant abuse with intoxication, unspecified",
              label: "(F18.129) Inhalant abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.14) Inhalant abuse with inhalant-induced mood disorder",
              label: "(F18.14) Inhalant abuse with inhalant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.150) Inhalant abuse with inhalant-induced psychotic disorder with delusions",
              label: "(F18.150) Inhalant abuse with inhalant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.151) Inhalant abuse with inhalant-induced psychotic disorder with hallucinations",
              label: "(F18.151) Inhalant abuse with inhalant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.159) Inhalant abuse with inhalant-induced psychotic disorder, unspecified",
              label: "(F18.159) Inhalant abuse with inhalant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.17) Inhalant abuse with inhalant-induced dementia",
              label: "(F18.17) Inhalant abuse with inhalant-induced dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.180) Inhalant abuse with inhalant-induced anxiety disorder",
              label: "(F18.180) Inhalant abuse with inhalant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.188) Inhalant abuse with other inhalant-induced disorder",
              label: "(F18.188) Inhalant abuse with other inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.19) Inhalant abuse with unspecified inhalant-induced disorder",
              label: "(F18.19) Inhalant abuse with unspecified inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.20) Inhalant dependence, uncomplicated",
              label: "(F18.20) Inhalant dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.21) Inhalant dependence, in remission",
              label: "(F18.21) Inhalant dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.220) Inhalant dependence with intoxication, uncomplicated",
              label: "(F18.220) Inhalant dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.221) Inhalant dependence with intoxication delirium",
              label: "(F18.221) Inhalant dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.229) Inhalant dependence with intoxication, unspecified",
              label: "(F18.229) Inhalant dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.24) Inhalant dependence with inhalant-induced mood disorder",
              label: "(F18.24) Inhalant dependence with inhalant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.250) Inhalant dependence with inhalant-induced psychotic disorder with delusions",
              label: "(F18.250) Inhalant dependence with inhalant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.251) Inhalant dependence with inhalant-induced psychotic disorder with hallucinations",
              label: "(F18.251) Inhalant dependence with inhalant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.259) Inhalant dependence with inhalant-induced psychotic disorder, unspecified",
              label: "(F18.259) Inhalant dependence with inhalant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.27) Inhalant dependence with inhalant-induced dementia",
              label: "(F18.27) Inhalant dependence with inhalant-induced dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.280) Inhalant dependence with inhalant-induced anxiety disorder",
              label: "(F18.280) Inhalant dependence with inhalant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.288) Inhalant dependence with other inhalant-induced disorder",
              label: "(F18.288) Inhalant dependence with other inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.29) Inhalant dependence with unspecified inhalant-induced disorder",
              label: "(F18.29) Inhalant dependence with unspecified inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.90) Inhalant use, unspecified, uncomplicated",
              label: "(F18.90) Inhalant use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.91) Inhalant use, unspecified, in remission",
              label: "(F18.91) Inhalant use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.920) Inhalant use, unspecified with intoxication, uncomplicated",
              label: "(F18.920) Inhalant use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.921) Inhalant use, unspecified with intoxication with delirium",
              label: "(F18.921) Inhalant use, unspecified with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.929) Inhalant use, unspecified with intoxication, unspecified",
              label: "(F18.929) Inhalant use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.94) Inhalant use, unspecified with inhalant-induced mood disorder",
              label: "(F18.94) Inhalant use, unspecified with inhalant-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.950) Inhalant use, unspecified with inhalant-induced psychotic disorder with delusions",
              label: "(F18.950) Inhalant use, unspecified with inhalant-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.951) Inhalant use, unspecified with inhalant-induced psychotic disorder with hallucinations",
              label: "(F18.951) Inhalant use, unspecified with inhalant-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.959) Inhalant use, unspecified with inhalant-induced psychotic disorder, unspecified",
              label: "(F18.959) Inhalant use, unspecified with inhalant-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.97) Inhalant use, unspecified with inhalant-induced persisting dementia",
              label: "(F18.97) Inhalant use, unspecified with inhalant-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.980) Inhalant use, unspecified with inhalant-induced anxiety disorder",
              label: "(F18.980) Inhalant use, unspecified with inhalant-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.988) Inhalant use, unspecified with other inhalant-induced disorder",
              label: "(F18.988) Inhalant use, unspecified with other inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F18.99) Inhalant use, unspecified with unspecified inhalant-induced disorder",
              label: "(F18.99) Inhalant use, unspecified with unspecified inhalant-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.10) Other psychoactive substance abuse, uncomplicated",
              label: "(F19.10) Other psychoactive substance abuse, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.11) Other psychoactive substance abuse, in remission",
              label: "(F19.11) Other psychoactive substance abuse, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.120) Other psychoactive substance abuse with intoxication, uncomplicated",
              label: "(F19.120) Other psychoactive substance abuse with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.121) Other psychoactive substance abuse with intoxication delirium",
              label: "(F19.121) Other psychoactive substance abuse with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.122) Other psychoactive substance abuse with intoxication with perceptual disturbances",
              label: "(F19.122) Other psychoactive substance abuse with intoxication with perceptual disturbances",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.129) Other psychoactive substance abuse with intoxication, unspecified",
              label: "(F19.129) Other psychoactive substance abuse with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.130) Other psychoactive substance abuse with withdrawal, uncomplicated",
              label: "(F19.130) Other psychoactive substance abuse with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.131) Other psychoactive substance abuse with withdrawal delirium",
              label: "(F19.131) Other psychoactive substance abuse with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.132) Other psychoactive substance abuse with withdrawal with perceptual disturbance",
              label: "(F19.132) Other psychoactive substance abuse with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.139) Other psychoactive substance abuse with withdrawal, unspecified",
              label: "(F19.139) Other psychoactive substance abuse with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.14) Other psychoactive substance abuse with psychoactive substance-induced mood disorder",
              label: "(F19.14) Other psychoactive substance abuse with psychoactive substance-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.150) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with delusions",
              label: "(F19.150) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.151) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with hallucinations",
              label: "(F19.151) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.159) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder, unspecified",
              label: "(F19.159) Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.16) Other psychoactive substance abuse with psychoactive substance-induced persisting amnestic disorder",
              label: "(F19.16) Other psychoactive substance abuse with psychoactive substance-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.17) Other psychoactive substance abuse with psychoactive substance-induced persisting dementia",
              label: "(F19.17) Other psychoactive substance abuse with psychoactive substance-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.180) Other psychoactive substance abuse with psychoactive substance-induced anxiety disorder",
              label: "(F19.180) Other psychoactive substance abuse with psychoactive substance-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.181) Other psychoactive substance abuse with psychoactive substance-induced sexual dysfunction",
              label: "(F19.181) Other psychoactive substance abuse with psychoactive substance-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.182) Other psychoactive substance abuse with psychoactive substance-induced sleep disorder",
              label: "(F19.182) Other psychoactive substance abuse with psychoactive substance-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.188) Other psychoactive substance abuse with other psychoactive substance-induced disorder",
              label: "(F19.188) Other psychoactive substance abuse with other psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.19) Other psychoactive substance abuse with unspecified psychoactive substance-induced disorder",
              label: "(F19.19) Other psychoactive substance abuse with unspecified psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.20) Other psychoactive substance dependence, uncomplicated",
              label: "(F19.20) Other psychoactive substance dependence, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.21) Other psychoactive substance dependence, in remission",
              label: "(F19.21) Other psychoactive substance dependence, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.220) Other psychoactive substance dependence with intoxication, uncomplicated",
              label: "(F19.220) Other psychoactive substance dependence with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.221) Other psychoactive substance dependence with intoxication delirium",
              label: "(F19.221) Other psychoactive substance dependence with intoxication delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.222) Other psychoactive substance dependence with intoxication with perceptual disturbance",
              label: "(F19.222) Other psychoactive substance dependence with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.229) Other psychoactive substance dependence with intoxication, unspecified",
              label: "(F19.229) Other psychoactive substance dependence with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.230) Other psychoactive substance dependence with withdrawal, uncomplicated",
              label: "(F19.230) Other psychoactive substance dependence with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.231) Other psychoactive substance dependence with withdrawal delirium",
              label: "(F19.231) Other psychoactive substance dependence with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.232) Other psychoactive substance dependence with withdrawal with perceptual disturbance",
              label: "(F19.232) Other psychoactive substance dependence with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.239) Other psychoactive substance dependence with withdrawal, unspecified",
              label: "(F19.239) Other psychoactive substance dependence with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.24) Other psychoactive substance dependence with psychoactive substance-induced mood disorder",
              label: "(F19.24) Other psychoactive substance dependence with psychoactive substance-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.250) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with delusions",
              label: "(F19.250) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.251) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with hallucinations",
              label: "(F19.251) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.259) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder, unspecified",
              label: "(F19.259) Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.26) Other psychoactive substance dependence with psychoactive substance-induced persisting amnestic disorder",
              label: "(F19.26) Other psychoactive substance dependence with psychoactive substance-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.27) Other psychoactive substance dependence with psychoactive substance-induced persisting dementia",
              label: "(F19.27) Other psychoactive substance dependence with psychoactive substance-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.280) Other psychoactive substance dependence with psychoactive substance-induced anxiety disorder",
              label: "(F19.280) Other psychoactive substance dependence with psychoactive substance-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.281) Other psychoactive substance dependence with psychoactive substance-induced sexual dysfunction",
              label: "(F19.281) Other psychoactive substance dependence with psychoactive substance-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.282) Other psychoactive substance dependence with psychoactive substance-induced sleep disorder",
              label: "(F19.282) Other psychoactive substance dependence with psychoactive substance-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.288) Other psychoactive substance dependence with other psychoactive substance-induced disorder",
              label: "(F19.288) Other psychoactive substance dependence with other psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.29) Other psychoactive substance dependence with unspecified psychoactive substance-induced disorder",
              label: "(F19.29) Other psychoactive substance dependence with unspecified psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.90) Other psychoactive substance use, unspecified, uncomplicated",
              label: "(F19.90) Other psychoactive substance use, unspecified, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.91) Other psychoactive substance use, unspecified, in remission",
              label: "(F19.91) Other psychoactive substance use, unspecified, in remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.920) Other psychoactive substance use, unspecified with intoxication, uncomplicated",
              label: "(F19.920) Other psychoactive substance use, unspecified with intoxication, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.921) Other psychoactive substance use, unspecified with intoxication with delirium",
              label: "(F19.921) Other psychoactive substance use, unspecified with intoxication with delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.922) Other psychoactive substance use, unspecified with intoxication with perceptual disturbance",
              label: "(F19.922) Other psychoactive substance use, unspecified with intoxication with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.929) Other psychoactive substance use, unspecified with intoxication, unspecified",
              label: "(F19.929) Other psychoactive substance use, unspecified with intoxication, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.930) Other psychoactive substance use, unspecified with withdrawal, uncomplicated",
              label: "(F19.930) Other psychoactive substance use, unspecified with withdrawal, uncomplicated",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.931) Other psychoactive substance use, unspecified with withdrawal delirium",
              label: "(F19.931) Other psychoactive substance use, unspecified with withdrawal delirium",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.932) Other psychoactive substance use, unspecified with withdrawal with perceptual disturbance",
              label: "(F19.932) Other psychoactive substance use, unspecified with withdrawal with perceptual disturbance",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.939) Other psychoactive substance use, unspecified with withdrawal, unspecified",
              label: "(F19.939) Other psychoactive substance use, unspecified with withdrawal, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.94) Other psychoactive substance use, unspecified with psychoactive substance-induced mood disorder",
              label: "(F19.94) Other psychoactive substance use, unspecified with psychoactive substance-induced mood disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.950) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with delusions",
              label: "(F19.950) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with delusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.951) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with hallucinations",
              label: "(F19.951) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with hallucinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.959) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder, unspecified",
              label: "(F19.959) Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.96) Other psychoactive substance use, unspecified with psychoactive substance-induced persisting amnestic disorder",
              label: "(F19.96) Other psychoactive substance use, unspecified with psychoactive substance-induced persisting amnestic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.97) Other psychoactive substance use, unspecified with psychoactive substance-induced persisting dementia",
              label: "(F19.97) Other psychoactive substance use, unspecified with psychoactive substance-induced persisting dementia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.980) Other psychoactive substance use, unspecified with psychoactive substance-induced anxiety disorder",
              label: "(F19.980) Other psychoactive substance use, unspecified with psychoactive substance-induced anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.981) Other psychoactive substance use, unspecified with psychoactive substance-induced sexual dysfunction",
              label: "(F19.981) Other psychoactive substance use, unspecified with psychoactive substance-induced sexual dysfunction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.982) Other psychoactive substance use, unspecified with psychoactive substance-induced sleep disorder",
              label: "(F19.982) Other psychoactive substance use, unspecified with psychoactive substance-induced sleep disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.988) Other psychoactive substance use, unspecified with other psychoactive substance-induced disorder",
              label: "(F19.988) Other psychoactive substance use, unspecified with other psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F19.99) Other psychoactive substance use, unspecified with unspecified psychoactive substance-induced disorder",
              label: "(F19.99) Other psychoactive substance use, unspecified with unspecified psychoactive substance-induced disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.0) Paranoid schizophrenia",
              label: "(F20.0) Paranoid schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.1) Disorganized schizophrenia",
              label: "(F20.1) Disorganized schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.2) Catatonic schizophrenia",
              label: "(F20.2) Catatonic schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.3) Undifferentiated schizophrenia",
              label: "(F20.3) Undifferentiated schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.5) Residual schizophrenia",
              label: "(F20.5) Residual schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.81) Schizophreniform disorder",
              label: "(F20.81) Schizophreniform disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.89) Other schizophrenia",
              label: "(F20.89) Other schizophrenia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F20.9) Schizophrenia, unspecified",
              label: "(F20.9) Schizophrenia, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F21.) Schizotypal disorder",
              label: "(F21.) Schizotypal disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F22.) Delusional disorders",
              label: "(F22.) Delusional disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F23.) Brief psychotic disorder",
              label: "(F23.) Brief psychotic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F24.) Shared psychotic disorder",
              label: "(F24.) Shared psychotic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F25.0) Schizoaffective disorder, bipolar type",
              label: "(F25.0) Schizoaffective disorder, bipolar type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F25.1) Schizoaffective disorder, depressive type",
              label: "(F25.1) Schizoaffective disorder, depressive type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F25.8) Other schizoaffective disorders",
              label: "(F25.8) Other schizoaffective disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F25.9) Schizoaffective disorder, unspecified",
              label: "(F25.9) Schizoaffective disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F28.) Other psychotic disorder not due to a substance or known physiological condition",
              label: "(F28.) Other psychotic disorder not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F29.) Unspecified psychosis not due to a substance or known physiological condition",
              label: "(F29.) Unspecified psychosis not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.10) Manic episode without psychotic symptoms, unspecified",
              label: "(F30.10) Manic episode without psychotic symptoms, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.11) Manic episode without psychotic symptoms, mild",
              label: "(F30.11) Manic episode without psychotic symptoms, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.12) Manic episode without psychotic symptoms, moderate",
              label: "(F30.12) Manic episode without psychotic symptoms, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.13) Manic episode, severe, without psychotic symptoms",
              label: "(F30.13) Manic episode, severe, without psychotic symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.2) Manic episode, severe with psychotic symptoms",
              label: "(F30.2) Manic episode, severe with psychotic symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.3) Manic episode in partial remission",
              label: "(F30.3) Manic episode in partial remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.4) Manic episode in full remission",
              label: "(F30.4) Manic episode in full remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.8) Other manic episodes",
              label: "(F30.8) Other manic episodes",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F30.9) Manic episode, unspecified",
              label: "(F30.9) Manic episode, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.0) Bipolar disorder, current episode hypomanic",
              label: "(F31.0) Bipolar disorder, current episode hypomanic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.10) Bipolar disorder, current episode manic without psychotic features, unspecified",
              label: "(F31.10) Bipolar disorder, current episode manic without psychotic features, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.11) Bipolar disorder, current episode manic without psychotic features, mild",
              label: "(F31.11) Bipolar disorder, current episode manic without psychotic features, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.12) Bipolar disorder, current episode manic without psychotic features, moderate",
              label: "(F31.12) Bipolar disorder, current episode manic without psychotic features, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.13) Bipolar disorder, current episode manic without psychotic features, severe",
              label: "(F31.13) Bipolar disorder, current episode manic without psychotic features, severe",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.2) Bipolar disorder, current episode manic severe with psychotic features",
              label: "(F31.2) Bipolar disorder, current episode manic severe with psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.30) Bipolar disorder, current episode depressed, mild or moderate severity, unspecified",
              label: "(F31.30) Bipolar disorder, current episode depressed, mild or moderate severity, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.31) Bipolar disorder, current episode depressed, mild",
              label: "(F31.31) Bipolar disorder, current episode depressed, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.32) Bipolar disorder, current episode depressed, moderate",
              label: "(F31.32) Bipolar disorder, current episode depressed, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.4) Bipolar disorder, current episode depressed, severe, without psychotic features",
              label: "(F31.4) Bipolar disorder, current episode depressed, severe, without psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.5) Bipolar disorder, current episode depressed, severe, with psychotic features",
              label: "(F31.5) Bipolar disorder, current episode depressed, severe, with psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.60) Bipolar disorder, current episode mixed, unspecified",
              label: "(F31.60) Bipolar disorder, current episode mixed, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.61) Bipolar disorder, current episode mixed, mild",
              label: "(F31.61) Bipolar disorder, current episode mixed, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.62) Bipolar disorder, current episode mixed, moderate",
              label: "(F31.62) Bipolar disorder, current episode mixed, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.63) Bipolar disorder, current episode mixed, severe, without psychotic features",
              label: "(F31.63) Bipolar disorder, current episode mixed, severe, without psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.64) Bipolar disorder, current episode mixed, severe, with psychotic features",
              label: "(F31.64) Bipolar disorder, current episode mixed, severe, with psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.70) Bipolar disorder, currently in remission, most recent episode unspecified",
              label: "(F31.70) Bipolar disorder, currently in remission, most recent episode unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.71) Bipolar disorder, in partial remission, most recent episode hypomanic",
              label: "(F31.71) Bipolar disorder, in partial remission, most recent episode hypomanic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.72) Bipolar disorder, in full remission, most recent episode hypomanic",
              label: "(F31.72) Bipolar disorder, in full remission, most recent episode hypomanic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.73) Bipolar disorder, in partial remission, most recent episode manic",
              label: "(F31.73) Bipolar disorder, in partial remission, most recent episode manic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.74) Bipolar disorder, in full remission, most recent episode manic",
              label: "(F31.74) Bipolar disorder, in full remission, most recent episode manic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.75) Bipolar disorder, in partial remission, most recent episode depressed",
              label: "(F31.75) Bipolar disorder, in partial remission, most recent episode depressed",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.76) Bipolar disorder, in full remission, most recent episode depressed",
              label: "(F31.76) Bipolar disorder, in full remission, most recent episode depressed",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.77) Bipolar disorder, in partial remission, most recent episode mixed",
              label: "(F31.77) Bipolar disorder, in partial remission, most recent episode mixed",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.78) Bipolar disorder, in full remission, most recent episode mixed",
              label: "(F31.78) Bipolar disorder, in full remission, most recent episode mixed",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.81) Bipolar II disorder",
              label: "(F31.81) Bipolar II disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.89) Other bipolar disorder",
              label: "(F31.89) Other bipolar disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F31.9) Bipolar disorder, unspecified",
              label: "(F31.9) Bipolar disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.0) Major depressive disorder, single episode, mild",
              label: "(F32.0) Major depressive disorder, single episode, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.1) Major depressive disorder, single episode, moderate",
              label: "(F32.1) Major depressive disorder, single episode, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.2) Major depressive disorder, single episode, severe without psychotic features",
              label: "(F32.2) Major depressive disorder, single episode, severe without psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.3) Major depressive disorder, single episode, severe with psychotic features",
              label: "(F32.3) Major depressive disorder, single episode, severe with psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.4) Major depressive disorder, single episode, in partial remission",
              label: "(F32.4) Major depressive disorder, single episode, in partial remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.5) Major depressive disorder, single episode, in full remission",
              label: "(F32.5) Major depressive disorder, single episode, in full remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.81) Premenstrual dysphoric disorder",
              label: "(F32.81) Premenstrual dysphoric disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.89) Other specified depressive episodes",
              label: "(F32.89) Other specified depressive episodes",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.9) Major depressive disorder, single episode, unspecified",
              label: "(F32.9) Major depressive disorder, single episode, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F32.A) Depression, unspecified",
              label: "(F32.A) Depression, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.0) Major depressive disorder, recurrent, mild",
              label: "(F33.0) Major depressive disorder, recurrent, mild",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.1) Major depressive disorder, recurrent, moderate",
              label: "(F33.1) Major depressive disorder, recurrent, moderate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.2) Major depressive disorder, recurrent severe without psychotic features",
              label: "(F33.2) Major depressive disorder, recurrent severe without psychotic features",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.3) Major depressive disorder, recurrent, severe with psychotic symptoms",
              label: "(F33.3) Major depressive disorder, recurrent, severe with psychotic symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.40) Major depressive disorder, recurrent, in remission, unspecified",
              label: "(F33.40) Major depressive disorder, recurrent, in remission, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.41) Major depressive disorder, recurrent, in partial remission",
              label: "(F33.41) Major depressive disorder, recurrent, in partial remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.42) Major depressive disorder, recurrent, in full remission",
              label: "(F33.42) Major depressive disorder, recurrent, in full remission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.8) Other recurrent depressive disorders",
              label: "(F33.8) Other recurrent depressive disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F33.9) Major depressive disorder, recurrent, unspecified",
              label: "(F33.9) Major depressive disorder, recurrent, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F34.0) Cyclothymic disorder",
              label: "(F34.0) Cyclothymic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F34.1) Dysthymic disorder",
              label: "(F34.1) Dysthymic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F34.81) Disruptive mood dysregulation disorder",
              label: "(F34.81) Disruptive mood dysregulation disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F34.89) Other specified persistent mood disorders",
              label: "(F34.89) Other specified persistent mood disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F34.9) Persistent mood [affective] disorder, unspecified",
              label: "(F34.9) Persistent mood [affective] disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F39.) Unspecified mood [affective] disorder",
              label: "(F39.) Unspecified mood [affective] disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.00) Agoraphobia, unspecified",
              label: "(F40.00) Agoraphobia, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.01) Agoraphobia with panic disorder",
              label: "(F40.01) Agoraphobia with panic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.02) Agoraphobia without panic disorder",
              label: "(F40.02) Agoraphobia without panic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.10) Social phobia, unspecified",
              label: "(F40.10) Social phobia, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.11) Social phobia, generalized",
              label: "(F40.11) Social phobia, generalized",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.210) Arachnophobia",
              label: "(F40.210) Arachnophobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.218) Other animal type phobia",
              label: "(F40.218) Other animal type phobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.220) Fear of thunderstorms",
              label: "(F40.220) Fear of thunderstorms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.228) Other natural environment type phobia",
              label: "(F40.228) Other natural environment type phobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.230) Fear of blood",
              label: "(F40.230) Fear of blood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.231) Fear of injections and transfusions",
              label: "(F40.231) Fear of injections and transfusions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.232) Fear of other medical care",
              label: "(F40.232) Fear of other medical care",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.233) Fear of injury",
              label: "(F40.233) Fear of injury",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.240) Claustrophobia",
              label: "(F40.240) Claustrophobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.241) Acrophobia",
              label: "(F40.241) Acrophobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.242) Fear of bridges",
              label: "(F40.242) Fear of bridges",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.243) Fear of flying",
              label: "(F40.243) Fear of flying",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.248) Other situational type phobia",
              label: "(F40.248) Other situational type phobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.290) Androphobia",
              label: "(F40.290) Androphobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.291) Gynephobia",
              label: "(F40.291) Gynephobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.298) Other specified phobia",
              label: "(F40.298) Other specified phobia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.8) Other phobic anxiety disorders",
              label: "(F40.8) Other phobic anxiety disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F40.9) Phobic anxiety disorder, unspecified",
              label: "(F40.9) Phobic anxiety disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F41.0) Panic disorder [episodic paroxysmal anxiety]",
              label: "(F41.0) Panic disorder [episodic paroxysmal anxiety]",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F41.1) Generalized anxiety disorder",
              label: "(F41.1) Generalized anxiety disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F41.3) Other mixed anxiety disorders",
              label: "(F41.3) Other mixed anxiety disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F41.8) Other specified anxiety disorders",
              label: "(F41.8) Other specified anxiety disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F41.9) Anxiety disorder, unspecified",
              label: "(F41.9) Anxiety disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F42.2) Mixed obsessional thoughts and acts",
              label: "(F42.2) Mixed obsessional thoughts and acts",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F42.3) Hoarding disorder",
              label: "(F42.3) Hoarding disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F42.4) Excoriation (skin-picking) disorder",
              label: "(F42.4) Excoriation (skin-picking) disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F42.8) Other obsessive-compulsive disorder",
              label: "(F42.8) Other obsessive-compulsive disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F42.9) Obsessive-compulsive disorder, unspecified",
              label: "(F42.9) Obsessive-compulsive disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.0) Acute stress reaction",
              label: "(F43.0) Acute stress reaction",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.10) Post-traumatic stress disorder, unspecified",
              label: "(F43.10) Post-traumatic stress disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.11) Post-traumatic stress disorder, acute",
              label: "(F43.11) Post-traumatic stress disorder, acute",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.12) Post-traumatic stress disorder, chronic",
              label: "(F43.12) Post-traumatic stress disorder, chronic",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.20) Adjustment disorder, unspecified",
              label: "(F43.20) Adjustment disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.21) Adjustment disorder with depressed mood",
              label: "(F43.21) Adjustment disorder with depressed mood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.22) Adjustment disorder with anxiety",
              label: "(F43.22) Adjustment disorder with anxiety",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.23) Adjustment disorder with mixed anxiety and depressed mood",
              label: "(F43.23) Adjustment disorder with mixed anxiety and depressed mood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.24) Adjustment disorder with disturbance of conduct",
              label: "(F43.24) Adjustment disorder with disturbance of conduct",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.25) Adjustment disorder with mixed disturbance of emotions and conduct",
              label: "(F43.25) Adjustment disorder with mixed disturbance of emotions and conduct",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.29) Adjustment disorder with other symptoms",
              label: "(F43.29) Adjustment disorder with other symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.81) Prolonged grief disorder",
              label: "(F43.81) Prolonged grief disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.89) Other reactions to severe stress",
              label: "(F43.89) Other reactions to severe stress",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F43.9) Reaction to severe stress, unspecified",
              label: "(F43.9) Reaction to severe stress, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.0) Dissociative amnesia",
              label: "(F44.0) Dissociative amnesia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.1) Dissociative fugue",
              label: "(F44.1) Dissociative fugue",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.2) Dissociative stupor",
              label: "(F44.2) Dissociative stupor",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.4) Conversion disorder with motor symptom or deficit",
              label: "(F44.4) Conversion disorder with motor symptom or deficit",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.5) Conversion disorder with seizures or convulsions",
              label: "(F44.5) Conversion disorder with seizures or convulsions",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.6) Conversion disorder with sensory symptom or deficit",
              label: "(F44.6) Conversion disorder with sensory symptom or deficit",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.7) Conversion disorder with mixed symptom presentation",
              label: "(F44.7) Conversion disorder with mixed symptom presentation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.81) Dissociative identity disorder",
              label: "(F44.81) Dissociative identity disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.89) Other dissociative and conversion disorders",
              label: "(F44.89) Other dissociative and conversion disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F44.9) Dissociative and conversion disorder, unspecified",
              label: "(F44.9) Dissociative and conversion disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.0) Somatization disorder",
              label: "(F45.0) Somatization disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.1) Undifferentiated somatoform disorder",
              label: "(F45.1) Undifferentiated somatoform disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.20) Hypochondriacal disorder, unspecified",
              label: "(F45.20) Hypochondriacal disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.21) Hypochondriasis",
              label: "(F45.21) Hypochondriasis",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.22) Body dysmorphic disorder",
              label: "(F45.22) Body dysmorphic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.29) Other hypochondriacal disorders",
              label: "(F45.29) Other hypochondriacal disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.41) Pain disorder exclusively related to psychological factors",
              label: "(F45.41) Pain disorder exclusively related to psychological factors",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.42) Pain disorder with related psychological factors",
              label: "(F45.42) Pain disorder with related psychological factors",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.8) Other somatoform disorders",
              label: "(F45.8) Other somatoform disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F45.9) Somatoform disorder, unspecified",
              label: "(F45.9) Somatoform disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F48.1) Depersonalization-derealization syndrome",
              label: "(F48.1) Depersonalization-derealization syndrome",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F48.2) Pseudobulbar affect",
              label: "(F48.2) Pseudobulbar affect",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F48.8) Other specified nonpsychotic mental disorders",
              label: "(F48.8) Other specified nonpsychotic mental disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F48.9) Nonpsychotic mental disorder, unspecified",
              label: "(F48.9) Nonpsychotic mental disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.00) Anorexia nervosa, unspecified",
              label: "(F50.00) Anorexia nervosa, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.01) Anorexia nervosa, restricting type",
              label: "(F50.01) Anorexia nervosa, restricting type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.02) Anorexia nervosa, binge eating/purging type",
              label: "(F50.02) Anorexia nervosa, binge eating/purging type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.2) Bulimia nervosa",
              label: "(F50.2) Bulimia nervosa",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.81) Binge eating disorder",
              label: "(F50.81) Binge eating disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.82) Avoidant/restrictive food intake disorder",
              label: "(F50.82) Avoidant/restrictive food intake disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.89) Other specified eating disorder",
              label: "(F50.89) Other specified eating disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F50.9) Eating disorder, unspecified",
              label: "(F50.9) Eating disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.01) Primary insomnia",
              label: "(F51.01) Primary insomnia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.02) Adjustment insomnia",
              label: "(F51.02) Adjustment insomnia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.03) Paradoxical insomnia",
              label: "(F51.03) Paradoxical insomnia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.04) Psychophysiologic insomnia",
              label: "(F51.04) Psychophysiologic insomnia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.05) Insomnia due to other mental disorder",
              label: "(F51.05) Insomnia due to other mental disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.09) Other insomnia not due to a substance or known physiological condition",
              label: "(F51.09) Other insomnia not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.11) Primary hypersomnia",
              label: "(F51.11) Primary hypersomnia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.12) Insufficient sleep syndrome",
              label: "(F51.12) Insufficient sleep syndrome",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.13) Hypersomnia due to other mental disorder",
              label: "(F51.13) Hypersomnia due to other mental disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.19) Other hypersomnia not due to a substance or known physiological condition",
              label: "(F51.19) Other hypersomnia not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.3) Sleepwalking [somnambulism]",
              label: "(F51.3) Sleepwalking [somnambulism]",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.4) Sleep terrors [night terrors]",
              label: "(F51.4) Sleep terrors [night terrors]",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.5) Nightmare disorder",
              label: "(F51.5) Nightmare disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.8) Other sleep disorders not due to a substance or known physiological condition",
              label: "(F51.8) Other sleep disorders not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F51.9) Sleep disorder not due to a substance or known physiological condition, unspecified",
              label: "(F51.9) Sleep disorder not due to a substance or known physiological condition, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.0) Hypoactive sexual desire disorder",
              label: "(F52.0) Hypoactive sexual desire disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.1) Sexual aversion disorder",
              label: "(F52.1) Sexual aversion disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.21) Male erectile disorder",
              label: "(F52.21) Male erectile disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.22) Female sexual arousal disorder",
              label: "(F52.22) Female sexual arousal disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.31) Female orgasmic disorder",
              label: "(F52.31) Female orgasmic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.32) Male orgasmic disorder",
              label: "(F52.32) Male orgasmic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.4) Premature ejaculation",
              label: "(F52.4) Premature ejaculation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.5) Vaginismus not due to a substance or known physiological condition",
              label: "(F52.5) Vaginismus not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.6) Dyspareunia not due to a substance or known physiological condition",
              label: "(F52.6) Dyspareunia not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.8) Other sexual dysfunction not due to a substance or known physiological condition",
              label: "(F52.8) Other sexual dysfunction not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F52.9) Unspecified sexual dysfunction not due to a substance or known physiological condition",
              label: "(F52.9) Unspecified sexual dysfunction not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F53.0) Postpartum depression",
              label: "(F53.0) Postpartum depression",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F53.1) Puerperal psychosis",
              label: "(F53.1) Puerperal psychosis",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F54.) Psychological and behavioral factors associated with disorders or diseases classified elsewhere",
              label: "(F54.) Psychological and behavioral factors associated with disorders or diseases classified elsewhere",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.0) Abuse of antacids",
              label: "(F55.0) Abuse of antacids",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.1) Abuse of herbal or folk remedies",
              label: "(F55.1) Abuse of herbal or folk remedies",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.2) Abuse of laxatives",
              label: "(F55.2) Abuse of laxatives",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.3) Abuse of steroids or hormones",
              label: "(F55.3) Abuse of steroids or hormones",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.4) Abuse of vitamins",
              label: "(F55.4) Abuse of vitamins",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F55.8) Abuse of other non-psychoactive substances",
              label: "(F55.8) Abuse of other non-psychoactive substances",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F59.) Unspecified behavioral syndromes associated with physiological disturbances and physical factors",
              label: "(F59.) Unspecified behavioral syndromes associated with physiological disturbances and physical factors",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.0) Paranoid personality disorder",
              label: "(F60.0) Paranoid personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.1) Schizoid personality disorder",
              label: "(F60.1) Schizoid personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.2) Antisocial personality disorder",
              label: "(F60.2) Antisocial personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.3) Borderline personality disorder",
              label: "(F60.3) Borderline personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.4) Histrionic personality disorder",
              label: "(F60.4) Histrionic personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.5) Obsessive-compulsive personality disorder",
              label: "(F60.5) Obsessive-compulsive personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.6) Avoidant personality disorder",
              label: "(F60.6) Avoidant personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.7) Dependent personality disorder",
              label: "(F60.7) Dependent personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.81) Narcissistic personality disorder",
              label: "(F60.81) Narcissistic personality disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.89) Other specific personality disorders",
              label: "(F60.89) Other specific personality disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F60.9) Personality disorder, unspecified",
              label: "(F60.9) Personality disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.0) Pathological gambling",
              label: "(F63.0) Pathological gambling",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.1) Pyromania",
              label: "(F63.1) Pyromania",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.2) Kleptomania",
              label: "(F63.2) Kleptomania",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.3) Trichotillomania",
              label: "(F63.3) Trichotillomania",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.81) Intermittent explosive disorder",
              label: "(F63.81) Intermittent explosive disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.89) Other impulse disorders",
              label: "(F63.89) Other impulse disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F63.9) Impulse disorder, unspecified",
              label: "(F63.9) Impulse disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F64.0) Transsexualism",
              label: "(F64.0) Transsexualism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F64.1) Dual role transvestism",
              label: "(F64.1) Dual role transvestism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F64.2) Gender identity disorder of childhood",
              label: "(F64.2) Gender identity disorder of childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F64.8) Other gender identity disorders",
              label: "(F64.8) Other gender identity disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F64.9) Gender identity disorder, unspecified",
              label: "(F64.9) Gender identity disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.0) Fetishism",
              label: "(F65.0) Fetishism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.1) Transvestic fetishism",
              label: "(F65.1) Transvestic fetishism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.2) Exhibitionism",
              label: "(F65.2) Exhibitionism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.3) Voyeurism",
              label: "(F65.3) Voyeurism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.4) Pedophilia",
              label: "(F65.4) Pedophilia",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.50) Sadomasochism, unspecified",
              label: "(F65.50) Sadomasochism, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.51) Sexual masochism",
              label: "(F65.51) Sexual masochism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.52) Sexual sadism",
              label: "(F65.52) Sexual sadism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.81) Frotteurism",
              label: "(F65.81) Frotteurism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.89) Other paraphilias",
              label: "(F65.89) Other paraphilias",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F65.9) Paraphilia, unspecified",
              label: "(F65.9) Paraphilia, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F66.) Other sexual disorders",
              label: "(F66.) Other sexual disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.10) Factitious disorder imposed on self, unspecified",
              label: "(F68.10) Factitious disorder imposed on self, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.11) Factitious disorder imposed on self, with predominantly psychological signs and symptoms",
              label: "(F68.11) Factitious disorder imposed on self, with predominantly psychological signs and symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.12) Factitious disorder imposed on self, with predominantly physical signs and symptoms",
              label: "(F68.12) Factitious disorder imposed on self, with predominantly physical signs and symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.13) Factitious disorder imposed on self, with combined psychological and physical signs and symptoms",
              label: "(F68.13) Factitious disorder imposed on self, with combined psychological and physical signs and symptoms",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.A) Factitious disorder imposed on another",
              label: "(F68.A) Factitious disorder imposed on another",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F68.8) Other specified disorders of adult personality and behavior",
              label: "(F68.8) Other specified disorders of adult personality and behavior",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F69.) Unspecified disorder of adult personality and behavior",
              label: "(F69.) Unspecified disorder of adult personality and behavior",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F70.) Mild intellectual disabilities",
              label: "(F70.) Mild intellectual disabilities",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F71.) Moderate intellectual disabilities",
              label: "(F71.) Moderate intellectual disabilities",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F72.) Severe intellectual disabilities",
              label: "(F72.) Severe intellectual disabilities",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F73.) Profound intellectual disabilities",
              label: "(F73.) Profound intellectual disabilities",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F78.A1) SYNGAP1-related intellectual disability",
              label: "(F78.A1) SYNGAP1-related intellectual disability",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F78.A9) Other genetic related intellectual disability",
              label: "(F78.A9) Other genetic related intellectual disability",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F79.) Unspecified intellectual disabilities",
              label: "(F79.) Unspecified intellectual disabilities",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.0) Phonological disorder",
              label: "(F80.0) Phonological disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.1) Expressive language disorder",
              label: "(F80.1) Expressive language disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.2) Mixed receptive-expressive language disorder",
              label: "(F80.2) Mixed receptive-expressive language disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.4) Speech and language development delay due to hearing loss",
              label: "(F80.4) Speech and language development delay due to hearing loss",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.81) Childhood onset fluency disorder",
              label: "(F80.81) Childhood onset fluency disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.82) Social pragmatic communication disorder",
              label: "(F80.82) Social pragmatic communication disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.89) Other developmental disorders of speech and language",
              label: "(F80.89) Other developmental disorders of speech and language",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F80.9) Developmental disorder of speech and language, unspecified",
              label: "(F80.9) Developmental disorder of speech and language, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F81.0) Specific reading disorder",
              label: "(F81.0) Specific reading disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F81.2) Mathematics disorder",
              label: "(F81.2) Mathematics disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F81.81) Disorder of written expression",
              label: "(F81.81) Disorder of written expression",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F81.89) Other developmental disorders of scholastic skills",
              label: "(F81.89) Other developmental disorders of scholastic skills",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F81.9) Developmental disorder of scholastic skills, unspecified",
              label: "(F81.9) Developmental disorder of scholastic skills, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F82.) Specific developmental disorder of motor function",
              label: "(F82.) Specific developmental disorder of motor function",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.0) Autistic disorder",
              label: "(F84.0) Autistic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.2) Rett's syndrome",
              label: "(F84.2) Rett's syndrome",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.3) Other childhood disintegrative disorder",
              label: "(F84.3) Other childhood disintegrative disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.5) Asperger's syndrome",
              label: "(F84.5) Asperger's syndrome",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.8) Other pervasive developmental disorders",
              label: "(F84.8) Other pervasive developmental disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F84.9) Pervasive developmental disorder, unspecified",
              label: "(F84.9) Pervasive developmental disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F88.) Other disorders of psychological development",
              label: "(F88.) Other disorders of psychological development",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F89.) Unspecified disorder of psychological development",
              label: "(F89.) Unspecified disorder of psychological development",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F90.0) Attention-deficit hyperactivity disorder, predominantly inattentive type",
              label: "(F90.0) Attention-deficit hyperactivity disorder, predominantly inattentive type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F90.1) Attention-deficit hyperactivity disorder, predominantly hyperactive type",
              label: "(F90.1) Attention-deficit hyperactivity disorder, predominantly hyperactive type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F90.2) Attention-deficit hyperactivity disorder, combined type",
              label: "(F90.2) Attention-deficit hyperactivity disorder, combined type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F90.8) Attention-deficit hyperactivity disorder, other type",
              label: "(F90.8) Attention-deficit hyperactivity disorder, other type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F90.9) Attention-deficit hyperactivity disorder, unspecified type",
              label: "(F90.9) Attention-deficit hyperactivity disorder, unspecified type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.0) Conduct disorder confined to family context",
              label: "(F91.0) Conduct disorder confined to family context",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.1) Conduct disorder, childhood-onset type",
              label: "(F91.1) Conduct disorder, childhood-onset type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.2) Conduct disorder, adolescent-onset type",
              label: "(F91.2) Conduct disorder, adolescent-onset type",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.3) Oppositional defiant disorder",
              label: "(F91.3) Oppositional defiant disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.8) Other conduct disorders",
              label: "(F91.8) Other conduct disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F91.9) Conduct disorder, unspecified",
              label: "(F91.9) Conduct disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F93.0) Separation anxiety disorder of childhood",
              label: "(F93.0) Separation anxiety disorder of childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F93.8) Other childhood emotional disorders",
              label: "(F93.8) Other childhood emotional disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F93.9) Childhood emotional disorder, unspecified",
              label: "(F93.9) Childhood emotional disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F94.0) Selective mutism",
              label: "(F94.0) Selective mutism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F94.1) Reactive attachment disorder of childhood",
              label: "(F94.1) Reactive attachment disorder of childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F94.2) Disinhibited attachment disorder of childhood",
              label: "(F94.2) Disinhibited attachment disorder of childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F94.8) Other childhood disorders of social functioning",
              label: "(F94.8) Other childhood disorders of social functioning",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F94.9) Childhood disorder of social functioning, unspecified",
              label: "(F94.9) Childhood disorder of social functioning, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F95.0) Transient tic disorder",
              label: "(F95.0) Transient tic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F95.1) Chronic motor or vocal tic disorder",
              label: "(F95.1) Chronic motor or vocal tic disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F95.2) Tourette's disorder",
              label: "(F95.2) Tourette's disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F95.8) Other tic disorders",
              label: "(F95.8) Other tic disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F95.9) Tic disorder, unspecified",
              label: "(F95.9) Tic disorder, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.0) Enuresis not due to a substance or known physiological condition",
              label: "(F98.0) Enuresis not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.1) Encopresis not due to a substance or known physiological condition",
              label: "(F98.1) Encopresis not due to a substance or known physiological condition",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.21) Rumination disorder of infancy",
              label: "(F98.21) Rumination disorder of infancy",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.29) Other feeding disorders of infancy and early childhood",
              label: "(F98.29) Other feeding disorders of infancy and early childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.3) Pica of infancy and childhood",
              label: "(F98.3) Pica of infancy and childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.4) Stereotyped movement disorders",
              label: "(F98.4) Stereotyped movement disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.5) Adult onset fluency disorder",
              label: "(F98.5) Adult onset fluency disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.8) Other specified behavioral and emotional disorders with onset usually occurring in childhood and adolescence",
              label: "(F98.8) Other specified behavioral and emotional disorders with onset usually occurring in childhood and adolescence",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F98.9) Unspecified behavioral and emotional disorders with onset usually occurring in childhood and adolescence",
              label: "(F98.9) Unspecified behavioral and emotional disorders with onset usually occurring in childhood and adolescence",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(F99.) Mental disorder, not otherwise specified",
              label: "(F99.) Mental disorder, not otherwise specified",
              Icon: MenuAlt2Icon,
            },
            { value: "() ", label: "() ", Icon: MenuAlt2Icon },
            {
              value: "(Z00.00) Encounter for general adult medical examination without abnormal findings",
              label: "(Z00.00) Encounter for general adult medical examination without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.01) Encounter for general adult medical examination with abnormal findings",
              label: "(Z00.01) Encounter for general adult medical examination with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.110) Health examination for newborn under 8 days old",
              label: "(Z00.110) Health examination for newborn under 8 days old",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.111) Health examination for newborn 8 to 28 days old",
              label: "(Z00.111) Health examination for newborn 8 to 28 days old",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.121) Encounter for routine child health examination with abnormal findings",
              label: "(Z00.121) Encounter for routine child health examination with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.129) Encounter for routine child health examination without abnormal findings",
              label: "(Z00.129) Encounter for routine child health examination without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.2) Encounter for examination for period of rapid growth in childhood",
              label: "(Z00.2) Encounter for examination for period of rapid growth in childhood",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.3) Encounter for examination for adolescent development state",
              label: "(Z00.3) Encounter for examination for adolescent development state",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.5) Encounter for examination of potential donor of organ and tissue",
              label: "(Z00.5) Encounter for examination of potential donor of organ and tissue",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.6) Encounter for examination for normal comparison and control in clinical research program",
              label: "(Z00.6) Encounter for examination for normal comparison and control in clinical research program",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.70) Encounter for examination for period of delayed growth in childhood without abnormal findings",
              label: "(Z00.70) Encounter for examination for period of delayed growth in childhood without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.71) Encounter for examination for period of delayed growth in childhood with abnormal findings",
              label: "(Z00.71) Encounter for examination for period of delayed growth in childhood with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z00.8) Encounter for other general examination",
              label: "(Z00.8) Encounter for other general examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.00) Encounter for examination of eyes and vision without abnormal findings",
              label: "(Z01.00) Encounter for examination of eyes and vision without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.01) Encounter for examination of eyes and vision with abnormal findings",
              label: "(Z01.01) Encounter for examination of eyes and vision with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.020) Encounter for examination of eyes and vision following failed vision screening without abnormal findings",
              label: "(Z01.020) Encounter for examination of eyes and vision following failed vision screening without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.021) Encounter for examination of eyes and vision following failed vision screening with abnormal findings",
              label: "(Z01.021) Encounter for examination of eyes and vision following failed vision screening with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.10) Encounter for examination of ears and hearing without abnormal findings",
              label: "(Z01.10) Encounter for examination of ears and hearing without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.110) Encounter for hearing examination following failed hearing screening",
              label: "(Z01.110) Encounter for hearing examination following failed hearing screening",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.118) Encounter for examination of ears and hearing with other abnormal findings",
              label: "(Z01.118) Encounter for examination of ears and hearing with other abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.12) Encounter for hearing conservation and treatment",
              label: "(Z01.12) Encounter for hearing conservation and treatment",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.20) Encounter for dental examination and cleaning without abnormal findings",
              label: "(Z01.20) Encounter for dental examination and cleaning without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.21) Encounter for dental examination and cleaning with abnormal findings",
              label: "(Z01.21) Encounter for dental examination and cleaning with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.30) Encounter for examination of blood pressure without abnormal findings",
              label: "(Z01.30) Encounter for examination of blood pressure without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.31) Encounter for examination of blood pressure with abnormal findings",
              label: "(Z01.31) Encounter for examination of blood pressure with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.411) Encounter for gynecological examination (general) (routine) with abnormal findings",
              label: "(Z01.411) Encounter for gynecological examination (general) (routine) with abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.419) Encounter for gynecological examination (general) (routine) without abnormal findings",
              label: "(Z01.419) Encounter for gynecological examination (general) (routine) without abnormal findings",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.42) Encounter for cervical smear to confirm findings of recent normal smear following initial abnormal smear",
              label: "(Z01.42) Encounter for cervical smear to confirm findings of recent normal smear following initial abnormal smear",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.810) Encounter for preprocedural cardiovascular examination",
              label: "(Z01.810) Encounter for preprocedural cardiovascular examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.811) Encounter for preprocedural respiratory examination",
              label: "(Z01.811) Encounter for preprocedural respiratory examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.812) Encounter for preprocedural laboratory examination",
              label: "(Z01.812) Encounter for preprocedural laboratory examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.818) Encounter for other preprocedural examination",
              label: "(Z01.818) Encounter for other preprocedural examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.82) Encounter for allergy testing",
              label: "(Z01.82) Encounter for allergy testing",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.83) Encounter for blood typing",
              label: "(Z01.83) Encounter for blood typing",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.84) Encounter for antibody response examination",
              label: "(Z01.84) Encounter for antibody response examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z01.89) Encounter for other specified special examinations",
              label: "(Z01.89) Encounter for other specified special examinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.0) Encounter for examination for admission to educational institution",
              label: "(Z02.0) Encounter for examination for admission to educational institution",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.1) Encounter for pre-employment examination",
              label: "(Z02.1) Encounter for pre-employment examination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.2) Encounter for examination for admission to residential institution",
              label: "(Z02.2) Encounter for examination for admission to residential institution",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.3) Encounter for examination for recruitment to armed forces",
              label: "(Z02.3) Encounter for examination for recruitment to armed forces",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.4) Encounter for examination for driving license",
              label: "(Z02.4) Encounter for examination for driving license",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.5) Encounter for examination for participation in sport",
              label: "(Z02.5) Encounter for examination for participation in sport",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.6) Encounter for examination for insurance purposes",
              label: "(Z02.6) Encounter for examination for insurance purposes",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.71) Encounter for disability determination",
              label: "(Z02.71) Encounter for disability determination",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.79) Encounter for issue of other medical certificate",
              label: "(Z02.79) Encounter for issue of other medical certificate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.81) Encounter for paternity testing",
              label: "(Z02.81) Encounter for paternity testing",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.82) Encounter for adoption services",
              label: "(Z02.82) Encounter for adoption services",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.83) Encounter for blood-alcohol and blood-drug test",
              label: "(Z02.83) Encounter for blood-alcohol and blood-drug test",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.89) Encounter for other administrative examinations",
              label: "(Z02.89) Encounter for other administrative examinations",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z02.9) Encounter for administrative examinations, unspecified",
              label: "(Z02.9) Encounter for administrative examinations, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.6) Encounter for observation for suspected toxic effect from ingested substance ruled out",
              label: "(Z03.6) Encounter for observation for suspected toxic effect from ingested substance ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.71) Encounter for suspected problem with amniotic cavity and membrane ruled out",
              label: "(Z03.71) Encounter for suspected problem with amniotic cavity and membrane ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.72) Encounter for suspected placental problem ruled out",
              label: "(Z03.72) Encounter for suspected placental problem ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.73) Encounter for suspected fetal anomaly ruled out",
              label: "(Z03.73) Encounter for suspected fetal anomaly ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.74) Encounter for suspected problem with fetal growth ruled out",
              label: "(Z03.74) Encounter for suspected problem with fetal growth ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.75) Encounter for suspected cervical shortening ruled out",
              label: "(Z03.75) Encounter for suspected cervical shortening ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.79) Encounter for other suspected maternal and fetal conditions ruled out",
              label: "(Z03.79) Encounter for other suspected maternal and fetal conditions ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.810) Encounter for observation for suspected exposure to anthrax ruled out",
              label: "(Z03.810) Encounter for observation for suspected exposure to anthrax ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.818) Encounter for observation for suspected exposure to other biological agents ruled out",
              label: "(Z03.818) Encounter for observation for suspected exposure to other biological agents ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.821) Encounter for observation for suspected ingested foreign body ruled out",
              label: "(Z03.821) Encounter for observation for suspected ingested foreign body ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.822) Encounter for observation for suspected aspirated (inhaled) foreign body ruled out",
              label: "(Z03.822) Encounter for observation for suspected aspirated (inhaled) foreign body ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.823) Encounter for observation for suspected inserted (injected) foreign body ruled out",
              label: "(Z03.823) Encounter for observation for suspected inserted (injected) foreign body ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.83) Encounter for observation for suspected conditions related to home physiologic monitoring device ruled out",
              label: "(Z03.83) Encounter for observation for suspected conditions related to home physiologic monitoring device ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z03.89) Encounter for observation for other suspected diseases and conditions ruled out",
              label: "(Z03.89) Encounter for observation for other suspected diseases and conditions ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.1) Encounter for examination and observation following transport accident",
              label: "(Z04.1) Encounter for examination and observation following transport accident",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.2) Encounter for examination and observation following work accident",
              label: "(Z04.2) Encounter for examination and observation following work accident",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.3) Encounter for examination and observation following other accident",
              label: "(Z04.3) Encounter for examination and observation following other accident",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.41) Encounter for examination and observation following alleged adult rape",
              label: "(Z04.41) Encounter for examination and observation following alleged adult rape",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.42) Encounter for examination and observation following alleged child rape",
              label: "(Z04.42) Encounter for examination and observation following alleged child rape",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.6) Encounter for general psychiatric examination, requested by authority",
              label: "(Z04.6) Encounter for general psychiatric examination, requested by authority",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.71) Encounter for examination and observation following alleged adult physical abuse",
              label: "(Z04.71) Encounter for examination and observation following alleged adult physical abuse",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.72) Encounter for examination and observation following alleged child physical abuse",
              label: "(Z04.72) Encounter for examination and observation following alleged child physical abuse",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.81) Encounter for examination and observation of victim following forced sexual exploitation",
              label: "(Z04.81) Encounter for examination and observation of victim following forced sexual exploitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.82) Encounter for examination and observation of victim following forced labor exploitation",
              label: "(Z04.82) Encounter for examination and observation of victim following forced labor exploitation",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.89) Encounter for examination and observation for other specified reasons",
              label: "(Z04.89) Encounter for examination and observation for other specified reasons",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z04.9) Encounter for examination and observation for unspecified reason",
              label: "(Z04.9) Encounter for examination and observation for unspecified reason",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.0) Observation and evaluation of newborn for suspected cardiac condition ruled out",
              label: "(Z05.0) Observation and evaluation of newborn for suspected cardiac condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.1) Observation and evaluation of newborn for suspected infectious condition ruled out",
              label: "(Z05.1) Observation and evaluation of newborn for suspected infectious condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.2) Observation and evaluation of newborn for suspected neurological condition ruled out",
              label: "(Z05.2) Observation and evaluation of newborn for suspected neurological condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.3) Observation and evaluation of newborn for suspected respiratory condition ruled out",
              label: "(Z05.3) Observation and evaluation of newborn for suspected respiratory condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.41) Observation and evaluation of newborn for suspected genetic condition ruled out",
              label: "(Z05.41) Observation and evaluation of newborn for suspected genetic condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.42) Observation and evaluation of newborn for suspected metabolic condition ruled out",
              label: "(Z05.42) Observation and evaluation of newborn for suspected metabolic condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.43) Observation and evaluation of newborn for suspected immunologic condition ruled out",
              label: "(Z05.43) Observation and evaluation of newborn for suspected immunologic condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.5) Observation and evaluation of newborn for suspected gastrointestinal condition ruled out",
              label: "(Z05.5) Observation and evaluation of newborn for suspected gastrointestinal condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.6) Observation and evaluation of newborn for suspected genitourinary condition ruled out",
              label: "(Z05.6) Observation and evaluation of newborn for suspected genitourinary condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.71) Observation and evaluation of newborn for suspected skin and subcutaneous tissue condition ruled out",
              label: "(Z05.71) Observation and evaluation of newborn for suspected skin and subcutaneous tissue condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.72) Observation and evaluation of newborn for suspected musculoskeletal condition ruled out",
              label: "(Z05.72) Observation and evaluation of newborn for suspected musculoskeletal condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.73) Observation and evaluation of newborn for suspected connective tissue condition ruled out",
              label: "(Z05.73) Observation and evaluation of newborn for suspected connective tissue condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.8) Observation and evaluation of newborn for other specified suspected condition ruled out",
              label: "(Z05.8) Observation and evaluation of newborn for other specified suspected condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z05.9) Observation and evaluation of newborn for unspecified suspected condition ruled out",
              label: "(Z05.9) Observation and evaluation of newborn for unspecified suspected condition ruled out",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z08.) Encounter for follow-up examination after completed treatment for malignant neoplasm",
              label: "(Z08.) Encounter for follow-up examination after completed treatment for malignant neoplasm",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z09.) Encounter for follow-up examination after completed treatment for conditions other than malignant neoplasm",
              label: "(Z09.) Encounter for follow-up examination after completed treatment for conditions other than malignant neoplasm",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.0) Encounter for screening for intestinal infectious diseases",
              label: "(Z11.0) Encounter for screening for intestinal infectious diseases",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.1) Encounter for screening for respiratory tuberculosis",
              label: "(Z11.1) Encounter for screening for respiratory tuberculosis",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.2) Encounter for screening for other bacterial diseases",
              label: "(Z11.2) Encounter for screening for other bacterial diseases",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.3) Encounter for screening for infections with a predominantly sexual mode of transmission",
              label: "(Z11.3) Encounter for screening for infections with a predominantly sexual mode of transmission",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.4) Encounter for screening for human immunodeficiency virus [HIV]",
              label: "(Z11.4) Encounter for screening for human immunodeficiency virus [HIV]",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.51) Encounter for screening for human papillomavirus (HPV)",
              label: "(Z11.51) Encounter for screening for human papillomavirus (HPV)",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.52) Encounter for screening for COVID-19",
              label: "(Z11.52) Encounter for screening for COVID-19",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.59) Encounter for screening for other viral diseases",
              label: "(Z11.59) Encounter for screening for other viral diseases",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.6) Encounter for screening for other protozoal diseases and helminthiases",
              label: "(Z11.6) Encounter for screening for other protozoal diseases and helminthiases",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.7) Encounter for testing for latent tuberculosis infection",
              label: "(Z11.7) Encounter for testing for latent tuberculosis infection",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.8) Encounter for screening for other infectious and parasitic diseases",
              label: "(Z11.8) Encounter for screening for other infectious and parasitic diseases",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z11.9) Encounter for screening for infectious and parasitic diseases, unspecified",
              label: "(Z11.9) Encounter for screening for infectious and parasitic diseases, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.0) Encounter for screening for malignant neoplasm of stomach",
              label: "(Z12.0) Encounter for screening for malignant neoplasm of stomach",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.10) Encounter for screening for malignant neoplasm of intestinal tract, unspecified",
              label: "(Z12.10) Encounter for screening for malignant neoplasm of intestinal tract, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.11) Encounter for screening for malignant neoplasm of colon",
              label: "(Z12.11) Encounter for screening for malignant neoplasm of colon",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.12) Encounter for screening for malignant neoplasm of rectum",
              label: "(Z12.12) Encounter for screening for malignant neoplasm of rectum",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.13) Encounter for screening for malignant neoplasm of small intestine",
              label: "(Z12.13) Encounter for screening for malignant neoplasm of small intestine",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.2) Encounter for screening for malignant neoplasm of respiratory organs",
              label: "(Z12.2) Encounter for screening for malignant neoplasm of respiratory organs",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.31) Encounter for screening mammogram for malignant neoplasm of breast",
              label: "(Z12.31) Encounter for screening mammogram for malignant neoplasm of breast",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.39) Encounter for other screening for malignant neoplasm of breast",
              label: "(Z12.39) Encounter for other screening for malignant neoplasm of breast",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.4) Encounter for screening for malignant neoplasm of cervix",
              label: "(Z12.4) Encounter for screening for malignant neoplasm of cervix",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.5) Encounter for screening for malignant neoplasm of prostate",
              label: "(Z12.5) Encounter for screening for malignant neoplasm of prostate",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.6) Encounter for screening for malignant neoplasm of bladder",
              label: "(Z12.6) Encounter for screening for malignant neoplasm of bladder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.71) Encounter for screening for malignant neoplasm of testis",
              label: "(Z12.71) Encounter for screening for malignant neoplasm of testis",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.72) Encounter for screening for malignant neoplasm of vagina",
              label: "(Z12.72) Encounter for screening for malignant neoplasm of vagina",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.73) Encounter for screening for malignant neoplasm of ovary",
              label: "(Z12.73) Encounter for screening for malignant neoplasm of ovary",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.79) Encounter for screening for malignant neoplasm of other genitourinary organs",
              label: "(Z12.79) Encounter for screening for malignant neoplasm of other genitourinary organs",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.81) Encounter for screening for malignant neoplasm of oral cavity",
              label: "(Z12.81) Encounter for screening for malignant neoplasm of oral cavity",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.82) Encounter for screening for malignant neoplasm of nervous system",
              label: "(Z12.82) Encounter for screening for malignant neoplasm of nervous system",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.83) Encounter for screening for malignant neoplasm of skin",
              label: "(Z12.83) Encounter for screening for malignant neoplasm of skin",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.89) Encounter for screening for malignant neoplasm of other sites",
              label: "(Z12.89) Encounter for screening for malignant neoplasm of other sites",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z12.9) Encounter for screening for malignant neoplasm, site unspecified",
              label: "(Z12.9) Encounter for screening for malignant neoplasm, site unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.0) Encounter for screening for diseases of the blood and blood-forming organs and certain disorders involving immune mechanism",
              label: "(Z13.0) Encounter for screening for diseases of the blood and blood-forming organs and certain disorders involving immune mechanism",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.1) Encounter for screening for diabetes mellitus",
              label: "(Z13.1) Encounter for screening for diabetes mellitus",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.21) Encounter for screening for nutritional disorder",
              label: "(Z13.21) Encounter for screening for nutritional disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.220) Encounter for screening for lipoid disorders",
              label: "(Z13.220) Encounter for screening for lipoid disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.228) Encounter for screening for other metabolic disorders",
              label: "(Z13.228) Encounter for screening for other metabolic disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.29) Encounter for screening for other suspected endocrine disorder",
              label: "(Z13.29) Encounter for screening for other suspected endocrine disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.30) Encounter for screening examination for mental health and behavioral disorders, unspecified",
              label: "(Z13.30) Encounter for screening examination for mental health and behavioral disorders, unspecified",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.31) Encounter for screening for depression",
              label: "(Z13.31) Encounter for screening for depression",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.32) Encounter for screening for maternal depression",
              label: "(Z13.32) Encounter for screening for maternal depression",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.39) Encounter for screening examination for other mental health and behavioral disorders",
              label: "(Z13.39) Encounter for screening examination for other mental health and behavioral disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.40) Encounter for screening for unspecified developmental delays",
              label: "(Z13.40) Encounter for screening for unspecified developmental delays",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.41) Encounter for autism screening",
              label: "(Z13.41) Encounter for autism screening",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.42) Encounter for screening for global developmental delays (milestones)",
              label: "(Z13.42) Encounter for screening for global developmental delays (milestones)",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.49) Encounter for screening for other developmental delays",
              label: "(Z13.49) Encounter for screening for other developmental delays",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.5) Encounter for screening for eye and ear disorders",
              label: "(Z13.5) Encounter for screening for eye and ear disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.6) Encounter for screening for cardiovascular disorders",
              label: "(Z13.6) Encounter for screening for cardiovascular disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.71) Encounter for nonprocreative screening for genetic disease carrier status",
              label: "(Z13.71) Encounter for nonprocreative screening for genetic disease carrier status",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.79) Encounter for other screening for genetic and chromosomal anomalies",
              label: "(Z13.79) Encounter for other screening for genetic and chromosomal anomalies",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.810) Encounter for screening for upper gastrointestinal disorder",
              label: "(Z13.810) Encounter for screening for upper gastrointestinal disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.811) Encounter for screening for lower gastrointestinal disorder",
              label: "(Z13.811) Encounter for screening for lower gastrointestinal disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.818) Encounter for screening for other digestive system disorders",
              label: "(Z13.818) Encounter for screening for other digestive system disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.820) Encounter for screening for osteoporosis",
              label: "(Z13.820) Encounter for screening for osteoporosis",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.828) Encounter for screening for other musculoskeletal disorder",
              label: "(Z13.828) Encounter for screening for other musculoskeletal disorder",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.83) Encounter for screening for respiratory disorder NEC",
              label: "(Z13.83) Encounter for screening for respiratory disorder NEC",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.84) Encounter for screening for dental disorders",
              label: "(Z13.84) Encounter for screening for dental disorders",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.850) Encounter for screening for traumatic brain injury",
              label: "(Z13.850) Encounter for screening for traumatic brain injury",
              Icon: MenuAlt2Icon,
            },
            {
              value: "(Z13.858) Encounter for screening for other nervous system disorders",
              label: "(Z13.858) Encounter for screening for other nervous system disorders",
              Icon: MenuAlt2Icon,
            },
          ],
          // maxLength: 50,
          // max: 100,
          // min: 5,
          required: true,
          error: "",
          example: "SOAP",
        },
        {
          title: "Progression",
          attr: "progression",
          // value: "",
          placeholder: "Progression of Client/Patient",
          label: "Progression of Client/Patient",
          type: "dropdown",
          options: [
            { value: "", label: "", Icon: MenuAlt2Icon },
            {
              value: "Progressing",
              label: "Progressing",
              Icon: MenuAlt2Icon,
            },
            {
              value: "Maintenance Needed",
              label: "Maintenance Needed",
              Icon: MenuAlt2Icon,
            },
            {
              value: "Worsening",
              label: "Worsening",
              Icon: MenuAlt2Icon,
            },
          ],
          // maxLength: 50,
          // max: 100,
          // min: 5,
          required: true,
          error: "",
          example: "Informative",
        },
        {
          title: "Mood",
          attr: "mood",
          // value: "",
          placeholder: "Mood",
          label: "Mood",
          type: "checkbox",
          displayintable: true,
          column: 0,
          row: 0,
          options: [
            {
              value: "Depressed",
              label: "Depressed",
              checked: false,
            },
            {
              value: "Euthymic",
              label: "Euthymic",
              checked: false,
            },
            { value: "Anxious", label: "Anxious", checked: false },
            {
              value: "Irritable",
              label: "Irritable",
              checked: false,
            },
            {
              value: "Dysphoric",
              label: "Dysphoric",
              checked: false,
            },
            {
              value: "Apathetic",
              label: "Apathetic",
              checked: false,
            },
            {
              value: "Stressed",
              label: "Stressed",
              checked: false,
            },
            { value: "Manic", label: "Manic", checked: false },
            {
              value: "Hypomanic",
              label: "Hypomanic",
              checked: false,
            },
            {
              value: "Hopeless",
              label: "Hopeless",
              checked: false,
            },
            {
              value: "Agitated",
              label: "Agitated",
              checked: false,
            },
            { value: "Content", label: "Content", checked: false },
            {
              value: "Indifferent",
              label: "Indifferent",
              checked: false,
            },
            { value: "Guilty", label: "Guilty", checked: false },
            {
              value: "Suicidal",
              label: "Suicidal",
              checked: false,
            },
            {
              value: "Other",
              label: "Other",
              checked: false,
              isInput: true,
              input: {
                title: "",
                attr: "",
                placeholder: "",
                label: "",
                required: true,
                error: "",
                size: "sm",
                example: "",
              },
            },
          ],
          // maxLength: 500,
          // max: 500,
          // min: 5,
          required: true,
          error: "",
          example: "Angry",
        },
        {
          title: "Affect",
          attr: "affect",
          // value: "",
          placeholder: "Affect",
          label: "Affect",
          type: "checkbox",
          displayintable: true,
          column: 1,
          row: 0,
          options: [
            {
              value: "Restricted",
              label: "Restricted",
              checked: false,
            },
            { value: "Labile", label: "Labile", checked: false },
            { value: "Blunted", label: "Blunted", checked: false },
            {
              value: "Elevated",
              label: "Elevated",
              checked: false,
            },
            { value: "Flat", label: "Flat", checked: false },
            { value: "Angry", label: "Angry", checked: false },
            {
              value: "Agitated",
              label: "Agitated",
              checked: false,
            },
            { value: "Tearful", label: "Tearful", checked: false },
            {
              value: "Appropriate",
              label: "Appropriate",
              checked: false,
            },
            {
              value: "Incongruent",
              label: "Incongruent",
              checked: false,
            },
            {
              value: "Inhibited",
              label: "Inhibited",
              checked: false,
            },
            {
              value: "Uninhibited",
              label: "Uninhibited",
              checked: false,
            },
            {
              value: "Exaggerated",
              label: "Exaggerated",
              checked: false,
            },
            {
              value: "Other",
              label: "Other",
              checked: false,
              isInput: true,
              input: {
                title: "",
                attr: "",
                placeholder: "",
                label: "",
                required: true,
                error: "",
                size: "sm",
                example: "",
              },
            },
          ],
          // maxLength: 500,
          // max: 500,
          // min: 5,
          required: true,
          error: "",
          example: "Nervous",
        },
        {
          title: "Physical",
          attr: "physical",
          // value: "",
          placeholder: "Physical",
          label: "Physical",
          type: "checkbox",
          displayintable: true,
          column: 0,
          row: 1,
          options: [
            {
              value: "Well-groomed",
              label: "Well-groomed",
              checked: false,
            },
            {
              value: "Disheveled",
              label: "Disheveled",
              checked: false,
            },
            {
              value: "Appropriate clothing",
              label: "Appropriate clothing",
              checked: false,
            },
            {
              value: "Inappropriate clothing",
              label: "Inappropriate clothing",
              checked: false,
            },
            {
              value: "Hygienic",
              label: "Hygienic",
              checked: false,
            },
            {
              value: "Poor hygiene",
              label: "Poor hygiene",
              checked: false,
            },
            {
              value: "Clean and tidy",
              label: "Clean and tidy",
              checked: false,
            },
            { value: "Unkempt", label: "Unkempt", checked: false },
            {
              value: "Well-nourished",
              label: "Well-nourished",
              checked: false,
            },
            {
              value: "Emaciated",
              label: "Emaciated",
              checked: false,
            },
            {
              value: "Overweight",
              label: "Overweight",
              checked: false,
            },
            {
              value: "Pale complexion",
              label: "Pale complexion",
              checked: false,
            },
            { value: "Flushed", label: "Flushed", checked: false },
            {
              value: "Signs of self-harm",
              label: "Signs of self-harm",
              checked: false,
            },
            {
              value: "Other",
              label: "Other",
              checked: false,
              isInput: true,
              input: {
                title: "",
                attr: "",
                placeholder: "",
                label: "",
                required: true,
                error: "",
                size: "sm",
                example: "",
              },
            },
          ],
          // maxLength: 500,
          // max: 500,
          // min: 5,
          required: true,
          error: "",
          example: "Disheveled",
        },
        {
          title: "Behavior",
          attr: "behavior",
          // value: "",
          placeholder: "Behavior",
          label: "Behavior",
          type: "checkbox",
          displayintable: true,
          column: 1,
          row: 1,
          options: [
            {
              value: "Aggressive ",
              label: "Aggressive",
              checked: false,
            },
            {
              value: "Withdrawn",
              label: "Withdrawn",
              checked: false,
            },
            {
              value: "Restless",
              label: "Restless",
              checked: false,
            },
            {
              value: "Disruptive",
              label: "Disruptive",
              checked: false,
            },
            {
              value: "Hygienic",
              label: "Hygienic",
              checked: false,
            },
            {
              value: "Oppositional",
              label: "Oppositional",
              checked: false,
            },
            {
              value: "Hyperactive",
              label: "Hyperactive",
              checked: false,
            },
            {
              value: "Avoidant",
              label: "Avoidant",
              checked: false,
            },
            {
              value: "Isolating",
              label: "Isolating",
              checked: false,
            },
            {
              value: "Obsessive",
              label: "Obsessive",
              checked: false,
            },
            {
              value: "Compulsive",
              label: "Compulsive",
              checked: false,
            },
            {
              value: "Agitated",
              label: "Agitated",
              checked: false,
            },
            { value: "Flushed", label: "Flushed", checked: false },
            { value: "Violent", label: "Violent", checked: false },
            {
              value: "Other",
              label: "Other",
              checked: false,
              isInput: true,
              input: {
                title: "",
                attr: "",
                placeholder: "",
                label: "",
                required: true,
                error: "",
                size: "sm",
                example: "",
              },
            },
          ],
          // maxLength: 500,
          // max: 500,
          // min: 5,
          required: true,
          error: "",
          example: "Lonely",
        },
        {
          title: "Speech",
          attr: "speech",
          // value: "",
          placeholder: "Speech",
          label: "Speech",
          type: "checkbox",
          displayintable: true,
          column: 0,
          row: 2,
          options: [
            { value: "Slowed ", label: "Slowed", checked: false },
            { value: "Rapid", label: "Rapid", checked: false },
            { value: "Slurred", label: "Slurred", checked: false },
            {
              value: "Incoherent",
              label: "Incoherent",
              checked: false,
            },
            {
              value: "Tangential ",
              label: "Tangential ",
              checked: false,
            },
            {
              value: "Pressured",
              label: "Pressured",
              checked: false,
            },
            {
              value: "Stuttering",
              label: "Stuttering",
              checked: false,
            },
            {
              value: "Mumbling",
              label: "Mumbling",
              checked: false,
            },
            {
              value: "Dramatic",
              label: "Dramatic",
              checked: false,
            },
            {
              value: "Disorganized",
              label: "Disorganized",
              checked: false,
            },
            {
              value: "Explosive",
              label: "Explosive",
              checked: false,
            },
            {
              value: "Hesitant",
              label: "Hesitant",
              checked: false,
            },
            { value: "Evasive", label: "Evasive", checked: false },
            {
              value: "Other",
              label: "Other",
              checked: false,
              isInput: true,
              input: {
                title: "",
                attr: "",
                placeholder: "",
                label: "",
                required: true,
                error: "",
                size: "sm",
                example: "",
              },
            },
          ],
          // maxLength: 500,
          // max: 500,
          // min: 5,
          required: true,
          error: "",
          example: "Hesitant",
        },
        {
          title: "Session Notes",
          attr: "sessionnotes",
          value: "",
          placeholder:
            "Write a summary of the session. Include information about treatment plans and goals, methods used in the session.  Also include information about medications.",
          label: "Any specific keywords you want to include?",
          type: "textarea",
          maxLength: 5000,
          // max: 100,
          // min: 100,
          // required: true,
          error: "",
          example:
            "Client sat in a hunched position upon the beginning of the session, and appeared tired. Client discussed experiencing increased difficulty with depressive symptoms beginning last week, following an argument with his partner. Discussion of symptoms, supportive counseling, identification and exploration of emotions. Client endorsed symptoms and presentation are in keeping with depression, and are part of a sudden change prompted by an argument and reduction in sleep. Client is agreeable to following the steps outlined in his safety plan if symptoms increase or ideation occurs. However, the client is at risk for worsening depression if he is unable to prevent further decompensation. Client has committed to resuming a sleep schedule, beginning tonight, and agreed to turn off his smartphone an hour before the established 11PM bedtime. Client will monitor his symptoms, and reach out to this writer if symptoms increase.",
        },
      ],
      example: {
        outputs: [
          "Subjective:\nJohn Smith reported feeling depressed and tearful during the session. He discussed experiencing increased difficulty with depressive symptoms beginning last week, following an argument with his partner.\n\nObjective:\nJohn Smith appeared disheveled with a pale complexion and was restless throughout the session. His speech was pressured and hesitant. He sat in a hunched position upon the beginning of the session, and appeared tired.\n\nAssessment:\nJohn Smith's symptoms and presentation are in keeping with depression, and are part of a sudden change prompted by an argument and reduction in sleep. His primary diagnosis is (F43.10) Post-traumatic stress disorder, unspecified. John Smith is at risk for worsening depression if he is unable to prevent further decompensation.\n\nPlan:\nJohn Smith is agreeable to following the steps outlined in his safety plan if symptoms increase or ideation occurs. He has committed to resuming a sleep schedule, beginning tonight, and agreed to turn off his smartphone an hour before the established 11PM bedtime. John Smith will monitor his symptoms, and reach out to this writer if symptoms increase. Maintenance is needed for John Smith's progression.",
        ],
        // Icon: RefreshIcon,
        // color: "",
      },
    },
  ],
};

export default obj;
