import {
  ClipboardListIcon,
} from '@heroicons/react/solid'


const obj = {

  title: "Notes Rewriter",
  desc: "Provide your draft text and our AI will produce notes in SOAP or DAP format",
  category: "Clinical",
  Icon: ClipboardListIcon,
  // tags: [],
  permissions: ['user'],

  to: "/ai/clinical/progressnoterewriter",
  api: "/ai/clinical/progressnoterewriter",

  fromColor: "gray-500",
  toColor: "gray-500",

  output: {
    title: "AI Response",
    desc: "Here is the suggested revision to your note. You are advised that this AI-generated content may contain errors and requires your review.",
    // Icon: RefreshIcon,
    // color: "",
  },

  prompts: [{
    title: "SOAP Format",
    desc: "Enter your progress note details",
    prompts: [
      {
        title: "Enter your Progress Note",
        attr: "content",
        value: "",
        placeholder: "Enter the narrative for your progress note here...",
        label: "",
        type: "textarea",
        maxLength: 6000,
        // max: 100,
        // min: 1,
        required: true,
        error: "",
        example: "Client noted concerns about mood, endorsing depressed mood, lethargy, insomnia, loss of energy and motivation, and urges to isolate from his partner. Client appeared disheveled. Client sat in a hunched position upon the beginning of the session, and appeared tired. He was attentive and cooperative, and had congruent and appropriate affect. Client discussed experiencing increased difficulty with depressive symptoms beginning last week, following an argument with his partner. Discussion of symptoms, supportive counseling, identification and exploration of emotions. Client endorsed symptoms and presentation are in keeping with depression, and are part of a sudden change prompted by an argument and reduction in sleep. Client is agreeable to following the steps outlined in his safety plan if symptoms increase or ideation occurs. However, the client is at risk for worsening depression if he is unable to prevent further decompensation. Client has committed to resuming a sleep schedule, beginning tonight, and agreed to turn off his smartphone an hour before the established 11PM bedtime. Client will monitor his symptoms, and reach out to this writer if symptoms increase.",
      },
    ],


    example: {
      outputs: [
        "Subjective:\nClient noted concerns about mood, endorsing depressed mood, lethargy, insomnia, loss of energy and motivation, and urges to isolate from his partner. \n\nObjective:\nClient appeared disheveled. Client sat in a hunched position upon the beginning of the session, and appeared tired. He was attentive and cooperative, and had congruent and appropriate affect.\n\nAssessment:\nClient discussed experiencing increased difficulty with depressive symptoms beginning last week, following an argument with his partner. Client endorsed symptoms and presentation are in keeping with depression, and are part of a sudden change prompted by an argument and reduction in sleep. Client is at risk for worsening depression if he is unable to prevent further decompensation. \n\nPlan:\n CDiscussion of symptoms, supportive counseling, identification and exploration of emotions. Client has committed to resuming a sleep schedule, beginning tonight, and agreed to turn off his smartphone an hour before the established 11PM bedtime. Client will monitor his symptoms, and reach out to this writer if symptoms increase. Client is agreeable to following the steps outlined in his safety plan if symptoms increase or ideation occurs.\n",
      ],
      // Icon: RefreshIcon,
      // color: "",
    }
  }, {
    title: "DAP Format",
    desc: "Enter your progress note details",
    // n: 1,
    prompts: [
      {
        title: "Enter your Progress Note",
        attr: "content",
        value: "",
        placeholder: "Enter the narrative for your progress note here...",
        label: "",
        type: "textarea",
        maxLength: 6000,
        // max: 100,
        // min: 1,
        required: true,
        error: "",
        example: "Client noted concerns about mood, endorsing depressed mood, lethargy, insomnia, loss of energy and motivation, and urges to isolate from his partner. Client endorsed symptoms and presentation are in keeping with depression, and are part of a sudden change prompted by an argument and reduction in sleep. Client is agreeable to following the steps outlined in his safety plan if symptoms increase or ideation occurs. Client appeared disheveled. Client sat in a hunched position upon the beginning of the session, and appeared tired. He was attentive and cooperative, and had congruent and appropriate affect. Client discussed experiencing increased difficulty with depressive symptoms beginning last week, following an argument with his partner. Discussion of symptoms, supportive counseling, identification and exploration of emotions.   However, the client is at risk for worsening depression if he is unable to prevent further decompensation. Client has committed to resuming a sleep schedule, beginning tonight, and agreed to turn off his smartphone an hour before the established 11PM bedtime. Client will monitor his symptoms, and reach out to this writer if symptoms increase.",
      },
    ],
    example: {
      outputs: [
        "Data:\nClient noted concerns about mood, endorsing depressed mood, lethargy, insomnia, loss of energy and motivation, and urges to isolate from his partner. Client appeared disheveled. Client sat in a hunched position upon the beginning of the session, and appeared tired. He was attentive and cooperative, and had congruent and appropriate affect. Client discussed experiencing increased difficulty with depressive symptoms beginning last week, following an argument with his partner.\n\nAssessment:\nClient endorsed symptoms and presentation are in keeping with depression, and are part of a sudden change prompted by an argument and reduction in sleep. Client is at risk for worsening depression if he is unable to prevent further decompensation.\n\nPlan:\nClient is agreeable to following the steps outlined in his safety plan if symptoms increase or ideation occurs. Client has committed to resuming a sleep schedule, beginning tonight, and agreed to turn off his smartphone an hour before the established 11PM bedtime. Client will monitor his symptoms, and reach out to this writer if symptoms increase.",
      ],
      // Icon: RefreshIcon,
      // color: "",
    }
  }]

}

export default obj

