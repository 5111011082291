import { ViewListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Talk to Database",
  desc: "As of now this is for internal use only. Tables used here can be seen inside of EMR -> Hub",
  category: "Advisor",
  Icon: ViewListIcon,
  // tags: [],
  permissions: ["admin"],

  fromColor: "navix-turquoise",
  toColor: "navix-turquoise",

  to: "/ai/general/talk-to-database",
  api: "https://api.navix.ai/api/v1/ai/mysql",
  //api: "http://localhost:8080/api/v1/ai/mysql",

  output: {
    title: "Generated output from AI",
    desc: "Here is the output from database based from your query.",
    Icon: false,
    color: "blue"
  },

  prompts: [
    {
      title: "Enter query",
      desc: "Enter your query in the text box",
      // n: 1,
      prompts: [
        {
          type: "radio",
          title: "Features",
          value: "Beds Management",
          example: "EMR Client Hub",
          attr: "feature",
          options: [
            {
              title: "Beds Management",
              value: "Beds Management"
            },
            {
              title: "Shift Notes",
              value: "Shift Notes"
            },
            {
              title: "Events",
              value: "Events"
            },
            {
              title: "Rounds",
              value: "Rounds"
            },
            {
              title: "EMR Client Hub",
              value: "EMR Client Hub"
            },
            {
              title: "EMR Client Medications",
              value: "EMR Client Medications"
            },
            {
              title: "EMR Client Treatment Plan",
              value: "EMR Client Treatment Plan"
            }
          ]
        },
        {
          title: "Query",
          attr: "query",
          value: "",
          placeholder:
            "Give me the list of all names that were born before year 2000",
          label: "",
          type: "textarea",
          maxLength: 15000,
          // max: 100,
          min: 3,
          required: false,
          error: "",
          example:
            "Give me the list of all names that were born before year 2000"
        }
      ],
      example: {
        outputs: [
          "John Doe"
        ],
        // Icon: RefreshIcon,
        color: "blue"
      }
    }
  ]
};

export default obj;
