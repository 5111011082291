import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { computed, } from 'mobx'
import MainBody from './Components/Body'
import { Helmet } from "react-helmet";


import { observer, inject } from 'mobx-react'
@inject('store')
@observer
class Body extends Component {

  @computed get permissions() {
    return this.props.store.tools.filter(tool =>
      tool.permissions.some(r => this.props.store.profile.permissions.includes(r))
    )
  }

  @computed get beta() {
    return this.permissions.filter(tool => tool.category === 'Beta')
  }

	@computed get business() {
		return this.permissions.filter(tool => tool.category === 'Business')
	}
	
	@computed get advisor() {
		const advisors = this.permissions.filter((tool) => {
				const hidden = [
					'Treatment Planning',
					'Compliance',
					'Human Resource'
				]

				return tool.category === 'Advisor' && !hidden.includes(tool.title)
			}).map((tool) => {
				if (tool.title === 'Behavioral Health') {
					tool.title = 'Advisors & Resource Libraries'
					tool.desc = 'Chat with our AI Advisors on topics including Behavioral Health, Compliance, Treatment Planning and Human Resources.'
				}

				return tool
			})

		return advisors
	}

  @computed get clinical() {
    return this.permissions.filter(tool => tool.category === 'Clinical')
  }

  @computed get content() {
    return this.permissions.filter(tool => tool.category === 'Content')
  }

  @computed get rcm() {
    return this.permissions.filter(tool => tool.category === 'RCM')
  }

  @computed get research() {
    return this.permissions.filter(tool => tool.category === 'Research')
  }

  @computed get audio() {
    return this.permissions.filter(tool => tool.category === 'Audio')
  }


  render() {
    return (

      <>
        <Helmet>
          <title>{`Tools - NavixAI`}</title>
        </Helmet>
        <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">

          {this.business.length ? <>
            <Title title="Business Management" />
            <Grid>
              {this.business.map((tool, index) =>
                <Tool
                  key={index}
                  group={tool.category}
                  title={tool.title}
                  to={tool.to}
                  Icon={tool.Icon}
                  desc={tool.desc}
                  fromColor={tool.fromColor}
                  toColor={tool.toColor}
                />)}
            </Grid>
            <Divider />
          </> : null}

          {this.advisor.length ? <>
            <Title title="Advisors" />
            <Grid>
              {this.advisor.map((tool, index) =>
                <Tool
                  key={index}
                  group={tool.category}
                  title={tool.title}
                  to={tool.to}
                  Icon={tool.Icon}
                  desc={tool.desc}
                  fromColor={tool.fromColor}
                  toColor={tool.toColor}
                />)}
            </Grid>
            <Divider />
          </> : null}

          {this.clinical.length ? <>
            <Title title="Clinical & Medical" />
            <Grid>
              {this.clinical.map((tool, index) =>
                <Tool
                  key={index}
                  group={tool.category}
                  title={tool.title}
                  to={tool.to}
                  Icon={tool.Icon}
                  desc={tool.desc}
                  fromColor={tool.fromColor}
                  toColor={tool.toColor}
                />)}
            </Grid>
            <Divider />
          </> : null}

          {this.content.length ? <>
            <Title title="CRM & Marketing" />
            <Grid>
              {this.content.map((tool, index) =>
                <Tool
                  key={index}
                  group={tool.category}
                  title={tool.title}
                  to={tool.to}
                  Icon={tool.Icon}
                  desc={tool.desc}
                  fromColor={tool.fromColor}
                  toColor={tool.toColor}
                />)}
            </Grid>
            <Divider />
          </> : null}

          {this.research.length ? <>
            <Title title="Research & Reference" />
            <Grid>
              {this.research.map((tool, index) =>
                <Tool
                  key={index}
                  group={tool.category}
                  title={tool.title}
                  to={tool.to}
                  Icon={tool.Icon}
                  desc={tool.desc}
                  fromColor={tool.fromColor}
                  toColor={tool.toColor}
                />)}
            </Grid>
            <Divider />
          </> : null}

          {this.rcm.length ? <>
            <Title title="Revenue Cycle Management (RCM)" />
            <Grid>
              {this.rcm.map((tool, index) =>
                <Tool
                  key={index}
                  group={tool.category}
                  title={tool.title}
                  to={tool.to}
                  Icon={tool.Icon}
                  desc={tool.desc}
                  fromColor={tool.fromColor}
                  toColor={tool.toColor}
                />)}
            </Grid>
            <Divider />
          </> : null}

          {this.audio.length ? <>
            <Title title="Audio Processor" />
            <Grid>
              {this.audio.map((tool, index) =>
                <Tool
                  key={index}
                  group={tool.category}
                  title={tool.title}
                  to={tool.to}
                  Icon={tool.Icon}
                  desc={tool.desc}
                  fromColor={tool.fromColor}
                  toColor={tool.toColor}
                />)}
            </Grid>
            <Divider />
          </> : null}

        </MainBody>
      </>)
  }
}

export const Divider = () => <div className=" py-3 md:py-3"> <div></div>
  <div></div></div>

export const Title = ({ title }) => <h2 className="text-lg sm:text-xl md:text-xl text-gray-700 mb-1 md:mb-1">
  {title}
</h2>

export const Grid = ({ children }) => <div className="grid grid-cols-1 gap-8 mt-2 lg:grid-cols-2 xl:grid-cols-3 ">{children}</div>

export const Tool = ({ Icon, title, desc, to, group, fromColor, toColor }) => <Link to={to || "/"} className="flex relative ">

  <div className={`bg-gray-200 flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-400 md:flex relative transform hover:scale-105  hover:text-black`}>
    <div className="p-4">
      <div className={`uppercase tracking-wide text-xs text-${fromColor ? fromColor : "green-500"} font-semibold leading-none`}>{group || "New"}</div>
      <div href="#" className="block text-sm xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none">{title}</div>
      <p className="pr-1 text-xs ">{desc} </p>
    </div>
  </div>
</Link>



export default Body
