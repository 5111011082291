import progressnoterewriter from './progressnoterewriter';
import summarize from './summarize';
import blogwriter from './blogwriter';
import medicalcoding from './medicalcoding';
// import navixchat from './navixchat';
import clinicalnoteswriter from './clinicalnoteswriter';
import talktodatabase from './talktodatabase';
import audios from './audio'
import advisors from './advisors';

const TOOLS = [
	...advisors,
	...audios,
	clinicalnoteswriter,
	progressnoterewriter,
	summarize,
	blogwriter,
	medicalcoding,
	talktodatabase,
]

export default TOOLS
