import { ArrowLeftIcon, FingerPrintIcon } from "@heroicons/react/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import * as yup from "yup";
import { useQuery } from "../Hooks";
import { useEffect, useState } from "react";
import Loader from "../Components/Loader";

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const changePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password length should be at least 6 characters")
    .max(16, "Password cannot exceed more than 16 characters")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter and one number"
    ),
  repeatPassword: yup
    .string()
    .required("Confirm Password is required")
    .min(6, "Password length should be at least 6 characters")
    .max(16, "Password cannot exceed more than 16 characters")
    .oneOf([yup.ref("password")], "Passwords do not match"),
});

const ForgotPassword = inject("store")(
  observer(({ store }) => {
    let query = useQuery();
    const [{ c, t }, setQuery] = useState({ c: null, t: null });
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
      setQuery({ c: query.get("c"), t: query.get("t") });

      const givenTimestamp = query.get("t") * 1000;
      setIsValid(isTimestampAfter(givenTimestamp));

      if (query.get("c") === null || query.get("t") === null) setIsValid(true);
    }, []);

    function isTimestampAfter(timestampToCheck) {
      const currentTimestamp = Date.now();
      return timestampToCheck > currentTimestamp;
    }

    return (
      <>
        <Helmet>
          <title>{`NavixAI - Tools for Healthcare Professionals`}</title>
        </Helmet>
        <div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">
          <div className="text-center mb-6">
            <div className="text-3xl md:text-5xl relative font-medium text-transparent bg-clip-text bg-gradient-to-r from-gray-700 to-gray-600 mb-4">
              NavixAI<span className="font-normal "></span>
              <div className="absolute top-0 ml-3 left-full bg-gradient-to-r from-gray-500 to-gray-500 text-white text-sm px-2 py-0.5 hidden md:inline-block rounded-md font-normal ">
                beta
              </div>
            </div>
          </div>
          <div
            className={`min-w-full md:min-w-0 bg-white rounded-xl shadow-xl transform transition-all  transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2`}
          >
            <div className="px-4 py-4 md:px-12 md:py-12">
              {isValid ? (
                c && t ? (
                  <ChangePasswordForm api={store.api} user={c} />
                ) : (
                  <>
                    <NavLink to={"/login"} className="absolute left-3 top-3">
                      <ArrowLeftIcon className="h-5 w-5 text-gray-500" />
                    </NavLink>
                    <Form api={store.api} />
                  </>
                )
              ) : (
                <h1 className="text-xl text-gray-800 flex justify-center">
                  Link expired
                </h1>
              )}
            </div>
            <a
              href={`https://www.navixhealth.com/navixai`}
              className="block text-center bg-gray-100 text-gray-500 text-sm p-3 rounded-b-lg hover:bg-gray-200 cursor-pointer"
            >
              Visit our website for details
            </a>
            <a
              href={`https://www.navixhealth.com/navixai-terms-of-use`}
              className="block text-center bg-gray-100 text-gray-500 text-sm p-3 rounded-b-lg hover:bg-gray-200 cursor-pointer"
            >
              Terms of Use
            </a>
          </div>
        </div>
      </>
    );
  })
);

const Form = observer(({ api }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmitted, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = async (data) => {
    await api.post("/auth/forgot-password", data);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div
        className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-green-300  `}
      >
        <FingerPrintIcon
          className={`h-8 w-8  text-green-700`}
          aria-hidden="true"
        />
      </div>
      <div className="mt-3 text-center ">
        <div className="text-3xl font-medium text-gray-900">
          Forgot Password
        </div>
        <p className="text-lg text-gray-500">Reset your password</p>
        <p
          className={`mt-3 ${isSubmitted && "p-2"} bg-green-300 rounded-lg text-lg text-gray-700`}
        >
          {isSubmitted &&
            "If email exists, we will send you a link to reset your password."}
        </p>
        <div className="flex flex-col flex-1">
          <label className="text-gray-400 text-sm block mt-4 inline-block text-left">
            Email Address
          </label>
          <input
            {...register("email")}
            focus="true"
            className="rounded-md text-lg px-4 py-2  border border-gray-300 "
            placeholder="jean@smith.com"
          />
          <p className="text-xs text-red-500">{errors.email?.message}</p>
        </div>
        <div className="flex flex-col">
          <button
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
            className={`${!isDirty || !isValid ? "disabled:" : "enabled:"
              }hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 flex items-center justify-center`}
          >
            {isSubmitting && <Loader active={true} className="w-6 h-6 mr-2" />}
            Reset Password
          </button>
        </div>
      </div>
    </form>
  );
});

const ChangePasswordForm = observer(({ api, user }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmitted },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(changePasswordSchema),
  });

  const onSubmitHandler = async (data) => {
    await api.post("/auth/change-password", { ...data, user });
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div
        className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-green-300  `}
      >
        <FingerPrintIcon
          className={`h-8 w-8  text-green-700`}
          aria-hidden="true"
        />
      </div>
      <div className="mt-3 text-center ">
        <div className="text-3xl font-medium text-gray-900">
          Change Password
        </div>
        <p className="text-lg text-gray-500">Set your new password</p>
        <p
          className={`mt-3 ${isSubmitted && "py-2"
            } bg-green-300 rounded-lg text-lg text-gray-700`}
        >
          {isSubmitted && (
            <span>
              Password updated successfully. Click here to{" "}
              <NavLink to={"/login"} className="font-semibold underline">
                login
              </NavLink>
              .
            </span>
          )}
        </p>
        <div className="flex flex-col flex-1">
          <label className="text-gray-400 text-sm block mt-4 inline-block text-left">
            Password
          </label>
          <input
            {...register("password")}
            type="password"
            focus="true"
            className="rounded-md text-lg px-4 py-2  border border-gray-300"
            placeholder="******"
          />
          <p className="text-xs text-red-500">{errors.password?.message}</p>
          <p className="text-xs text-red-500">
            {errors.repeatPassword?.message}
          </p>
        </div>
        <div className="flex flex-col flex-1">
          <label className="text-gray-400 text-sm block mt-4 inline-block text-left">
            Confirm Password
          </label>
          <input
            {...register("repeatPassword")}
            type="password"
            focus="true"
            className="rounded-md text-lg px-4 py-2  border border-gray-300 "
            placeholder="******"
          />
        </div>
        <div className="flex flex-col">
          <button
            type="submit"
            disabled={!isDirty || !isValid}
            className={`${!isDirty || !isValid ? "disabled:" : "enabled:"
              }hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block`}
          >
            Change Password
          </button>
        </div>
      </div>
    </form>
  );
});

export default ForgotPassword;
