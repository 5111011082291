import { ThemeProvider } from 'styled-components'
import React, { Component } from 'react';

import io from 'socket.io-client';

import { Provider  } from 'mobx-react'
import { observer,  } from 'mobx-react'

import AppStore from './store'
import colors from 'tailwindcss/colors' 
import notifySound from './media/notify.mp3'

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
	Link
} from "react-router-dom";

import Header from './Header'
import Search from './Search'
import Pricing from './Pricing' 

import Dashboard from './Dashboard'

import Tool from './Core/Tool'
import Chat from './Core/Chat'

import Login from './Login/Login'

import Profile from './Profile/'
import LoginSuccess from './Login/Success'

import './styles/index.scss'
import './styles/App.scss'
import toast, { Toaster } from 'react-hot-toast';
import ForgotPassword from './Login/ForgotPassword';

if(!window.store){
  window.store = new AppStore();
}


@observer
class App extends Component {
	constructor(props) {
		super(props);
		this.socket = null;
	}

	componentDidMount() {
		this.connectToSocket();
	}

	componentWillUnmount() {
			this.socket?.disconnect();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!!window.store.profile.status) {
			if (!this.socket?.connected) {
				this.connectToSocket()
			}
		} else {
			this.socket?.disconnect();
		}
  }

	connectToSocket() {
		if (!!window.store.profile.status) {
				this.socket = io(
					// "http://localhost:3080", 
					{
					auth: {
						token: localStorage.getItem("token")
					}
				});

				this.socket.on('assembly-transcription-webhook-success', ({message, id}) => {
					new Audio(notifySound).play();
					toast.success((t) => (
						<span>
							{message},&nbsp;
								<Link 
									to={`/ai/audio/transcriber?tool=${id}`} 
									style={{color: 'blue', textDecoration: 'underline'}}
									onClick={() => toast.dismiss(t.id)}
								>
									click here
								</Link>
							!
						</span>
					), {duration: Infinity})
				});

				this.socket.on('assembly-transcription-webhook-error', (data) => {
					toast.error(data, {duration: 4000})
			});
		}
}

  render() {
    return (
    <ThemeProvider theme={colors}>
        <Provider store={window.store}>
          <Router>
						<Toaster 
							position="top-right"
						/>
            {window.store.redirect ? <Redirect to={window.store.redirect} /> : null }
            {window.store.isLoggedIn ? <>
              {window.store.profile.status ? <>  {/*  Logged in with plan */}

                <Switch>
                  <Route path="/writing/document"><div/></Route>
                  <Route component={Header} />
                </Switch>
                <Switch>
                  <Route path="/" exact component={Dashboard} />
                  <Route path="/search" exact component={Search} />
                  <Route path="/ai/" >
                    <Switch>
                      <Route path="/ai/advisors" component={Chat} />
                      <Route component={Tool} />
                    </Switch>
                  </Route>
                  <Route path="/my-profile" component={Profile} />
                  <Route path="/signup/failed" component={Profile} />
                  <Route path="/signup/success" component={LoginSuccess} />
                  <Route path="/signup/success" component={LoginSuccess} />
                </Switch>

              </> : <> {/* Logged in but no plan */}
                
                <Switch>
                  <Route path="/signup/success" component={LoginSuccess} />
                  <Route>
                    <Pricing />
                  </Route>
                  </Switch>

              </>} </> : <> {/*  Not Logged In */}

                  <Switch>
									<Route path="/forgot-password" component={ForgotPassword} />
                    <Route path="/" exact>
                      <Redirect to="/login" />
                    </Route>
                    <Route path="/" component={Login} />
                  </Switch>

              </>}

          </Router>
        </Provider>
      </ThemeProvider>
    )
  }
}

export default App
