import { ChatAlt2Icon } from '@heroicons/react/solid'

const obj = {
	title: 'Behavioral Health',
	desc: 'Chat with our Behavioral Health AI',
	category: 'Advisor',
	Icon: ChatAlt2Icon,
	permissions: ['user'],
	fromColor: 'gray-500',
	to: '/ai/advisors/behavioral-health',
	api: '/ai/advisors/behavioral-health',
	welcomeMessage: `I can assist you with a wide range of topics related to behavioral health and mental health. Some areas I can help with include:

	1. Understanding mental health conditions: I can provide information on various mental health disorders, their symptoms, causes, and treatment options.
	2. Coping strategies: I can offer guidance on managing stress, anxiety, depression, and other emotional challenges.
	3. Self-care practices: I can provide tips on maintaining good mental health, promoting self-care, and improving overall well-being.
	4. Relationship issues: I can offer advice on communication skills, conflict resolution, and building healthy relationships.
	5. Addiction and substance abuse: I can provide information on addiction, treatment options, and support resources.
	6. Mental health resources: I can help you find local mental health services, support groups, and therapists in your area.
	
	Please keep in mind that while I can provide information and support, it's important to consult with a qualified healthcare professional for a comprehensive evaluation and personalized advice.`,
}

export default obj
