
const Logo = (props) => (
	<svg width="92px" height="92px" viewBox="0 0 92 92" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Style-Guide" transform="translate(-135.000000, -34.000000)" fill-rule="nonzero">
            <g id="Navix_icon_light-Copy" transform="translate(135.000000, 34.000000)">
                <polygon id="Path" fill="#E8E4E1" points="0 45.73324 25.00891 60.01812 16.84958 45.73324 25.00891 31.44696"></polygon>
                <polygon id="Path" fill="#A18292" points="60.01766 25.00937 45.73137 0 31.44696 25.00937 45.73137 16.84958"></polygon>
                <polygon id="Path" fill="#9DB6AF" points="31.44696 66.45524 45.73137 91.46461 60.01766 66.45524 45.73137 74.61503"></polygon>
                <polygon id="Path" fill="#C2986B" points="66.4557 31.44696 74.61503 45.73324 66.4557 60.01812 91.46461 45.73324"></polygon>
            </g>
        </g>
    </g>
	</svg>

)

export default Logo